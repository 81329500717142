import { Button, Input, Modal, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CustomMessage } from './AlertToast'
import MarkdownRenderer from './MarkdownRender'
import { Message } from './MessageList'
import './MessageHeader.css'

function MessageHeader({
    chatTitle,
    setChatTitle,
    threadID,
    fromLanding,
    setShowHistory,
    showHistory,
    setDocMessages,
    setDocThreadID,
    setNewfiThreadID,
    setChatThreadID,
    setCurrentMessageID,
    greetingMessage,
    isMobile,
    setMobileAIChat,
}) {
    const [isEditing, setIsEditing] = useState(false)
    const [editableTitle, setEditableTitle] = useState(chatTitle)
    const [showShareModal, setShowShareModal] = useState(false)
    const [loadingShare, setLoadingShare] = useState(false)
    const [loadingSent, setLoadingSent] = useState(false)
    const [chatMessages, setChatMessages] = useState([])
    const [emailToShare, setEmailToShare] = useState('')

    const dispatch = useDispatch()

    const handleDoubleClick = useCallback(() => {
        setIsEditing(true)
    }, [])

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            setIsEditing(false)
            setChatTitle(editableTitle)
            customizeChatTitle()
        }
    }, [editableTitle])

    const handleChange = useCallback((event) => {
        setEditableTitle(event.target.value)
    }, [])

    const customizeChatTitle = useCallback(async () => {
        const token = sessionStorage.getItem('ZeitroA')
        try {
            const response = await fetch('/los/mortgagegpt/customchattitle', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
                body: JSON.stringify({
                    ThreadID: threadID,
                    ChatTitle: editableTitle,
                }),
            })

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
            setChatTitle(editableTitle)
        } catch (error) {
            console.error('Fetch Error:', error)
        }
    }, [editableTitle, threadID])

    const handleShare = useCallback(async () => {
        setLoadingShare(true)

        const token = sessionStorage.getItem('ZeitroA')
        const body = { ThreadID: threadID }
        try {
            const response = await fetch('/los/mortgagegpt/sharechat', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.error(`Request failed. Status code: ${response.status}`)
                setLoadingShare(false)
                alert('Something went wrong, please try again later.')
                return
            }

            const json = await response.json()
            const history = json.chat_history.map((msg) => {
                if (msg.role === 'user') {
                    return {
                        text: msg.content,
                        sender: 'user',
                    }
                }

                // Remove invalid reference links
                const answer = msg.content.replace(
                    /\[([1-9]\d*(?:\s*,\s*[1-9]\d*)*)\]/g,
                    (match, numbers) => {
                        // Only filter refs if we have valid locations
                        const hasValidLocations = Array.isArray(msg.locations) && msg.locations.length > 0
                        if (hasValidLocations) {
                            const refs = numbers.split(',').map((n) => parseInt(n.trim()))
                            // Get unique page numbers from quoteLocations
                            const uniquePages = new Set(
                                msg.locations.map(([page]) => page)
                            )
                            const maxValidRef = uniquePages.size
                            const validRefs = refs.filter((ref) => ref <= maxValidRef)
                            return validRefs.length > 0 ? match : ''
                        }

                        // If no valid locations, keep original reference
                        return match
                    }
                )

                return {
                    text: (
                        <MarkdownRenderer answer={answer} />
                    ),
                    sender: 'bot',
                }
            })
            setChatMessages(history)
            setLoadingShare(false)
            setShowShareModal(true)
        } catch (err) {
            setLoadingShare(false)
            console.error('Fetch Error:', err)
        }
    }, [threadID])

    const copyLinkToClipboard = useCallback(async () => {
        try {
            const url =
                window.location.origin + '/mortgagegpt/sharechat/' + threadID
            await navigator.clipboard.writeText(url)
            CustomMessage.success('Link copied to clipboard')
        } catch (err) {
            console.error('Copy link to clipboard failed: ', err)
        }
    }, [threadID])

    const hideChat = useCallback(async () => {
        const token = sessionStorage.getItem('ZeitroA')
        const body = { ThreadID: threadID }
        try {
            const response = await fetch('/los/mortgagegpt/hidechat', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.error(
                    'Looks like there was a problem. Status Code: ' +
                        response.status
                )
                return
            }
        } catch (err) {
            console.error('Fetch Error :-S', err)
        }
    }, [threadID])

    const handleHide = useCallback(() => {
        hideChat()
        window.location.href = '/app/mortgagegpt'
    }, [])

    const sendShare = useCallback(async () => {
        setLoadingSent(true)

        const token = sessionStorage.getItem('ZeitroA')
        const body = { Email: emailToShare, ThreadID: threadID }
        try {
            const response = await fetch('/los/mortgagegpt/sendshareemail', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.error(
                    'Looks like there was a problem. Status Code: ' +
                        response.status
                )
                setLoadingSent(false)
                return
            }

            setLoadingSent(false)
            setShowShareModal(false)
            alert('Email sent successfully')
        } catch (err) {
            setLoadingSent(false)
            console.error('Fetch Error :-S', err)
        }
    }, [emailToShare, threadID])

    if (isMobile) {
        return (
            <div className="welcome-guideline-header">
                <div className="chat-title">{chatTitle || 'Chat'}</div>
                <div style={{ display: 'flex', gap: '5px' }}>
                    {!fromLanding && (
                        <img
                            src="/images/chat-history.svg"
                            alt="Chat history"
                            onClick={() => setShowHistory(!showHistory)}
                        />
                    )}
                    <img
                        src="/images/new-chat.svg"
                        alt="New chat"
                        onClick={() => {
                            dispatch({ type: 'SHOW_PDF', data: true })
                            dispatch({
                                type: 'CHANGE_PDF',
                                data: undefined,
                            })
                            setDocMessages([greetingMessage])
                            setChatTitle('')
                            setDocThreadID('')
                            setNewfiThreadID('')
                            setChatThreadID('')
                            setCurrentMessageID('')
                            setMobileAIChat(true)
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="message-header bottomBorder">
            {/* Message header actions */}
            <div className="d-flex align-items-center">
                {isEditing ? (
                    <>
                        <input
                            className="flex-grow-1"
                            type="text"
                            value={editableTitle}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoFocus
                        />
                        <button
                            className="cancel-button"
                            onClick={() => {
                                setIsEditing(false)
                                setChatTitle(chatTitle)
                            }}
                        >
                            Cancel
                        </button>
                    </>
                ) : (
                    <>
                        {!fromLanding && (
                            <>
                                {loadingShare ? (
                                    <Spin className="mr-2" />
                                ) : (
                                    <img
                                        className="action-icon"
                                        src="/images/share.png"
                                        alt="Share"
                                        onClick={() => handleShare()}
                                    />
                                )}
                                <div className="chat-title">
                                    {chatTitle || 'Chat'}
                                </div>
                                {!isEmpty(threadID) && (
                                    <div
                                        style={{
                                            borderLeft: '1px solid #ECECEC',
                                            paddingLeft: '4px',
                                        }}
                                    >
                                        <img
                                            className="action-icon"
                                            src="/images/edit.png"
                                            onClick={handleDoubleClick}
                                        />
                                        <img
                                            className="action-icon"
                                            src="/images/delete.png"
                                            alt="Delete"
                                            onClick={() => handleHide()}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>

            {/* Chat header actions */}
            {!showHistory && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!fromLanding && (
                        <div
                            style={{ cursor: 'pointer', marginRight: '27px' }}
                            onClick={() => {
                                setShowHistory(!showHistory)
                            }}
                        >
                            <img src="/images/close.svg"></img>
                        </div>
                    )}
                    <Button
                        className="new-chat-button"
                        onClick={() => {
                            dispatch({ type: 'SHOW_PDF', data: true })
                            dispatch({
                                type: 'CHANGE_PDF',
                                data: undefined,
                            })
                            setDocMessages([greetingMessage])
                            setChatTitle('')
                            setDocThreadID('')
                            setNewfiThreadID('')
                            setChatThreadID('')
                            setCurrentMessageID('')
                        }}
                        type="outline"
                    >
                        New chat
                    </Button>
                </div>
            )}

            <Modal
                className="share-chat-modal"
                title={<div className="share-chat-modal-title">Share chat</div>}
                open={showShareModal}
                onCancel={() => setShowShareModal(false)}
                footer={
                    <>
                        <a
                            className="copy-link-button"
                            onClick={() => copyLinkToClipboard()}
                        >
                            <img
                                src="/images/copy-link.svg"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                }}
                            />{' '}
                            Copy link
                        </a>
                        <Button
                            type="primary"
                            disabled={isEmpty(emailToShare)}
                            loading={loadingSent}
                            onClick={() => sendShare()}
                        >
                            Submit
                        </Button>
                    </>
                }
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
            >
                <div className="share-chat-content">
                    <div className="section-title">Email address</div>
                    <Input
                        placeholder="Enter email address"
                        onChange={(e) => setEmailToShare(e.target.value)}
                    />
                    <div className="section-title">Preview</div>
                    <div className="messages-preview">
                        {chatMessages.map(({ text, sender }, index) => (
                            <Message key={index} text={text} sender={sender} />
                        ))}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default MessageHeader
