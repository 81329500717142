import {
    BulbOutlined,
    QuestionCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons'
import {
    Button,
    Col,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Tooltip,
    Typography,
} from 'antd'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { commaize } from '../../../Common'
import ScrollBar from '../../../Common/components/ScrollBar'
import './AffordabilityModal.css'

const { Text, Paragraph } = Typography

const DEFAULT_LOAN_TERM = 30

const PROPERTY_USAGE_LIST = [
    { value: 'primary', label: 'Primary residence' },
    { value: 'secondHome', label: 'Second home' },
    { value: 'investment', label: 'Investment' },
]

const PROPERTY_TYPE_LIST = [
    { value: 'singlefamily', label: 'Single family/Townhouse' },
    { value: 'multifamily', label: 'Multi family' },
    { value: 'condo', label: 'Condo' },
]

const FICO_SCORE_LIST = [
    { value: 760, label: '740+' },
    { value: 740, label: '721-740' },
    { value: 720, label: '701-720' },
    { value: 700, label: '681-700' },
    { value: 680, label: '661-680' },
    { value: 660, label: '641-660' },
    { value: 640, label: '621-640' },
    { value: 620, label: 'Less than 621' },
]

function AffordabilityModal({
    open,
    onClose,
    isFromLanding,
    customerid,
    loid,
    appLink,
    companyLink,
}) {
    const [annualIncome, setAnnualIncome] = useState(96000)
    const [monthlyDebt, setMonthlyDebt] = useState(1500)
    const [creditScore, setCreditScore] = useState(760)
    const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM)
    const [downPayment, setDownPayment] = useState(100000)
    const [occupancyType, setOccupancyType] = useState('primary')
    const [propertyType, setPropertyType] = useState('singlefamily')
    const [unitNumber, setUnitNumber] = useState(1)
    const [taxes, setTaxes] = useState(true)
    const [hoaDue, setHOADue] = useState(0)
    const [propertyTax, setPropertyTax] = useState(1.25)
    const [homeInsurance, setHomeInsurance] = useState(0)
    const [pmi, setPmi] = useState(0.5)
    const [detailExpand, setDetailExpand] = useState(true)
    const [customerUrl, setCustomerUrl] = useState('https://app.zeitro.com')

    const isSection = useMemo(() => {
        return open === undefined && onClose === undefined
    }, [open, onClose])

    const getCustomerUrl = useCallback(() => {
        fetch('/data/customerurls/' + customerid)
            .then((res) => {
                if (!res.ok) {
                    console.warn('Get customer url failed: ', res)
                    throw Error(res.statusText)
                }
                return res.json()
            })
            .then((res) => {
                if (window.location.hostname.includes('localhost')) {
                    setCustomerUrl('http://localhost:3000')
                } else {
                    setCustomerUrl(res.Prodapp)
                }
            })
            .catch((err) => {
                console.error('Get customer url failed: ', err)
            })
    }, [customerid])

    useEffect(() => {
        getCustomerUrl()
    }, [])

    const approvedNowUrl = useMemo(() => {
        console.log('customerid', customerid, appLink, companyLink, isFromLanding, 'customerUrl', customerUrl)
        if (typeof customerid === 'undefined') return ''
        let url = `${customerUrl}/services/preapp?customerid=${customerid}`
        if (typeof loid !== 'undefined') {
            url = `${customerUrl}/services/preapp?customerid=${customerid}&loid=${loid}`
        }
        if (isFromLanding) {
            if (customerid === 'gmcc') {
                if (!isEmpty(appLink)) {
                    url = appLink
                } else {
                    url = companyLink
                }
            }
            if (!isEmpty(appLink)) {
                url = appLink
            }
        }
        return url
    }, [appLink, companyLink, customerUrl, isFromLanding])

    const calcMonthlyInterestRate = useCallback((creditScore) => {
        if (creditScore <= 600) return undefined
        switch (true) {
            case creditScore > 740 && creditScore <= 760:
                return 5.75
            case creditScore > 720 && creditScore <= 740:
                return 6.125
            case creditScore > 700 && creditScore <= 720:
                return 6.25
            case creditScore > 680 && creditScore <= 700:
                return 6.375
            case creditScore > 660 && creditScore <= 680:
                return 6.5
            case creditScore > 640 && creditScore <= 660:
                return 6.725
            case creditScore > 620 && creditScore <= 640:
                return 7.125
            case creditScore == 620:
                return 7.25
            default:
                return 5.75
        }
    }, [])

    const [estimatedRate, setEstimatedRate] = useState(
        calcMonthlyInterestRate(760)
    )
    const [incomeTerm, setIncomeTerm] = useState('yearly')

    const getIncome = useCallback(() => {
        if (incomeTerm === 'yearly') return annualIncome / 12
        return annualIncome
    }, [incomeTerm, annualIncome])

    const handleTaxesChange = () => {
        setTaxes(!taxes)
        if (!taxes) {
            setHOADue(0)
            setPropertyTax(1.25)
            setHomeInsurance(0)
            setPmi(0.5)
        } else {
            setHOADue(0)
            setPropertyTax(0)
            setHomeInsurance(0)
            setPmi(0)
        }
    }

    const [maxLTV, setMaxLTV] = useState(97)
    const [indexPayments, setIndexPayments] = useState(0)
    const [curPrice, setCurPrice] = useState({
        monthlyPayment: 0,
        homePrice: 0,
        mortgagePayment: 0,
        loanAmount: 0,
        ltv: 0,
        dti: 0,
        overLTV: false,
        overJumbo: false,
    })

    const getMaxConvLTV = useCallback(() => {
        let ltv = -1
        if (occupancyType === 'primary' && unitNumber === 1) {
            ltv = 97
        } else if (occupancyType === 'primary' && unitNumber === 2) {
            ltv = 85
        } else if (
            occupancyType === 'primary' &&
            (unitNumber === 3 || unitNumber === 4)
        ) {
            ltv = 75
        } else if (occupancyType === 'secondHome' && unitNumber === 1) {
            ltv = 90
        } else if (occupancyType === 'investment' && unitNumber === 1) {
            ltv = 85
        } else if (
            occupancyType === 'investment' &&
            (unitNumber === 2 || unitNumber === 3 || unitNumber === 4)
        ) {
            ltv = 75
        }
        return ltv
    }, [occupancyType, unitNumber])

    const getMaxJumboLTV = useCallback(() => {
        let ltv = -1
        if (occupancyType === 'primary' && unitNumber === 1) {
            ltv = 90
        } else if (
            occupancyType === 'primary' &&
            (unitNumber === 2 || unitNumber === 3 || unitNumber === 4)
        ) {
            ltv = 80
        } else {
            ltv = 80
        }
        return ltv
    }, [occupancyType, unitNumber])

    const loanLimit = useMemo(() => 806500, [])

    const getHousePriceByLTV = useCallback(
        (maxConvLTV, maxJumboLTV) => {
            let hpConv = downPayment / (1 - maxConvLTV * 0.01)
            if (hpConv * maxConvLTV * 0.01 > loanLimit) {
                hpConv = loanLimit + downPayment
            }

            const hpJumbo = downPayment / (1 - maxJumboLTV * 0.01)

            if (hpConv > hpJumbo) {
                setMaxLTV(maxConvLTV)
                return hpConv
            }
            setMaxLTV(maxJumboLTV)
            return hpJumbo
        },
        [loanLimit, downPayment]
    )

    useEffect(() => {
        const maxConvLTV = getMaxConvLTV()
        const maxJumboLTV = getMaxJumboLTV()
        const housePriceLTV = getHousePriceByLTV(maxConvLTV, maxJumboLTV)

        const possiblePayments = {}
        const counts = []

        if (annualIncome > 0 && downPayment > 0 && creditScore >= 620) {
            const n = loanTerm * 12
            const rate = (estimatedRate * 0.01) / 12

            for (let dti = 20; dti <= 45; dti++) {
                const monthlyPay = getIncome() * dti * 0.01 - monthlyDebt
                const amorFactor =
                    (rate * Math.pow(1 + rate, n)) / (Math.pow(1 + rate, n) - 1)

                let loanAmt =
                    (monthlyPay -
                        hoaDue / 12 -
                        homeInsurance / 12 -
                        ((propertyTax * 0.01) / 12) * downPayment) /
                    (amorFactor + (propertyTax * 0.01) / 12)

                let housePriceDTI = loanAmt + downPayment
                let finalHousePrice = housePriceDTI
                if (downPayment < finalHousePrice * 0.2) {
                    loanAmt =
                        (monthlyPay -
                            hoaDue / 12 -
                            homeInsurance / 12 -
                            ((propertyTax * 0.01) / 12) * downPayment) /
                        (amorFactor +
                            (propertyTax * 0.01) / 12 +
                            (pmi * 0.01) / 12)

                    housePriceDTI = loanAmt + downPayment
                    finalHousePrice = housePriceDTI
                }

                let overLTV = false
                let overJumbo = false
                if (housePriceDTI > housePriceLTV) {
                    overLTV = true
                    finalHousePrice = housePriceLTV
                    loanAmt = finalHousePrice - downPayment
                }

                if (loanAmt > 5000000) {
                    overJumbo = true
                    finalHousePrice = 5000000 + downPayment
                    loanAmt = 5000000
                }

                const mp = loanAmt * amorFactor
                let finalMonthlyPay =
                    mp +
                    (finalHousePrice * propertyTax * 0.01) / 12 +
                    hoaDue / 12 +
                    homeInsurance / 12
                if (downPayment < finalHousePrice * 0.2) {
                    finalMonthlyPay =
                        finalMonthlyPay + (loanAmt * pmi * 0.01) / 12
                }

                if (finalMonthlyPay > 0 && finalHousePrice > 0) {
                    possiblePayments[dti] = {
                        monthlyPayment: finalMonthlyPay,
                        homePrice: finalHousePrice,
                        mortgagePayment: mp,
                        loanAmount: loanAmt,
                        ltv: Math.round((100 * loanAmt) / finalHousePrice),
                        dti: dti,
                        overLTV: overLTV,
                        overJumbo: overJumbo,
                    }
                    counts.push(dti)
                }
            }

            const i = counts.length - 1 // set dti default to 45%
            setIndexPayments(counts[i])
            const curPay = possiblePayments[counts[i]]
            if (curPay) {
                setCurPrice(curPay)
            }
        }
    }, [
        annualIncome,
        incomeTerm,
        monthlyDebt,
        downPayment,
        loanTerm,
        creditScore,
        estimatedRate,
        hoaDue,
        homeInsurance,
        propertyTax,
        propertyType,
        unitNumber,
        occupancyType,
        pmi,
    ])

    const renderLeftForm = () => {
        return (
            <Col span={13}>
                <Text
                    className="basic-info-title"
                    style={{ marginBottom: '8px' }}
                >
                    Basic Information
                </Text>

                <Row gutter={[24, 20]}>
                    {/* Annual Gross Income */}
                    <Col span={12}>
                        <label className="form-label">
                            Annual Gross Income
                        </label>
                        <ScrollBar
                            max={3000000}
                            min={1}
                            step={100}
                            initVal={annualIncome}
                            hideSlider={true}
                            scrollChangeValue={(value) =>
                                setAnnualIncome(value)
                            }
                            style={{
                                width: '100%',
                                height: '44px',
                                borderRadius: '4px',
                                border: '1px solid #D9D9D9',
                            }}
                        />
                    </Col>

                    {/* Monthly Debt */}
                    <Col span={12}>
                        <label className="form-label">Monthly Debt</label>
                        <ScrollBar
                            max={getIncome() * 0.6}
                            min={1}
                            step={100}
                            initVal={monthlyDebt}
                            hideSlider={true}
                            scrollChangeValue={(value) => setMonthlyDebt(value)}
                            style={{
                                width: '100%',
                                height: '44px',
                                borderRadius: '4px',
                                border: '1px solid #D9D9D9',
                            }}
                        />
                    </Col>

                    {/* FICO Score */}
                    <Col span={12}>
                        <div className="fico-score-container">
                            <label
                                className="form-label"
                                style={{ margin: 0, lineHeight: 1.7 }}
                            >
                                FICO Score
                            </label>
                            <Tooltip
                                title="Your FICO score, similar to a credit score, is a three-digit number indicating your creditworthiness, typically falling between 300 and 850. It plays an important role in determining the loan terms and interest rates you may be eligible for. You have several options for obtaining your FICO score, including checking your credit card statements, requesting it from major credit reporting agencies, or utilizing credit monitoring services."
                                placement="right"
                            >
                                <QuestionCircleOutlined className="question-icon" />
                            </Tooltip>
                        </div>
                        <Select
                            className="w-full"
                            value={creditScore}
                            onChange={(value) => {
                                setCreditScore(value)
                                setEstimatedRate(calcMonthlyInterestRate(value))
                            }}
                            options={FICO_SCORE_LIST}
                        />
                    </Col>

                    {/* Estimated Rate */}
                    <Col span={12}>
                        <label className="form-label">Estimated Rate</label>
                        <Input
                            suffix="%"
                            value={estimatedRate}
                            onChange={(e) => setEstimatedRate(e.target.value)}
                            className="form-input"
                        />
                    </Col>

                    {/* Loan Term */}
                    <Col span={12}>
                        <label className="form-label">Loan Term</label>
                        <Select
                            className="w-full"
                            value={loanTerm}
                            onChange={(value) => setLoanTerm(value)}
                            options={[
                                { value: 30, label: '30 year' },
                                { value: 25, label: '25 year' },
                                { value: 20, label: '20 year' },
                                { value: 15, label: '15 year' },
                                { value: 10, label: '10 year' },
                            ]}
                        />
                    </Col>

                    {/* Down Payment */}
                    <Col span={12}>
                        <label className="form-label">Down Payment</label>
                        <ScrollBar
                            max={1000000}
                            min={0}
                            step={1000}
                            initVal={downPayment}
                            hideSlider={true}
                            scrollChangeValue={(value) => setDownPayment(value)}
                            style={{
                                width: '100%',
                                height: '40px',
                                borderRadius: '4px',
                                border: '1px solid #CFD4D9',
                            }}
                        />
                    </Col>

                    {/* Property Usage */}
                    <Col span={12}>
                        <label className="form-label">Property Usage</label>
                        <Select
                            className="w-full"
                            value={occupancyType}
                            onChange={(value) => setOccupancyType(value)}
                            options={PROPERTY_USAGE_LIST}
                        />
                    </Col>

                    {/* Property Type */}
                    <Col span={12}>
                        <label className="form-label">Property Type</label>
                        <Select
                            className="w-full"
                            value={propertyType}
                            onChange={(value) => setPropertyType(value)}
                            options={PROPERTY_TYPE_LIST}
                        />
                    </Col>

                    {/* Multi Family Unit */}
                    {propertyType === 'multifamily' && (
                        <Col span={12}>
                            <label className="form-label">
                                Multi Family Units
                            </label>
                            <Select
                                className="w-full"
                                value={unitNumber}
                                defaultValue={2}
                                onChange={(value) =>
                                    setUnitNumber(parseInt(value))
                                }
                                options={[
                                    { value: 1, label: '1' },
                                    { value: 2, label: '2' },
                                    { value: 3, label: '3' },
                                    { value: 4, label: '4' },
                                ]}
                            />
                        </Col>
                    )}

                    {/* Tax, insurance and fees section */}
                    <Col span={24}>
                        <div className="tax-fees-container">
                            <div>
                                <Text className="tax-fees-title">
                                    Tax, insurance and fees
                                </Text>
                                <Text className="tax-fees-description">
                                    {taxes ? 'Including' : 'Excluding'} tax, fee
                                    and insurance
                                </Text>
                            </div>
                            <Switch
                                checked={taxes}
                                onChange={handleTaxesChange}
                            />
                        </div>
                    </Col>

                    {taxes && (
                        <>
                            {/* Homeowners Insurance */}
                            <Col span={12}>
                                <label
                                    className="form-label"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    Homeowners Insurance
                                    <span className="taxes-period">/year</span>
                                </label>
                                <Input
                                    value={homeInsurance}
                                    onChange={(e) =>
                                        setHomeInsurance(
                                            e.target.value === ''
                                                ? ''
                                                : e.target.value
                                        )
                                    }
                                    prefix="$"
                                    type="number"
                                    min={0}
                                    className="form-input"
                                />
                            </Col>

                            {/* Property Tax */}
                            <Col span={12}>
                                <label
                                    className="form-label"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    Property Tax
                                    <span className="taxes-period">/year</span>
                                </label>
                                <Input
                                    value={propertyTax}
                                    onChange={(e) =>
                                        setPropertyTax(
                                            e.target.value === ''
                                                ? ''
                                                : e.target.value
                                        )
                                    }
                                    suffix="%"
                                    className="form-input"
                                />
                            </Col>

                            {/* HOA Fees */}
                            <Col span={12}>
                                <label
                                    className="form-label"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    HOA Fees
                                    <span className="taxes-period">/year</span>
                                </label>
                                <Input
                                    value={hoaDue}
                                    onChange={(e) =>
                                        setHOADue(
                                            e.target.value === ''
                                                ? ''
                                                : e.target.value
                                        )
                                    }
                                    prefix="$"
                                    type="number"
                                    min={0}
                                    className="form-input"
                                />
                            </Col>

                            {/* PMI */}
                            <Col span={12}>
                                <label
                                    className="form-label"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    PMI
                                    <span className="taxes-period">/year</span>
                                </label>
                                <Input
                                    value={pmi}
                                    onChange={(e) =>
                                        setPmi(
                                            e.target.value === ''
                                                ? ''
                                                : e.target.value
                                        )
                                    }
                                    suffix="%"
                                    className="form-input"
                                    disabled={!taxes}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </Col>
        )
    }

    const renderRightCard = () => {
        return (
            <Col span={11}>
                <div className="result-card">
                    <div className="result-container">
                        <p className="result-preview-text">
                            You can afford a house up to:
                        </p>
                        <h3 className="result-preview-amount">
                            ${commaize(curPrice.homePrice.toFixed(0))}
                        </h3>

                        {/* LTV Alert */}
                        {curPrice.overLTV && (
                            <div className="alert-message">
                                <div className="alert-content">
                                    <BulbOutlined className="alert-icon bulb" />
                                    <span>
                                        Your current affordable house price is
                                        limited by maximum LTV requirements or
                                        loan limits, try increasing the down
                                        payment to get a higher house price.
                                    </span>
                                </div>
                            </div>
                        )}

                        {/* Jumbo Alert */}
                        {curPrice.overJumbo && (
                            <div className="alert-message">
                                <div className="alert-content">
                                    <WarningOutlined className="alert-icon warning" />
                                    <span>
                                        Currently we could provide a purchase
                                        loan as high as $5,000,000
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="result-divider"></div>
                    </div>

                    {/* Payment Details Row */}
                    <div className="payment-details-container">
                        <div className="payment-detail-item">
                            <p className="payment-detail-label">
                                Monthly payment
                            </p>
                            <Col span={8}>
                                <p className="payment-detail-value">
                                    ${commaize(curPrice.monthlyPayment)}
                                    <img
                                        src={
                                            !detailExpand
                                                ? '/images/showDetail-Expand.png'
                                                : '/images/showDetail-Close.png'
                                        }
                                        style={{
                                            maxHeight: '20px',
                                            maxWidth: '20px',
                                            marginLeft: '8px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            setDetailExpand(!detailExpand)
                                        }
                                    />
                                </p>
                            </Col>
                        </div>
                        <div className="payment-detail-item">
                            <p className="payment-detail-label">Down payment</p>
                            <p className="payment-detail-value">
                                ${commaize(downPayment)}
                            </p>
                        </div>
                        <div className="payment-detail-item">
                            <p className="payment-detail-label">Loan amount</p>
                            <p className="payment-detail-value">
                                ${commaize(curPrice.loanAmount.toFixed(0))}
                            </p>
                        </div>
                    </div>

                    {detailExpand && (
                        <div className="house-price-details-wrap">
                            <div className="house-price-details">
                                <div>Mortgage payment</div>
                                <div className="sub-value">
                                    $
                                    {commaize(
                                        curPrice.mortgagePayment.toFixed(0)
                                    )}
                                </div>
                            </div>
                            <div className="house-price-details">
                                <div>Property tax</div>
                                <div className="sub-value">
                                    {taxes
                                        ? `$${commaize(
                                              (
                                                  (curPrice.homePrice *
                                                      propertyTax *
                                                      0.01) /
                                                  12
                                              ).toFixed(0)
                                          )}`
                                        : '$---'}
                                </div>
                            </div>
                            <div className="house-price-details">
                                <div>Homeowner insurance</div>
                                <div className="sub-value">
                                    {taxes
                                        ? `$${commaize(
                                              (homeInsurance / 12).toFixed(0)
                                          )}`
                                        : '$---'}
                                </div>
                            </div>
                            <div className="house-price-details">
                                <div>HOA fees</div>
                                <div className="sub-value">
                                    {taxes
                                        ? `$${commaize(
                                              (hoaDue / 12).toFixed(0)
                                          )}`
                                        : '$---'}
                                </div>
                            </div>
                            <div className="house-price-details">
                                <div>PMI</div>
                                <div className="sub-value">
                                    {taxes
                                        ? curPrice.ltv > 80
                                            ? `$${commaize(
                                                  (
                                                      (curPrice.loanAmount *
                                                          pmi *
                                                          0.01) /
                                                      12
                                                  ).toFixed(0)
                                              )}`
                                            : 'No Charge'
                                        : '$---'}
                                </div>
                            </div>
                        </div>
                    )}

                    <p className="dti-explanation">
                        Your affordability is calculated by a common
                        Debt-to-income ratio (DTI) of 45%.{' '}
                        <Tooltip
                            title="Your debt-to-income ratio (DTI) is all your monthly debt payments divided by your gross monthly income. This number is one way lenders measure your ability to manage the monthly payments to repay the money you plan to borrow."
                            placement="top"
                        >
                            <QuestionCircleOutlined className="question-icon" />
                        </Tooltip>
                    </p>

                    <Button
                        type="primary"
                        className="approve-button"
                        onClick={() => window.open(approvedNowUrl, '_blank')}
                    >
                        Get Approved Now
                    </Button>
                </div>
            </Col>
        )
    }

    if (isSection) {
        return (
            <div
                className="affordability-modal"
                style={{
                    backgroundColor: 'white',
                    padding: '30px 30px 41px 30px',
                }}
            >
                <div
                    style={{
                        color: '#081265',
                        fontSize: '32px',
                        fontWeight: '500',
                        lineHeight: '38.73px',
                        marginBottom: '27px',
                    }}
                >
                    Affordability calculator
                </div>

                <Paragraph
                    style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#222222',
                        marginBottom: '44px',
                    }}
                >
                    Use our Affordability Calculator to estimate the loan amount
                    you qualify for, based on your income, debts, and down
                    payment. Take control of your mortgage journey with a clear
                    view of your financial options.
                </Paragraph>

                <Row gutter={40} align="middle">
                    {/* Left Form Section */}
                    {renderLeftForm()}

                    {/* Right Result Card */}
                    {renderRightCard()}
                </Row>
            </div>
        )
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={null}
            width={932}
            centered
            className="affordability-modal"
        >
            <div className="modal-container">
                <Text className="modal-title">How Much Can You Afford?</Text>
                <Paragraph className="modal-description">
                    Use our Affordability Calculator to estimate the loan amount
                    you qualify for, based on your income, debts, and down
                    payment. Take control of your mortgage journey with a clear
                    view of your financial options.
                </Paragraph>

                <Row gutter={40} align="middle">
                    {/* Left Form Section */}
                    {renderLeftForm()}

                    {/* Right Result Card */}
                    {renderRightCard()}
                </Row>
            </div>
        </Modal>
    )
}

export default AffordabilityModal
