import React, { useState } from 'react'
import { Modal, Divider, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import * as com from '../../Common'
import './PaywallModal.css'
export default function PaywallModal(props) {
    const {
        open, onCancel
    } = props
    const modalCancel = () => {
        onCancel()
    }

    const serviceList = [
        {
            key: '1',
            text: 'Unlimited 1003 PDF & FNM 3.4 exports'
        },
        {
            key: '2',
            text: 'Unlimited GuidelineGPT'
        },
        {
            key: '3',
            text: 'Call reports'
        },
        {
            key: '3',
            text: 'Customizable personal website and more...'
        }
    ]
    return (
        <>
            <Modal
                className="paywall-modal"
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.80)' }}
                maskClosable={true}
                width={566}
                open={open}
                centered={true}
                title=""
                onCancel={modalCancel}
                footer={null}
            >
                <div className='paywall-title'>Upgrade to Originator</div>
                <div className='paywall-description'>You’ve hit the limit for free chats. You can elevate your potential to new heights with our Originator plan.</div>
                <div className='paywall-price'><span className='amount'>$99</span><span className='unit'>/mo per loan officer</span></div>
                <div className='paywall-divider'></div>
                <div className='paywall-service-list'>
                    {
                        serviceList.map(item => {
                            return (
                                <div key={item.key} className='paywall-service-item'><img src='/images/paywall-check.svg' /><div className='paywall-service-item-text'>{item.text}</div></div>
                            )
                        })
                    }
                </div>
                <div className='paywall-btn'>
                    <Button className="paywall-text-btn" type='link'>View plan details</Button>
                    <Button className="paywall-btn-primary" type="primary">Get started</Button>
                </div>
                <Divider
                    style={{
                        borderColor: '#D9D9D9'
                    }}
                    >
                    <span style={{ color: '#6E6E70', fontWeight: 400, fontSize: 14 }}>or</span>
                </Divider>
                <div className='paywall-recommend-card'>
                    <div className='paywall-recommend-card-title'>
                        <div className='paywall-recommend-card-title-text'><img src='/images/paywall-recommend.svg' /><span>Refer your friend</span></div>
                        <img style={{width: 20, height: 20}} src='/images/paywall-recommend-link.svg' />
                    </div>
                    <div className='paywall-recommend-card-description'>Earn free Originator features for you and your friend.</div>
                </div>
            </Modal>
        </>
    )
}