import { Button, Typography } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import ChatInput from './ChatInput'
import { MOST_POPULAR_GUIDELINES, OTHER_GUIDELINES } from './constants'
import './WelcomeGuideline.css'

const { Text } = Typography

function WelcomeGuideline({
    showHistory,
    setShowHistory,
    setDocMessages,
    greetingMessage,
    setChatTitle,
    setDocThreadID,
    setNewfiThreadID,
    setChatThreadID,
    setCurrentMessageID,
    isMobile,
    fromLanding,
}) {
    const dispatch = useDispatch()

    const lastUsedGuideline = localStorage.getItem('lastUsedGuideline')
    const sortedGuidelines = useMemo(() => {
        if (!lastUsedGuideline) return MOST_POPULAR_GUIDELINES

        return [...MOST_POPULAR_GUIDELINES].sort((a, b) => {
            if (a.id === lastUsedGuideline) return -1
            if (b.id === lastUsedGuideline) return 1
            return 0
        })
    }, [lastUsedGuideline])

    const handleGuidelineClick = useCallback(
        (item, isPopular = false) => {
            if (isPopular) {
                localStorage.setItem('lastUsedGuideline', item.id)
            }

            dispatch({ type: 'SHOW_PDF', data: false })
            dispatch({ type: 'CHANGE_PDF', data: item.id })
            setShowHistory(false)
        },
        [dispatch, setShowHistory]
    )

    return (
        <>
            {isMobile ? (
                <div className="welcome-guideline-mobile">
                    {/* Header */}
                    <div className="welcome-guideline-header">
                        <div className="chat-title">Chat</div>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            {!fromLanding && (
                                <img
                                    src="/images/chat-history.svg"
                                    alt="Chat history"
                                    onClick={() =>
                                        setShowHistory(!showHistory)
                                    }
                                />
                            )}
                            <img
                                src="/images/new-chat.svg"
                                alt="New chat"
                                onClick={() => {
                                    dispatch({ type: 'SHOW_PDF', data: true })
                                    dispatch({
                                        type: 'CHANGE_PDF',
                                        data: undefined,
                                    })
                                    setDocMessages([greetingMessage])
                                    setChatTitle('')
                                    setDocThreadID('')
                                    setNewfiThreadID('')
                                    setChatThreadID('')
                                    setCurrentMessageID('')
                                }}
                            />
                        </div>
                    </div>

                    {/* Content */}
                    <div className="welcome-guideline-page">
                        {/* Title */}
                        <div className="welcome-guideline-content">
                            <Text className="welcome-guideline-title">
                                Welcome to Guideline GPT
                            </Text>
                        </div>

                        {/* Guidelines */}
                        <div className="guideline-sections-container">
                            {/* Most popular guidelines */}
                            <div>
                                <Text className="guideline-section-title">
                                    Most popular guidelines:
                                </Text>
                                <div className="guideline-wrapper">
                                    {sortedGuidelines.map((item) => (
                                        <div
                                            key={item.id}
                                            className="guideline-item"
                                            onClick={() =>
                                                handleGuidelineClick(item, true)
                                            }
                                        >
                                            <img
                                                className="guideline-item-icon"
                                                src="/images/pdfIcon.svg"
                                                alt="PDF Icon"
                                            />
                                            <span className="guideline-item-text">
                                                {item.value}
                                            </span>
                                            {lastUsedGuideline === item.id && (
                                                <span className="last-used-badge">
                                                    Last used
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Other guidelines */}
                            <div style={{ marginTop: '24px' }}>
                                <Text className="guideline-section-title">
                                    Other guidelines:
                                </Text>
                                <div className="guideline-wrapper">
                                    {OTHER_GUIDELINES.map((item) => (
                                        <div
                                            key={item.id}
                                            className="guideline-item"
                                            onClick={() =>
                                                handleGuidelineClick(
                                                    item,
                                                    false
                                                )
                                            }
                                        >
                                            <img
                                                className="guideline-item-icon"
                                                src="/images/pdfIcon.svg"
                                                alt="PDF Icon"
                                            />
                                            <span className="guideline-item-text">
                                                {item.value}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="welcome-guideline-page">
                    {/* Header */}
                    {showHistory ? (
                        <div style={{ height: '42px' }}></div>
                    ) : (
                        <div className="welcome-guideline-header">
                            {!fromLanding && (
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        marginRight: '12px',
                                    }}
                                    onClick={() => {
                                        setShowHistory(!showHistory)
                                    }}
                                >
                                    <img src="/images/close.svg"></img>
                                </div>
                            )}
                            <Button
                                className="new-chat-button"
                                onClick={() => {
                                    dispatch({ type: 'SHOW_PDF', data: true })
                                    dispatch({
                                        type: 'CHANGE_PDF',
                                        data: undefined,
                                    })
                                    setDocMessages([greetingMessage])
                                    setChatTitle('')
                                    setDocThreadID('')
                                    setNewfiThreadID('')
                                    setChatThreadID('')
                                    setCurrentMessageID('')
                                }}
                                type="outline"
                            >
                                New chat
                            </Button>
                        </div>
                    )}

                    {/* Title */}
                    <div className="welcome-guideline-content">
                        <Text className="welcome-guideline-title">
                            Welcome to Guideline GPT
                        </Text>
                        <Text className="welcome-guideline-subtitle">
                            Select a guideline to get started
                        </Text>
                    </div>

                    <div className="guideline-sections-container">
                        {/* Most popular guidelines */}
                        <div>
                            <Text className="guideline-section-title">
                                Most popular guidelines:
                            </Text>
                            <div className="guideline-wrapper">
                                {sortedGuidelines.map((item) => (
                                    <div
                                        key={item.id}
                                        className="guideline-item"
                                        onClick={() =>
                                            handleGuidelineClick(item, true)
                                        }
                                    >
                                        <img
                                            className="guideline-item-icon"
                                            src="/images/pdfIcon.svg"
                                            alt="PDF Icon"
                                        />
                                        <span className="guideline-item-text">
                                            {item.value}
                                        </span>
                                        {lastUsedGuideline === item.id && (
                                            <span className="last-used-badge">
                                                Last used
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Other guidelines */}
                        <div style={{ marginTop: '24px' }}>
                            <Text className="guideline-section-title">
                                Other guidelines:
                            </Text>
                            <div className="guideline-wrapper">
                                {OTHER_GUIDELINES.map((item) => (
                                    <div
                                        key={item.id}
                                        className="guideline-item"
                                        onClick={() =>
                                            handleGuidelineClick(item, false)
                                        }
                                    >
                                        <img
                                            className="guideline-item-icon"
                                            src="/images/pdfIcon.svg"
                                            alt="PDF Icon"
                                        />
                                        <span className="guideline-item-text">
                                            {item.value}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default WelcomeGuideline
