import React, { useState, useEffect, useRef } from 'react'
import { useImmer } from "use-immer"
import { Form, Input, Button, Col, Row, Modal, Space, Popover, Carousel, Checkbox } from 'antd'
import NewEmailVerification from '../Application/NewEmailVerification'
import "./signup.css"
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import Terms from './terms'
import jwt_decode from 'jwt-decode'
import { useStatsigClient } from "@statsig/react-bindings/src";
import { updateStatsigUser } from "../statsig/statsigMethod"


const validateTeamID = (_, value) => {
    if (!value) return Promise.reject(new Error('Team ID is required!'))
    if (/^[0-9]*$/.test(value)) {
        return Promise.reject(new Error('Team ID cannot be purely numeric. Please include at least one letter.'))
    }
    return Promise.resolve()
}


function PersonalInformation(props) {
    const { signData, back, pageTime } = props
    const [errorInput, setError] = useState(false)
    const [goodtogo, setGoodtogo] = useState(false)
    const [flag, setFlag] = useImmer(true)
    const [showAgreement, setShowAgreement] = useState(false)
    const [form] = Form.useForm()
    const formvalues = Form.useWatch([], form)
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(false)
    const dispatch = useDispatch()
    const { client } = useStatsigClient();

    const [pageTimes, setPageTimes] = useState({
        ...pageTime,
        creatCompanyPage: {
            componentTime: {
                startTime: null,
                totalDuration: 0.0
            },
            inputField: [],
            agreementPopupTime: {
                startTime: null,
                totalDuration: 0.0
            }
        },
    });

    const isFirstRender = useRef(true);
    const previousPageTimes = useRef(null);
    const [currentFocusTime, setCurrentFocusTime] = useState(null);

    useEffect(() => {
        setPageTimes(prev => ({
            ...prev,
            creatCompanyPage: {
                ...prev.creatCompanyPage,
                componentTime: {
                    ...prev.creatCompanyPage.componentTime,
                    startTime: new Date()
                }
            }
        }));
        previousPageTimes.current = new Date();
    }, [])

    useEffect(() => {
        //Update the dwell time of the input email address component
        if (showAgreement === true) {
            const currentTime = new Date();
            const duration = parseFloat(((currentTime - previousPageTimes.current) / 1000.0).toFixed(1));
            setPageTimes(prev => ({
                ...prev,
                creatCompanyPage: {
                    ...prev.creatCompanyPage,
                    componentTime: {
                        ...prev.creatCompanyPage.componentTime,
                        totalDuration: parseFloat((prev.creatCompanyPage.componentTime.totalDuration + duration).toFixed(1))
                    }
                }
            }));
        }
        if (showAgreement === false) {
            const currentTime = new Date();
            if (pageTimes.creatCompanyPage.inputField.length !== 0) { 
                const duration = parseFloat(((currentTime - previousPageTimes.current) / 1000.0).toFixed(1));
                setPageTimes(prev => ({
                    ...prev,
                    creatCompanyPage: {
                        ...prev.creatCompanyPage,
                        agreementPopupTime: {
                            ...prev.creatCompanyPage.agreementPopupTime,
                            totalDuration: parseFloat(((prev.creatCompanyPage.agreementPopupTime.totalDuration || 0) + duration).toFixed(1))
                        }
                    }
                }))
            } else {
                setPageTimes(prev => ({
                    ...prev,
                    creatCompanyPage: {
                        ...prev.creatCompanyPage,
                        agreementPopupTime: {
                            ...prev.creatCompanyPage.agreementPopupTime,
                            startTime: currentTime,
                        }
                    }
                }))
            }
            
            
        }
        previousPageTimes.current = new Date();
    }, [showAgreement]);

    const handleFocus = (e) => {
        const fieldName = e.target.id;
        const fieldValue = e.target.value;

        setCurrentFocusTime(new Date());

        setPageTimes(prev => {
            const existingField = prev.creatCompanyPage.inputField.find(
                field => field.name === fieldName
            );

            if (!existingField) {
                return {
                    ...prev,
                    creatCompanyPage: {
                        ...prev.creatCompanyPage,
                        inputField: [
                            ...prev.creatCompanyPage.inputField,
                            {
                                name: fieldName,
                                value: fieldValue,
                                focusTime: 0,
                                startTime: new Date()
                            }
                        ]
                    }
                };
            }
            return prev;
        });
    };

    const handleBlur = (e) => {
        const fieldName = e.target.id;
        const fieldValue = e.target.value;

        if (currentFocusTime) {
            const endTime = new Date();
            const timeSpent = parseFloat(((endTime - currentFocusTime) / 1000.0).toFixed(1));

            setPageTimes(prev => ({
                ...prev,
                creatCompanyPage: {
                    ...prev.creatCompanyPage,
                    inputField: prev.creatCompanyPage.inputField.map(field => {
                        if (field.name === fieldName) {
                            const currentFocusTime = typeof field.focusTime === 'string'
                                ? parseFloat(field.focusTime)
                                : (field.focusTime || 0);

                            return {
                                ...field,
                                value: fieldValue,
                                focusTime: parseFloat((currentFocusTime + timeSpent).toFixed(1))
                            };
                        }
                        return field;
                    })
                }
            }));
        }

        setCurrentFocusTime(null);
    };

    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(() => {
                setGoodtogo(true)
            })
            .catch(() => {
                setGoodtogo(false)
            })
    }, [form, formvalues])
    useEffect(() => {
        dispatch({ type: "OPEN", data: false })

    }, [])
    const onAcceptAgreement = async () => {
        setLoading(true)
        const currentDate = new Date();
        // console.log(signData)
        // console.log(formvalues)
        try {
            const response = await fetch('/los/createnewcustomer', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(
                    {
                        Email: signData.emailaddress.toLowerCase().trim(),
                        ...formvalues
                    }
                )
            })
            const { Token, Text } = await response.json()

            if (Token !== '') {
                setError(false)
                sessionStorage.setItem("ZeitroA", Token)
                let path = "/app/welcome?signUp=1"
                const payload = jwt_decode(Token)
                updateStatsigUser(client, signData.emailaddress.toLowerCase().trim(), payload.customerid)
                const duration = parseFloat(((currentDate - pageTimes.inputEmailAddressPage.componentTime.startTime) / 1000.0).toFixed(1));
                client.logEvent("sign_up_with_onetime_code", duration, {
                    item_name: "lo_signup",
                    signup_method: "onetime_code",
                    entry_point: "/signup",
                    timestamp: new Date().getTime(),
                    email: signData.emailaddress.toLowerCase().trim(),
                    customerid: payload.customerid,
                    totalDuration: duration,
                    ...pageTimes,
                    creatCompanyPage: {
                        ...pageTimes.creatCompanyPage,
                        agreementPopupTime: {
                            ...pageTimes.creatCompanyPage.agreementPopupTime,
                            totalDuration: parseFloat(
                                (
                                    ((currentDate - previousPageTimes.current) / 1000.0) +
                                    (pageTimes.creatCompanyPage.agreementPopupTime.totalDuration || 0)
                                ).toFixed(1)
                            )
                        }
                    }
                })
                window.location.href = path
            } else {
                if (Text.includes('customers_id_key')) {
                    setError(true)
                    setLoading(false)
                    return
                }
                alert("Something went wrong. Please try again later.")
                back()
            }

        } catch (error) {
            console.log(error)
            alert("Something went wrong. Please try again later.")
            back()
        }
        setLoading(false)
    }
    const onChangeCheckBox = (e) => {
        setChecked(e.target.checked)
    }
    const footer = () => {
        return (
            <div className='service-agreement-footer'>
                <div><Checkbox className='service-agreement-checkbox' checked={checked} onChange={onChangeCheckBox}>I have read and understood the Software as a Service Agreement.</Checkbox></div>
                <div className='service-agreement-btn-group'>
                    <Button className='service-agreement-btn decline' type='link' onClick={() => setShowAgreement(false)}>Decline</Button>
                    <Button className='service-agreement-btn accept' type='primary' disabled={!checked || loading} loading={loading} onClick={() => onAcceptAgreement()}>Accept  </Button>
                </div>
            </div>
        )
    }
    const displayAgreement = () => {
        return (
            <Modal
                className='sign-up-service-agreement-modal'
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                // destroyOnClose
                maskClosable={true}
                width={610}
                open={showAgreement}
                centered={true}
                title=""
                onCancel={() => {
                    setShowAgreement(false)
                }}
                footer={footer}
            >
                <div className='service-agreement-title'>Software as a service agreement</div>
                <div className='service-agreement-content'>
                    <Terms />
                </div>
            </Modal>
        )
    }

    const onFinish = async () => {
        setShowAgreement(true)
    }



    const validateMessages = {
        required: 'Please enter your ${label}.',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <div className='Welcome'>Welcome aboard!</div>
                    <div className='inter'>Please provide the following information. Fields marked with <span className='requiredMark'>*</span> are required. </div>
                    <Form
                        className=''
                        style={{ marginTop: '30px' }}
                        layout='vertical'
                        form={form}

                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        size="large"
                    >
                        <Row>
                            <Col span={11}>
                                <Form.Item
                                    name="FirstName"
                                    label="First Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter legal first name"
                                        onFocus={(e) => handleFocus(e)}
                                        onBlur={(e) => handleBlur(e)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11} push={2}>
                                <Form.Item
                                    name="LastName"
                                    label="Last Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter legal last name"
                                        onFocus={(e) => handleFocus(e)}
                                        onBlur={(e) => handleBlur(e)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="Password"
                                    label="Please create your password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                        {
                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*.,]{8,}$/,
                                            message:
                                                'Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, and one number.',
                                        },
                                    ]}
                                >
                                    <Input
                                        type='password'
                                        placeholder="Password must contain at least 8 characters, 1 lowercase, 1 upper case and 1 number"
                                        onFocus={(e) => handleFocus(e)}
                                        onBlur={(e) => handleBlur(e)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="IndividualNMLS"
                                    label="NMLS ID"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter your individual NMLS ID"
                                        onFocus={(e) => handleFocus(e)}
                                        onBlur={(e) => handleBlur(e)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11} >
                                <Form.Item
                                    name="CompanyName"
                                    label={(<div className='d-flex'>
                                        <div>Organization Name</div>
                                        <div className='Popover'>
                                            <Popover placement="right" content={(
                                                <div >
                                                    If you're an individual loan officer, you could use your own name as the organization name.
                                                </div>
                                            )}>
                                                <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 5 }} />
                                            </Popover>
                                        </div>

                                    </div>)}
                                    rules={[{ required: true, },]}
                                >
                                    <Input
                                        placeholder="Enter the company name"
                                        onFocus={(e) => handleFocus(e)}
                                        onBlur={(e) => handleBlur(e)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11} push={2}>
                                <Form.Item
                                    name="BrokerageNMLS"
                                    label="Brokerage NMLS ID"

                                >
                                    <Input
                                        placeholder="Enter your company's NMLS ID"
                                        onFocus={(e) => handleFocus(e)}
                                        onBlur={(e) => handleBlur(e)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>


                        <div style={{ display: 'flex', justifyContent: "center", marginTop: "30px" }}>
                            <Button style={{ width: '150px', marginRight: '20px', background: "#fff" }} className='ant-btn-default-zeitro' onClick={() => { back() }}>Back</Button>
                            <Button disabled={!goodtogo} loading={loading} htmlType="submit" type='primary' style={{ width: '150px' }}>Next</Button>

                        </div>
                    </Form>
                </Col>
            </Row >
            {displayAgreement()}
        </>
    )

}
export default function SignUpPage() {
    const [pageTimes, setPageTimes] = useState({
        inputEmailAddressPage: {
            componentTime: {
                startTime: null,
                totalDuration: 0.0
            },
            inputField: []
        },
    });

    const previousPageTimes = useRef(null);
    const [currentFocusTime, setCurrentFocusTime] = useState(null);

    const [form] = Form.useForm()
    const formInitial = {

    }
    const [showPersonalInformation, setShowPersonalInformation] = useState('')
    const [signData, setSignData] = useImmer({})
    const validateMessages = {
        required: 'Please enter ${label}.',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const onFinish = (val) => {
        setSignData(val)
        setShowPersonalInformation('email')

    }

    useEffect(() => {
        setPageTimes(prev => ({
            ...prev,
            inputEmailAddressPage: {
                ...prev.inputEmailAddressPage,
                componentTime: {
                    ...prev.inputEmailAddressPage.componentTime,
                    startTime: new Date()
                }
            }
        }));
        previousPageTimes.current = new Date();
    }, [])

    useEffect(() => {
        //Update the dwell time of the input email address component
        if (showPersonalInformation === "email") {
            const currentTime = new Date();
            const duration = parseFloat(((currentTime - previousPageTimes.current) / 1000.0).toFixed(1));
            setPageTimes(prev => ({
                ...prev,
                inputEmailAddressPage: {
                    ...prev.inputEmailAddressPage,
                    componentTime: {
                        ...prev.inputEmailAddressPage.componentTime,
                        totalDuration: parseFloat((prev.inputEmailAddressPage.componentTime.totalDuration + duration).toFixed(1))
                    }
                }
            }));
        }
        previousPageTimes.current = new Date();
    }, [showPersonalInformation]);

    const handleFocus = (e) => {
        const fieldName = e.target.id;
        const fieldValue = e.target.value;

        setCurrentFocusTime(new Date());

        setPageTimes(prev => {
            const existingField = prev.inputEmailAddressPage.inputField.find(
                field => field.name === fieldName
            );

            if (!existingField) {
                return {
                    ...prev,
                    inputEmailAddressPage: {
                        ...prev.inputEmailAddressPage,
                        inputField: [
                            ...prev.inputEmailAddressPage.inputField,
                            {
                                name: fieldName,
                                value: fieldValue,
                                focusTime: 0,
                                startTime: new Date()
                            }
                        ]
                    }
                };
            }
            return prev;
        });
    };

    const handleBlur = (e) => {
        const fieldName = e.target.id;
        const fieldValue = e.target.value;

        if (currentFocusTime) {
            const endTime = new Date();
            const timeSpent = parseFloat(((endTime - currentFocusTime) / 1000.0).toFixed(1));

            setPageTimes(prev => ({
                ...prev,
                inputEmailAddressPage: {
                    ...prev.inputEmailAddressPage,
                    inputField: prev.inputEmailAddressPage.inputField.map(field => {
                        if (field.name === fieldName) {
                            const currentFocusTime = typeof field.focusTime === 'string'
                                ? parseFloat(field.focusTime)
                                : (field.focusTime || 0);

                            return {
                                ...field,
                                value: fieldValue,
                                focusTime: parseFloat((currentFocusTime + timeSpent).toFixed(1))
                            };
                        }
                        return field;
                    })
                }
            }));
        }

        setCurrentFocusTime(null);
    };

    const displayCarousel = () => {
        const contentStyle = {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          };
        return (
            <div className='los-carousel-box'>
                <div className='content-box'>
                <Carousel 
                    className='create-carousel' 
                    // autoplay
                >
                    <div>
                        <div style={contentStyle}>
                            <div className='center-box'>
                                <div>GuidelineGPT</div>
                                <div style={{fontSize: 16, color:"#222222", fontWeight: 400, marginBottom: 20}}>AI-powered expertise across Conventional, Non-QM, FHA, VA, USDA, and Business-Purpose Loan guidelines allows you to solve complex scenarios quickly and efficiently. </div>
                                <img src='/images/carousel-img-1.svg' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={contentStyle}>
                            <div className='center-box'>
                                <div>AI-powered efficiency</div>
                                <div style={{fontSize: 16, color:"#222222", fontWeight: 400, marginBottom: 20}}>Streamline every aspect of mortgage origination, from rate quotes to document processing, saving hours on every loan.</div>
                                <img src='/images/carousel-img-2.svg' />
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div style={contentStyle}>
                            <div style={{padding: '0 44px', width: 'auto'}} className='center-box'>
                                <div style={{padding: '0 26px'}}>Wholesale integration</div>
                                <div style={{fontSize: 16, color:"#222222", fontWeight: 400, marginBottom: 20, padding: '0 26px'}}>Automatically connect to multiple wholesalers, ensuring you always have the most competitive rates for your borrowers.</div>
                                <div >
                                    <img src='/images/carousel-img-3.svg' />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Carousel>
                </div>
            </div>
        )
    }



    return (
        <div className='los-signup-wrap'>
            <div className='sign-up-logo'>
                <img style={{ width: '100px' }} src='/images/logo.svg' />
                <div className='signIn-text'>Already have an account? <span className='to-signIn' onClick={() => {

                window.location.href = '/'

                }}>Sign in</span></div>
            </div>
            <div className='mobile-nav'>
                <div className='mobile-logo'><img src='/images/mobile-logo.svg' className='w-100' /></div>
            </div>

            <div className="sign-up-content">
                <div className='d-flex flex-column w-100 text-center'>
                    <div className='create-los-account'>
                    {showPersonalInformation === '' ?
                        <div className='create-centerBox'>
                            <div className='create-title'>Create your free account</div>
                            <Form

                                layout='vertical'
                                style={{ marginTop: '30px' }}
                                form={form}
                                initialValues={formInitial}
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                size="large"
                                requiredMark={false}
                            >

                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="emailaddress"
                                            label="Email Address"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email',
                                                    validateTrigger: ['onSubmit']
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Enter your work email"
                                                onFocus={(e) => handleFocus(e)}
                                                onBlur={(e) => handleBlur(e)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Button htmlType="submit" className='create-btn sign-zeitro' block style={{ marginTop: '20px' }}>Continue</Button>
                            </Form>
                            {/* <Divider plain>or sign up with</Divider> */}


                        </div> : showPersonalInformation === 'email' ? <NewEmailVerification fromSignUp email={signData.emailaddress} back={(e) => {


                            setShowPersonalInformation(e)

                            }}></NewEmailVerification> : <PersonalInformation pageTime={pageTimes} signData={signData} back={() => {

                            setShowPersonalInformation('')
                        }} />
                    }
                    </div>
                    <div className='create-copyright'>© {new Date().getFullYear()} Zeitro. All rights reserved.</div>
                </div>
                {(showPersonalInformation === '' || showPersonalInformation === 'email') && displayCarousel()}
            </div>
        </div>
    )
}