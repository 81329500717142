import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import './AlertToast.css'

const AlertToast = ({ content }) => {
    return (
        <div className="alert-toast">
            <img src="/images/toast-check.svg" className="alert-toast-icon" />
            <span className="alert-toast-content">{content}</span>
        </div>
    )
}

AlertToast.propTypes = {
    content: PropTypes.string.isRequired
}

const showCustomMessage = (content) => {
    const container = document.createElement('div')
    container.className = 'alert-toast-container'
    document.body.appendChild(container)

    // Render the component
    ReactDOM.render(<AlertToast content={content} />, container)

    // Remove after 3 seconds
    setTimeout(() => {
        // Add the closing class name
        container.classList.add('closing')

        // Listen for animation end and remove the element
        container.addEventListener('animationend', () => {
            ReactDOM.unmountComponentAtNode(container)
            container.remove()
        })
    }, 3000)
}

export const CustomMessage = {
    success: (content) => showCustomMessage(content),
}
