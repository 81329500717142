
import HomePage from './HomePage'
import Auth from "./Auth";
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import WelcomePage from './WelcomePage';
import Error from "./Error.js"
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

export default class AuthenticatedApp extends Component {
    render() {
        return (

            <div className="mx-0 px-0">
                <Auth />
                <Router>
                    <Switch>
                        <Route path="/app/">
                            <HomePage />
                        </Route>
                        <Route path="/error/401">
                            <Error code={401} />
                        </Route>
                        <Route >
                            <Error code={404} />
                        </Route>

                    </Switch>
                </Router>
            </div>
        )
    }
}