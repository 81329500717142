import React, { useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import dayjs from 'dayjs'
import MarkdownRenderer from './MarkdownRender'
import { Message } from './MessageList'
import './ShareChat.css'

function ShareChat() {
    const [loadingShare, setLoadingShare] = useState(false)
    const [chatTitle, setChatTitle] = useState('Untitled')
    const [createdAt, setCreatedAt] = useState('--/--/----')
    const [chatMessages, setChatMessages] = useState([])
    const { threadid } = useParams()

    useEffect(() => {
        setLoadingShare(true)
        const body = { ThreadID: threadid }
        fetch('/los/mortgagegpt/sharechat', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Cache: 'no-cache',
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn(
                        'Looks like there was a problem. Status Code: ',
                        response.status
                    )
                    setLoadingShare(false)
                    alert('Something went wrong, please try again later')
                    return
                }
                response.json().then((js) => {
                    if (js.thread.custom_title || js.thread.initial_title) {
                        setChatTitle(
                            js.thread.custom_title || js.thread.initial_title
                        )
                    }
                    if (js.thread.created_at) {
                        setCreatedAt(
                            dayjs(js.thread.created_at).format('MM/DD/YYYY')
                        )
                    }
                    const history = js.chat_history.map((msg) => {
                        // console.log('🚀 ~ file: ShareChat.js:44 ~ history ~ msg:', msg);
                        if (msg.role === 'user') {
                            return {
                                text: msg.content,
                                sender: 'user',
                            }
                        }

                        // Remove invalid reference links
                        const answer = msg.content.replace(
                            /\[([1-9]\d*(?:\s*,\s*[1-9]\d*)*)\]/g,
                            (match, numbers) => {
                                // Only filter refs if we have valid locations
                                const hasValidLocations = Array.isArray(msg.locations) && msg.locations.length > 0
                                if (hasValidLocations) {
                                    const refs = numbers.split(',').map((n) => parseInt(n.trim()))
                                    // Get unique page numbers from quoteLocations
                                    const uniquePages = new Set(
                                        msg.locations.map(([page]) => page)
                                    )
                                    const maxValidRef = uniquePages.size
                                    const validRefs = refs.filter((ref) => ref <= maxValidRef)
                                    return validRefs.length > 0 ? match : ''
                                }

                                // If no valid locations, keep original reference
                                return match
                            }
                        )

                        return {
                            text: <MarkdownRenderer answer={answer} />,
                            sender: 'bot',
                        }
                    })
                    setChatMessages(history)
                    setLoadingShare(false)
                })
            })
            .catch(function (err) {
                setLoadingShare(false)
                console.error('Share chat failed: ', err)
            })
    }, [])

    return (
        <div className="share-chat">
            {loadingShare ? (
                <Skeleton active />
            ) : (
                <div className="share-chat-container">
                    <img
                        className="share-chat-logo"
                        src="/images/share-chat-logo.svg"
                        alt="Logo"
                    />
                    <div className="share-chat-title">{chatTitle}</div>
                    <div className="share-chat-subtitle">
                        Created on {createdAt}
                    </div>
                    <div className="share-chat-divider"></div>
                    <div className="share-chat-messages">
                        {chatMessages.map(({ text, sender }, index) => (
                            <Message
                                key={index}
                                text={text}
                                sender={sender}
                                isShareChat={true}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default withRouter(ShareChat)
