import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, div } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ListGroup from "react-bootstrap/ListGroup";
import { termOptions } from "./common";
import HorizontalSliderV2 from "../../Common/HorizontalSliderV2";
import Select from 'react-select';
import "../../App.css";
import "./RateQuote.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import * as com from "../../Common.js";
import NumericalInput,{CommaizedInput} from "../../NumericalInput";
import { withRouter } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import IneligibleProductsModal from "./IneligibleProducts";
import AllRatesModal, {AllRates} from "./AllRates";
import ReportIssuesModal from "./ReportIssues";
import RateReport from "./RateReport";
import Spinner from 'react-bootstrap/Spinner'
import { Tips } from "../../Common/Tooltip";
import { Dropdown, Space, Input } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';


const DEFAULT_LOAN_TERM = 30; //year

let fixed30 = [];
let fixed25 = [];
let fixed20 = [];
let fixed15 = [];
let fixed10 = [];
let arm56 = [];
let arm76 = [];
let arm106 = [];
let allRates = {};
let customerUrl = "https://app.zeitro.com"
const RateQuoteBorrowerVersion = (props) => {
  let customerid = com.getCustomerId();
  if (props.customerid != undefined) {
    customerid = props.customerid
  }

  const [loanLimits, setLoanLimits] = useState({});
  const [onUpdateRates, setOnUpdateRates] = useState(false)
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [propertyType, setPropertyType] = useState("singlefamilydetached");
  const [propertyValue, setPropertyValue] = useState(500000);
  const [purpose, setPurpose] = useState("Purchase");

  const [occupancy, onOccupancy] = useState("principal");
  const [validated, setValidated] = useState(false);
  const [downpayment, setDownpayment] = useState(100000);
  const [county, setCounty] = useState("SANTA CLARA COUNTY");
  const [state, setState] = useState("CALIFORNIA");
  const [monthlyIncome, setMonthlyIncome] = useState(50000);
  const [monthlyDebt, setMonthlyDebt] = useState(1000);
  const [estOtherCosts, setEstOtherCosts] = useState(3195);
  const [escrowWaiver, setEscrowWaiver] = useState(true);

  const [units, setUnits] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState(fixed30);
  const [fico, setFico] = useState(780);
  const [indexfixed30, setIndexFixed30] = useState(8);
  const [indexfixed25, setIndexFixed25] = useState(6);
  const [indexfixed20, setIndexFixed20] = useState(6);
  const [indexfixed15, setIndexFixed15] = useState(6);
  const [indexfixed10, setIndexFixed10] = useState(6);
  const [indexarm56, setIndexArm56] = useState(6);
  const [indexarm76, setIndexArm76] = useState(6);
  const [indexarm106, setIndexArm106] = useState(6);
  const [ineligibleProducts, setIneligibleProducts] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [showAllRates, setShowAllRates] = useState(false);
  const [showReportIssues, setShowReportIssues] = useState(false);
  const [showRatePreview, setShowRatePreview] = useState(false);
  const [showSendReportSpinner, setShowSendReportSpinner] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [previewEmail, setPreviewEmail] = useState("");
  const [previewResult, setPreviewResult] = useState("");
  const [loProfile, setLOProfile] = useState({})
  const [currentMtype, setCurrentMtype] = useState("fixed30")

  const [message, setMessage] = useState("");
  const [showMoreOptions,setShowMoreOptions] = useState(false)
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showSubscribeResult, setShowSubscribeResult] = useState(false);
  const [subscribeResultMessage, setSubscribeResultMessage] = useState("");
  const [validatedSubscribe, setValidatedSubscribe] = useState(false);
  const subscribeFormRef = useRef()
  const [alreadysubscribe, setAlreadysubscribe] = useState(false);
  const [pastSetting, setPastSetting] = useState({});
  const [newSetting, setNewSetting] = useState({});
  const [lenderNotSet, setLendersNotSet] = useState(false)
  const [showDetails, setShowDetails] = useState(false);

  const [showFileModal, setShowFileModel] = useState(false);
  const handleCloseFileModal = () => setShowFileModel(false);
  const handleShowFileModal = () => setShowFileModel(true);

  const [showEditCostDetails, setShowEditCostDetails] = useState(false);
  const handleEditCostDetailsModal = () => setShowEditCostDetails(false);
  const [isGMCCJumbo, setIsGMCCJumbo] = useState(false)
  const [cannotshop, setCannotshop] = useState([
    { name: "Appraisal Fee", value: 750 },
    { name: "Credit Report", value: 65 },
    { name: "Flood Certification", value: 10 },
    { name: "HOA Certification Fee", value: 350 },
    { name: "Lender Doc Prep", value: 75 },
    { name: "Tax Service Fee", value: 80 },
  ]);
  const [canshop, setCanshop] = useState([
    { name: "Pest Inspection", value: 175 },
    { name: "Title-Closing/Escrow Fee", value: 475 },
    { name: "Title-Courier Fee", value: 35 },
    { name: "Title-Endorsement Fee", value: 25 },
    { name: "Title-Lender's Title Insurance", value: 645 },
    { name: "Title-Loan Tie in Fee", value: 280 },
    { name: "Title-Notary Fee", value: 200 },
    { name: "Title-Processing Fees to Escrow", value: 15 },
    { name: "Title-Wire Fee to Title", value: 15 },
  ]);

  const preselectedTermOptions = [
    { label: "30 Year",value: "fixed30" },
    { label: "15 Year",value: "fixed15" },
    { label: "ARM 5/6",value: "arm56" },
    { label: "ARM 7/6",value: "arm76" },
    { label: "ARM 10/6",value: "arm106" },
  ];

  let fileSuffixMap = {
    Fairway: "pdf",
    HomePoint: "xlsx",
    PRMG: "xlsx",
    AmeriHome: "xlsx",
    HomePointDelegated: "xlsx",
    FifthThirdBank: "xls",
    RocketPro: "xlsx",
    USBank: "pdf",
    PRMG: "xls",
    Caliber: "pdf",
    LoanDepot: "pdf",
    Amwest: "pdf",
    DivineMortgage: "pdf",
    LoanStore: "pdf",
    NewWaveLending: "pdf",
    WholesaleMortgageLender: "pdf",
    ResiCentral: "pdf",
    Provident: "csv",
    FCB:"xlsx",
    PlanetHome:"xlsx",
    GMCCCelebrities : "xlsx",
    FlagStar : "xlsx",
    PlanetHome : "xlsx",
    PennyMac : "xlsx",
    JMAC : "pdf",
  };

  const [selectedTerms, setSelectedTerms] = useState(preselectedTermOptions);

  const form = useRef();

  let getLtv = () => {
    let ltv = Math.ceil((getLoanAmount() / parseInt(propertyValue)) * 100);
    // let cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
    // let hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
    return [ltv, ltv, ltv];
  };

  const getCustomerUrl = () => {
    fetch("/data/customerurls/" + customerid)
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        if(window.location.hostname.includes("localhost")){
          customerUrl="http://localhost:3000"
        }
        else{
          customerUrl = res.Prodapp;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  useEffect(()=>{
    getCustomerUrl()
  },[])

  useEffect(() => {
    if (propertyType === "manufacturedhome") {
      setCurrentMtype("fixed25")
    }
  }, [propertyType])

  let getLoanAmount = () => {
    if (purpose == "Purchase") {
      return parseInt(propertyValue) - parseInt(downpayment);
    } else {
      return parseInt(downpayment);
    }
  };

  let downloadRatesheet = (loan) => {
    let s2ab = (s) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    let blob = new Blob([s2ab(atob(loan.FileB))], { type: "" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    if (fileSuffixMap[loan.Lender] == undefined) {
      handleShowFileModal();
      return;
    }

    link.download = loan.Lender + "_Ratesheet." + fileSuffixMap[loan.Lender];
    link.click();
  };

  let getProducts = (controller) => {
    let pmi = 0.51;
    let zeitro_fee = [{ lender: "default", percent: 1.0, fixed: 500 }];
    let _units = 1;
    let _county = "ZAVALA COUNTY";
    if (propertyType === "twotofourfamily") _units = units;

    setMessage("");

    arm56 = [];
    arm76 = [];
    arm106 = [];
    fixed30 = [];
    fixed25 = [];
    fixed20 = [];
    fixed15 = [];
    fixed10 = [];
    let [ltv, cltv, hcltv] = getLtv();

    if (state != "TEXAS") _county = county;

    let body = {
      state: state,
      county: _county,
      purpose: purpose,
      loanAmount: getLoanAmount(),
      units: parseInt(_units),
      LTV: ltv,
      CLTV: cltv,
      HCLTV: hcltv,
      FICO: fico,
      occupancy: com.getOccupancy(occupancy),
      property: com.getPropertyType(propertyType),
      point: zeitro_fee,
      estimatedClosingCosts: parseFloat(estOtherCosts),
      pmi: pmi,
      getFiles: props.isFromLanding? false : true,
      monthlyIncome: parseFloat(monthlyIncome),
      monthlyDebt: parseFloat(monthlyDebt),
      escrowWaiver: escrowWaiver,
    };
    setLoading(true);
    fetch("/data/getrefinance/" + customerid, {
      method: "POST",
      headers: {
        Cache: "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          setOnUpdateRates(false)
          setMessage("An internal error has occured.");
          return;
        }
        response.json().then((pr) => {
          setOnUpdateRates(false)

          if (pr.status_code === "12") {
            setLendersNotSet(true)
            setLoading(false);
            return
          }

          let products = pr.result;
          if (pr.ineligible_reasons != undefined) {
            setIneligibleProducts(pr.ineligible_reasons);
          } else {
            setIneligibleProducts({});
          }
          if (pr.message != undefined) {
            setMessage(pr.message);
          } else {
            setMessage("");
          }
          if (products === undefined) {
            setShowIneligible(true)
            setLoading(false);
            return;
          }
          products.forEach((product) => {
            // console.log("prd", product);
            let lender = product.Lender;
            let fb = product.FileB;
            product.ProductRatesCosts.forEach((pr) => {
              let commonproduct = pr.product;
              let promotions = pr.promotions
              let adjustments = pr.appliedAdjustments;
              // if (commonproduct.Term !== loanTerm) return;
              let ausengine = pr.AUSEngine;
              pr.rateCosts.forEach((rate) => {
                let pdkt = {
                  Lender: lender,
                  ...commonproduct,
                  Aus: ausengine,
                  ...rate,
                  promotions,
                  Adjustments: adjustments,
                  FileB: fb,
                };

                if (rate.lockDay !== 30) {
                  return;
                }
                /// SOMRTHING ELSE HERE
                pdkt.loanAmount = getLoanAmount();

                const filterPdkt = () => {
                  if (customerid==="siliconcitymortgage" && pdkt.Mtype.includes("FHA")) {
                    return false
                  }
                  // filter out GMCC proudcts
                  if (pdkt.Lender.includes("GMCC") || pdkt.Lender.includes("FCB")) {
                    return false
                  }
                  return true
                }

                if (filterPdkt()) {
                  if (pdkt.Arm != null) {
                    if (pdkt.Arm.fixedperiod == 5) {
                      arm56.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 7) {
                      arm76.push(pdkt);
                    } else if (pdkt.Arm.fixedperiod == 10) {
                      arm106.push(pdkt);
                    }
                  } else {
                    if (pdkt.Term == 30) {
                      fixed30.push(pdkt);
                    } else if (pdkt.Term == 25) {
                      fixed25.push(pdkt);
                    } else if (pdkt.Term == 20) {
                      fixed20.push(pdkt);
                    } else if (pdkt.Term == 15) {
                      fixed15.push(pdkt);
                    } else if (pdkt.Term == 10) {
                      fixed10.push(pdkt);
                    }
                  }
                }
              });
            });
          });

          let compareRate = (a, b) => {
            if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate;

            return a.closing_cost - b.closing_cost; // take into account remaining credits
          };

          let cullRate = (products) => {
            let filteredPrds = products
            if (filteredPrds.length === 0) return filteredPrds;
            let newproducts = [];
            newproducts.push(filteredPrds[0]);
            let base_rate = filteredPrds[0].base_rate;
            let closing_cost = filteredPrds[0].closing_cost;

            for (let i = 1; i < filteredPrds.length; i++) {
              if (filteredPrds[i].base_rate !== base_rate) {
                if (filteredPrds[i].closing_cost < closing_cost) {
                  newproducts.push(filteredPrds[i]);
                  closing_cost = filteredPrds[i].closing_cost;
                }
                base_rate = filteredPrds[i].base_rate;
              } else {
              }
            }
            return newproducts;
          };

          let getIndex = (loans) => {
            let bestIndex = 0
            let bestPoints = 1000000
            for (let i = 0; i < loans.length; i++) {
              if (Math.abs(loans[i].lender_credit - 1500) < bestPoints) {
                bestPoints = Math.abs(loans[i].lender_credit - 1500)
                bestIndex = i
              }
            }
            return bestIndex
          };

          fixed30.sort(compareRate);
          fixed25.sort(compareRate);
          fixed20.sort(compareRate);
          fixed15.sort(compareRate);
          fixed10.sort(compareRate);
          arm56.sort(compareRate);
          arm76.sort(compareRate);
          arm106.sort(compareRate);
          fixed30 = cullRate(fixed30);
          fixed25 = cullRate(fixed25);
          fixed20 = cullRate(fixed20);
          fixed15 = cullRate(fixed15);
          fixed10 = cullRate(fixed10);
          arm56 = cullRate(arm56);
          arm76 = cullRate(arm76);
          arm106 = cullRate(arm106);

          setIndexFixed30(getIndex(fixed30));
          setIndexFixed25(getIndex(fixed25));
          setIndexFixed20(getIndex(fixed20));
          setIndexFixed15(getIndex(fixed15));
          setIndexFixed10(getIndex(fixed10));
          setIndexArm56(getIndex(arm56));
          setIndexArm76(getIndex(arm76));
          setIndexArm106(getIndex(arm106));

          allRates = {}
          allRates.fixed30 = fixed30
          allRates.fixed25 = fixed25
          allRates.fixed20 = fixed20
          allRates.fixed15 = fixed15
          allRates.fixed10 = fixed10
          allRates.arm56 = arm56
          allRates.arm76 = arm76
          allRates.arm106 = arm106

          if (fixed30.length !== 0) {
            setLoans(fixed30);
          } else if (arm56.length !== 0) {
            setLoans(arm56);
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        setOnUpdateRates(false)
        setLoading(false);
      });
  };

  const getLoanLimits = () => {
    setLoading(true);
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    };

  const ref = useRef();
  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });

  useEffect(() => {
    const controller = new AbortController()
    getProducts(controller);
    getLoanLimits(controller);

    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/getprofile', {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
    }).then(
        response => {
            if (response.status !== 200) {
                alert('Looks like there was a problem. Please contact Zeitro for help.');
                return;
            }
            response.json().then(js => {
                if (js.Status !== "OK") {
                  console.log("Error in /los/getprofile: " + js.Error)
                } else {
                    let lo = js.LoanOfficers[0]
                    // console.log("profile info returned: %s ", JSON.stringify(js))
                    setLOProfile({
                        firstName: lo.FirstName, lastName: lo.LastName,
                        email: lo.Email, phone: lo.Phone, avatarsrc: lo.Image, nmls: lo.Nmls
                    })


                }
            });
        }
    ).catch(function (err) {
        alert('Fetch Error :-S', err);
    });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!showSubscribe) setShowRatePreview(false)
  }, [showSubscribe, showRatePreview]);

  let showCounties = () => {
    let options = [<option value=""></option>];
    if (state in loanLimits) {
      let keys = Object.keys(loanLimits[state]);

      keys.forEach((x) => {
        options.push(<option value={x}> {com.capitalize(x.replace("COUNTY",""))}</option>);
      });
    }
    return options;
  };

  let showStates=() =>{
    let options = [<option value=""></option>];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  useEffect(()=>{
    const controller = new AbortController()
    if (onUpdateRates) {
      getProducts(controller);
    }
    return () => {
      controller.abort();
    };
  }, [onUpdateRates])

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsGMCCJumbo(false)
    if (form.current.reportValidity() === false) {
      setValidated(true);
      return false;
    }
    if (parseInt(downpayment) === 0) {
      setValidated(true);
      alert("Down payment can't be zero");
      return false;
    }
    if (parseInt(downpayment) >= parseInt(propertyValue)) {
      setValidated(true);
      alert("Down payment can't be bigger than purchase price");
      return false;
    }
    if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
      setValidated(true);
      alert("FICO score should be in the range of 300 - 1000");
      return false;
    }
    let overConforming = false
    if (state in loanLimits && loanLimits[state][county]) {
      let lldata = loanLimits[state][county]
      let ll
      let _units = 1;
      if (propertyType === "twotofourfamily") _units = units;
      switch (parseInt(_units)) {
        case 1:
          ll = lldata.OneUnit < 806500 ? lldata.OneUnit : 806500
          break;
        case 2:
          ll = lldata.TwoUnit < 1032650 ? lldata.TwoUnit : 1032650
          break;
        case 3:
          ll = lldata.ThreeUnit < 1248150 ? lldata.ThreeUnit : 1248150
          break;
        case 4:
          ll = lldata.FourUnit < 1551250 ? lldata.FourUnit : 1551250
          break;
        default:
          break;
      }
      if (getLoanAmount() > ll) {
        overConforming = true
      }
    }
    if (customerid === "gmcc" && overConforming) {
      setIsGMCCJumbo(true)
      return
    }
    setOnUpdateRates(true)
    return false;
  };

  let showCards = () => {
    let buttons = []
    let termMap = {
      fixed30:"30 Year",
      fixed25:"25 Year",
      fixed20:"20 Year",
      fixed15:"15 Year",
      fixed10:"10 Year",
      arm56:"ARM 5/6",
      arm76:"ARM 7/6",
      arm106:"ARM 10/6",
    }
    for (let mtype in allRates) {
      const buttonStyle = mtype === currentMtype ? "rate-quoter-mtype-selected" : "rate-quoter-mtype-unselected";
      buttons.push(<Button variant={buttonStyle} id={mtype} onClick={()=>setCurrentMtype(mtype)}>{termMap[mtype]}</Button>)
    }

    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date();
    const dayOfWeek = today.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      // If today is Sunday (0), subtract 2 days to get Friday
      // If today is Saturday (6), subtract 1 day to get Friday
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (dayOfWeek === 0 ? 2 : 1));
    }

    return (
        <div className="rate-quoter-term-selector pt-2 pb-2">
            <div>
              <div className="rate-quoter-term-rates text-center" >Rate as of 09:00 {today.toLocaleDateString("en-US", options)}</div>
              {customerid === "gmcc"?(
                <div className="rate-quoter-special-notes text-center" >
                  *Rate quotations currently are estimates for Conforming products only and subject to market changes without advance notice. Hign Balance and Jumbo products coming soon.
                </div>
              ):(
                <div className="rate-quoter-special-notes text-center" >
                  *Rates are updated periodically to reflect real-time lender pricing; some lenders may not update their rates daily.
                </div>
              )}
            </div>
            <div className="rate-quoter-term-buttons">
              {buttons}
            </div>
        </div>
    )
  };

  let generateTableData = () => {

    if (typeof allRates[currentMtype] === "undefined") {
      return []
    }

    let point1Diff = 999
    let point1I = 0
    let point0Diff = 999
    let point0I = 0
    let indexes = new Set();


    for (var i = 0; i < allRates[currentMtype].length; i++){
      let diff0 = Math.abs(allRates[currentMtype][i].points )
      let diff1 = Math.abs(allRates[currentMtype][i].points -1 )
      if (diff0 < point0Diff) {
        point0I = i
        point0Diff = diff0
      }
      if (diff1 < point1Diff) {
        point1I = i
        point1Diff = diff1
      }
    }

    indexes.add(point0I)
    indexes.add(point1I)

    let indexdiff = 2
    if (customerid === "siliconcitymortgage"){
      indexdiff = 6
    }

    let start = point1I+1
    while (start <= allRates[currentMtype].length-1 &&  start - point1I <= indexdiff) {
      indexes.add(start)
      start++
    }

    if (point0I+1 <= allRates[currentMtype].length-1) indexes.add(point0I+1)

    let data = []
    let indexesSorted = Array.from(indexes).sort((a, b) => a - b)
    for (let i of indexesSorted) {
      let d = allRates[currentMtype][i]
      if (typeof d !== "undefined") {
        data.push({
          rate: d.base_rate.toFixed(3) +"%",
          apr: d.APR.toFixed(3) + "%",
          monthlyPayment: "$"+com.commaizeFloat(d.monthly_payment),
          points: "$"+com.commaizeFloat(d.lender_credit),
        })
      }
    }

    return data

  }

  const getPreQualificationLink = () => {
    if (props.isFromLanding){
      if (customerid === "gmcc") {
        if(props.appLink !== ""){
          return props.appLink
        } else {
          return props.companyLink
        }
      }
      if (props.appLink !== "") {
        return props.appLink
      }
    }
    if (window.location.host.includes("zeitro.us")) {
      return customerUrl + "/services/preapp?customerid=" + customerid + "&loid=" + props.loid
    }
    if (window.location.host.includes("localhost")) {
      return customerUrl + "/services/preapp?customerid=" + customerid + "&loid=" + props.loid
    }
    return customerUrl + "/services/preapp?customerid=" + customerid + "&loid=" + props.loid
  }

  let showRatesForMtype = () => {
    if (typeof allRates[currentMtype] === "undefined") {
      return ""
    }
    let headerStyle = {
      borderBottom: "#D9D9D9 solid 1px",
      borderTop: "none",
    };
    const rowStyle = { border: "none", fontSize:"28px", padding: '25px 0' };
    let columns = [
      {
        dataField: "rate",
        text: "Rate",
        formatter: (cell, row) => {return <span><strong>{cell}</strong></span>}
      },
      {
        dataField: "apr",
        text: "APR",
      },
      {
        dataField: "monthlyPayment",
        text: "Mo.Pay",
      },
      {
        dataField: "points",
        text: "Pts/Credit",
        headerFormatter: (column, index) => {
          return <div className="d-flex justify-content-center">
          <div >{column.text}</div>
          <Tips
          placement="top"
          title=""
          content={
            <div>
              A positive number means you have to pay lender to get the lower rates. A negative number means lender will give you a credit to help you with the closing costs.
            </div>
          }
        /></div>
        }
      },
      {
        dataField: "action",
        text: "",
        formatter: (cell, row) => {return <div><Button variant="rate-quoter-get-started" onClick={()=>window.open(getPreQualificationLink(), "_blank")}>Apply</Button></div>}
      },
    ]
    let data = generateTableData()
    return (
      <div className="rate-quoter-mtype-table">
        {data.length === 0 ? <div className="ratesWarning">
          <Row className="justify-content-md-center">
            <Col xs={1}>
              <img
                src="/images/warningSign.png"
                style={{ maxWidth: "20px" }}
              />
            </Col>
            <Col xs={11}>There are no eligible products available for this type of amortization.</Col>
          </Row>
        </div> : <BootstrapTable
          bootstrap4
          keyField="rate"
          columns={columns}
          data={data}
          bordered={false}
          striped={false}
          // rowStyle={rowStyle}
          headerClasses={props.isFromLanding?"landing-rate-quoter-header":"rate-quoter-header"}
          rowClasses='rate-quoter-row'
          hover
          condensed
        />}

      </div>
    )
  }

  let getEditCostsModal = () => {
    let updateTotal = (f1, f2) => {
      let t = 0;
      let all = [...f1, ...f2];
      for (let fe of all) {
        t += parseFloat(fe.value);
      }
      setEstOtherCosts(t);
    };

    let handleCannotshop = (i, e) => {
      let newFormValues = [...cannotshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };

    let addCannotshop = () => {
      setCannotshop([...cannotshop, { name: "", value: "" }]);
    };

    let removeCannotshop = (i) => {
      let newFormValues = [...cannotshop];
      newFormValues.splice(i, 1);
      setCannotshop(newFormValues);
      updateTotal(newFormValues, canshop);
    };
    let handleCanshop = (i, e) => {
      let newFormValues = [...canshop];
      newFormValues[i][e.target.name] = e.target.value;
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    let addCanshop = () => {
      setCanshop([...canshop, { name: "", value: "" }]);
    };

    let removeCanshop = (i) => {
      let newFormValues = [...canshop];
      newFormValues.splice(i, 1);
      setCanshop(newFormValues);
      updateTotal(newFormValues, cannotshop);
    };

    return (
      <Modal
        show={showEditCostDetails}
        onHide={handleEditCostDetailsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h3>
              <b>Services you cannot shop for</b>
            </h3>
            {cannotshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCannotshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCannotshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCannotshop()}>
              Add More Fees
            </Button>
            <h3>
              <b>Services you can shop for</b>
            </h3>
            {canshop.map((element, index) => (
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={element.name || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    name="value"
                    type="number"
                    value={element.value || ""}
                    onChange={(e) => handleCanshop(index, e)}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    onClick={() => removeCanshop(index)}
                    variant="danger"
                  >
                    X
                  </Button>
                </Col>
              </Form.Group>
            ))}
            <Button variant="link" onClick={() => addCanshop()}>
              Add More Fees
            </Button>
          </Form>
          <h3>
            <b>Total estimated fees: ${com.commaize(estOtherCosts)}</b>
          </h3>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={handleEditCostDetailsModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditCostDetailsModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  let onSubscribe = (e) => {
    setShowSubscribe(true);
    setShowSubscribeResult(false);
    setSubscribeResultMessage("");
  };

  let getSubscribeModal = () => {
    const handleCloseModal = () => setShowSubscribe(false);
    const handleReset = () => {
      subscribeFormRef.current.reset();
      setValidatedSubscribe(false);
    };
    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        return
      }
      setValidatedSubscribe(true);

      let stname = [];
      selectedTerms.forEach((t) => {
        stname.push(t.label);
      });
      let [ltv, cltv, hcltv] = getLtv();
      let body = {
        Email: event.target.elements.email.value,
        Fname: event.target.elements.fname.value,
        Lname: event.target.elements.lname.value,
        Frequency: event.target.elements.frequency.value,
        SelectedTerms: stname,
        Fixed30lc:
          fixed30[Math.max(indexfixed30-1,0)] !== undefined
            ? fixed30[Math.max(indexfixed30-1, 0)].lender_credit
            : 3000,
        Fixed25lc:
          fixed25[Math.max(indexfixed25-1,0)] !== undefined
            ? fixed25[Math.max(indexfixed25-1,0)].lender_credit
            : 3000,
        Fixed20lc:
          fixed20[Math.max(indexfixed20-1,0)] !== undefined
            ? fixed20[Math.max(indexfixed20-1,0)].lender_credit
            : 3000,
        Fixed15lc:
          fixed15[Math.max(indexfixed15-1,0)] !== undefined
            ? fixed15[Math.max(indexfixed15-1,0)].lender_credit
            : 3000,
        Fixed10lc:
          fixed10[Math.max(indexfixed10-1,0)] !== undefined
            ? fixed10[Math.max(indexfixed10-1,0)].lender_credit
            : 3000,
        Arm56lc:
          arm56[Math.max(indexarm56-1,0)] !== undefined ? arm56[indexarm56].lender_credit : 3000,
        Arm76lc:
          arm76[Math.max(indexarm76-1,0)] !== undefined ? arm76[indexarm76].lender_credit : 3000,
        Arm106lc:
          arm106[Math.max(indexarm106-1,0)] !== undefined
            ? arm106[Math.max(indexarm106-1,0)].lender_credit
            : 3000,
        PropertyType: com.getPropertyType(propertyType),
        LTV: ltv,
        LoanAmount: getLoanAmount(),
        Occupancy: com.getOccupancy(occupancy),
        Purpose: purpose,
        State: state,
        County: county,
        MonthlyIncome: monthlyIncome,
        MonthlyDebt: monthlyDebt,
        Fico: fico,
      };
      // console.log(body);
      setNewSetting(body);
      let token = sessionStorage.getItem("ZeitroA");
      fetch("/los/subscriberates/" + customerid + "/" + props.loid, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "Content-Type": "application/json",
          "X-Customerid": customerid,
          "X-LoID": props.id
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("Looks like there was a problem: " + response);
          return;
        }
        response.json().then((r) => {
          if (r.Status == "OK") {
            setAlreadysubscribe(false);
            setNewSetting(body);
            setShowSubscribeResult(true);
          } else {
            console.log(r);
            setPastSetting(r.Settings);
            setAlreadysubscribe(true);
            setShowSubscribeResult(true);
          }
        });
      });
      handleReset()
      setShowSubscribe(false);
    };

    let [ltv, cltv, hcltv] = getLtv();
    let ratesPreviewData = {
      PropertyType: com.getPropertyType(propertyType),
      LTV: ltv,
      LoanAmount: getLoanAmount(),
      Occupancy: occupancy,
      Purpose: purpose,
      State: state,
      County: county,
      MonthlyIncome: monthlyIncome,
      MonthlyDebt: monthlyDebt,
      Fico: fico,
      selected: selectedTerms,
      fixed30: fixed30[Math.max(indexfixed30-1, 0)],
      fixed25: fixed25[Math.max(indexfixed25-1,0)],
      fixed20: fixed20[Math.max(indexfixed20-1,0)],
      fixed15: fixed15[Math.max(indexfixed15-1,0)],
      fixed10: fixed10[Math.max(indexfixed10-1,0)],
      arm56: arm56[Math.max(indexarm56-1,0)],
      arm76: arm76[Math.max(indexarm76-1,0)],
      arm106: arm106[Math.max(indexarm106-1,0)],
    }

    return (
      <Modal
        show={showSubscribe}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="ratesReportModal"
        centered
      >
          <Modal.Body className="ratesReportModalBody">
            <div className="ratesReportModalDiv">
                <Form ref={subscribeFormRef} validated={validatedSubscribe} onSubmit={handleSubmit}>
              <div className="ratesReportForm">
                <div>
                  <div className="ratesReportFormCol">
                    <Form.Group controlId="fname">
                      <Form.Label className="RateQuoterformLabel">First Name</Form.Label>
                      <Form.Control placeholder="Your first name" size="sm"/>
                    </Form.Group>
                  </div>
                  <div className="ratesReportFormCol">
                    <Form.Group controlId="lname">
                      <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text"  placeholder="Your last name" size="sm"/>
                  </Form.Group>
                  </div>
                </div>
                <div>
                <div className="ratesReportFormCol">
                  <Form.Group controlId="email">
                    <Form.Label className="RateQuoterformLabel">Email</Form.Label>
                    <Form.Control placeholder="example@email.com" type="email" required size="sm"/>
                  </Form.Group>
                </div>
                <div className="ratesReportFormCol">
                  <Form.Group controlId="frequency">
                    <Form.Label>Report Frequency</Form.Label>
                  <Form.Control as="select" size="sm">
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </Form.Control>
                </Form.Group>
                </div>
                </div>
              </div>
                <div className="ratesReportFormButton">
                  <Button variant="saveSubscribe" type="submit">
                    Save
                  </Button>
                </div>
                </Form>
              {!props.isMobile && <div className="ratesReportPreview">
                <RateReport data={ratesPreviewData} isMobile={props.isMobile} selectedTerms={selectedTerms} setSelectedTerms={setSelectedTerms}/>
              </div>}
            </div>
        {/* <br/>
            <h3>Or, send the rate report one time and right away:</h3>
            <Form>
            <Form.Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" required onChange={(e)=>setPreviewEmail(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
                <Button variant="primary" size="md" style={{marginTop: "32px", width: "80%"}} onClick={handleShowRatePreview}>
                 Preview
                </Button>
              </Col>

            </Form.Row>
         </Form> */}
          </Modal.Body>
          {!props.isMobile && <Modal.Footer>
            {showRatePreview && <RateReport data={previewData} selectedTerms={selectedTerms} setSelectedTerms={setSelectedTerms}/>}
            {previewResult !== "" && <div><Row><b style={{size:"120%"}}>{previewResult}</b></Row></div>}
            {showRatePreview && <Button variant="light" size="md" onClick={()=>setShowRatePreview(false)}>
              Hide Preview
            </Button>}
            <Button variant="light" size="md" onClick={handleCloseModal}>
              {showRatePreview? "Re-config" : "Close"}
            </Button>
            {showRatePreview && <Button variant="primary" size="md" onClick={()=>sendRatesReport()}>
              {showSendReportSpinner ? <Spinner animation="border" variant="light" size="sm" /> : "Send"}
            </Button>}
          </Modal.Footer>}
      </Modal>
    );
  };

  let getSubscribeResultModal = () => {
    const handleCloseModal = () => setShowSubscribeResult(false);
    let s = (string) => {
      return string.toString().replaceAll("_", " ").toLowerCase();
    };
    let showSubscribeMessageExisted = () => {
      let data = [
        {
          field: "Loan Purpose",
          past: s(pastSetting.Purpose),
          new: s(newSetting.Purpose),
        },
        {
          field: "Loan Amount",
          past: pastSetting.LoanAmount,
          new: newSetting.LoanAmount,
        },
        {
          field: "FICO Score",
          past: pastSetting.Fico,
          new: newSetting.Fico,
        },
        {
          field: "Occupancy Type",
          past: s(pastSetting.Occupancy),
          new: s(newSetting.Occupancy),
        },
        {
          field: "Property Type",
          past: s(pastSetting.PropertyType),
          new: s(newSetting.PropertyType),
        },
        {
          field: "Monthly Income",
          past: s(pastSetting.MonthlyIncome),
          new: s(newSetting.MonthlyIncome),
        },
        {
          field: "Monthly Debt",
          past: s(pastSetting.MonthlyDebt),
          new: s(newSetting.MonthlyDebt),
        },
        {
          field: "Email Frequency",
          past: s(pastSetting.Frequency),
          new: s(newSetting.Frequency),
        },
        // {
        //   field: "Preferred Products",
        //   past: s(pastSetting.SelectedTerms),
        //   new: s(newSetting.SelectedTerms),
        // },
      ];
      let columns = [
        {
          dataField: "field",
          text: "",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "past",
          text: "Past Settings",
          headerStyle: { backgroundColor: "white" },
        },
        {
          dataField: "new",
          text: "New Settings",
          headerStyle: { backgroundColor: "white" },
        },
      ];

      return (
        <BootstrapTable
          bootstrap4
          condensed={true}
          bordered={false}
          striped={false}
          keyField="lock"
          data={data}
          columns={columns}
        />
      );
    };

    let handleUpdateSubscribe = () => {
      fetch("/data/updatesubscribe/" + customerid, {
        method: "POST",
        headers: {
          Cache: "no-cache",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSetting),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("Error in /agent/updatesubscribe:", response)
        }
      });

      setShowSubscribe(false);
      setShowSubscribeResult(false);
    };

    return (
      <Modal
        show={showSubscribeResult}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="ratesReportModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {alreadysubscribe
              ? "This email account has subscribed before"
              : "Done!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="alreadySubscribedModalBody">
          {!alreadysubscribe ? (
            <div>
              <p></p>
              <p style={{ fontSize: "130%" }}>
                {newSetting.Email} will receive rates report on a{" "}
                {newSetting.Frequency} basis.
              </p>
              <p></p>
            </div>
          ) : (
            showSubscribeMessageExisted()
          )}
        </Modal.Body>

        {alreadysubscribe ? (
          <Modal.Footer>
            {!props.isMobile && <Button variant="light" size="md" onClick={handleCloseModal}>
              Close
            </Button>}
            <Button variant="update" onClick={handleCloseModal}>
              Keep Past Setting
            </Button>
            <Button variant="update" onClick={handleUpdateSubscribe}>
              Use New Setting
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button variant="light" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };


  let sendRatesReport = () => {
    let html = document.getElementById("ratesreport").outerHTML

    if (previewEmail=="") {
      setPreviewResult("Please provide a valid email.")
      setShowRatePreview(false)
      return
    }

    let token = sessionStorage.getItem("ZeitroA")

    if (loProfile.firstName!==undefined) {
      html +=  `
      <div class="col"><p><b>${loProfile.firstName} ${loProfile.lastName}</b> | Loan Officer<br>
      ${loProfile.email} | ${loProfile.phone}<br>
      NMLS: ${loProfile.nmls}</p></div>
      `
    }
    setShowSendReportSpinner(true)
    fetch('/los/sendratesreport', {
      method: 'POST',
      body: JSON.stringify({
        Email: previewEmail,
        Subject: "Your Personalized Mortgage Rate",
        Body: "<html><body>" + html + "</body></html>"
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(
      response => {
        if (response.status !== 200) {
          alert("Something went srong when sending the email, please report this issue to Zeitro.")
          setShowRatePreview(false)
          setShowSubscribe(false)
        } else {
          alert("Rates report has been sent successfully.")
          setShowSendReportSpinner(false)
          setShowRatePreview(false)
          setPreviewEmail("")
          setPreviewResult("")
          setShowSubscribe(false)
        }
        response.json().then(js => {
          console.log(js)
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :', err);
      });
  }

  const getLenderNotSetMessage = () => {

    return  <div className="ratesWarning">
    <Row className="justify-content-md-center">
      <Col xs={1}>
        <img
          src="/images/warningSign.png"
          style={{ maxWidth: "20px" }}
        />
      </Col>
      <Col xs={11}>
      {(props.isFromLanding && !props.isFromPreview) ? "This functionality is not ready yet. Please check later." : <div><p>No lender has been configured yet.</p><p> If you are company owener, please go to "Management" - "Lenders" to config your lenders.</p></div>}
      </Col>
    </Row>
  </div>
  }

  const updateProperValue = (e) => {
    let val = e.target.value
    if (val==="") {
      setPropertyValue("")
    } else {
      setPropertyValue(parseInt(val))
    }
  }

  const updateDownpayment = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment("")
    } else {
      setDownpayment(parseInt(val))
    }
  }

  const updateLTV = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownpayment(0)
    } else {
      let p = parseInt(val)
      setDownpayment(Math.round(propertyValue * 0.01 * p))
    }
  }

  const items = [];

  const getDownpaymentDomain = () => {
    if (purpose === "Purchase") {
      return [propertyValue*0.03, propertyValue*0.6]
    } else {
      return [0, propertyValue*0.97]
    }
  }

  const displayResult = () => {
    if (isGMCCJumbo ) {
      return <div className="getRatesReport mb-4">
      <div className="getRatesReportCol1"><img className="getRatesReportImg" src="/images/getRatesReport.png" style={{maxWidth:"60px"}} /></div>
      <div className="getRatesReportCol2">
        <div className="getRatesReportTitle">Get your personalized high-balance, or jumbo rates</div>
        <div className="getRatesReportText">At GMCC, we have a variety of High-balance and Jumbo Programs to fit your personal needs, please contact our professional loan officers for more details. </div>
      </div>
      <div className="getRatesReportCol3">
        <Button
          variant="getRatesReportImgButton"
          onClick={()=>props.contact()}
          >
          Contact my loan officer
        </Button>
        </div>
      </div>
    }
    return (
      <div className="w-100">
              {loading ? (
        <Row>
          <div className="text-center w100 justify-content-center  align-content-center">
            <div id="spinner" className="pt-5 mt-4 ml-5 text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        </Row>
      ) : (
        <div className="pl-0 pr-0">
          {lenderNotSet && getLenderNotSetMessage()}
          {(!lenderNotSet && message === "") ? (
            <div>
              <div>{showCards()}</div>
              <div>{showRatesForMtype()}</div>
              {customerid === "gmcc" && <div className="px-2 ml-2">
                <div className="title-6 text-left mb-3">Disclaimer:</div>
                <div className="title-6 text-left text-wrap w-100">
                The rate quotations and program offerings detailed herein are exclusively applicable to applicants who can provide W-2 forms and have no more than two properties. As of now, quotes are only for conforming loan amount. These offerings are contingent upon the successful completion of our underwriting process and may be revised in response to market dynamics without prior notification. Furthermore, additional limitations may be enforced. It is imperative to understand that the information provided does not constitute a definitive credit decision.
                </div>
              </div>}
              {props.isFromLanding &&(<div className="getRatesReportDiv">
                <div className="getRatesReport">
                <div className="getRatesReportCol1"><img className="getRatesReportImg" src="/images/getRatesReport.png" style={{maxWidth:"60px"}} /></div>
                <div className="getRatesReportCol2">
                  <div className="getRatesReportTitle">Get your personalized rates report</div>
                  <div className="getRatesReportText">Enter your email address and delivery frequency, you will receive a personalized rates report based on your inputs. </div>
                </div>
                <div className="getRatesReportCol3">
                  <Button
                    variant="getRatesReportImgButton"
                    onClick={onSubscribe}
                    >
                    Get Rates Report
                  </Button>
                  </div>
                </div>
                  <div className="getRatesReportButton2">
                    <Button
                      variant="getRatesReportImgButton"
                      onClick={onSubscribe}
                      >
                      Get Rates Report
                    </Button>
                  </div>
              </div>)}
            </div>


          ) : (
            <div className="text-wrap w-100">{message}</div>
          )}
        </div>
      )}

      {showReportIssues && (
        <ReportIssuesModal
          show={true}
          onHide={() => setShowReportIssues(false)}
        />
      )}
      {/* {(showIneligible && !props.isFromLanding) && (
        <IneligibleProductsModal
          show={true}
          onHide={() => setShowIneligible(false)}
          ineligibleProducts={ineligibleProducts}
          message={message}
        />
      )} */}
      {showAllRates && (
        <AllRatesModal
          show={true}
          onHide={() => setShowAllRates(false)}
          allRates={allRates}
        />
      )}
      </div>
    )
  }


  return (
    <div
      id="contain"
      ref={ref}
      style={{ color: "black" }}
      className="w-100 text-center rateQuoteBorrowerVersion"
    >
      <div className={`${props.isLandingPage ? 'landingPage-div' : 'px-5'} py-4`} style={props.isFromLanding?{background:"rgb(247, 248, 252)"}:{background:"#F7F7F7"}}>
        <Col className="text-center landingPage-col" md="auto">
          <Form
            ref={form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Row>
              <Col md={4} xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Loan purpose
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    onChange={(e) => setPurpose(e.target.value)}
                    required
                  >
                    <option value="Purchase">Purchase</option>
                    <option value="RateTermRefi">Refinance</option>
                    <option value="CashOutRefi">Cash Out Refinance</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4} xs={6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>State</Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  >
                    {showStates()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {state !== "TEXAS" ? (
                <Col md={4} xs={6}>
                  <Form.Group className="text-left ">
                    <Form.Label style={{ marginBottom: "0" }}>
                      County
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      id="country"
                      name="country"
                      value={county}
                      onChange={(e) => setCounty(e.target.value)}
                      required
                    >
                      {showCounties()}
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}

              <Col md={4} xs={state === "TEXAS"? 12: 6}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    Credit score
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="input"
                    onChange={(e) =>
                      setFico(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      )
                    }
                    value={fico}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>

              <Col xs={12} md={4} lg={4}>
                <Form.Group className="text-left ">
                  <Form.Label style={{ marginBottom: "0" }}>
                    {purpose === "Purchase"
                      ? "Est. purchase price"
                      : "Home value"}
                  </Form.Label>
                  <div className="rateQuoterFormSliderOutline">
                    <div className="dollarSign" style={{fontSize:"16px", marginTop:"10px"}}>$</div>
                    {/* <div className="rateQuoterFormText">{"$ " + com.commaize(propertyValue)}</div> */}
                    <CommaizedInput style={{border:"none", width:"100%", fontSize:"16px", marginTop:"2px", maxHeight:"40px"}} value={propertyValue} onChange={updateProperValue} />
                    {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={[100000, 4000000]} defaultValues={[propertyValue]} onChange={setPropertyValue} onUpdate={setPropertyValue} step={10000}/></div> */}
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} md={4} lg={4} style={{display:"-webkit-inline-box"}}>
                <Form.Row>
                  <Col md={8} xs={8}>
                    <Form.Group className="text-left rate-quoter-downpay">
                      <Form.Label
                        className="text-left "
                        style={{ marginBottom: "0" }}
                      >
                        {purpose === "Purchase"
                          ? "Down payment"
                          : "Mortgage balance"}
                      </Form.Label>
                      <div className="rateQuoterFormSliderOutline">
                        <div className="dollarSign" style={{fontSize:"16px", marginTop:"10px"}}>$</div>
                        {/* <div className="rateQuoterFormText">{"$ " + com.commaize(downpayment)}</div> */}
                        <CommaizedInput style={{border:"none", width:"100%", fontSize:"16px",marginTop:"2px", maxHeight:"40px"}} value={downpayment} onChange={updateDownpayment} />
                        {/* <div className="rateQuoterFormSlider"><HorizontalSliderV2 domain={getDownpaymentDomain()} defaultValues={[downpayment]} onChange={setDownpayment} onUpdate={setDownpayment} step={1000}/></div> */}
                      </div>
                    </Form.Group>
                  </Col>
                  {purpose === "Purchase" ? <Col md={4} xs={4}><Input className="rate-quoter-ltv" suffix="%" defaultValue={100-getLtv()[0]} value={100-getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{borderRadius: "4px"}}/></Col> :
                    <Col md={4} xs={4}><Input className="rate-quoter-ltv" suffix="%" defaultValue={getLtv()[0]} value={getLtv()[0]} onChange={e => updateLTV(e)} type="number" style={{borderRadius: "4px"}}/></Col>
                  }
                </Form.Row>
              </Col>

              <Col xs={6} md={4} lg={4}>
                <Form.Group className="text-left monthlyIncomeWrap">
                    <Form.Label style={{ marginBottom: "0" }}>
                      Monthly income
                    </Form.Label>
                    <NumericalInput
                      size="sm"
                      value={monthlyIncome}
                      required
                      onChange={(e) => setMonthlyIncome(e.target.value)}
                      min={0}
                    />
                </Form.Group>
              </Col>
              <Col xs={6} md={4} lg={4}>
                <Form.Group className="text-left monthlyDebtWrap">
                  <Form.Label style={{ marginBottom: "0" }}>
                  Monthly liability
                  </Form.Label>
                  <NumericalInput
                    size="sm"
                    value={monthlyDebt}
                    required
                    onChange={(e) => setMonthlyDebt(e.target.value)}
                    min={0}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <Form.Group controlId="propertytype" className="text-left ">
                        <Form.Label style={{ marginBottom: "0" }}>
                          Property type
                        </Form.Label>
                        <Form.Control
                          name="propertytype"
                          as="select"
                          size="sm"
                          type="text"
                          required
                          value={propertyType}
                          onChange={(e) => setPropertyType(e.target.value)}
                        >
                          <option value="singlefamilydetached">
                            Single Family Home, Detached
                          </option>
                          <option value="singlefamilyattached">
                            Single Family Home, Attached
                          </option>
                          <option value="condominiumdetached">
                            Condominium, Detached
                          </option>
                          <option value="condominium">Condominium, Attached</option>
                          <option value="pudattached">
                            Planned Unit Development, attached
                          </option>
                          <option value="puddetached">
                            Planned Unit Development, detached
                          </option>
                          <option value="twotofourfamily">
                            2 to 4 Unit Multifamily
                          </option>
                          <option value="manufacturedhome">
                            Manufactured Home
                          </option>
                        </Form.Control>
                      </Form.Group>
              </Col>
              {state === "TEXAS" && <Col md={4}>{""}</Col>}
              <Col xs={12} md={4} className="filterandButtonWrap">
                <Dropdown
                  menu={{
                    items,
                  }}
                    dropdownRender={(menu) => (
                            <div id="filtersDropdown">
                              {React.cloneElement(menu, {
                      style: {
                        boxShadow: 'none',
                      },
                    })}
                        <Space
                          style={{
                            padding: 30,
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
                            backgroundColor:"white",
                          }}
                          className="d-block"
                        >
                          <div>
                    </div>
                    {propertyType === "twotofourfamily" ? (
                      <div style={{ width: '200px'}}>
                        <Form.Group controlId="propertytype" className="text-left ">
                          Number of Units
                          <Form.Control
                            name="propertytype"
                            as="select"
                            size="sm"
                            type="text"
                            required
                            value={units}
                            onChange={(e) => setUnits(e.target.value)}
                          >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    ) : (
                      ""
                    )}
                    <div style={{ width: '200px'}}>
                      <Form.Group controlId="occupancy" className="text-left ">
                        <Form.Label
                          className="text-left "
                          style={{ marginBottom: "0" }}
                        >
                          Occupancy
                        </Form.Label>
                        <Form.Control
                          name="occupancy"
                          as="select"
                          size="sm"
                          defaultValue={occupancy}
                          onChange={(e) => {
                            onOccupancy(e.target.value);
                          }}
                        >
                          <option value="principal">Primary Residence</option>
                          <option value="secondhome">Second Home</option>
                          <option value="investment">Investment</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div style={{ width: '200px'}}>
                        <Form.Group controlId="impound" className="text-left ">
                          Impound/Escrow
                          <Form.Control
                            name="propertytype"
                            as="select"
                            size="sm"
                            type="text"
                            required
                            value={escrowWaiver}
                            onChange={(e) => setEscrowWaiver(e.target.value === "true" ? true : false)}
                          >
                            <option value={true}>No</option>
                            <option value={false}>Yes</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                        </Space>
                      </div>
                    )}
                  >
                    <Space className="dropdownFilter">
                    <img src="/images/moreFilters.png" className="dropdownFilterImage" />
                      More filters
                    </Space>
                </Dropdown>
                <Button type="submit" style={{ marginTop: 0}} variant="rate-quoter-update">
                    Check rates
                </Button>
              </Col>

            </Form.Row>
          </Form>
        </Col>
      </div>
      {displayResult()}
      {showEditCostDetails ? getEditCostsModal() : ""}
      {showSubscribeResult ? getSubscribeResultModal() : ""}
      {showSubscribe && !showSubscribeResult ? getSubscribeModal() : ""}
    </div>
  );
};
export default withRouter(RateQuoteBorrowerVersion);
