import {
    LeftOutlined,
    MinusOutlined,
    PlusOutlined,
    RightOutlined,
} from '@ant-design/icons'
import { Input, Skeleton } from 'antd'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Document, Page } from 'react-pdf'
import { VariableSizeList as List } from 'react-window'
import {
    MOST_POPULAR_GUIDELINES,
    OTHER_GUIDELINES,
    getPdfType,
} from './constants'
import './PDFViewer.css'
import Worker from './pdfWorker.js'

const MIN_SCALE = 0.5
const MAX_SCALE = 3
const SCALE_STEP = 0.1
const PAGE_GAP = 0

function PDFViewer(props) {
    const { file, jumpEmitter, mountEmitter, isMobile, setMobileAIChat } = props

    const [numPages, setNumPages] = useState(null)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [inputPageNumber, setInputPageNumber] = useState('1')
    const [highlightPageNumber, setHighlightPageNumber] = useState(null)
    const [highlightCoordinates, setHighlightCoordinates] = useState([])
    const [scale, setScale] = useState(1)
    const [isZooming, setIsZooming] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [pages, setPages] = useState([])
    const containerRef = useRef(null)
    const pageRefs = useRef({})
    const listRef = useRef(null)
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
    const [firstPageRendered, setFirstPageRendered] = useState(false)

    const prevPages = useRef([])
    const aiChatWrapperRef = useRef(null)

    const isFirstPage = useMemo(
        () => currentPageNumber <= 1,
        [currentPageNumber]
    )
    const isLastPage = useMemo(
        () => currentPageNumber >= numPages,
        [currentPageNumber, numPages]
    )

    const contentType = useSelector((state) => state.common.content)
    const guidelineName = useMemo(() => {
        if (isEmpty(contentType)) return ''
        const guideline = [
            ...MOST_POPULAR_GUIDELINES,
            ...OTHER_GUIDELINES,
        ].find((guideline) => guideline.id === contentType)
        return guideline ? guideline.value : ''
    }, [contentType])

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect
                // Check if containerRef.current exists before accessing its properties
                if (containerRef.current) {
                    const offsetWidth = containerRef.current.offsetWidth
                    const clientWidth = containerRef.current.clientWidth
                    // Subtract scrollbar width from container width
                    const scrollbarWidth = offsetWidth - clientWidth
                    setContainerSize({ width: width - scrollbarWidth, height })
                }
            }
        })

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current)
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current)
            }
        }
    }, [])

    useEffect(() => {
        prevPages.current = pages
    }, [pages])

    useEffect(() => {
        const processFile = async () => {
            if (file) {
                setIsLoading(true)
                setCurrentPageNumber(1)
                setInputPageNumber('1')
                setHighlightPageNumber(null)
                setHighlightCoordinates([])

                const result = await Worker.processFile(file, 1)
                if (result.type === 'success') {
                    setNumPages(result.numPages)
                    setPages(result.pages)

                    // Initialize scale with the first page's scale
                    if (containerSize.width > 0 && containerSize.height > 0) {
                        const initialScale = calculateScale(
                            result.pages[0],
                            containerSize.width,
                            containerSize.height
                        )
                        setScale(initialScale)
                    }

                    setIsLoading(false)
                } else if (result.type === 'error') {
                    console.error('Error loading PDF:', result.error)
                    setIsLoading(false)
                }
            }
        }

        processFile()
    }, [file])

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0)
        }
    }, [scale, pages])

    // Subscribe to the jump event
    jumpEmitter.useSubscription((val) => {
        const { pageNumber, pageCoordinates } = val
        setHighlightCoordinates(pageCoordinates)
        if (Array.isArray(pageCoordinates) && pageCoordinates.length > 0) {
            setHighlightPageNumber(pageNumber)
        }
        // setTimeout(() => scrollToPage(pageNumber), 1000)
        scrollToPage(pageNumber)
    })

    useEffect(() => {
        if (isMobile && pages.length > 0 && firstPageRendered) {
            // Ensure PDF content is rendered
            mountEmitter.emit()
        }
    }, [isMobile, pages, firstPageRendered])

    const calculateScale = useCallback(
        (page, containerWidth, containerHeight) => {
            if (!page) return 1

            const pageWidth = page.viewport.width
            const pageHeight = page.viewport.height

            const scaleX = containerWidth / pageWidth
            const scaleY = containerHeight / pageHeight

            let newScale = Math.min(scaleX, scaleY)
            newScale = Math.max(newScale, MIN_SCALE)
            newScale = Math.min(newScale, MAX_SCALE)

            return newScale
        },
        []
    )

    useEffect(() => {
        if (
            pages.length > 0 &&
            containerSize.width > 0 &&
            containerSize.height > 0
        ) {
            const newScale = calculateScale(
                pages[0],
                containerSize.width,
                containerSize.height
            )
            if (
                Math.abs(newScale - scale) >= SCALE_STEP ||
                pages[0] !== prevPages.current[0]
            ) {
                setScale(newScale)
            }
        }
    }, [pages, containerSize, calculateScale])

    const getItemDimensions = useCallback(
        (index) => {
            if (!pages[index]) return { width: 0, height: 0 }
            const { width, height } = pages[index].viewport
            return {
                width: width * scale,
                height: height * scale + PAGE_GAP,
            }
        },
        [pages, scale]
    )

    /**
     * VariableSizeList needs a function to get the size of each item
     */
    const getItemSize = useCallback(
        (index) => getItemDimensions(index).height,
        [getItemDimensions]
    )

    const scrollToPage = useCallback(
        (pageNumber) => {
            if (listRef.current && pages.length > 0) {
                listRef.current.scrollToItem(pageNumber - 1, 'start')
                setCurrentPageNumber(pageNumber)
                setInputPageNumber(pageNumber.toString())
            }
        },
        [listRef, pages.length]
    )

    const handleScroll = useCallback(() => {
        if (!listRef.current || isZooming) return

        const listElement = listRef.current._outerRef
        const scrollTop = listElement.scrollTop
        const clientHeight = listElement.clientHeight

        let totalHeight = 0
        let newPageNumber = 1

        for (let i = 0; i < pages.length; i++) {
            const pageHeight = getItemSize(i)
            const pageTop = totalHeight
            const pageBottom = pageTop + pageHeight

            if (scrollTop + clientHeight / 2 < pageBottom) {
                newPageNumber = i + 1
                break
            }

            totalHeight += pageHeight
        }

        if (newPageNumber !== currentPageNumber) {
            setCurrentPageNumber(newPageNumber)
            setInputPageNumber(newPageNumber.toString())
        }
    }, [currentPageNumber, getItemSize, pages.length])

    const handlePrevious = useCallback(() => {
        const newPageNumber = Math.max(currentPageNumber - 1, 1)
        scrollToPage(newPageNumber)
    }, [scrollToPage, currentPageNumber])

    const handleNext = useCallback(() => {
        const newPageNumber = Math.min(currentPageNumber + 1, numPages)
        scrollToPage(newPageNumber)
    }, [scrollToPage, numPages, currentPageNumber])

    const handleInputChange = useCallback(
        (e) => {
            const value = e.target.value
            if (value === '') {
                setInputPageNumber('')
                return
            }
            if (/^\d+$/.test(value)) {
                const newValue = parseInt(value, 10)
                if (newValue === 0) {
                    setInputPageNumber('')
                } else if (newValue > numPages) {
                    setInputPageNumber(numPages.toString())
                } else {
                    setInputPageNumber(newValue.toString())
                }
            }
        },
        [numPages, setInputPageNumber]
    )

    const handleInputKeyPress = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                const newPage = parseInt(inputPageNumber, 10) || 1
                if (newPage >= 1 && newPage <= numPages) {
                    scrollToPage(newPage)
                } else {
                    setInputPageNumber(currentPageNumber.toString())
                }
            }
        },
        [
            scrollToPage,
            numPages,
            currentPageNumber,
            inputPageNumber,
            setInputPageNumber,
        ]
    )

    const handleInputBlur = useCallback(() => {
        if (
            inputPageNumber === '' ||
            parseInt(inputPageNumber, 10) < 1 ||
            parseInt(inputPageNumber, 10) > numPages
        ) {
            setInputPageNumber(currentPageNumber.toString())
        }
    }, [numPages, currentPageNumber, inputPageNumber])

    const handleZoom = useCallback(
        (newScale) => {
            if (!listRef.current) return

            const listElement = listRef.current._outerRef
            const containerHeight = listElement.clientHeight
            const containerWidth = listElement.clientWidth
            const currentScrollTop = listElement.scrollTop
            const currentScrollLeft = listElement.scrollLeft

            // Calculate the center point of the current page relative to the container's visible area
            let currentPageTop = 0
            for (let i = 0; i < currentPageNumber - 1; i++) {
                currentPageTop += getItemDimensions(i).height
            }
            const { width: currentPageWidth, height: currentPageHeight } =
                getItemDimensions(currentPageNumber - 1)
            const currentPageLeft = Math.max(
                0,
                (containerWidth - currentPageWidth) / 2
            )

            // Calculate the center point of the current page
            const currentPageVerticalCenter =
                currentPageTop + currentPageHeight / 2
            const currentPageHorizontalCenter =
                currentPageLeft + currentPageWidth / 2

            // Calculate the relative position of the viewport center to the page center
            const relativeVerticalPosition =
                (currentPageVerticalCenter - currentScrollTop) / containerHeight
            const relativeHorizontalPosition =
                (currentPageHorizontalCenter - currentScrollLeft) /
                containerWidth

            setIsZooming(true)
            setScale((prevScale) => {
                // Adjust the center point of the current page after zooming
                requestAnimationFrame(() => {
                    const scaleFactor = newScale / prevScale
                    const newPageTop = currentPageTop * scaleFactor
                    const newPageLeft = Math.max(
                        0,
                        (containerWidth - currentPageWidth * scaleFactor) / 2
                    )
                    const newPageHeight = currentPageHeight * scaleFactor
                    const newPageWidth = currentPageWidth * scaleFactor
                    const newPageVerticalCenter = newPageTop + newPageHeight / 2
                    const newPageHorizontalCenter =
                        newPageLeft + newPageWidth / 2

                    const newScrollTop =
                        newPageVerticalCenter -
                        relativeVerticalPosition * containerHeight
                    const newScrollLeft =
                        newPageHorizontalCenter -
                        relativeHorizontalPosition * containerWidth

                    listElement.scrollTop = newScrollTop
                    listElement.scrollLeft = newScrollLeft
                })

                return newScale
            })
            setIsZooming(false)
        },
        [currentPageNumber, getItemDimensions]
    )

    const drawHighlights = useCallback(
        (canvas) => {
            if (!canvas) return

            if (
                Array.isArray(highlightCoordinates) &&
                highlightCoordinates.length > 0
            ) {
                // Create a new canvas for highlights
                const ctx = canvas.getContext('2d')
                ctx.fillStyle = 'rgba(198, 50, 235, 0.2)'

                highlightCoordinates.forEach((polygon) => {
                    ctx.beginPath()
                    polygon.forEach((point, index) => {
                        const x = point[0] * canvas.width
                        const y = point[1] * canvas.height
                        if (index === 0) {
                            ctx.moveTo(x, y)
                        } else {
                            ctx.lineTo(x, y)
                        }
                    })
                    ctx.closePath()
                    ctx.fill()
                })
            }
        },
        [highlightCoordinates]
    )

    const Row = useCallback(
        ({ index, style }) => {
            const page = pages[index]
            if (!page) return null

            const pageNumber = index + 1
            const { width: pageWidth, height: pageHeight } =
                getItemDimensions(index)

            return (
                <div
                    style={{
                        ...style,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        minWidth: `${pageWidth}px`,
                        paddingBottom: `${PAGE_GAP}px`,
                        height: `${pageHeight - PAGE_GAP}px`,
                        transition: 'all 0.3s ease',
                    }}
                    ref={(el) => (pageRefs.current[pageNumber] = el)}
                    data-page-number={pageNumber}
                >
                    <Page
                        key={`page_${pageNumber}`}
                        pageNumber={pageNumber}
                        scale={scale}
                        renderTextLayer={true}
                        renderAnnotationLayer={false}
                        loading={<div style={{ visibility: 'hidden' }}></div>}
                        onRenderSuccess={() => {
                            if (pageNumber === 1) {
                                setFirstPageRendered(true)
                            }
                            if (
                                highlightPageNumber &&
                                pageNumber === highlightPageNumber
                            ) {
                                const canvas =
                                    pageRefs.current[
                                        highlightPageNumber
                                    ]?.querySelector('canvas')
                                drawHighlights(canvas)
                            }
                        }}
                        canvasRef={(canvas) => {
                            if (canvas) {
                                canvas.setAttribute('data-sl', 'canvas-hq')
                            }
                        }}
                    />
                </div>
            )
        },
        [
            drawHighlights,
            pages,
            scale,
            highlightPageNumber,
            pageRefs,
            getItemDimensions,
        ]
    )

    if (isLoading) {
        return (
            <div className="pdf-container" ref={containerRef}>
                <div
                    className="p-2"
                    style={{ minHeight: '200px', width: '100%' }}
                >
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </div>
            </div>
        )
    }

    return (
        <div className="pdf-container" ref={containerRef}>
            {isMobile && (
                <div className="pdf-controls-mobile">
                    <span className="guideline-name">{guidelineName}</span>
                    <div className="page-navigation">
                        <button
                            onClick={handlePrevious}
                            disabled={isFirstPage}
                            className="pdf-controls-button"
                        >
                            <LeftOutlined
                                style={{
                                    color: isFirstPage ? '#d9d9d9' : '#6e6e70',
                                    cursor: isFirstPage
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                            />
                        </button>
                        <Input
                            value={inputPageNumber}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            onBlur={handleInputBlur}
                            className="page-input"
                            placeholder="1"
                        />
                        <span className="pdf-page-number">/{numPages}</span>
                        <button
                            onClick={handleNext}
                            disabled={isLastPage}
                            className="pdf-controls-button"
                        >
                            <RightOutlined
                                style={{
                                    color: isLastPage ? '#d9d9d9' : '#6e6e70',
                                    cursor: isLastPage
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                            />
                        </button>
                    </div>
                </div>
            )}
            <Document file={file}>
                {numPages && (
                    <List
                        ref={listRef}
                        width={containerSize.width}
                        height={containerSize.height}
                        itemCount={numPages}
                        itemSize={getItemSize}
                        onScroll={handleScroll}
                        style={{
                            willChange: 'transform',
                            overscrollBehavior: 'none',
                        }}
                    >
                        {Row}
                    </List>
                )}
            </Document>
            {isMobile ? (
                <div
                    className="floating-button-wrapper"
                    ref={aiChatWrapperRef}
                >
                    <div
                        className="ai-chat-button"
                        onClick={() => setMobileAIChat(true)}
                    >
                        <img src="/images/ai-chat.svg" alt="AI Chat" />
                        <span>Chat</span>
                    </div>
                </div>
            ) : (
                <div className="pdf-controls">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '30px',
                        }}
                    >
                        <button
                            onClick={handlePrevious}
                            disabled={isFirstPage}
                            className="pdf-controls-button"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '8px',
                            }}
                        >
                            <LeftOutlined />
                        </button>
                        <Input
                            value={inputPageNumber}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            onBlur={handleInputBlur}
                            className="pdf-controls-input"
                            placeholder="1"
                        />
                        <span style={{ marginLeft: '8px' }}>/{numPages}</span>
                        <button
                            onClick={handleNext}
                            disabled={isLastPage}
                            className="pdf-controls-button"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '8px',
                            }}
                        >
                            <RightOutlined />
                        </button>
                    </div>
                    <div className="pdf-controls-divider"></div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '30px',
                        }}
                    >
                        <button
                            onClick={() =>
                                handleZoom(
                                    Math.max(scale - SCALE_STEP, MIN_SCALE)
                                )
                            }
                            className="pdf-controls-button"
                            style={{ width: '30px', height: '30px' }}
                            disabled={scale <= MIN_SCALE || isZooming}
                        >
                            <MinusOutlined />
                        </button>
                        <span style={{ marginLeft: '8px', marginRight: '8px' }}>
                            {Math.round(scale * 100)}%
                        </span>
                        <button
                            onClick={() =>
                                handleZoom(
                                    Math.min(scale + SCALE_STEP, MAX_SCALE)
                                )
                            }
                            className="pdf-controls-button"
                            style={{ width: '30px', height: '30px' }}
                            disabled={scale >= MAX_SCALE || isZooming}
                        >
                            <PlusOutlined />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PDFViewer
