import {
  affluent_states,
  cronus_qualifiedCounties,
  EProgramType,
  fiveK_states,
  hermes_states,
  ocean_states,
  MSAtoCountyList_nonagency_full,
  radiant_states,
  tenK_qualifiedCounties,
  thirtyK_qualifiedCounties,
  universe_qualifiedCounties,
  zeroDown_qualifiedCounties,
} from "../programs_consts";
import * as com from "../../../Common";
/**
 * Options for program type.
 */
export const programTypeOptions: {
  value: "" | EProgramType;
  label: string;
}[] = [
  { value: "", label: "Select a program" },
  { value: EProgramType.GRANDSLAM, label: "GMCC Grandslam" },
  { value: EProgramType.CONFORMING, label: "GMCC Special Conforming" },
  { value: EProgramType.NONAGENCY, label: "GMCC Jumbo CRA" },
  { value: EProgramType.DIAMOND, label: "GMCC Diamond Community Lending" },
  { value: EProgramType.CELEBRITY, label: "GMCC $10K - $15K Grants" },
];

export interface IPagination {
  current: number;
  pageSize: number;
  showSizeChanger: boolean;
}

/**
 * Get qualified programs for a listing.
 * @param listing - The listing data.
 * @param propertyState - The state of the property.
 * @param propertyCounty - The county of the property.
 * @param listingTarget - The target of the listing.
 * @param selectedMsa - The selected MSA.
 * @returns The qualified programs for the listing.
 */
export const getQualifiedProgramsForListing = (
  listing: any,
  propertyStateSource: string,
  propertyCounty: string,
  listingTarget: string,
  selectedMsa: string
): {
  flierOptions: {
    value: string;
    label: string;
  }[];
  results: {
    key: string;
    label: string;
  }[];
} => {
  const propertyStateAbbr = com.convertStateToAbbr(propertyStateSource);
  const results = [];
  const flierOptions = [
    {
      value: "",
      label: "Select a program",
    },
  ];
  let count = 0;
  const propertyState = com.convertAbbStateToFull(propertyStateSource);
  if (propertyState !== "" && propertyCounty !== "" && listingTarget !== "") {
    if (
      selectedMsa !== "" &&
      (100 * (parseInt(listing.black) + parseInt(listing.hispanic))) /
        listing.totalpopulation >
        50 &&
      (listing.incomeindicator === "1" || listing.incomeindicator === "2")
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Community Special",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Community Special",
      });
      count += 1;
    }
    if (
      cronus_qualifiedCounties[propertyState] !== undefined &&
      (cronus_qualifiedCounties[propertyState].length === 0 ||
        cronus_qualifiedCounties[propertyState].includes(
          propertyCounty.replace("County", "").trim()
        )) &&
      (listing.incomeindicator === "1" || listing.incomeindicator === "2")
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Grandslam",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Grandslam",
      });
      count += 1;
    }

    // JUMBO CRA
    if (
      checkForJumboCRA(
        propertyStateAbbr,
        propertyCounty,
        listing,
        selectedMsa || ""
      )
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Jumbo CRA",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Jumbo CRA",
      });
      count += 1;
    }

    if (
      fiveK_states !== undefined &&
      (fiveK_states.length !== 0 && fiveK_states.includes(propertyState)) &&
      (listing.minority > 50 ||
        listing.incomeindicator === "1" ||
        listing.incomeindicator === "2")
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Celebrity $5K Grant",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Celebrity $5K Grant",
      });
      count += 1;
    }
    if (
      tenK_qualifiedCounties[propertyState] !== undefined &&
      (tenK_qualifiedCounties[propertyState].length === 0 ||
        tenK_qualifiedCounties[propertyState].includes(
          propertyCounty.replace("County", "").trim()
        )) &&
      (listing.minority > 50 ||
        listing.incomeindicator === "1" ||
        listing.incomeindicator === "2")
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Celebrity $10K Grant",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Celebrity $10K Grant",
      });
      count += 1;
    }
    if (
      thirtyK_qualifiedCounties[propertyState] !== undefined &&
      (thirtyK_qualifiedCounties[propertyState].length === 0 ||
        thirtyK_qualifiedCounties[propertyState].includes(
          propertyCounty.replace("County", "").trim()
        )) &&
      (listing.minority > 50 ||
        listing.incomeindicator === "1" ||
        listing.incomeindicator === "2")
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Celebrity $15K Grant",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Celebrity $15K Grant",
      });
      count += 1;
    }
    if (
      universe_qualifiedCounties[propertyState] !== undefined &&
      (universe_qualifiedCounties[propertyState].length === 0 ||
        universe_qualifiedCounties[propertyState].includes(
          propertyCounty.replace("County", "").trim()
        ))
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Universe",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Universe",
      });
      count += 1;
    }
    if (
      zeroDown_qualifiedCounties[propertyState] !== undefined &&
      (zeroDown_qualifiedCounties[propertyState].length === 0 ||
        zeroDown_qualifiedCounties[propertyState].includes(
          propertyCounty.replace("County", "").trim()
        )) &&
      (listing.minority > 50 ||
        listing.incomeindicator === "1" ||
        listing.incomeindicator === "2")
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Community Opportunity",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Community Opportunity",
      });
      count += 1;
    }
    if (hermes_states.includes(propertyState)) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Hermes",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Hermes",
      });
      count += 1;
    }
    if (ocean_states.includes(propertyState)) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Ocean",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Ocean",
      });
      count += 1;
    }
    if (radiant_states.includes(propertyState)) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Radiant",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Radiant",
      });
      count += 1;
    }

    if (
      affluent_states.includes(propertyState) &&
      parseInt(listing.price) >= 1000000
    ) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Celebrity Jumbo",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Celebrity Jumbo",
      });
      count += 1;
    }

    if (parseInt(listing.price) >= 500000) {
      flierOptions.push({
        value: `${count}`,
        label: "GMCC - Jumbo Express",
      });
      results.push({
        key: `massiveresult ${count}`,
        label: "GMCC - Jumbo Express",
      });
      count += 1;
    }

    flierOptions.push({
      value: `${count}`,
      label: "GMCC - Bank Statement Self Employed",
    });
    results.push({
      key: `massiveresult ${count}`,
      label: "GMCC - Bank Statement Self Employed",
    });
    count += 1;

    flierOptions.push({
      value: `${count}`,
      label: "GMCC - Buy Without Sell First",
    });
    results.push({
      key: `massiveresult ${count}`,
      label: "GMCC - Buy Without Sell First",
    });
    count += 1;

    flierOptions.push({
      value: `${count}`,
      label: "GMCC - WVOE P&L",
    });
    results.push({
      key: `massiveresult ${count}`,
      label: "GMCC - WVOE P&L",
    });
    count += 1;

    flierOptions.push({
      value: `${count}`,
      label: "GMCC - DSCR Rental Flow",
    });
    results.push({
      key: `massiveresult ${count}`,
      label: "GMCC - DSCR Rental Flow",
    });
    count += 1;

    flierOptions.push({
      value: `${count}`,
      label: "GMCC - Diamond Community Lending",
    });
    results.push({
      key: `massiveresult ${count}`,
      label: "GMCC - Diamond Community Lending",
    });
    count += 1;
  }
  return { flierOptions, results };
};

export const checkForJumboCRA = (
  propertyStateAbbr: string,
  propertyCounty: string,
  listing: any,
  selectedMsa: string | ""
) => {
  // return false if the listing is not low to moderate income census tract (LMI CT)
  if (!(listing.incomeindicator === "1" || listing.incomeindicator === "2")) {
    return false;
  }
  if (MSAtoCountyList_nonagency_full[propertyStateAbbr] !== undefined) {
    // if selectedMsa is provided, check if the property county is in the selected MSA
    if (selectedMsa) {
      if (
        MSAtoCountyList_nonagency_full[propertyStateAbbr][selectedMsa] !==
          undefined &&
        (MSAtoCountyList_nonagency_full[propertyStateAbbr][selectedMsa]
          .length === 0 ||
          MSAtoCountyList_nonagency_full[propertyStateAbbr][
            selectedMsa
          ].includes(propertyCounty))
      )
        return true;
    }
    // if selectedMsa is not provided, check if the property county is in any MSA
    else {
      for (const msa in MSAtoCountyList_nonagency_full[propertyStateAbbr]) {
        if (
          MSAtoCountyList_nonagency_full[propertyStateAbbr][msa].includes(
            propertyCounty
          )
        )
          return true;
      }
    }
  }
  return false;
};
