import React from "react";
import { IProgram, IGroupedPrograms } from "./types";
import { filter, map } from "lodash";

export const flyerRootPath = "/images/gmccflyers/letter_size";

export const getTemplateFilePath = (order: number, fileName: string) => {
  return `${flyerRootPath}/Template_${order
    .toString()
    .padStart(2, "0")}_${fileName}`;
};

export const getOriginalFilePath = (order: number, fileName: string) => {
  const path = `${flyerRootPath}/${order
    .toString()
    .padStart(2, "0")}_${fileName}`;
  return path;
};

export const flyerAIVideoRootPath = "/images/gmccflyers/videos";

export const getAIVideoFilePath = (order: number, videoFileName: string) => {
  return `${flyerAIVideoRootPath}/${order
    .toString()
    .padStart(2, "0")}_${videoFileName}`;
};

export const programs: IProgram[] = [
  {
    order: 1,
    label: "GMCC - Jumbo Express",
    key: "gmcc_jumbo_express",
    value: "gmcc_jumbo_express",
    fileName: "GMCCJumboExpress.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 2,
    label: "GMCC - No Ratio Radiant",
    key: "gmcc_no_ratio_radiant",
    value: "gmcc_no_ratio_radiant",
    fileName: "GMCCNoRatioRadiant.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 3,
    label: "GMCC - Foreign Nationals",
    key: "gmcc_foreign_nationals",
    value: "gmcc_foreign_nationals",
    fileName: "GMCCForeignNationals.png",
    hasTemplate: true,
    hasOriginal: true,
    hasAIVideo: true,
    videoStreamingUrl:
      "https://drive.google.com/file/d/1on0UAsO6wqATrlI8xH_E78olw1XxBp3a/preview",
    videoDownloadUrl:
      "https://drive.google.com/uc?export=download&id=1on0UAsO6wqATrlI8xH_E78olw1XxBp3a",
  },
  {
    order: 4,
    label: "GMCC - 0% Down",
    key: "gmcc_0_percent_down",
    value: "gmcc_0_percent_down",
    fileName: "0PercentDown.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 5,
    label: "GMCC - 40% Down (No Income, No Employment needed)",
    key: "gmcc_40_percent_down_no_income",
    value: "gmcc_40_percent_down_no_income",
    fileName: "40PercentDownNoIncome.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 6,
    label: "GMCC - Celebrity Jumbo",
    key: "gmcc_affluent_celebrity_20_low_rate",
    value: "gmcc_affluent_celebrity_20_low_rate",
    fileName: "GMCCAffluentCelebrity20LowRate.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 7,
    label: "GMCC - Hermes (Alternatives)",
    key: "gmcc_hermes_alternatives",
    value: "gmcc_hermes_alternatives",
    fileName: "GMCCHermesAlternatives.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 8,
    label: "GMCC - Universe CRA Outreach",
    key: "gmcc_universe_cra_outreach",
    value: "gmcc_universe_cra_outreach",
    fileName: "UniverseCRAOutreach.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 9,
    label: "GMCC - Buy Without Sell First",
    key: "gmcc_buy_without_sell_first",
    value: "gmcc_buy_without_sell_first",
    fileName: "BuyWithoutSellFirst.png",
    hasTemplate: true,
    hasOriginal: true,
    loVideos: {
      "vivian.wan@gmccloan.com": {
        streamingUrl: "https://drive.google.com/file/d/1W-a20aBRa4iym3cC__Gx0cgFHmzWMsUv/preview",
        downloadUrl: "https://drive.google.com/uc?export=download&id=1W-a20aBRa4iym3cC__Gx0cgFHmzWMsUv",
      },
      "yingjie@gmcc.com": {
        streamingUrl: "https://drive.google.com/file/d/1W-a20aBRa4iym3cC__Gx0cgFHmzWMsUv/preview",
        downloadUrl: "https://drive.google.com/uc?export=download&id=1W-a20aBRa4iym3cC__Gx0cgFHmzWMsUv",
      },
      "jma@gmccloan.com": {
        streamingUrl: "https://drive.google.com/file/d/1k4cI0QuXvdPcVapQgevMXaGraXNPaHJZ/preview",
        downloadUrl: "https://drive.google.com/uc?export=download&id=1k4cI0QuXvdPcVapQgevMXaGraXNPaHJZ",
      },
      "jjin@gmccloan.com": {
        streamingUrl: "https://drive.google.com/file/d/1k4cI0QuXvdPcVapQgevMXaGraXNPaHJZ/preview",
        downloadUrl: "https://drive.google.com/uc?export=download&id=1k4cI0QuXvdPcVapQgevMXaGraXNPaHJZ",
      },
    },
  },
  {
    order: 10,
    label: "GMCC - Bank Statement Self Employed",
    key: "gmcc_bank_statement_self_employed",
    value: "gmcc_bank_statement_self_employed",
    fileName: "BankStatementSelfEmployed.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 11,
    label: "GMCC - DSCR",
    key: "gmcc_dscr",
    value: "gmcc_dscr",
    fileName: "DSCR.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 13,
    label: "GMCC - Free $10K",
    key: "gmcc_free_10k",
    value: "gmcc_free_10k",
    fileName: "Free10K.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 14,
    label: "GMCC - Free $15K",
    key: "gmcc_free_15k",
    value: "gmcc_free_15k",
    fileName: "Free15K.png",
    hasTemplate: true,
    hasOriginal: true,
    loVideos: {
      "jma@gmccloan.com": {
        streamingUrl: "https://drive.google.com/file/d/1S7fS4RRIZLyPVVYz4e6OME2XELAGSodI/preview",
        downloadUrl: "https://drive.google.com/uc?export=download&id=1S7fS4RRIZLyPVVYz4e6OME2XELAGSodI",
      },
      "jjin@gmccloan.com": {
        streamingUrl: "https://drive.google.com/file/d/1S7fS4RRIZLyPVVYz4e6OME2XELAGSodI/preview",
        downloadUrl: "https://drive.google.com/uc?export=download&id=1S7fS4RRIZLyPVVYz4e6OME2XELAGSodI",
      },
    },
  },

  {
    order: 17,
    label: "GMCC - Diamond Community Lending",
    key: "gmcc_diamond_community_lending",
    value: "gmcc_diamond_community_lending",
    fileName: "GMCCDiamondCommunityLending.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 18,
    label: "GMCC - Grandslam",
    key: "gmcc_grandslam",
    value: "gmcc_grandslam",
    fileName: "GMCCGrandslam.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 19,
    label: "GMCC - Conforming Special",
    key: "gmcc_conforming_special",
    value: "gmcc_conforming_special",
    fileName: "GMCCConformingSpecial.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 20,
    label: "GMCC - WVOE and P&L",
    key: "gmcc_wvoe_and_p_and_l",
    value: "gmcc_wvoe_and_p_and_l",
    fileName: "WVOEAndPAndL.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 21,
    label: "GMCC - Special Buy Down",
    key: "gmcc_special_buy_down",
    value: "gmcc_special_buy_down",
    fileName: "GMCCSpecialBuyDown.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 22,
    label: "GMCC - Jumbo CRA Program With Rates",
    key: "gmcc_jumbo_cra_program",
    value: "gmcc_jumbo_cra_program",
    fileName: "JumboCRA.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 23,
    label: "GMCC - Jumbo CRA Program Without Rates",
    key: "gmcc_jumbo_cra_program_without_rates",
    value: "gmcc_jumbo_cra_program_without_rates",
    fileName: "JumboCRAWithoutRates.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 24,
    label: "GMCC - Prime First $7500 Credit",
    key: "gmcc_prime_first",
    value: "gmcc_prime_first",
    fileName: "GMCCPrimeFirst.png",
    hasTemplate: true,
    hasOriginal: true,
  },
  {
    order: 25,
    label: "GMCC - Ocean With Rates",
    key: "gmcc_ocean",
    value: "gmcc_ocean",
    fileName: "GMCCOcean.png",
    hasTemplate: true,
    hasOriginal: true,
    unseeable: false, // mark as true if you want to hide this program from the user
  },
  {
    order: 26,
    label: "GMCC - Ocean Without Rates",
    key: "gmcc_ocean_without_rates",
    value: "gmcc_ocean_without_rates",
    fileName: "GMCCOceanWithoutRates.png",
    hasTemplate: true,
    hasOriginal: true,
    unseeable: false,
  },
];

const keyToProgramMap = new Map(programs.map((p) => [p.key, p]));

const Top7ProgramsGrouped: IGroupedPrograms = {
  key: "GMCC_top_7",
  label: React.createElement(
    "div",
    { style: { fontSize: "15px", color: "#325ceb" } },
    "GMCC Top 7"
  ),
  title: "GMCC Top 7",
  // For options, we need to add a suffix to the key to make it unique, the values
  // are kept the same, so we can use the value to identify the program and display it
  options: [
    "gmcc_free_15k",
    "gmcc_foreign_nationals",
    "gmcc_buy_without_sell_first",
    "gmcc_jumbo_express",
    "gmcc_grandslam",
    "gmcc_dscr",
    "gmcc_wvoe_and_p_and_l",
  ].map((key) => ({
    ...keyToProgramMap.get(key),
    key: key + "_gmcc_top_7",
  })),
};

const CommunityLendingCRAGrouped: IGroupedPrograms = {
  key: "Community Lending/CRA",
  label: React.createElement(
    "div",
    { style: { fontSize: "15px", color: "#325ceb" } },
    "Community Lending/CRA"
  ),
  title: "Community Lending/CRA",
  options: [
    "gmcc_diamond_community_lending",
    "gmcc_conforming_special",
    "gmcc_0_percent_down",
    "gmcc_free_15k",
    "gmcc_jumbo_cra_program",
    "gmcc_jumbo_cra_program_without_rates",
    "gmcc_grandslam",
    "gmcc_prime_first",
  ].map((key) => ({
    ...keyToProgramMap.get(key),
    key: key + "_community_lending_cra",
  })),
};

const CronusProgramsGrouped: IGroupedPrograms = {
  key: "Cronus",
  label: React.createElement(
    "div",
    { style: { fontSize: "15px", color: "#325ceb" } },
    "Cronus"
  ),
  title: "Cronus",
  options: [
    "gmcc_grandslam",
    "gmcc_jumbo_cra_program",
    "gmcc_jumbo_cra_program_without_rates",
    "gmcc_conforming_special",
  ].map((key) => ({
    ...keyToProgramMap.get(key),
    key: key + "_cronus",
  })),
};

export const groupedPrograms: (IGroupedPrograms | IProgram)[] = [
  Top7ProgramsGrouped,
  CommunityLendingCRAGrouped,
  CronusProgramsGrouped,
  ...filter(programs, (p) => {
    return (
      !map(Top7ProgramsGrouped.options, "value").includes(p.value) &&
      !map(CommunityLendingCRAGrouped.options, "value").includes(p.value) &&
      !map(CronusProgramsGrouped.options, "value").includes(p.value) &&
      !p.unseeable
    );
  }),
];

export const keyToProgram = (key: string): IProgram | undefined => {
  const program = keyToProgramMap.get(key);
  if (!program) {
    console.error(`Program with key ${key} not found`);
  }
  return program;
};
