import { QuestionCircleOutlined } from '@ant-design/icons'
import { Input, Select, Table, Tooltip, Row, Col } from 'antd'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import * as com from '../../../Common'
import NumericalInput, { CommaizedInput } from '../../../NumericalInput'
import AffordabilityModal from './AffordabilityModal'
import './MortgageRates.css'
import { useStatsigClient } from "@statsig/react-bindings/src";

const TERMS_MAP = {
    fixed30: '30 Year',
    fixed25: '25 Year',
    fixed20: '20 Year',
    fixed15: '15 Year',
    fixed10: '10 Year',
    arm56: 'ARM 5/6',
    arm76: 'ARM 7/6',
    arm106: 'ARM 10/6',
}

function PointsSelector({ selectedPoint, setSelectedPoint }) {
    const points = ['All', '0', '0-1', '1-2']

    const index = useMemo(() => points.indexOf(selectedPoint), [selectedPoint])

    const highlightWidth = useMemo(() => {
        return 'calc((100% - 17.5px) / 4)'
    }, [])

    const highlightLeft = useMemo(() => {
        return `calc(${index} * (((100% - 17.5px) / 4) + 3.5px) + 3.5px)`
    }, [index])

    return (
        <div className="points-selector">
            {points.map((point) => (
                <div
                    key={point}
                    className={`point-item ${
                        selectedPoint === point ? 'selected' : ''
                    }`}
                    onClick={() => setSelectedPoint(point)}
                >
                    {point}
                </div>
            ))}
            <div
                className="selector-highlight"
                style={{ width: highlightWidth, left: highlightLeft }}
            />
        </div>
    )
}

function MortgageRates({ firstName, customerid, loid, appLink, companyLink, showAffordability, isPreviewMode, onRatesUpdate, loanOfficerEmail }) {
    const [loading, setLoading] = useState(false)
    const [loanLimits, setLoanLimits] = useState({})
    const [state, setState] = useState('CALIFORNIA')
    const [county, setCounty] = useState('SANTA CLARA COUNTY')
    const [fico, setFico] = useState(780)
    const [purpose, setPurpose] = useState('Purchase')
    const [propertyValue, setPropertyValue] = useState(500000)
    const [downPayment, setDownPayment] = useState(100000)
    const [monthlyIncome, setMonthlyIncome] = useState(50000)
    const [monthlyDebt, setMonthlyDebt] = useState(1000)
    const [propertyType, setPropertyType] = useState('singlefamilydetached')
    const [units, setUnits] = useState(2)
    const [occupancy, setOccupancy] = useState('principal')
    const [escrowWaiver, setEscrowWaiver] = useState(true)
    const [selectedPoint, setSelectedPoint] = useState('All')
    const [validated, setValidated] = useState(false);
    const [isGMCCJumbo, setIsGMCCJumbo] = useState(false)
    const [onUpdateRates, setOnUpdateRates] = useState(false)

    const [currentMtype, setCurrentMtype] = useState('fixed30')
    const [customerUrl, setCustomerUrl] = useState('https://app.zeitro.com')

    const [message, setMessage] = useState('')
    const [lenderNotSet, setLendersNotSet] = useState(false)
    const [ineligibleProducts, setIneligibleProducts] = useState({})
    const [loans, setLoans] = useState([])
    const [allRates, setAllRates] = useState({})
    const [fixed30, setFixed30] = useState([])
    const [fixed25, setFixed25] = useState([])
    const [fixed20, setFixed20] = useState([])
    const [fixed15, setFixed15] = useState([])
    const [fixed10, setFixed10] = useState([])
    const [arm56, setArm56] = useState([])
    const [arm76, setArm76] = useState([])
    const [arm106, setArm106] = useState([])
    const [indexFixed30, setIndexFixed30] = useState(8)
    const [indexFixed25, setIndexFixed25] = useState(6)
    const [indexFixed20, setIndexFixed20] = useState(6)
    const [indexFixed15, setIndexFixed15] = useState(6)
    const [indexFixed10, setIndexFixed10] = useState(6)
    const [indexArm56, setIndexArm56] = useState(6)
    const [indexArm76, setIndexArm76] = useState(6)
    const [indexArm106, setIndexArm106] = useState(6)
    const [estOtherCosts, setEstOtherCosts] = useState(3195)
    const [showIneligible, setShowIneligible] = useState(false)

    const [calculatorOpen, setCalculatorOpen] = useState(false)

    const formRef = useRef();
    const client = useStatsigClient();

    const getLoanAmount = useCallback(() => {
        if (purpose == 'Purchase') {
            return parseInt(propertyValue) - parseInt(downPayment)
        } else {
            return parseInt(downPayment)
        }
    }, [purpose, propertyValue, downPayment])

    const getLtv = useCallback(() => {
        const ltv = Math.ceil((getLoanAmount() / parseInt(propertyValue)) * 100)
        // const cltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(helocsize)) / parseInt(propertyValue) * 100)
        // const hcltv = Math.ceil((getLoanAmount() + parseInt(second) + parseInt(heloclimit)) / parseInt(propertyValue) * 100)
        return [ltv, ltv, ltv]
    }, [propertyValue, getLoanAmount])

    const [ltvPercent, setLtvPercent] = useState(purpose === 'Purchase' ? 100 - getLtv()[0] : getLtv()[0])

    const getProducts = useCallback(async (controller) => {
        if (!controller) return;

        const pmi = 0.51
        const zeitro_fee = [{ lender: 'default', percent: 1.0, fixed: 500 }]

        let _units = 1
        if (propertyType === 'twotofourfamily') _units = units

        setMessage('')

        const [ltv, cltv, hcltv] = getLtv()

        let _county = 'ZAVALA COUNTY'
        if (state != 'TEXAS') _county = county

        const body = {
            state: state,
            county: _county,
            purpose: purpose,
            loanAmount: getLoanAmount(),
            units: parseInt(_units),
            LTV: ltv,
            CLTV: cltv,
            HCLTV: hcltv,
            FICO: fico,
            occupancy: com.getOccupancy(occupancy),
            property: com.getPropertyType(propertyType),
            point: zeitro_fee,
            estimatedClosingCosts: parseFloat(estOtherCosts),
            pmi: pmi,
            getFiles: false,
            monthlyIncome: parseFloat(monthlyIncome),
            monthlyDebt: parseFloat(monthlyDebt),
            escrowWaiver: escrowWaiver,
        }

        setLoading(true)
        try {
            const response = await fetch('/data/getrefinance/' + customerid, {
                method: 'POST',
                headers: {
                    Cache: 'no-cache',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
                signal: controller.signal,
            })
            if (response.status !== 200) {
                setOnUpdateRates(false)
                setMessage('An internal error has occured.')
                return
            }

            const pr = await response.json()
            setOnUpdateRates(false)

            if (pr.status_code === '12') {
                setLendersNotSet(true)
                return
            }

            const products = pr.result
            if (pr.ineligible_reasons != undefined) {
                setIneligibleProducts(pr.ineligible_reasons)
            } else {
                setIneligibleProducts({})
            }

            if (pr.message != undefined) {
                setMessage(pr.message)
            } else {
                setMessage('')
            }

            if (products === undefined) {
                setShowIneligible(true)
                return
            }

            let tmpFixed30 = []
            let tmpFixed25 = []
            let tmpFixed20 = []
            let tmpFixed15 = []
            let tmpFixed10 = []
            let tmpArm56 = []
            let tmpArm76 = []
            let tmpArm106 = []

            products.forEach((product) => {
                const lender = product.Lender
                const fb = product.FileB
                product.ProductRatesCosts.forEach((pr) => {
                    const commonproduct = pr.product
                    const promotions = pr.promotions
                    const adjustments = pr.appliedAdjustments
                    const ausengine = pr.AUSEngine
                    pr.rateCosts.forEach((rate) => {
                        const pdkt = {
                            Lender: lender,
                            ...commonproduct,
                            Aus: ausengine,
                            ...rate,
                            promotions,
                            Adjustments: adjustments,
                            FileB: fb,
                        }

                        if (rate.lockDay !== 30) {
                            return
                        }

                        pdkt.loanAmount = getLoanAmount()

                        const filterPdkt = () => {
                            if (customerid === 'siliconcitymortgage' && pdkt.Mtype.includes('FHA')) {
                                return false
                            }
                            if (pdkt.Lender.includes('GMCC') || pdkt.Lender.includes('FCB')) {
                                return false
                            }
                            return true
                        }

                        if (filterPdkt()) {
                            if (pdkt.Arm != null) {
                                if (pdkt.Arm.fixedperiod == 5) {
                                    tmpArm56.push(pdkt)
                                } else if (pdkt.Arm.fixedperiod == 7) {
                                    tmpArm76.push(pdkt)
                                } else if (pdkt.Arm.fixedperiod == 10) {
                                    tmpArm106.push(pdkt)
                                }
                            } else {
                                if (pdkt.Term == 30) {
                                    tmpFixed30.push(pdkt)
                                } else if (pdkt.Term == 25) {
                                    tmpFixed25.push(pdkt)
                                } else if (pdkt.Term == 20) {
                                    tmpFixed20.push(pdkt)
                                } else if (pdkt.Term == 15) {
                                    tmpFixed15.push(pdkt)
                                } else if (pdkt.Term == 10) {
                                    tmpFixed10.push(pdkt)
                                }
                            }
                        }
                    })
                })
            })

            const compareRate = (a, b) => {
                if (a.base_rate !== b.base_rate) return a.base_rate - b.base_rate
                return a.closing_cost - b.closing_cost
            }

            const cullRate = (products) => {
                const filteredPrds = products
                if (filteredPrds.length === 0) return filteredPrds
                const newproducts = [filteredPrds[0]]

                let base_rate = filteredPrds[0].base_rate
                let closing_cost = filteredPrds[0].closing_cost
                for (let i = 1; i < filteredPrds.length; i++) {
                    if (filteredPrds[i].base_rate !== base_rate) {
                        if (filteredPrds[i].closing_cost < closing_cost) {
                            newproducts.push(filteredPrds[i])
                            closing_cost = filteredPrds[i].closing_cost
                        }
                        base_rate = filteredPrds[i].base_rate
                    }
                }

                return newproducts
            }

            const getIndex = (loans) => {
                let bestIndex = 0
                let bestPoints = 1000000
                for (let i = 0; i < loans.length; i++) {
                    if (Math.abs(loans[i].lender_credit - 1500) < bestPoints) {
                        bestPoints = Math.abs(loans[i].lender_credit - 1500)
                        bestIndex = i
                    }
                }

                return bestIndex
            }

            tmpFixed30 = cullRate(tmpFixed30.sort(compareRate))
            tmpFixed25 = cullRate(tmpFixed25.sort(compareRate))
            tmpFixed20 = cullRate(tmpFixed20.sort(compareRate))
            tmpFixed15 = cullRate(tmpFixed15.sort(compareRate))
            tmpFixed10 = cullRate(tmpFixed10.sort(compareRate))
            tmpArm56 = cullRate(tmpArm56.sort(compareRate))
            tmpArm76 = cullRate(tmpArm76.sort(compareRate))
            tmpArm106 = cullRate(tmpArm106.sort(compareRate))

            setFixed30(tmpFixed30)
            setFixed25(tmpFixed25)
            setFixed20(tmpFixed20)
            setFixed15(tmpFixed15)
            setFixed10(tmpFixed10)
            setArm56(tmpArm56)
            setArm76(tmpArm76)
            setArm106(tmpArm106)

            setIndexFixed30(getIndex(tmpFixed30))
            setIndexFixed25(getIndex(tmpFixed25))
            setIndexFixed20(getIndex(tmpFixed20))
            setIndexFixed15(getIndex(tmpFixed15))
            setIndexFixed10(getIndex(tmpFixed10))
            setIndexArm56(getIndex(tmpArm56))
            setIndexArm76(getIndex(tmpArm76))
            setIndexArm106(getIndex(tmpArm106))

            setAllRates({
                fixed30: tmpFixed30,
                fixed25: tmpFixed25,
                fixed20: tmpFixed20,
                fixed15: tmpFixed15,
                fixed10: tmpFixed10,
                arm56: tmpArm56,
                arm76: tmpArm76,
                arm106: tmpArm106,
            })

            if (fixed30.length !== 0) {
                setLoans(fixed30)
            } else if (arm56.length !== 0) {
                setLoans(arm56)
            }

            onRatesUpdate({
                request: body,
                response: {
                    rates: {
                        fixed30: tmpFixed30,
                        fixed25: tmpFixed25,
                        fixed20: tmpFixed20,
                        fixed15: tmpFixed15,
                        fixed10: tmpFixed10,
                        arm56: tmpArm56,
                        arm76: tmpArm76,
                        arm106: tmpArm106,
                    },
                    currentProduct: currentMtype,
                    selectedRate: allRates[currentMtype]?.[indexFixed30]
                }
            })
        } catch (err) {
            setOnUpdateRates(false)
        } finally {
            setLoading(false)
        }
    }, [
        state,
        county,
        purpose,
        getLoanAmount,
        units,
        getLtv,
        fico,
        occupancy,
        propertyType,
        estOtherCosts,
        monthlyIncome,
        monthlyDebt,
        escrowWaiver,
        customerid,
        onRatesUpdate
    ])

    const getLoanLimits = useCallback(async () => {
        setLoading(true)
        try {
            const res = await fetch('/data/loanlimits')
            if (!res.ok) {
                console.warn('Fetching loan limits failed:', res)
                throw Error(res.statusText)
            }
            const data = await res.json()
            setLoanLimits(data)
        } catch (err) {
            console.error('Error fetching loan limits:', err)
        } finally {
            setLoading(false)
        }
    }, [])

    const getCustomerUrl = useCallback(async () => {
        try {
            const res = await fetch('/data/customerurls/' + customerid)
            if (!res.ok) {
                console.warn('Fetching customer URL failed:', res)
                throw Error(res.statusText)
            }
            const data = await res.json()
            if (window.location.hostname.includes('localhost')) {
                setCustomerUrl('http://localhost:3000')
            } else {
                setCustomerUrl(data.Prodapp)
            }
        } catch (err) {
            console.error('Error fetching customer URL:', err)
        }
    }, [customerid])

    useEffect(() => {
        const controller = new AbortController()

        const initData = async () => {
            try {
                await Promise.all([
                    getCustomerUrl(),
                    getProducts(controller),
                    getLoanLimits(),
                ])
            } catch (err) {
                console.error('Error initializing data:', err)
            }
        }

        initData()

        return () => {
            controller.abort()
        }
    }, [])

    useEffect(() => {
        if (propertyType === 'manufacturedhome') {
            setCurrentMtype('fixed25')
        }
    }, [propertyType])

    useEffect(() => {
        const controller = new AbortController()
        if (onUpdateRates) getProducts(controller)

        return () => {
            controller.abort()
        }
    }, [onUpdateRates])

    const stateOptions = useMemo(() => {
        const options = []
        const keys = Object.keys(loanLimits)
        keys.forEach((x) => {
            options.push(
                <Select.Option key={x} value={x}>
                    {com.capitalize(x)}
                </Select.Option>
            )
        })
        return options
    }, [loanLimits])

    const countyOptions = useMemo(() => {
        const options = []
        if (state in loanLimits) {
            const keys = Object.keys(loanLimits[state])
            keys.forEach((x) => {
                options.push(
                    <Select.Option key={x} value={x}>
                        {com.capitalize(x.replace('COUNTY', ''))}
                    </Select.Option>
                )
            })
        }
        return options
    }, [loanLimits, state])

    const updateProperValue = (e) => {
        const val = e.target.value
        if (val === '') {
            setPropertyValue('')
        } else {
            setPropertyValue(parseInt(val))
        }
    }

    const updateDownPayment = (e) => {
        const val = e.target.value
        if (val === '') {
            setDownPayment('')
            setLtvPercent(0)
        } else {
            const newDownPayment = parseInt(val)
            setDownPayment(newDownPayment)
            // Calculate and update LTV percentage based on the new down payment
            if (purpose === 'Purchase') {
                setLtvPercent(Math.round((newDownPayment / propertyValue) * 100))
            } else {
                setLtvPercent(Math.round((newDownPayment / propertyValue) * 100))
            }
        }
    }

    const updateLTV = (e) => {
        const val = e.target.value
        if (val === '') {
            setLtvPercent(0)
        } else {
            const p = parseInt(val)
            setLtvPercent(p)
        }
    }

    const logStatsigEvent = useCallback(async (item_name, event_name, value) => {
        try {
            const [ltv, cltv, hcltv] = getLtv()
            let eventData = {
                item_name: item_name,
                startTime: new Date,
                timestamp: Date.now(),
                pageUrl: window.location.href,
                loanOfficerID: loanOfficerEmail,
                type: "personalsite",
                loanPurpose: purpose,
                state: state,
                county: county,
                creditScore: fico,
                grossMonthlyIncome: monthlyIncome,
                monthlyLiabilities: monthlyDebt,
                propertyType: propertyType,
                occupancy: occupancy,
                impoundEscrow: escrowWaiver,
                points: selectedPoint,
                ltv: ltv,
                cltv: cltv,
                hcltv: hcltv,
            }

            if (purpose === 'Purchase') {
                eventData = {
                    ...eventData,
                    estPurchasePrice: propertyValue,
                    downPayment: downPayment,
                }
            } else {
                eventData = {
                    ...eventData,
                    homeValue: propertyValue,
                    mortgageBalance: downPayment,
                }
            }

            if (propertyType === 'twotofourfamily') {
                eventData = {
                    ...eventData,
                    numberOfUnits: units,
                }
            }

            await Promise.resolve(client.logEvent(event_name, value, eventData));
        } catch (error) {
            console.log('Error logging event:', error);
        }
    }, [
        getLtv, loid, purpose, state, county, fico,
        monthlyIncome, monthlyDebt, propertyType,
        occupancy, escrowWaiver, selectedPoint,
        propertyValue, downPayment, units, client
    ])

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        setIsGMCCJumbo(false)

        if (formRef.current.reportValidity() === false) {
            setValidated(true);
            return false;
        }

        setDownPayment(Math.round(propertyValue * 0.01 * ltvPercent))

        if (parseInt(downPayment) === 0) {
            setValidated(true);
            alert("Down payment can't be zero");
            return false;
        }

        if (parseInt(downPayment) >= parseInt(propertyValue)) {
            setValidated(true);
            alert("Down payment can't be bigger than purchase price");
            return false;
        }

        if (parseInt(fico) < 300 || parseInt(fico) > 1000) {
            setValidated(true);
            alert("FICO score should be in the range of 300 - 1000");
            return false;
        }

        let overConforming = false
        if (state in loanLimits && loanLimits[state][county]) {
            let _units = 1;
            if (propertyType === "twotofourfamily") _units = units;

            const lldata = loanLimits[state][county]
            let ll
            switch (parseInt(_units)) {
                case 1:
                    ll = lldata.OneUnit < 806500 ? lldata.OneUnit : 806500
                    break;
                case 2:
                    ll = lldata.TwoUnit < 1032650 ? lldata.TwoUnit : 1032650
                    break;
                case 3:
                    ll = lldata.ThreeUnit < 1248150 ? lldata.ThreeUnit : 1248150
                    break;
                case 4:
                    ll = lldata.FourUnit < 1551250 ? lldata.FourUnit : 1551250
                    break;
                default:
                    break;
            }

            if (getLoanAmount() > ll) {
                overConforming = true
            }
        }

        if (customerid === "gmcc" && overConforming) {
            setIsGMCCJumbo(true)
            return
        }

        setOnUpdateRates(true)
        return false;
    }, [loanLimits, state, county, fico, propertyValue, downPayment, propertyType, ltvPercent]);

    const preQualificationLink = useMemo(() => {
        if (customerid === 'gmcc') {
            if (!isEmpty(appLink)) {
                return appLink
            } else {
                return companyLink
            }
        }

        if (!isEmpty(appLink)) {
            return appLink
        }

        return `${customerUrl}/services/preapp?customerid=${customerid}&loid=${loid}`
    }, [customerid, loid, appLink, companyLink, customerUrl])

    const estimatedClosingCost = useMemo(() => {
        if (allRates[currentMtype] && allRates[currentMtype].length > 0) {
            if (selectedPoint === "All") {
                const max = allRates[currentMtype][0].closing_cost
                const min = Math.max(allRates[currentMtype][allRates[currentMtype].length - 1].closing_cost, 0)
                return `$${com.commaize(min)} - $${com.commaize(max)}`
            } else if (selectedPoint === "0") {
                return `$0`
            } else if (selectedPoint === "0-1") {
                const highBoundry = getLoanAmount() * 0.01
                let max = allRates[currentMtype][0].closing_cost
                for (let i = 0; i < allRates[currentMtype].length; i++) {
                    if (allRates[currentMtype][i].closing_cost <= highBoundry) {
                        max = allRates[currentMtype][i].closing_cost
                        break
                    }
                }
                return `$0 - $${com.commaize(max)}`
            } else if (selectedPoint === "1-2") {
                const highBoundry = getLoanAmount() * 0.02
                const lowBoundry = getLoanAmount() * 0.01
                let max = allRates[currentMtype][0].closing_cost
                let min = allRates[currentMtype][0].closing_cost
                for (let i = 0; i < allRates[currentMtype].length; i++) {
                    if (allRates[currentMtype][i].closing_cost <= highBoundry) {
                        max = allRates[currentMtype][i].closing_cost
                        break
                    }
                }
                for (let i = allRates[currentMtype].length - 1; i >= 0; i--) {
                    if (allRates[currentMtype][i].closing_cost >= lowBoundry) {
                        min = allRates[currentMtype][i].closing_cost
                        break
                    }
                }
                return `$${com.commaize(min)} - $${com.commaize(max)}`
            }
        }

        return '-'
    }, [selectedPoint, allRates, currentMtype])

    const rateChangeDay = useMemo(() => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        let today = new Date()
        const dayOfWeek = today.getDay()
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            // If today is Sunday (0), subtract 2 days to get Friday
            // If today is Saturday (6), subtract 1 day to get Friday
            today = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - (dayOfWeek === 0 ? 2 : 1)
            )
        }
        return today.toLocaleDateString('en-US', options)
    }, [])

    const termDivs = useMemo(() => {
        if (isEmpty(allRates)) return <></>

        const divs = []
        for (const mtype in allRates) {
            divs.push(
                <div
                    key={mtype}
                    className={`term-item ${
                        mtype === currentMtype ? 'selected' : ''
                    }`}
                    onClick={() => {
                        setCurrentMtype(mtype)
                        logStatsigEvent("loDetailPageChooseYearClick", "lo_detail_page_choose_year_click", TERMS_MAP[mtype])
                    }}
                >
                    {TERMS_MAP[mtype]}
                </div>
            )
        }
        return divs
    }, [allRates, currentMtype])

    const tableData = useMemo(() => {
        if (!allRates || !currentMtype || !allRates[currentMtype] || allRates[currentMtype].length === 0) {
            return null
        }

        // Filter rates based on selectedPoint
        let filteredIndexes = []

        if (selectedPoint === "All") {
            // Use existing logic for "All"
            let point1Diff = 999
            let point1I = 0
            let point0Diff = 999
            let point0I = 0

            for (var i = 0; i < allRates[currentMtype].length; i++) {
                const diff0 = Math.abs(allRates[currentMtype][i].points)
                const diff1 = Math.abs(allRates[currentMtype][i].points - 1)
                if (diff0 < point0Diff) {
                    point0I = i
                    point0Diff = diff0
                }
                if (diff1 < point1Diff) {
                    point1I = i
                    point1Diff = diff1
                }
            }

            const indexes = new Set()
            indexes.add(point0I)
            indexes.add(point1I)

            let indexdiff = 2
            if (customerid === 'siliconcitymortgage') {
                indexdiff = 6
            }

            let start = point1I + 1
            while (start <= allRates[currentMtype].length - 1 && start - point1I <= indexdiff) {
                indexes.add(start)
                start++
            }

            if (point0I + 1 <= allRates[currentMtype].length - 1) {
                indexes.add(point0I + 1)
            }

            filteredIndexes = Array.from(indexes).sort((a, b) => a - b)
        } else {
            // Filter based on points range
            for (let i = 0; i < allRates[currentMtype].length; i++) {
                const pointsPercent = allRates[currentMtype][i].points

                switch (selectedPoint) {
                    case "0":
                        if (pointsPercent > -1 && pointsPercent < 1) {
                            filteredIndexes.push(i)
                        }
                        break
                    case "0-1":
                        // Show rates with points between 0% and 1%
                        if (pointsPercent >= 0 && pointsPercent <= 1) {
                            filteredIndexes.push(i)
                        }
                        break
                    case "1-2":
                        // Show rates with points between 1% and 2%
                        if (pointsPercent > 1 && pointsPercent <= 2) {
                            filteredIndexes.push(i)
                        }
                        break
                }
            }
        }

        // Format the data for display
        const data = []
        for (const i of filteredIndexes) {
            const d = allRates[currentMtype][i]
            if (typeof d !== 'undefined') {
                data.push({
                    rate: d.base_rate.toFixed(3) + '%',
                    apr: d.APR.toFixed(3) + '%',
                    monthlyPayment: '$' + com.commaizeFloat(d.monthly_payment),
                    points: '$' + com.commaizeFloat(d.lender_credit),
                })
            }
        }

        return data
    }, [allRates, currentMtype, selectedPoint, customerid])

    const handleApplyClick = useCallback((rowData) => {
        client.logEvent("lo_detail_page_apply_rate_click", window.location.href, {
            item_name: "loDetailPageApplyRateClick",
            startTime: new Date,
            timestamp: Date.now(),
            pageUrl: window.location.href,
            loanOfficerID: loanOfficerEmail,
            type: "personalsite",
            rate: rowData?.rate,
            apr: rowData?.apr,
            moPay: rowData?.monthlyPayment,
            ptsCredit: rowData?.points,
        });
        window.open(preQualificationLink, '_blank')
    }, [loid, client, preQualificationLink])

    const displayRatesForMtype = useCallback(() => {
        if (loading || tableData === null) {
            return (
                <div className="text-center w100 justify-content-center  align-content-center">
                    <div id="spinner" className="pt-5 mt-4 ml-5 text-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                </div>
            )
        }

        if (typeof allRates[currentMtype] === 'undefined') return <></>

        const columns = [
            {
                title: 'Rate',
                dataIndex: 'rate',
                key: 'rate',
                align: 'left',
                width: '20%',
            },
            {
                title: 'APR',
                dataIndex: 'apr',
                key: 'apr',
                align: 'left',
                width: '20%',
            },
            {
                title: 'Mo.Pay',
                dataIndex: 'monthlyPayment',
                key: 'monthlyPayment',
                align: 'left',
                width: '20%',
            },
            {
                title: () => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        Points/Credit
                        <Tooltip
                            title="A positive number means you have to pay lender to get the lower rates. A negative number means lender will give you a credit to help you with the closing costs."
                            placement="top"
                        >
                            <QuestionCircleOutlined className="question-icon" style={{ fontSize: '14px' }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'points',
                key: 'points',
                align: 'left',
                width: '20%',
            },
            {
                title: '',
                key: 'action',
                align: 'right',
                width: '20%',
                render: (_, record) => (
                    <Button
                        className="apply-button"
                        style={{ cursor: isPreviewMode ? 'not-allowed' : 'pointer' }}
                        disabled={isPreviewMode}
                        onClick={() => handleApplyClick(record)}
                    >
                        Apply
                    </Button>
                ),
            },
        ]

        if (tableData.length === 0) {
            return (
                <div className="rates-warning">
                    <div className="justify-content-md-center">
                        <img src="/images/warningSign.png" alt="warning" style={{ maxWidth: "20px", marginRight: "10px" }} />
                        <span>There are no eligible products available for this type of amortization.</span>
                    </div>
                </div>
            )
        }

        return (
            <>
                <Table
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    rowKey="rate"
                    className="rates-table"
                />

                <p className="disclaimer-title">Disclaimer:</p>
                <p className="disclaimer-content">
                    The rate quotations and program offerings detailed herein are exclusively applicable to applicants who can provide income verification and have no more than two properties. As of now, quotes are only for conforming loan amount. These offerings are contingent upon the successful completion of our underwriting process and may be revised in response to market dynamics without prior notification.  Furthermore, additional limitations may be enforced. It is imperative to understand that the information provided does not constitute a definitive credit decision.
                </p>
            </>
        )
    }, [allRates, currentMtype, loading, preQualificationLink, tableData])

    return (
        <div className="mortgage-rates-wrapper">
            {/* Header Section */}
            <div className="header-container">
                <div className="title-section">
                    <span className="main-title">
                        Mortgage rates{' '}
                        <span className="sub-title">
                            with {firstName}
                        </span>
                    </span>
                </div>
                {showAffordability && (
                    <div className="calculator-container">
                        <span className="calculator-hint">
                            Unsure what home price to consider?
                        </span>
                    <Button
                        className="calculator-button"
                        onClick={() => {
                            setCalculatorOpen(true);
                            client.logEvent("lo_detail_page_affordability_calculator_click", window.location.href, {
                                item_name: "loDetailPageAffordabilityCalculatorClick",
                                startTime: new Date,
                                timestamp: Date.now(),
                                pageUrl: window.location.href,
                                loanOfficerID: loanOfficerEmail,
                                type: "personalsite",
                            });
                        }}
                    >
                        Affordability Calculator
                    </Button>
                    </div>
                )}
            </div>

            {/* Form Section */}
            <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmit} className="form-container rates-form">
                <Row gutter={24} className="form-row">
                    <Col span={6}>
                        <label className="form-label">Loan purpose</label>
                        <Select
                            className="form-select"
                            value={purpose}
                            onChange={(value) => setPurpose(value)}
                            required
                        >
                            <Select.Option value="Purchase">Purchase</Select.Option>
                            <Select.Option value="RateTermRefi">Refinance</Select.Option>
                            <Select.Option value="CashOutRefi">Cash Out Refinance</Select.Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        <label className="form-label">State</label>
                        <Select
                            className="form-select"
                            value={state}
                            onChange={(value) => setState(value)}
                            required
                        >
                            {stateOptions}
                        </Select>
                    </Col>
                    {state !== 'TEXAS' && (
                        <Col span={6}>
                            <label className="form-label">County</label>
                            <Select
                                className="form-select"
                                value={county}
                                onChange={(value) => setCounty(value)}
                                required
                            >
                                {countyOptions}
                            </Select>
                        </Col>
                    )}
                    <Col span={6}>
                        <label className="form-label">Credit score</label>
                        <Input
                            prefix="$"
                            value={fico}
                            onChange={(e) => {
                                const val = e.target.value === '' ? '' : parseInt(e.target.value)
                                setFico(val)
                            }}
                            required
                        />
                    </Col>
                </Row>

                <Row gutter={24} className="form-row">
                    <Col span={6}>
                        <label className="form-label">
                            {purpose === 'Purchase' ? 'Est. purchase price' : 'Home value'}
                        </label>
                        <div className="down-payment-row">
                            <div className="form-slider-outline" style={{ width: '100%' }}>
                                <div className="dollar-sign">$</div>
                                <CommaizedInput
                                    className="commaized-input"
                                    style={{
                                        border: 'none',
                                        fontSize: '16px',
                                        color: '#6e6e70',
                                        maxHeight: '38px',
                                    }}
                                    value={propertyValue}
                                    onChange={updateProperValue}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <label className="form-label">
                            {purpose === 'Purchase' ? 'Down payment' : 'Mortgage balance'}
                        </label>
                        <div className="down-payment-row" style={{ display: 'flex', gap: '2px' }}>
                            <div className="form-slider-outline" style={{ width: '65%' }}>
                                <div className="dollar-sign">$</div>
                                <CommaizedInput
                                    className="commaized-input"
                                    style={{
                                        border: 'none',
                                        fontSize: '16px',
                                        color: '#6e6e70',
                                        maxHeight: '38px',
                                    }}
                                    value={downPayment}
                                    onChange={updateDownPayment}
                                />
                            </div>
                            <div style={{ width: '35%' }}>
                                <Input
                                    type="number"
                                    className="percentage-input"
                                    suffix="%"
                                    value={ltvPercent}
                                    onChange={(e) => updateLTV(e)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <label className="form-label">
                            Gross monthly income
                        </label>
                        <div className="numerical-input-wrapper">
                            <NumericalInput
                                size="sm"
                                value={monthlyIncome}
                                required
                                onChange={(e) =>
                                    setMonthlyIncome(e.target.value)
                                }
                                min={0}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <label className="form-label">
                            Monthly liabilities
                        </label>
                        <div className="numerical-input-wrapper">
                            <NumericalInput
                                size="sm"
                                value={monthlyDebt}
                                required
                                onChange={(e) => setMonthlyDebt(e.target.value)}
                                min={0}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={24} className="form-row">
                    <Col span={6}>
                        <label className="form-label">Property type</label>
                        <Select
                            className="form-select"
                            value={propertyType}
                            onChange={(value) => setPropertyType(value)}
                            required
                        >
                            <Select.Option value="singlefamilydetached">Single Family Home, Detached</Select.Option>
                            <Select.Option value="singlefamilyattached">Single Family Home, Attached</Select.Option>
                            <Select.Option value="condominiumdetached">Condominium, Detached</Select.Option>
                            <Select.Option value="condominium">Condominium, Attached</Select.Option>
                            <Select.Option value="pudattached">Planned Unit Development, attached</Select.Option>
                            <Select.Option value="puddetached">Planned Unit Development, detached</Select.Option>
                            <Select.Option value="twotofourfamily">2 to 4 Unit Multifamily</Select.Option>
                            <Select.Option value="manufacturedhome">Manufactured Home</Select.Option>
                        </Select>
                    </Col>
                    {propertyType === 'twotofourfamily' && (
                        <Col span={6}>
                            <label className="form-label">Number of Units</label>
                            <Select
                                className="form-select"
                                value={units}
                                onChange={(value) => setUnits(value)}
                                required
                            >
                                <Select.Option value="2">2</Select.Option>
                                <Select.Option value="3">3</Select.Option>
                                <Select.Option value="4">4</Select.Option>
                            </Select>
                        </Col>
                    )}
                    <Col span={6}>
                        <label className="form-label">Occupancy</label>
                        <Select
                            className="form-select"
                            value={occupancy}
                            onChange={(value) => setOccupancy(value)}
                        >
                            <Select.Option value="principal">
                                Primary Residence
                            </Select.Option>
                            <Select.Option value="secondhome">
                                Secondary Home
                            </Select.Option>
                            <Select.Option value="investment">
                                Investment
                            </Select.Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        <label className="form-label">Impound/Escrow</label>
                        <Select
                            className="form-select"
                            value={escrowWaiver}
                            onChange={(value) => setEscrowWaiver(value)}
                            required
                        >
                            <Select.Option value={true}>No</Select.Option>
                            <Select.Option value={false}>Yes</Select.Option>
                        </Select>
                    </Col>
                </Row>

                <Row gutter={24} className="form-row">
                    <Col span={6}>
                        <div className="points-selector-container">
                            <label className="form-label">Points</label>
                            <Tooltip
                                title="A positive number means you have to pay lender to get the lower rates. A negative number means lender will give you a credit to help you with the closing costs."
                                placement="right"
                            >
                                <QuestionCircleOutlined className="question-icon" />
                            </Tooltip>
                        </div>
                        <PointsSelector selectedPoint={selectedPoint} setSelectedPoint={setSelectedPoint} />
                    </Col>
                    <Col span={12}>
                        <div className="closing-cost-container">
                            <span className="closing-cost-label">
                                Estimated closing cost:
                            </span>
                            <span className="closing-cost-value">
                                {estimatedClosingCost}
                            </span>
                        </div>
                    </Col>
                    <Col span={6} className="check-rates-button-container">
                        <Button
                            type="submit"
                            className="check-rates-button"
                            style={{ cursor: isPreviewMode ? 'not-allowed' : 'pointer' }}
                            disabled={isPreviewMode}
                            onClick={() =>
                                logStatsigEvent("loDetailPageCheckRateButtonClick", "lo_detail_page_check_rate_button_click", purpose)
                            }
                        >
                            Check rates
                        </Button>
                    </Col>
                </Row>
            </Form>

            {/* Rates Section */}
            {lenderNotSet && (
                <div className="rates-warning">
                    <Row className="justify-content-md-center">
                    <Col xs={1}>
                        <img
                            src="/images/warningSign.png"
                            alt="warning"
                            style={{ maxWidth: '20px' }}
                        />
                    </Col>
                    <Col xs={11}>
                        {!isPreviewMode ? (
                            "This functionality is not ready yet. Please check later."
                        ) : (
                            <div>
                                <p>No lender has been configured yet.</p>
                                <p> If you are company owener, please go to "Management" - "Lenders" to config your lenders.</p>
                            </div>
                        )}
                    </Col>
                    </Row>
                </div>
            )}
            {!lenderNotSet && message === '' ? (
                <div className="rates-section">
                    <div className="rates-date">
                        Rate as of 09:00 {rateChangeDay} (Changes daily)
                    </div>

                    {customerid === 'gmcc' ? (
                        <div className="rates-disclaimer">
                            *Rate quotations currently are estimates for
                            Conforming products only and subject to market
                            changes without advance notice. Hign Balance and
                            Jumbo products coming soon.
                        </div>
                    ) : (
                        <div className="rates-disclaimer">
                            *Rates are updated periodically to reflect real-time
                            lender pricing; some lenders may not update their
                            rates daily.
                        </div>
                    )}

                    <div className="terms-container">{termDivs}</div>

                    {displayRatesForMtype()}
                </div>
            ) : (
                <div className="text-wrap w-100">{message}</div>
            )}

            <AffordabilityModal
                open={calculatorOpen}
                onClose={() => setCalculatorOpen(false)}
                isFromLanding={true}
                customerid={customerid}
                loid={loid}
                appLink={appLink}
                companyLink={companyLink}
            />
        </div>
    )
}

export default MortgageRates
