import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { Modal, Tooltip, Pagination, Button, Radio, Image, Dropdown, Steps, DatePicker, Input, AutoComplete, Select, Row, Col, InputNumber, Collapse, Skeleton } from 'antd';
import { MinusOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash'
import * as com from "../Common.js"
import * as act from "../Store/actions"
import { Document, Page, pdfjs } from 'react-pdf';
import './IncomeCalculationModal.css';
const { TextArea } = Input;

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateIncomeMonthly: (val, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(val, who, verb))
    }
})
export default function IncomeCalculationModal(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const {
        open, borrowerId, currentView, onCancel
    } = props
    const { Option } = Select
    const [pageWidth, setPageWidth] = useState(0)
    const [expand, setExpand] = useState(false)
    const containerRef = useRef(null)
    const informationRef = useRef(null)
    const rightContainerRef = useRef(null)
    const manualChangeTimeout = useRef(null)
    const [currentPage, setCurrentPage] = useState(1)
    const isManualChange = useRef(false)
    const isLeftSyncing = useRef(false)
    const isAtBoundary = useRef(false)
    const isSwitching = useRef(false)
    const isRightSyncing = useRef(false)
    const justSwitched = useRef(false)
    const [visiblePages, setVisiblePages] = useState([])
    const [step, setStep] = useState(0)
    const [status, setStatus] = useState(['process', 'wait'])
    const [fileOptions, setFileOptions] = useState([])
    const [employmentInformation, setEmploymentInformation] = useState([])
    const [wagesList, setWagesList] = useState([])
    const [paystubs, setPaystubs] = useState([])
    const [originalData, setOriginalData] = useState(null)
    const [originalPaystubs, setOriginalPaystubs] = useState([])
    const [grossEarnings, setGrossEarnings] = useState([])
    const [millitaryPersonnel, setMillitaryPersonnel] = useState([])
    const [previousEmployment, setPreviousEmployment] = useState([])
    const [selectIncome, setSelectIncome] = useState("")
    const [incomeCalculation, setIncomeCalculation] = useState([])
    const [frequency, setFrequency] = useState(undefined)
    const [pageNumber, setPageNumber] = useState(1)
    const [numPages, setNumPages] = useState(1)
    const [scale, setScale] = useState(1)
    const [selectFile, setSelectFile] = useState(null)
    const [file, setFile] = useState(null)
    const pageRefs = useRef([])
    const observerRef = useRef(null)
    const refs = useRef({})
    useEffect(() => {
        return () => {
            if (manualChangeTimeout.current) {
                clearTimeout(manualChangeTimeout.current)
            }
        }
    }, [])
    useEffect(() => {
        if (open && selectFile) {
            downloadAll([selectFile])
        }
    }, [open, selectFile])
    
    useLayoutEffect (() => {
        if (open) {
            const handleResize = () => {
                if (containerRef.current) {
                    setPageWidth(containerRef.current.offsetWidth - 32)
                }
            }
            setTimeout(handleResize, 0)
            window.addEventListener('resize', handleResize)
            containerRef.current.addEventListener('scroll', handleScroll)
    
            return () => {
                window.removeEventListener('resize', handleResize)
                containerRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [open])
    useEffect(() => {
        if (open) {
            // mock data
            // const documentsData = {
            //     "Janet Testcase": [
            //         {
            //             "employmentInformation": [
            //                 {
            //                     "name": "Employer name",
            //                     "value": "arista networks",
            //                     "type": "text"
            //                 },
            //                 {
            //                     "name": "Phone number",
            //                     "value": "",
            //                     "type": "phone"
            //                 },
            //                 {
            //                     "name": "Start date",
            //                     "value": "",
            //                     "type": "date"
            //                 },
            //                 {
            //                     "name": "End date",
            //                     "value": "",
            //                     "type": "date"
            //                 }
            //             ],
            //             "w2s": [
            //                 {
            //                     "year": "2020",
            //                     "amount": 177515.98,
            //                     "filename": "W2-subha-ebay-1of2 2020.pdf",
            //                     "fileserialid": 695
            //                 },
            //                 {
            //                     "year": "2020",
            //                     "amount": 41664.24,
            //                     "filename": "W2-subha-populus 2020.pdf",
            //                     "fileserialid": 693
            //                 }
            //             ],
            //             "Paystubs": [
            //                 {
            //                     "payPeriodStart": "08/23/2021",
            //                     "payPeriodEnd": "09/05/2021",
            //                     "payPeriod": "Bi-Weekly",
            //                     "details": [
            //                         {
            //                             "name": "Regular hours",
            //                             "1003Field": "Base",
            //                             "thisPeriod": 7744.81,
            //                             "yearToDate": 1384.62
            //                         },
            //                         {
            //                             "name": "Overtime",
            //                             "1003Field": "Overtime",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 1384.62
            //                         },
            //                         {
            //                             "name": "Bonus",
            //                             "1003Field": "Bonus",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 55846.34
            //                         },
            //                         {
            //                             "name": "Commission",
            //                             "1003Field": "Commission",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 3692.32
            //                         },
            //                         {
            //                             "name": "Other",
            //                             "1003Field": "Others",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 42.4
            //                         }
            //                     ],
            //                     "filename": "Subha Aug Paystub.png",
            //                     "fileserialid": 670
            //                 },
            //                 {
            //                     "payPeriodStart": "09/06/2021",
            //                     "payPeriodEnd": "09/19/2021",
            //                     "payPeriod": "Bi-Weekly",
            //                     "details": [
            //                         {
            //                             "name": "Regular hours",
            //                             "1003Field": "Base",
            //                             "thisPeriod": 6970.33,
            //                             "yearToDate": 141218.71
            //                         },
            //                         {
            //                             "name": "Overtime",
            //                             "1003Field": "Overtime",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 0
            //                         },
            //                         {
            //                             "name": "Bonus",
            //                             "1003Field": "Bonus",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 34682.44
            //                         },
            //                         {
            //                             "name": "Commission",
            //                             "1003Field": "Commission",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 0
            //                         },
            //                         {
            //                             "name": "Other",
            //                             "1003Field": "Others",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 124491.53
            //                         }
            //                     ],
            //                     "filename": "Subha Sep Paystub.pdf",
            //                     "fileserialid": 699
            //                 }
            //             ],
            //             "grossEarnings": [
            //                 {
            //                     "name": "Base pay",
            //                     "yearToDate": 166,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 9
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Overtime",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Bonus",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Commission",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Total",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 177515.98
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 }
            //             ],
            //             "millitaryPersonnel": [
            //                 // {
            //                 //     "name": 'fileserialid',
            //                 //     "amount": 694
            //                 // },
            //                 // {
            //                 //     "name": 'filename',
            //                 //     "amount": "112-2018 W2 for the University, and the start date. (1).pdf"
            //                 // },
            //                 // {
            //                 //     "name": 'sdfsdf',
            //                 //     "amount": 4564
            //                 // }
            //             ],
            //             // "previousEmployment": [
            //             //     {
            //             //         "name": "Date Hired",
            //             //         "amount": "02/01/2021"
            //             //     },
            //             //     {
            //             //         "name": "Date Terminated",
            //             //         "amount": "02/01/2022"
            //             //     },
            //             //     {
            //             //         "name": "Pay Period",
            //             //         "amount": "Year"
            //             //     },
            //             //     {
            //             //         "name": "Base",
            //             //         "amount": 1520.63
            //             //     },
            //             //     {
            //             //         "name": "Overtime",
            //             //         "amount": 120.63
            //             //     },
            //             //     {
            //             //         "name": "Bonus",
            //             //         "amount": 1660.63
            //             //     },
            //             //     {
            //             //         "name": "Commission",
            //             //         "amount": 160.63
            //             //     },
            //             //     {
            //             //         "name": "filename",
            //             //         "amount": "1005-previous employment.pdf"
            //             //     },
            //             //     {
            //             //         "name": "fileserialid",
            //             //         "amount": 4375
            //             //     }
            //             // ]
            //         },
            //         {
            //             "employmentInformation": [
            //                 {
            //                     "name": "Employer name",
            //                     "value": "populus group llc",
            //                     "type": "text"
            //                 },
            //                 {
            //                     "name": "Phone number",
            //                     "value": "",
            //                     "type": "phone"
            //                 },
            //                 {
            //                     "name": "Start date",
            //                     "value": "",
            //                     "type": "date"
            //                 },
            //                 {
            //                     "name": "End date",
            //                     "value": "",
            //                     "type": "date"
            //                 }
            //             ],
            //             "w2s": [
            //                 {
            //                     "year": "2019",
            //                     "amount": 92051.92,
            //                     "filename": "Subha W2 2019.pdf",
            //                     "fileserialid": 1208
            //                 }
            //             ],
            //             "Paystubs": [],
            //             "grossEarnings": [],
            //             "millitaryPersonnel": []
            //         }
            //     ],
            //     "fdgdgd ewrwerw": [
            //         {
            //             "employmentInformation": [
            //                 {
            //                     "name": "Employer name",
            //                     "value": "arista networks",
            //                     "type": "text"
            //                 },
            //                 {
            //                     "name": "Phone number",
            //                     "value": "",
            //                     "type": "phone"
            //                 },
            //                 {
            //                     "name": "Start date",
            //                     "value": "",
            //                     "type": "date"
            //                 },
            //                 {
            //                     "name": "End date",
            //                     "value": "",
            //                     "type": "date"
            //                 }
            //             ],
            //             "w2s": [
            //                 {
            //                     "year": "2020",
            //                     "amount": 177515.98,
            //                     "filename": "W2-subha-ebay-1of2 2020.pdf",
            //                     "fileserialid": 695
            //                 },
            //                 {
            //                     "year": "2020",
            //                     "amount": 41664.24,
            //                     "filename": "W2-subha-populus 2020.pdf",
            //                     "fileserialid": 693
            //                 }
            //             ],
            //             "Paystubs": [
            //                 {
            //                     "payPeriodStart": "08/23/2021",
            //                     "payPeriodEnd": "09/05/2021",
            //                     "payPeriod": "Bi-Weekly",
            //                     "details": [
            //                         {
            //                             "name": "Regular hours",
            //                             "1003Field": "Base",
            //                             "thisPeriod": 7744.81,
            //                             "yearToDate": 134248.38
            //                         },
            //                         {
            //                             "name": "Overtime",
            //                             "1003Field": "Overtime",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 0
            //                         },
            //                         {
            //                             "name": "Bonus",
            //                             "1003Field": "Bonus",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 34682.44
            //                         },
            //                         {
            //                             "name": "Commission",
            //                             "1003Field": "Commission",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 0
            //                         },
            //                         {
            //                             "name": "Other",
            //                             "1003Field": "Others",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 42.4
            //                         }
            //                     ],
            //                     "filename": "Subha Aug Paystub.png",
            //                     "fileserialid": 670
            //                 },
            //                 {
            //                     "payPeriodStart": "09/06/2021",
            //                     "payPeriodEnd": "09/19/2021",
            //                     "payPeriod": "Bi-Weekly",
            //                     "details": [
            //                         {
            //                             "name": "Regular hours",
            //                             "1003Field": "Base",
            //                             "thisPeriod": 6970.33,
            //                             "yearToDate": 141218.71
            //                         },
            //                         {
            //                             "name": "Overtime",
            //                             "1003Field": "Overtime",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 0
            //                         },
            //                         {
            //                             "name": "Bonus",
            //                             "1003Field": "Bonus",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 34682.44
            //                         },
            //                         {
            //                             "name": "Commission",
            //                             "1003Field": "Commission",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 0
            //                         },
            //                         {
            //                             "name": "Other",
            //                             "1003Field": "Others",
            //                             "thisPeriod": 0,
            //                             "yearToDate": 124491.53
            //                         }
            //                     ],
            //                     "filename": "Subha Sep Paystub.pdf",
            //                     "fileserialid": 699
            //                 }
            //             ],
            //             "grossEarnings": [
            //                 {
            //                     "name": "Base pay",
            //                     "yearToDate": 166,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 9
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Overtime",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Bonus",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Commission",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 0
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 },
            //                 {
            //                     "name": "Total",
            //                     "yearToDate": 0,
            //                     "details": [
            //                         {
            //                             "year": "2024",
            //                             "amount": 177515.98
            //                         },
            //                         {
            //                             "year": "2023",
            //                             "amount": 0
            //                         }
            //                     ],
            //                     "filename": "1005 (1).pdf",
            //                     "fileserialid": 1209
            //                 }
            //             ],
            //             "millitaryPersonnel": [
            //                 {
            //                     "name": 'sdfsdf',
            //                     "amount": 4564
            //                 }
            //             ]
            //         }
            //     ]
            // }

            // const parsed_data = {
            //     "Janet Testcase_8544": {
            //         "person_id": "lu cao_8544",
            //         "employer_incomes": [
            //             {
            //                 "employer_name": "arista networks inc",
            //                 "w2_calculations": [],
            //                 "paystub_ytd_calculation": {
            //                     "amount": {
            //                         "base": 2373.2743718592965,
            //                         "overtime": 12.627135678391962,
            //                         "bonus": 0,
            //                         "commission": 0,
            //                         "other": 2.261306532663317
            //                     },
            //                     "method": "Most recent paystub YTD divided by months elapsed",
            //                     "source_docs": [
            //                         "14.pdf"
            //                     ],
            //                     "calculation_details": "Base YTD $15,742.72 / 6.6 months = $2,373.27 monthly\nOvertime: Holiday + Over Time YTD: $83.76\nOther: In-Service YTD: $15.00"
            //                 },
            //                 "paystub_average_calculation": {
            //                     "amount": {
            //                         "base": 2774.026666666666,
            //                         "overtime": 5.199999999999999,
            //                         "bonus": 0,
            //                         "commission": 0,
            //                         "other": 0
            //                     },
            //                     "method": "Average of 1 paystubs (weekly frequency)",
            //                     "source_docs": [
            //                         "14.pdf"
            //                     ],
            //                     "calculation_details": "Base: Average $640.16 × (52/12) = $2,774.03\nOvertime: Average $1.20 × (52/12) = $5.20"
            //                 },
            //                 "form1005_calculation": null,
            //                 "recommended_monthly_income": {
            //                     "base": 2373.2743718592965,
            //                     "overtime": 12.627135678391962,
            //                     "bonus": 0,
            //                     "commission": 0,
            //                     "other": 2.261306532663317
            //                 },
            //                 "recommendation_reason": "Using YTD calculation (no W2s available)"
            //             },
            //             {
            //                 "employer_name": "ebay inc",
            //                 "w2_calculations": [],
            //                 "paystub_ytd_calculation": {
            //                     "amount": {
            //                         "base": 2121.2743718592965,
            //                         "overtime": 12.627135678391962,
            //                         "bonus": 772,
            //                         "commission": 0,
            //                         "other": 2.261306532663317
            //                     },
            //                     "method": "Most recent paystub YTD divided by months elapsed",
            //                     "source_docs": [
            //                         "14.pdf"
            //                     ],
            //                     "calculation_details": "Base YTD $15,742.72 / 6.6 months = $2,373.27 monthly\nOvertime: Holiday + Over Time YTD: $83.76\nOther: In-Service YTD: $15.00"
            //                 },
            //                 "paystub_average_calculation": {
            //                     "amount": {
            //                         "base": 2774.026666666666,
            //                         "overtime": 5.199999999999999,
            //                         "bonus": 0,
            //                         "commission": 0,
            //                         "other": 0
            //                     },
            //                     "method": "Average of 1 paystubs (weekly frequency)",
            //                     "source_docs": [
            //                         "14.pdf"
            //                     ],
            //                     "calculation_details": "Base: Average $640.16 × (52/12) = $2,774.03\nOvertime: Average $1.20 × (52/12) = $5.20"
            //                 },
            //                 "form1005_calculation": null,
            //                 "recommended_monthly_income": {
            //                     "base": 2373.2743718592965,
            //                     "overtime": 12.627135678391962,
            //                     "bonus": 0,
            //                     "commission": 0,
            //                     "other": 2.261306532663317
            //                 },
            //                 "recommendation_reason": "Using YTD calculation (no W2s available)"
            //             }
            //         ],
            //         "total_monthly_income": 2364.771731448763
            //     },
            //     "subha viswanathan_9929": {
            //         "person_id": "subha viswanathan_9929",
            //         "employer_incomes": [
            //             {
            //                 "employer_name": "ebay inc",
            //                 "w2_calculations": [
            //                     {
            //                         "amount": {
            //                             "base": 14792.998333333335,
            //                             "overtime": 0.0,
            //                             "bonus": 0.0,
            //                             "commission": 0.0,
            //                             "other": 0.0
            //                         },
            //                         "method": "W2 Unknown annual income divided by 12",
            //                         "source_docs": [
            //                             "W2-subha-ebay-1of2 2020.pdf"
            //                         ],
            //                         "calculation_details": "Annual income $177,515.98 / 12 = $14,793.00 monthly"
            //                     }
            //                 ],
            //                 "paystub_ytd_calculation": {
            //                     "amount": {
            //                         "base": 14383.755,
            //                         "overtime": 407.64857142857136,
            //                         "bonus": 3715.9757142857143,
            //                         "commission": 0.0,
            //                         "other": 4.542857142857143
            //                     },
            //                     "method": "Most recent paystub YTD divided by months elapsed",
            //                     "source_docs": [
            //                         "Subha Aug Paystub.pdf"
            //                     ],
            //                     "calculation_details": "Base YTD $134,248.38 / 9.3 months = $14,383.75 monthly\nOvertime: Holiday Pay YTD: $3,804.72\nBonus: Exec Bonus YTD: $34,682.44\nOther: Award GrossUp YTD: $42.40"
            //                 },
            //                 "paystub_average_calculation": {
            //                     "amount": {
            //                         "base": 16780.421666666665,
            //                         "overtime": 0.0,
            //                         "bonus": 0.0,
            //                         "commission": 0.0,
            //                         "other": 0.0
            //                     },
            //                     "method": "Average of 1 paystubs (biweekly frequency)",
            //                     "source_docs": [
            //                         "Subha Aug Paystub.pdf"
            //                     ],
            //                     "calculation_details": "Base: Average $7,744.81 × (26/12) = $16,780.42"
            //                 },
            //                 "form1005_calculation": null,
            //                 "recommended_monthly_income": {
            //                     "base": 14792.998333333335,
            //                     "overtime": 0.0,
            //                     "bonus": 0.0,
            //                     "commission": 0.0,
            //                     "other": 0.0
            //                 },
            //                 "recommendation_reason": "Using most recent W2 (no current paystubs)"
            //             }
            //         ],
            //         "total_monthly_income": 14792.998333333335
            //     },
            //     "e quach_9236": {
            //         "person_id": "e quach_9236",
            //         "employer_incomes": [
            //             {
            //                 "employer_name": "united states postal service",
            //                 "w2_calculations": [
            //                     {
            //                         "amount": {
            //                             "base": 0.0,
            //                             "overtime": 0.0,
            //                             "bonus": 0.0,
            //                             "commission": 0.0,
            //                             "other": 0.0
            //                         },
            //                         "method": "W2 Unknown annual income divided by 12",
            //                         "source_docs": [
            //                             "w2_2022_加水印.pdf"
            //                         ],
            //                         "calculation_details": "Annual income $0.00 / 12 = $0.00 monthly"
            //                     }
            //                 ],
            //                 "paystub_ytd_calculation": null,
            //                 "paystub_average_calculation": null,
            //                 "form1005_calculation": null,
            //                 "recommended_monthly_income": {
            //                     "base": 0.0,
            //                     "overtime": 0.0,
            //                     "bonus": 0.0,
            //                     "commission": 0.0,
            //                     "other": 0.0
            //                 },
            //                 "recommendation_reason": "Using most recent W2 (no current paystubs)"
            //             }
            //         ],
            //         "total_monthly_income": 0.0
            //     }
            // }
            // setOriginalData(documentsData)
            // const name = props.application[currentView].firstname + " " + props.application[currentView].lastname
            // const employerName = props.application[currentView].occupation.employername
            // const fileList = getFileList(documentsData)
            // setFileOptions(fileList)
            // if (fileList.length > 0) {
            //     setSelectFile(fileList[0].value)
            // } else {
            //     setSelectFile('')
            // }
            // const docPersonArr = Object.keys(documentsData)
            // const finddocPersonIndex = findMatchingIndex(name, docPersonArr)
            // if (finddocPersonIndex !== -1) {
            //     const dynamicKey = docPersonArr[finddocPersonIndex]
            //     const dynamicDocument = documentsData[dynamicKey]
            //     const findEmployerIndex = findMatchingIndex(employerName, dynamicDocument.map(item => 
            //         item.employmentInformation.find(info => info.name === "Employer name")?.value
            //     ).filter(Boolean))
            //     if (findEmployerIndex !== -1) {
            //         const documentData = dynamicDocument[findEmployerIndex]
            //         const { employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel, previousEmployment } = documentData
            //         console.log(previousEmployment)
            //         formatIncomedocuments(employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel, previousEmployment)
            //     } else {
            //         formatIncomedocuments([], [], [], [], [], [])
            //     }
            // } else {
            //     formatIncomedocuments([], [], [], [], [], [])
            // }

            // const personArr = Object.keys(parsed_data)
            // const findPersonIndex = findMatchingIndex(name, personArr)
            // if (findPersonIndex !== -1) {
            //     const dynamicKey = personArr[findPersonIndex]
            //     const employerIncomes = parsed_data[dynamicKey]?.employer_incomes
            //     const findEmployerIndex = findMatchingIndex(employerName, employerIncomes.map((e) => e.employer_name))
            //     if (findEmployerIndex !== -1) {
            //         const incomeData = employerIncomes[findEmployerIndex]
            //         formatCalculateincome(incomeData)
            //     } else {
            //         setIncomeCalculation([])
            //     }
            // } else {
            //     setIncomeCalculation([])
            // }
            // mock end
            getIncomedocuments()
            setCurrentPage(1)
        }
    }, [open])
    useEffect(() => {
        if (step === 1) {
            getCalculateincome()
        }
    }, [step])
    const commaizeFloat = (v) => {
        if (null === v || typeof v === "undefined" || v === "") {
            return ""
        }
    
        v = parseFloat(v)
        if (isNaN(v)) return ""
    
        v = v.toFixed(2)
    
        let minus = ""
        if (v.charAt(0) === '-') {
            minus = '-';
            v = v.substring(1, v.length)
        }
    
        const parts = v.split('.')
        const input = parts[0].replace(/[^0-9-]/g, '')
    
        parts[0] = com.commaize(input)
    
        return minus + parts.join('.')
    }
    const commaize = (v) => {
        if (null === v || typeof v === "undefined" || v === "") {
            return ""
        }
    
        v = v.toString()
        let input = v.replace(/[^0-9-.]/g, '');

        if (input === "") {
            return ""
        }

        input = Math.round(parseFloat(input)).toString()

        input = input.toString()
        let output = ""
        while (true) {
            if (input.length <= 3) {
                output = input + output
                break
            }
            const right = input.substr(-3)
            const left = input.substr(0, input.length - 3)
            input = left
            output = "," + right + output
        }

        return output
    }
    const isSame = (name1, name2) => {
        const normalize = (str) => str.toLowerCase().replace(/\s+/g, ' ').trim()
        const normalized1 = normalize(name1)
        const normalized2 = normalize(name2)
        const words1 = normalized1.split(' ')
        const words2 = normalized2.split(' ')
        const [shorter, longer] = words1.length <= words2.length ? [words1, words2] : [words2, words1]
        return shorter.every(word => longer.includes(word))
    }
    const findMatchingIndex = (name, array) => {
        const extractName = (str) => str.includes('_') ? str.split('_')[0].trim() : str.trim()
        for (let i = 0; i < array.length; i++) {
            const currentName = extractName(array[i])
            if (isSame(name, currentName)) {
                return i
            }
        }
        return -1
    }

    const getFileList = (data) => {
        const fileList = Object.values(data).flat().reduce((acc, item) => {
            if (item.w2s && item.w2s.length > 0) {
              acc.push(...item.w2s.map(file => ({
                key: 'w2s',
                label: file.filename,
                value: file.fileserialid
              })))
            }
          
            if (item.Paystubs && item.Paystubs.length > 0) {
              acc.push(...item.Paystubs.map(file => ({
                key: 'Paystubs',
                label: file.filename,
                value: file.fileserialid
              })))
            }
          
            if (item.grossEarnings && item.grossEarnings.length > 0) {
              acc.push(...item.grossEarnings.map(file => ({
                key: 'grossEarnings',
                label: file.filename,
                value: file.fileserialid
              })))
            }
          
            if (item.millitaryPersonnel && item.millitaryPersonnel.length > 0) {
              const militaryFile = item.millitaryPersonnel.reduce((militaryAcc, militaryItem) => {
                if (militaryItem.name === 'filename') {
                  militaryAcc.label = militaryItem.amount;
                } else if (militaryItem.name === 'fileserialid') {
                  militaryAcc.value = militaryItem.amount;
                }
                return militaryAcc
              }, { key: 'millitaryPersonnel' })
          
              if (militaryFile.label && militaryFile.value) {
                acc.push(militaryFile)
              }
            }

            if (item.previousEmployment && item.previousEmployment.length > 0) {
                const militaryFile = item.previousEmployment.reduce((previousAcc, previousItem) => {
                  if (previousItem.name === 'filename') {
                    previousAcc.label = previousItem.amount;
                  } else if (previousItem.name === 'fileserialid') {
                    previousAcc.value = previousItem.amount;
                  }
                  return previousAcc
                }, { key: 'previousEmployment' })
            
                if (militaryFile.label && militaryFile.value) {
                  acc.push(militaryFile)
                }
            }
          
            return acc
          }, [])
          
          const seenFiles = new Set()
          const uniqueFileList = fileList.filter(file => {
            if (seenFiles.has(file.value)) {
              return false
            }
            seenFiles.add(file.value)
            return true
          })
        return uniqueFileList
    }
    const formatIncomedocuments = (employmentInformation, w2s, paystubs, grossEarnings, millitaryPersonnel, previousEmployment) => {
        setEmploymentInformation(employmentInformation)
        setWagesList(w2s)
        const deepCopiedPaystubs = cloneDeep(paystubs)
        setOriginalPaystubs(deepCopiedPaystubs)
        setPaystubs(paystubs)
        setFrequency(paystubs[0]?.payPeriod)
        const transformedGrossEarnings = grossEarnings.map(({ details, ...rest }) => {
            const orderedDetails = {};
            details.forEach(detail => {
                orderedDetails[detail.year] = detail.amount
            })
    
            return {
                ...rest,
                ...orderedDetails,
            }
        })
        // setGrossEarnings(transformedGrossEarnings)
        // setMillitaryPersonnel(millitaryPersonnel)
        // setPreviousEmployment(previousEmployment)
    }
    const formatCalculateincome = (data) => {
        const calculationKeys = [
            { key: "w2_calculations", name: "W-2s" },
            { key: "paystub_ytd_calculation", name: "Paystubs" },
            { key: "paystub_average_calculation", name: "Paystubs" },
            { key: "form1005_calculation", name: "VOE" }
        ];
        
        const getEmptyStructure = () => ({
            amount: {
                base: "",
                overtime: "",
                bonus: "",
                commission: "",
                other: ""
            },
            method: "",
            source_docs: [],
            calculation_details: ""
        });
        
        const result = calculationKeys.map((item, index) => {
            const calculation = data[item.key] || null
            const processedCalculation = calculation
                ? { ...getEmptyStructure(), ...calculation }
                : getEmptyStructure();
            return {
                id: index,
                name: item.name,
                ...processedCalculation
            }
        })
        const emp = currentView === "borrower" ? props.application.borrower.occupation : props.application.coborrower.occupation
        result.push({
            id: result.length,
            name: "Current 1003",
            amount: {
                base: emp.selfemployed ? (emp.incomeorlossperiod === "monthly" ? emp.incomeorloss : emp.incomeorloss / 12) : (emp.income.basemonthly === 'monthly' ? emp.income.base : emp.income.base / 12),
                overtime: emp.income.overtimemonthly === "monthly" ? emp.income.overtime : emp.income.overtime / 12,
                bonus: emp.income.bonusesmonthly === "monthly" ? emp.income.bonuses : emp.income.bonuses / 12,
                commission: emp.income.commissionsmonthly === "monthly" ? emp.income.commissions : emp.income.commissions / 12,
                other: emp.income.othermonthly === "monthly" ? emp.income.other : emp.income.other / 12
            },
            method: "",
            source_docs: [],
            calculation_details: ""
        })
        setIncomeCalculation(result)
    }

    const updateParsedData = (parsed_data, name, employerName, newStateValues) => {
        const { employmentInformation, wagesList, paystubs, grossEarnings, millitaryPersonnel, previousEmployment } = newStateValues

        const transformGrossEarnings = (earnings) => {
            return earnings.map((item) => {
                const details = Object.keys(item)
                    .filter((key) => key.match(/^\d{4}$/))
                    .map((year) => ({
                        year,
                        amount: item[year]
                    }))
                    .sort((a, b) => b.year - a.year)
                
                return {
                    name: item.name,
                    yearToDate: item.yearToDate,
                    details,
                    filename: item.filename,
                    fileserialid: item.fileserialid
                };
            });
        };

        const transformedGrossEarnings = transformGrossEarnings(grossEarnings)
        const updatedData = Object.keys(parsed_data).reduce((acc, personName) => {
            if (personName === name) {
                const updatedEmployers = parsed_data[personName].map((employer) => {
                    const employerInfo = employer.employmentInformation.find(
                        (info) => info.name === "Employer name" && info.value === employerName
                    )
    
                    if (employerInfo) {
                        return {
                            ...employer,
                            employmentInformation,
                            w2s: wagesList,
                            Paystubs: paystubs,
                            grossEarnings: transformedGrossEarnings,
                            millitaryPersonnel,
                            previousEmployment
                        }
                    }
    
                    return employer
                })
    
                acc[personName] = updatedEmployers
            } else {
                acc[personName] = parsed_data[personName]
            }
    
            return acc
        }, {})
    
        return updatedData
    }

    const incomedocumentsRegroup = (file) => {
        let token = sessionStorage.getItem("ZeitroA")
        const name = props.application[currentView].firstname + " " + props.application[currentView].lastname
        const employerName = props.application[currentView].occupation.employername
        const newStateValues = {
            employmentInformation,
            wagesList,
            paystubs,
            grossEarnings,
            millitaryPersonnel,
        }
        const updatedParsedData = updateParsedData(originalData, name, employerName, newStateValues)
        fetch('/los/manualregroup', {
            body: JSON.stringify({ 
                "income_files": updatedParsedData,
                "file_to_change_id": file,
                "new_name_job_file": {
                    [name]: {
                        [employerName]: getKeyByFileserialID(file)
                    }
                }
            }),
            method: 'POST',
            headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    return
                }
                response.json().then(js => {
                    const parsed_data = js.parsed_data
                    setOriginalData(parsed_data)
                    const fileList = getFileList(parsed_data)
                    setFileOptions(fileList)
                    if (fileList.length > 0) {
                        setSelectFile(fileList[0].value)
                    } else {
                        setSelectFile('')
                    }
                    const docPersonArr = Object.keys(parsed_data)
                    const name = props.application[currentView].firstname + " " + props.application[currentView].lastname
                    const employerName = props.application[currentView].occupation.employername
                    const finddocPersonIndex = findMatchingIndex(name, docPersonArr)
                    if (finddocPersonIndex !== -1) {
                        const dynamicKey = docPersonArr[finddocPersonIndex]
                        const dynamicDocument = parsed_data[dynamicKey]
                        const findEmployerIndex = findMatchingIndex(employerName, dynamicDocument.map(item => 
                            item.employmentInformation.find(info => info.name === "Employer name")?.value
                        ).filter(Boolean))
                        if (findEmployerIndex !== -1) {
                            const documentData = dynamicDocument[findEmployerIndex]
                            const { employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel, previousEmployment } = documentData
                            formatIncomedocuments(employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel, previousEmployment)
                        } else {
                            formatIncomedocuments([], [], [], [], [], [])
                        }
                    } else {
                        formatIncomedocuments([], [], [], [], [], [])
                    }
                    
                })
            }
        ).catch(function (err) {
            
        })
    }
    const getIncomedocuments = () => {
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/');
        fetch('/los/getincomedocuments', {
            body: JSON.stringify({ loan_id: paths[paths.length - 1] }),
            method: 'POST',
            headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    return
                }
                response.json().then(js => {
                    const parsed_data = js.parsed_data
                    setOriginalData(parsed_data)
                    const fileList = getFileList(parsed_data)
                    setFileOptions(fileList)
                    if (fileList.length > 0) {
                        setSelectFile(fileList[0].value)
                    } else {
                        setSelectFile('')
                    }
                    const docPersonArr = Object.keys(parsed_data)
                    const name = props.application[currentView].firstname + " " + props.application[currentView].lastname
                    const employerName = props.application[currentView].occupation.employername
                    const finddocPersonIndex = findMatchingIndex(name, docPersonArr)
                    if (finddocPersonIndex !== -1) {
                        const dynamicKey = docPersonArr[finddocPersonIndex]
                        const dynamicDocument = parsed_data[dynamicKey]
                        const findEmployerIndex = findMatchingIndex(employerName, dynamicDocument.map(item => 
                            item.employmentInformation.find(info => info.name === "Employer name")?.value
                        ).filter(Boolean))
                        if (findEmployerIndex !== -1) {
                            const documentData = dynamicDocument[findEmployerIndex]
                            const { employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel, previousEmployment } = documentData
                            formatIncomedocuments(employmentInformation, w2s, Paystubs, grossEarnings, millitaryPersonnel, previousEmployment)
                        } else {
                            formatIncomedocuments([], [], [], [], [], [])
                        }
                    } else {
                        formatIncomedocuments([], [], [], [], [], [])
                    }
                })
            }
        ).catch(function (err) {
            
        })
    }

    const getCalculateincome = () => {
        let token = sessionStorage.getItem("ZeitroA")
        const paths = window.location.pathname.split('/')
        const name = props.application[currentView].firstname + " " + props.application[currentView].lastname
        const employerName = props.application[currentView].occupation.employername
        const newStateValues = {
            employmentInformation,
            wagesList,
            paystubs,
            grossEarnings,
            millitaryPersonnel,
        }
        const updatedParsedData = updateParsedData(originalData, name, employerName, newStateValues)
        fetch('/los/calculateincome', {
            body: JSON.stringify({
                customer_id: com.getCustomerId(),
                loan_id: paths[paths.length - 1],
                modified_data: updatedParsedData,
                payPeriod: frequency
            }),
            method: 'POST',
            headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    return
                }
                response.json().then(js => {
                    const parsed_data = js.parsed_data
                    const personArr = Object.keys(parsed_data)
                    const name = props.application[currentView].firstname + " " + props.application[currentView].lastname
                    const employerName = props.application[currentView].occupation.employername
                    const findPersonIndex = findMatchingIndex(name, personArr)
                    if (findPersonIndex !== -1) {
                        const dynamicKey = personArr[findPersonIndex]
                        const employerIncomes = parsed_data[dynamicKey]?.employer_incomes
                        const findEmployerIndex = findMatchingIndex(employerName, employerIncomes.map((e) => e.employer_name))
                        if (findEmployerIndex !== -1) {
                            const incomeData = employerIncomes[findEmployerIndex]
                            formatCalculateincome(incomeData)
                        } else {
                            setIncomeCalculation([])
                        }
                    } else {
                        setIncomeCalculation([])
                    }
                })
            }
        ).catch(function (err) {
            
        })
    }
    const downloadAll = (ids) => {

        let token = sessionStorage.getItem("ZeitroA")
        // this.setState({ loading: true })

        fetch('/los/downloadalldocs', {
            method: 'POST',
            body: JSON.stringify({ BorrowerID: borrowerId, DocumentIDs: ids }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(async response => {
            // this.setState({ loading: false })
            if (response.status !== 200) {
                setFile(null)
                return;
            }

            await response.json().then(js => {
                let files = js.Documents
                setFile(base64ToArrayBuffer(files[0].Content))
            })
        }).catch(() => {
            setFile(null)
            // this.setState({ loading: false })
        })
    }
    const previewCancel = () => {
        setExpand(false)
        setStep(0)
        setStatus(['process', 'wait'])
        setSelectIncome("")
        onCancel()
        if (informationRef.current) {
            informationRef.current.scrollTop = 0
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setCurrentPage(1)
        setNumPages(numPages)
        setVisiblePages([1])
    }
    const createObserver = useCallback((entries) => {
        entries.forEach((entry) => {
            const pageNum = parseInt(entry.target.dataset.page, 10)
            if (entry.isIntersecting) {
                setVisiblePages((prevPages) => {
                    if (!prevPages.includes(pageNum)) {
                        return [...prevPages, pageNum]
                    }
                    return prevPages
                })
            }
        })
    }, [])

    useEffect(() => {
        observerRef.current = new IntersectionObserver(createObserver, {
            root: containerRef.current,
            rootMargin: '0px',
            threshold: 0.1
        })

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        }
    }, [createObserver])
    useEffect(() => {
        if (pageRefs.current.length && observerRef.current) {
            pageRefs.current.forEach((pageRef) => {
                if (pageRef) observerRef.current.observe(pageRef)
            })
        }

        return () => {
            if (observerRef.current) {
                pageRefs.current.forEach((pageRef) => {
                    if (pageRef) observerRef.current.unobserve(pageRef)
                })
            }
        }
    }, [numPages, visiblePages])
    const onChangePage = (page) => {
        isManualChange.current = true

        if (page === 1 || page === numPages) {
            isAtBoundary.current = true
        } else {
            isAtBoundary.current = false
        }
    
        setCurrentPage(page)
        const pdfTargetElement = document.querySelector(`#pdfPage-${page}`)
    
        if (pdfTargetElement) {
            containerRef.current.scrollTo({
                top: pdfTargetElement.offsetTop,
                behavior: 'smooth',
            })
        }
    
        if (manualChangeTimeout.current) {
            clearTimeout(manualChangeTimeout.current)
        }

        manualChangeTimeout.current = setTimeout(() => {
            isManualChange.current = false
        }, 500)
    }
    const resetScrollHandling = () => {
        containerRef.current.removeEventListener('scroll', resetScrollHandling)
        containerRef.current.addEventListener('scroll', handleScroll)
    }
    const handleScroll = () => {
        if (isManualChange.current || isLeftSyncing.current) return
        const scrollTop = containerRef.current.scrollTop
        const containerHeight = containerRef.current.offsetHeight
        const scrollHeight = containerRef.current.scrollHeight
    
        if (handleScroll.lastScrollTop === undefined) {
            handleScroll.lastScrollTop = 0
        }
        handleScroll.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    
        if (justSwitched.current) {
            return
        }
        
        if (isAtBoundary.current) {
            isAtBoundary.current = false
            return
        }

        if (isSwitching.current) return

        if (scrollTop + containerHeight >= scrollHeight - 5) {
            isSwitching.current = true
            changeFile('next')
            setTimeout(() => {
                isSwitching.current = false
            }, 500)
            return
        }
        const anchors = document.querySelectorAll('.pdf-anchor')
        let closestPage = currentPage
        let minDistance = Infinity
    
        anchors.forEach(anchor => {
            const { top } = anchor.getBoundingClientRect()
            const distance = Math.abs(top - containerHeight / 2)
            if (distance < minDistance) {
                minDistance = distance
                closestPage = parseInt(anchor.id.split('-')[1])
            }
        })
    
        if (closestPage !== currentPage) {
            setCurrentPage(closestPage)
        }
    
        setTimeout(() => {
            isLeftSyncing.current = false
        }, 100)
    }
    
    const changeZoom = (type) => {
        if (type === 'add') {
            setScale(+(Math.min(scale + 0.1, 3).toFixed(1)))
        } else {
            setScale(+(Math.max(scale - 0.1, 0.5).toFixed(1)))
        }
    }
    const removeFileExtension = (fileName) => {
        return fileName && fileName.replace(/\.[^/.]+$/, "")
    }
    const arrayBufferToBlob = (buffer, type) => {
        return new Blob([buffer], { type })
    }
    
    const createImageURLFromBase64 = (arrayBuffer, type) => {
        const blob = arrayBufferToBlob(arrayBuffer, type)
        return URL.createObjectURL(blob)
    }
    const changeFile = (type) => {
        const currentIndex = getIndexByValue(selectFile)
        if (type === 'prev' && currentIndex > 0) {
            setSelectFile(fileOptions[currentIndex - 1].value)
            if (refs.current[fileOptions[currentIndex - 1].value]) {
                if (getKeyByFileserialID(fileOptions[currentIndex - 1].value) === 'w2s') {
                    if (informationRef.current) {
                        informationRef.current.scrollTop = 0
                    }
                } else {
                    refs.current[fileOptions[currentIndex - 1].value].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    })
                }
            }
        } else if (type === 'next' && currentIndex >= 0 && currentIndex < fileOptions.length - 1) {
            setSelectFile(fileOptions[currentIndex + 1].value)
            if (refs.current[fileOptions[currentIndex + 1].value]) {
                if (getKeyByFileserialID(fileOptions[currentIndex + 1].value) === 'w2s') {
                    if (informationRef.current) {
                        informationRef.current.scrollTop = 0
                    }
                } else {
                    refs.current[fileOptions[currentIndex + 1].value].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    })
                }
            }
        }
    }
    const showDetails = () => {
        setExpand(!expand)
    }

    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }

    const handleInfoChange = (value, index, type) => {
        setEmploymentInformation((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    let updatedValue
                    if (type === 'date') {
                        updatedValue = value ? dayjs(value).format('MM/DD/YYYY') : null
                    } else if (type === 'phone') {
                        updatedValue = com.fixPhoneInput(value.target.value)
                    } else {
                        updatedValue = value.target.value
                    }
    
                    return {
                        ...item,
                        value: updatedValue,
                    }
                }
                return item
            })
        )
    }
    const handleW2Change = (value, index, field) => {
        setWagesList((prev) => {
            const updatedList = [...prev]
            if (field === 'year') {
                updatedList[index][field] = value ? dayjs(value).format('YYYY') : null
            } else if (field === 'amount') {
                updatedList[index][field] = value || ''
            }
            return updatedList
        })
    } 
    const stepChange = (value) => {
        if (value === 1 && (frequency === "" || frequency === undefined || !selectFile || selectFile === '')) {
            return
        }
        const newStatus = status.map((s, index) =>
            index === value ? 'process' : index === 0 ? 'finish' : s
        )
        setStatus(newStatus)
        setStep(value)
    }
    const getStepIcon = (index) => {
        if (status[index] === 'finish') {
            return <img src="/images/income-step-success.svg" />
        }
        return null
    }
    const disabledDate = (current, fieldName, employmentInformation) => {
        if (fieldName === "End date") {
          const startDateValue = employmentInformation.find(
            (info) => info.name === "Start date"
          )?.value
          if (startDateValue) {
            return current && current < dayjs(startDateValue)
          }
          return false
        } else if (fieldName === "Start date") {
          const endDateValue = employmentInformation.find(
            (info) => info.name === "End date"
          )?.value
          if (endDateValue) {
            return current && current > dayjs(endDateValue)
          }
          return false
        }
        return false
    }
    const onChangeFrequency = (value) => {
        setFrequency(value)
    }
    const handlePaystubs = (value, index, periodIndex, fieldName) => {
        setPaystubs((prevPaystubs) => {
            const updatedPaystubs = cloneDeep(prevPaystubs)
            updatedPaystubs[index].details[periodIndex][fieldName] = value
            return updatedPaystubs
        })
    }
    const handleReset = (index) => {
        setPaystubs((prevPaystubs) => {
            const updatedPaystubs = [...prevPaystubs]
            updatedPaystubs[index].details = originalPaystubs[index].details
            return updatedPaystubs
        })
    }

    const handleDeleteRow = (paystubIndex, rowIndex) => {
        setPaystubs((prevPaystubs) => {
          const updatedPaystubs = [...prevPaystubs]
          updatedPaystubs[paystubIndex].details.splice(rowIndex, 1)
          return updatedPaystubs
        })
    }
    const handleAddRow = (index) => {
        setPaystubs((prevPaystubs) => {
          const updatedPaystubs = [...prevPaystubs]
          updatedPaystubs[index].details.push({
            'name': '',
            'type': 'add',
            '1003Field': null,
            'thisPeriod': 0,
            'yearToDate': 0
          })
          return updatedPaystubs
        })
    }
    const handleGrossearnings = (value, periodIndex, key) => {
        setGrossEarnings(prevGrossEarnings => {
            const updatedGrossEarnings = [...prevGrossEarnings]
            updatedGrossEarnings[periodIndex][key] = value
            return updatedGrossEarnings
        })
    }
    const handleMillitaryPersonnel = (value, periodIndex) => {
        const filteredMillitaryPersonnel = millitaryPersonnel.filter(
            item => item.name !== 'filename' && item.name !== 'fileserialid'
        )
        const targetItem = filteredMillitaryPersonnel[periodIndex]
        if (targetItem) {
            setMillitaryPersonnel(prevMillitaryPersonnel => {
                return prevMillitaryPersonnel.map(item => {
                    if (item.name === targetItem.name) {
                        return { ...item, amount: value }
                    }
                    return item
                })
            })
        }
    }
    const handlePreviousEmployment = (value, periodIndex) => {
        const filteredPreviousEmployment = previousEmployment.filter(
            item => item.name !== 'filename' && item.name !== 'fileserialid'
        )
        const targetItem = filteredPreviousEmployment[periodIndex]
        if (targetItem) {
            setMillitaryPersonnel(prevPreviousEmployment => {
                return prevPreviousEmployment.map(item => {
                    if (item.name === targetItem.name) {
                        return { ...item, amount: value }
                    }
                    return item
                })
            })
        }
    }
    const handleDeleteGrossRow = (periodIndex) => {
        setGrossEarnings(prevGrossEarnings => {
            const updatedGrossEarnings = prevGrossEarnings.map((item, index) => {
                if (index === periodIndex) {
                    return {
                        ...item,
                        ...Object.fromEntries(
                            Object.keys(item)
                                .filter(key => key !== 'name' && key !== 'filename' && key !== 'fileserialid')
                                .map(key => [key, 0])
                        )
                    }
                }
                return item
            })
            return updatedGrossEarnings
        })
    }
    const onChangeIncomeCalculation = (e) => {
        setSelectIncome(e.target.value)
    }
    const getLabelByValue = (value) => {
        const option = fileOptions.find((item) => item.value === value)
        return option ? option.label : null
    }
    const frequencyOptions = [
        {
            label: 'Bi-Monthly',
            value: 'semimonthly',
        },
        {
            label: 'Weekly',
            value: 'weekly',
        },
        {
            label: 'Monthly',
            value: 'monthly',
        },
        {
            label: 'Every Two months',
            value: 'bi-monthly',
        },
        {
            label: 'Every two weeks',
            value: 'biweekly',
        }
    ]
    const paystubsOptions = [
        {
            label: 'Base',
            value: 'Base',
        },
        {
            label: 'Overtime',
            value: 'Overtime',
        },
        {
            label: 'Bonus',
            value: 'Bonus',
        },
        {
            label: 'Commission',
            value: 'Commission',
        },
        {
            label: 'Others',
            value: 'Others',
        }
    ]
    const customHeader = (item) => {
        return <div className='income-calculation-collapse-header'>Pay period <span style={{fontWeight: 700}}>{item.payPeriodStart} - {item.payPeriodEnd}</span></div>
    }
    const formatIncome = (price) => {
        return <span>$ {price && price !== 0 ? commaize(price) : '-'} /month</span>
    }
    const getFileIcon = (fileName) => {
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)
        if (fileExtension === 'pdf') {
            return <img src="/images/pdf-icon.svg" />
        } else {
            return <img src="/images/img-icon.svg" />
        }
    }
    const handleFileChange = (value) => {
        incomedocumentsRegroup(value)
        setSelectFile(value)
        if (refs.current[value]) {
            if (getKeyByFileserialID(value) === 'w2s') {
                if (informationRef.current) {
                    informationRef.current.scrollTop = 0
                }
            } else {
                refs.current[value].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        }
    }
    const getIndexByValue = (value) => {
        return fileOptions.findIndex((item) => item.value === value)
    }
    const getKeyByFileserialID = (id) => {
        const file = fileOptions.find(item => item.value === id)
        return file ? file.key : null
    }
    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes.buffer
    }
    const update1003 = () => {
        const selected = incomeCalculation.find(item => item.id === selectIncome)
        props.updateIncomeMonthly(String(Math.floor(selected.amount.base || 0)), currentView, "base")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.overtime || 0)), currentView, "overtime")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.bonus || 0)), currentView, "bonuses")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.commission || 0)), currentView, "commissions")
        props.updateIncomeMonthly(String(Math.floor(selected.amount.other || 0)), currentView, "other")
        previewCancel()
    }
    const fileSelectDropdownContent = () => {
        return <Select
            style={{
                width: '314px',
            }}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            className='income-file-select'
            suffixIcon={<img src='/images/income-select-search.svg' />}
            placeholder="Document name"
            value={selectFile}
            onChange={handleFileChange}
        >
            {fileOptions.map(option => (
                <Option key={option.value} value={option.value}>
                    <span style={{ display: 'flex', alignItems: 'center', height: 38 }}>
                        {getFileIcon(option.label)}
                        <div style={{ marginLeft: 8, fontFamily: 'Inter', fontSize: 16, color: '#222', lineHeight: '20px', flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{removeFileExtension(option.label)}</div>
                    </span>
                </Option>
            ))}
        </Select>
    }
    const footer = step === 0 ? [
        <div></div>,
        <Button className="income-btn-confirm" type="primary" disabled={frequency === "" || frequency === undefined || !selectFile || selectFile === ''} onClick={() => stepChange(step + 1)}>Confirm</Button>
    ] : [
        <Button className="income-btn-back" type='link' onClick={() => stepChange(step - 1)}>Back</Button>,
        <div>
            {/* <Button className="income-btn-back" type='link' disabled={selectIncome === ''} onClick={update1003}>Update to 1003 only</Button> */}
            <Button className="income-btn-confirm" type="primary" disabled={selectIncome === ''} onClick={update1003}>Update to 1003</Button>
        </div>
    ]
    const noData = () => {
        return <div className='income-item-nodata'>Document unavailable. Please
            <Dropdown
                getPopupContainer={triggerNode => triggerNode.parentElement}
                trigger={['click']}
                dropdownRender={fileSelectDropdownContent}
                placement="bottomLeft"
            >
                <div style={{ width: 28, height: 28, marginRight: 8, marginLeft: 8 }} className='income-calculation-select-file'><img style={{ width: 22, height: 22 }} src='/images/income-file.svg'/></div> 
            </Dropdown>
            add relevant documents.
        </div>
    }
    return (
        <>
            <Modal
                className={`income-calculation-modal check-list`}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                maskClosable={true}
                width='90%'
                open={open}
                title=""
                onCancel={previewCancel}
                footer={footer}
            >
                <div className='preview-modal-top'>Income calculation</div>
                <Row style={{flex: step === 0 ? 1 : 'none'}}>
                    <Col span={24}>
                        <div className='income-calculation-steps-wrapper'>
                            <Steps
                                className='income-calculation-steps'
                                size="small"
                                current={step}
                                onChange={stepChange}
                                items={[
                                    {
                                        title: 'Information',
                                        icon: getStepIcon(0),
                                    },
                                    {
                                        title: 'Calculation',
                                        icon: getStepIcon(1),
                                    }
                                ]}
                            />
                            {/* {step === 1 && <div style={{fontSize: 16, fontWeight: 600, color: '#325CEB', lineHeight: 'normal', display: 'flex', alignItems: 'center', gap: 12, cursor: 'pointer'}}><img style={{width: 22, height: 22}} src='/images/document-download-blue.svg'/> Download worksheet</div> } */}
                        </div>
                    </Col>
                    <Col style={{ display: step === 0 ? 'block' : 'none'}} span={12}>
                        <div style={{padding: '0 34px 24px 50px'}} ref={informationRef} className='income-calculation-content'>
                            <div className='income-item'>
                                <div className='income-item-title'>Employment information</div>
                                {
                                    employmentInformation.length > 0 ? (
                                        employmentInformation.map((item, index) => (
                                            <Row className='income-item-content' gutter={10} key={index}>
                                                <Col>
                                                    <div className='income-item-label'>{item.name}</div>
                                                </Col>
                                                <Col>
                                                    <div style={{width: 240}}>
                                                        {item.type === 'date' ? 
                                                            <DatePicker
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                                className='income-date'
                                                                disabledDate={(current) =>
                                                                    disabledDate(current, item.name, employmentInformation)
                                                                }
                                                                placeholder="MM/DD/YYYY"
                                                                format="MM/DD/YYYY"
                                                                suffixIcon={null}
                                                                value={item.value ? dayjs(item.value) : null}
                                                                onChange={(e) => handleInfoChange(e, index, item.type)}
                                                            />  : 
                                                            <Tooltip title={item.type === 'phone' ? com.fixPhoneInput(item.value) : item.value}>
                                                                <Input
                                                                    style={{
                                                                        width: '100%',
                                                                        fontFamily: 'Inter',
                                                                        height: 34,
                                                                        fontSize: 16,
                                                                        color: '#222',
                                                                        padding: '0 6px',
                                                                        borderRadius: '6px',
                                                                        backgroundColor: '#FCFCFC',
                                                                        border: '1px solid #ECECEC'
                                                                    }}
                                                                    disabled={item.name === 'Employer name'}
                                                                    value={item.type === 'phone' ? com.fixPhoneInput(item.value) : item.value}
                                                                    onChange={(e) => handleInfoChange(e, index, item.type)}
                                                                />
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                    ) : noData()
                                }
                            </div>
                            <div className='income-item'>
                                <div className='income-item-title'>W-2</div>
                                {
                                    wagesList.length > 0 ? (
                                        wagesList.map((item, index) => (
                                            <Row 
                                                className='income-item-content' 
                                                gutter={10} 
                                                key={index}
                                                ref={(el) => (refs.current[item.fileserialid] = el)}
                                            >
                                                <Col>
                                                    <div className='income-item-label'>Wages</div>
                                                </Col>
                                                <Col>
                                                    <div style={{width: 140}}>
                                                        <DatePicker
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                            picker="year"
                                                            className='income-date wages-date'
                                                            suffixIcon={<img src="/images/income-date.svg" />}
                                                            value={item.year ? dayjs(item.year, 'YYYY') : null}
                                                            onChange={(e) => handleW2Change(e, index, 'year')}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{width: 140}}>
                                                        <InputNumber
                                                            style={{
                                                                width: '100%',
                                                                fontFamily: 'Inter',
                                                                height: 34,
                                                                fontSize: 16,
                                                                fontWeight: 400,
                                                                color: '#222',
                                                                padding: '0 6px',
                                                                borderRadius: '6px',
                                                                backgroundColor: '#FCFCFC',
                                                                border: '1px solid #ECECEC'
                                                            }}
                                                            className='income-inputNumber income-inputNumber-w2'
                                                            prefix="$"
                                                            min={0}
                                                            formatter={inputFormatter}
                                                            parser={inputParser}
                                                            value={item.amount}
                                                            onChange={(e) => handleW2Change(e, index, 'amount')}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                    ) : noData()
                                }
                            </div>
                            <div className='income-item'>
                                <div className='income-item-title'>Paystubs
                                    <Select
                                        style={{ width: 190, marginLeft: 20 }}
                                        className="income-select"
                                        placeholder="Select"
                                        allowClear
                                        value={frequency}
                                        onChange={onChangeFrequency}
                                        options={frequencyOptions}
                                        suffixIcon={<img style={{ width: 16, height: 16 }} src="/images/dti-calculator-arrow.svg" />}
                                    />
                                </div>
                                {
                                    paystubs.length > 0 ? (
                                        <Collapse 
                                            className='income-calculation-collapse' 
                                            collapsible="icon" 
                                            expandIcon={({ isActive }) => <img className={`${isActive ? 'expand' : ''} collapse-icon`} src="/images/dti-calculator-arrow.svg" /> } 
                                            expandIconPosition="end"
                                        >
                                            {
                                                paystubs.map((item, index) => {
                                                    const totalThisPeriod = item.details.reduce((sum, detail) => sum + (detail.thisPeriod || 0), 0)
                                                    const totalYearToDate = item.details.reduce((sum, detail) => sum + (detail.yearToDate || 0), 0)
                                                    return (
                                                        <Collapse.Panel className="income-calculation-collapse-item" ref={(el) => (refs.current[item.fileserialid] = el)} header={customHeader(item)} key={index}>
                                                            <div className="dti-calculator-card-wrap">
                                                                <div className='paystubs-table'>
                                                                    <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                                        <div className='paystubs-table-form'>
                                                                            <div style={{width: '23%', color: '#325CEB', cursor: 'pointer'}} onClick={() => handleReset(index)}>Reset all</div>
                                                                            <div style={{width: '31%'}}>1003 field</div>
                                                                            <div style={{width: '23%'}}>This period</div>
                                                                            <div style={{width: '23%'}}>Year to date</div>
                                                                        </div>
                                                                        <div className='paystubs-table-delete'></div>
                                                                    </div>
                                                                    {
                                                                        item.details.map((period, periodIndex) => (
                                                                            <div style={{marginBottom: 16}} className='paystubs-table-row' key={periodIndex}>
                                                                                <div className='paystubs-table-form'>
                                                                        
                                                                                    <div style={{width: '23%'}}>
                                                                                        {
                                                                                            period.type === 'add' ? (
                                                                                                <AutoComplete
                                                                                                    className="income-select"
                                                                                                    // options={servicesOptions}
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        height: 34
                                                                                                    }}
                                                                                                    value={period.name}
                                                                                                    placeholder="Text"
                                                                                                    filterOption={(inputValue, option) =>
                                                                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                                                    }
                                                                                                    onChange={(e) => handlePaystubs(e, index, periodIndex, 'name')}
                                                                                                />
                                                                                            ) : (
                                                                                                <span>{period.name}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{width: '31%'}}>
                                                                                        <Select
                                                                                            style={{ width: '100%' }}
                                                                                            className="income-select"
                                                                                            value={period['1003Field']}
                                                                                            onChange={(e) => handlePaystubs(e, index, periodIndex, '1003Field')}
                                                                                            options={paystubsOptions}
                                                                                            placeholder='Options'
                                                                                            suffixIcon={<img src="/images/auto-reminder-select-arrow.svg" />}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '23%'}}>
                                                                                        <InputNumber
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                fontFamily: 'Inter',
                                                                                                height: 34,
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                color: '#222',
                                                                                                padding: '0 6px',
                                                                                                borderRadius: '6px',
                                                                                                backgroundColor: '#FCFCFC',
                                                                                                border: '1px solid #ECECEC'
                                                                                            }}
                                                                                            className='income-inputNumber'
                                                                                            prefix="$"
                                                                                            min={0}
                                                                                            formatter={inputFormatter}
                                                                                            parser={inputParser}
                                                                                            value={period.thisPeriod}
                                                                                            onChange={(e) => handlePaystubs(e, index, periodIndex, 'thisPeriod')}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{width: '23%'}}>
                                                                                        <InputNumber
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                fontFamily: 'Inter',
                                                                                                height: 34,
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                color: '#222',
                                                                                                padding: '0 6px',
                                                                                                borderRadius: '6px',
                                                                                                backgroundColor: '#FCFCFC',
                                                                                                border: '1px solid #ECECEC'
                                                                                            }}
                                                                                            className='income-inputNumber'
                                                                                            prefix="$"
                                                                                            min={0}
                                                                                            formatter={inputFormatter}
                                                                                            parser={inputParser}
                                                                                            value={period.yearToDate}
                                                                                            onChange={(e) => handlePaystubs(e, index, periodIndex, 'yearToDate')}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='paystubs-table-delete'><img style={{cursor: 'pointer'}} onClick={() => handleDeleteRow(index, periodIndex)} src='/images/delete.svg'/></div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                                        <div className='paystubs-table-form'>
                                                                            <div style={{width: '23%', color: '#222'}}>Total</div>
                                                                            <div style={{width: '31%'}}></div>
                                                                            <div style={{width: '23%', color: '#222'}}>$ {commaizeFloat(totalThisPeriod)}</div>
                                                                            <div style={{width: '23%', color: '#222'}}>$ {commaizeFloat(totalYearToDate)}</div>
                                                                        </div>
                                                                        <div className='paystubs-table-delete'></div>
                                                                    </div>
                                                                    <div className='paystubs-table-add'>
                                                                        <div className='paystubs-table-add-btn' onClick={() => handleAddRow(index)}><img src='/images/add.svg'/><div>Add</div></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Collapse.Panel>
                                                    )   
                                                })
                                            }
                                        </Collapse>
                                    ) : noData()
                                }
                                
                            </div>
                            {
                                previousEmployment && previousEmployment.length > 0 ? (
                                    <div className='income-item'>
                                        <div className='income-item-title'>Verification of Employement (Form 1005)</div>
                                        {
                                            previousEmployment.length > 0 ? (
                                                <>
                                                    {
                                                        previousEmployment.filter(item => item.name !== 'filename' && item.name !== 'fileserialid').map((item, index) => (
                                                            <Row className='income-item-content' gutter={10} key={index}>
                                                                <Col>
                                                                    <div className='income-item-label'>{item.name}</div>
                                                                </Col>
                                                                <Col>
                                                                    <div style={{width: 140}}>
                                                                        <InputNumber
                                                                            style={{
                                                                                width: '100%',
                                                                                fontFamily: 'Inter',
                                                                                height: 34,
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                                color: '#222',
                                                                                padding: '0 6px',
                                                                                borderRadius: '6px',
                                                                                backgroundColor: '#FCFCFC',
                                                                                border: '1px solid #ECECEC'
                                                                            }}
                                                                            className='income-inputNumber'
                                                                            prefix="$"
                                                                            min={0}
                                                                            formatter={inputFormatter}
                                                                            parser={inputParser}
                                                                            value={item.amount}
                                                                            onChange={(e) => handlePreviousEmployment(e, index)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ))
                                                    }
                                                    <div style={{ height: 34, fontFamily: 'Inter', color: '#222', fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center', gap: 10 }}>
                                                        <div style={{width: 128}}>Total</div>
                                                        <div>$ {commaizeFloat(previousEmployment.filter(item => item.name !== 'filename' && item.name !== 'fileserialid').reduce((sum, period) => sum + (period.amount || 0), 0))}</div>
                                                    </div>
                                                </>
                                            ) : noData()
                                        }
                                    </div>
                                ) : (
                                    <div>
                                        <div className='income-item' ref={(el) => (refs.current[millitaryPersonnel.filter(item => item.name == 'fileserialid')[0]?.amount] = el)}>
                                            <div className='income-item-title'>Verification of Employement (Form 1005)</div>
                                            <div className='income-item-subtitle'>Gross earnings</div>
                                            {
                                                grossEarnings.length > 0 ? (
                                                    <div style={{paddingRight: 26}} className='paystubs-table'>
                                                        <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                            <div className='paystubs-table-form'>
                                                                <div style={{width: '25%'}}></div>
                                                                <div style={{width: '25%'}}>Year to date</div>
                                                                {
                                                                    grossEarnings.length > 0 && Object.keys(grossEarnings[0]).filter(key => key !== 'name' && key !== 'yearToDate' && key !== 'filename' && key !== 'fileserialid')
                                                                    .sort((a, b) => {
                                                                        return b - a
                                                                    })
                                                                    .map((key, index) => (
                                                                        <div key={index} style={{ width: '23%' }}>{key}</div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className='paystubs-table-delete'></div>
                                                        </div>
                                                        {
                                                            grossEarnings.filter(item => item.name !== 'Total').map((period, periodIndex) => {
                                                                return (
                                                                    <div ref={(el) => (refs.current[period.fileserialid] = el)} style={{marginBottom: 16}} className='paystubs-table-row' key={periodIndex}>
                                                                        <div className='paystubs-table-form'>
                                                                            <div style={{width: '25%'}}>{period.name}</div>
                                                                            {
                                                                                Object.keys(period).filter(key => key !== 'name' && key !== 'filename' && key !== 'fileserialid')
                                                                                .sort((a, b) => {
                                                                                    if (a === 'yearToDate') return -1
                                                                                    if (b === 'yearToDate') return 1
                                                                                    return b - a;
                                                                                })
                                                                                .map((key, keyIndex) => (
                                                                                    <div key={keyIndex} style={{ width: '25%' }}>
                                                                                        <InputNumber
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                fontFamily: 'Inter',
                                                                                                height: 34,
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                color: '#222',
                                                                                                padding: '0 6px',
                                                                                                borderRadius: '6px',
                                                                                                backgroundColor: '#FCFCFC',
                                                                                                border: '1px solid #ECECEC'
                                                                                            }}
                                                                                            className='income-inputNumber'
                                                                                            prefix="$"
                                                                                            min={0}
                                                                                            formatter={inputFormatter}
                                                                                            parser={inputParser}
                                                                                            value={period[key]}
                                                                                            onChange={(e) => handleGrossearnings(e, periodIndex, key)}
                                                                                        />
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div className='paystubs-table-delete'><img style={{cursor: 'pointer'}} onClick={() => handleDeleteGrossRow(periodIndex)} src='/images/delete.svg'/></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div style={{marginBottom: 8}} className='paystubs-table-row'>
                                                            <div className='paystubs-table-form'>
                                                                <div style={{width: '25%', color: '#222'}}>Total</div>
                                                                {
                                                                    grossEarnings.length > 0 && Object.keys(grossEarnings[0]).filter(key => key !== 'name' && key !== 'filename' && key !== 'fileserialid')
                                                                    .sort((a, b) => {
                                                                        if (a === 'yearToDate') return -1
                                                                        if (b === 'yearToDate') return 1
                                                                        return b - a;
                                                                    })
                                                                    .map((key, keyIndex) => (
                                                                        <div key={keyIndex} style={{ width: '23%', color: '#222'}}>
                                                                            $ {commaizeFloat(grossEarnings.filter(item => item.name !== 'Total').reduce((sum, period) => sum + (period[key] || 0), 0))}
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className='paystubs-table-delete'></div>
                                                        </div>
                                                    </div>
                                                ) : noData()
                                            }
                                        </div>
                                        <div className='income-item'>
                                            <div className='income-item-subtitle'>For millitary personnel</div>
                                            {
                                                millitaryPersonnel.length > 0 ? (
                                                    <>
                                                        {
                                                            millitaryPersonnel.filter(item => item.name !== 'filename' && item.name !== 'fileserialid').map((item, index) => (
                                                                <Row className='income-item-content' gutter={10} key={index}>
                                                                    <Col>
                                                                        <div className='income-item-label'>{item.name}</div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div style={{width: 140}}>
                                                                            <InputNumber
                                                                                style={{
                                                                                    width: '100%',
                                                                                    fontFamily: 'Inter',
                                                                                    height: 34,
                                                                                    fontSize: 14,
                                                                                    fontWeight: 400,
                                                                                    color: '#222',
                                                                                    padding: '0 6px',
                                                                                    borderRadius: '6px',
                                                                                    backgroundColor: '#FCFCFC',
                                                                                    border: '1px solid #ECECEC'
                                                                                }}
                                                                                className='income-inputNumber'
                                                                                prefix="$"
                                                                                min={0}
                                                                                formatter={inputFormatter}
                                                                                parser={inputParser}
                                                                                value={item.amount}
                                                                                onChange={(e) => handleMillitaryPersonnel(e, index)}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                        <div style={{ height: 34, fontFamily: 'Inter', color: '#222', fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center', gap: 10 }}>
                                                            <div style={{width: 128}}>Total</div>
                                                            <div>$ {commaizeFloat(millitaryPersonnel.filter(item => item.name !== 'filename' && item.name !== 'fileserialid').reduce((sum, period) => sum + (period.amount || 0), 0))}</div>
                                                        </div>
                                                    </>
                                                ) : noData()
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                    <Col style={{position: 'relative', display: step === 0 ? 'block' : 'none'}} span={12}>
                        <div className='income-preview-wrap'>
                            <div style={{display: 'flex', justifyContent: 'center', gap: 8, marginBottom: 12}}>
                                <div className='income-calculation-change-file'>
                                    <img className={`prev-file ${getIndexByValue(selectFile) <= 0 && 'arrow-disabled'}`} src={`/images/document-arrow-left${getIndexByValue(selectFile) <= 0 ? '-disabled' : ''}.svg`} onClick={() => changeFile('prev')}/>
                                    <div className='flie-total'>
                                        File <span>{getIndexByValue(selectFile) > -1 ? (getIndexByValue(selectFile) + 1) : 0} / {fileOptions.length}</span>
                                    </div>
                                    <div className='separate'></div>
                                    <div className='file-name'>
                                        {selectFile && selectFile !== '' ? (
                                            <><img src={ getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf' ? '/images/pdf-icon.svg' : '/images/img-icon.svg'}/><div className='file-name-text'>{removeFileExtension(getLabelByValue(selectFile))}</div></>
                                        ) : (
                                            <div className='file-unavailable'><div></div>File unavailable</div>
                                        )}
                                    </div>
                                    <img className={`next-file ${(getIndexByValue(selectFile) < 0 || (getIndexByValue(selectFile) === fileOptions.length - 1)) && 'arrow-disabled'}`} src={`/images/document-arrow-right${(getIndexByValue(selectFile) < 0 || (getIndexByValue(selectFile) === fileOptions.length - 1)) ? '-disabled' : ''}.svg`} onClick={() => changeFile('next')}/>
                                </div>
                                <Dropdown
                                    trigger={['click']}
                                    dropdownRender={fileSelectDropdownContent}
                                    placement="bottomRight"
                                >
                                    <div className='income-calculation-select-file'><img src='/images/income-file.svg'/></div>
                                </Dropdown>
                            </div>
                            <div className='document-preview-content' ref={containerRef} onScroll={handleScroll}>
                                <div style={{ width: pageWidth * scale }} className='preview-box'>
                                    {
                                        selectFile && (getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf') ? (
                                            <Document error={<Skeleton active />} loading={<Skeleton active />} file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                                {Array.from(new Array(numPages), (_, index) => (
                                                    <div
                                                        id={`pdfPage-${index + 1}`}
                                                        key={`page_${index + 1}`}
                                                        data-page={index + 1}
                                                        ref={(ref) => (pageRefs.current[index] = ref)}
                                                        style={{ width: pageWidth * scale }}
                                                        className="pdf-anchor"
                                                    >
                                                        {visiblePages.includes(index + 1) ? (
                                                            <Page
                                                                loading={<Skeleton active />}
                                                                renderAnnotationLayer={false}
                                                                pageNumber={index + 1}
                                                                width={pageWidth * scale}
                                                            />
                                                        ) : (
                                                            <Skeleton active />
                                                        )}
                                                    </div>
                                                ))}
                                            </Document>
                                        ) : (
                                            selectFile && <Image
                                                preview={false}
                                                width={pageWidth * scale}
                                                src={createImageURLFromBase64(file, `image/${getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1)}` )}
                                            />
                                        )

                                    }
                                </div>
                            </div>
                            {
                                fileOptions.length > 0 && (
                                    <div className='document-preview-bottom-bar'>
                                        <div className="document-preview-pagination">
                                            <Pagination size='small' simple current={selectFile && (getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf') ? currentPage : 1} pageSize={1} total={selectFile && (getLabelByValue(selectFile).substring(getLabelByValue(selectFile).lastIndexOf('.') + 1) === 'pdf') ? numPages : 1} onChange={onChangePage} />
                                        </div>
                                        <div className="document-preview-scale">
                                            <div className='zoom-min'><MinusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('min')} /></div>
                                            <div className='zoom-value'>{Math.round(scale * 100)}%</div>
                                            <div className='zoom-add'><PlusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('add')} /></div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                    <Col style={{display: step === 1 ? 'block' : 'none'}} span={24}>
                        <div style={{padding: '0 16px 16px'}} className='income-calculation-content'>
                            <div style={{marginBottom: 10, paddingLeft: 30}} className='income-item-title'>Select income calculation</div>
                            <div style={{fontFamily: 'Inter', fontSize: 16, lineHeight: '20px', color: '#6E6E70', fontWeight: 500, paddingLeft: 30}}>Review the methods below and select an income calculation result to apply to Form 1003.</div>
                            <Radio.Group onChange={onChangeIncomeCalculation} value={selectIncome}>
                                <div className='income-calculation-list'>
                                    <div className='income-calculation-label'>
                                        <div>Base</div>
                                        <div>Overtime</div>
                                        <div>Bonus</div>
                                        <div>Commission</div>
                                        <div>Military Entitlements</div>
                                        <div>Other</div>
                                        <div style={{color: '#222', fontWeight: 700}}>Totals / Monthly</div>
                                        <div style={{color: '#222', fontWeight: 700}}>DTI</div>
                                    </div>
                                    <div className='income-calculation-select'>
                                        {
                                            incomeCalculation.map((item, index) => {
                                                const total = Object.values(item.amount).reduce((sum, value) => sum + (isNaN(Number(value)) ? 0 : Number(value)), 0)
                                                return (
                                                    <label key={item.id} className={`income-calculation-item ${item.id === selectIncome ? 'income-calculation-item-selected' : ''}`}>
                                                        <div>
                                                            <div className='income-calculation-item-name'>{index !== incomeCalculation.length - 1 ? <Radio className='income-calculation-radio' value={item.id}></Radio> : <span style={{display: 'inline-block', width: 26}}></span>}{item.name}</div>
                                                            <div className='income-calculation-item-description'>{item.method}</div>
                                                        </div>
                                                        <div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.base)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.overtime)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.bonus)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.commission)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.militaryEntitlements)}</div>
                                                            <div className='income-calculation-item-value'>{formatIncome(item.amount.other)}</div>
                                                            <div className='income-calculation-item-value bold-value'>{formatIncome(total)}</div>
                                                            {/* <div className='income-calculation-item-value bold-value'>{item.DTI} / {item.frontEndDTI}</div> */}
                                                            <div className='income-calculation-item-value bold-value'>- / -</div>
                                                        </div>
                                                    </label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
IncomeCalculationModal = connect(mapStateToProps, mapDispatchToProps)(IncomeCalculationModal)