import "./Affordability.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import {Form, Col, Row, InputGroup, Button, Collapse, ToggleButtonGroup, OverlayTrigger} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { Tips, tooltipWhite } from "../../Common/Tooltip";
import {CommaizedInput, NumericalInputSimple} from "../../NumericalInput";
import { commaize, MonthlyPayment, logo, capitalize } from "../../Common";
import {AffordabilityHorizontalSlider} from "../../Common/HorizontalSlider";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import CheckGroup from "../../Common/components/CheckGroup";
import ScrollBar from "../../Common/components/ScrollBar";
import HeaderFixed from "../../Common/components/HeaderFixed/HeaderFixed"
import { Switch, Popover, Input } from 'antd-mobile';
import { size } from "lodash";
import OverlayTrig from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Container = styled.span`
  display: flex;
  width: 100%;
  height: calc(0.625 * 100vw);
  align-items: center;
  justify-content: center;
`;
let possiblePayments = {};
let counts = [];
let loanLimits = {};
let customerUrl = "https://app.zeitro.com"
let customerid = null
let loid = null
let tempUrl = null

const PropertyUsageList = [
  {
    value: 'primary',
    label: 'Primary residence',
  },
  {
    value: 'secondHome',
    label: 'Second home',
  },
  {
    value: 'investment',
    label: 'Investment',
  },
]
const PropertyTypeList = [
  {
    value: 'singlefamily',
    label: 'Single family/Townhouse',
  },
  {
    value: 'multifamily',
    label: 'Multi family',
  },
  {
    value: 'condo',
    label: 'Condo',
  },
  // {
  //   value: 'pud',
  //   label: 'PUD',
  // }
]

const calcMonthlyInterestRate = (creditScore) => {
  if (creditScore <= 600) return undefined;
  switch (true) {
    case (creditScore > 740 && creditScore <= 760):
      return 5.75;
    case (creditScore > 720 && creditScore <= 740):
      return 6.125;
    case (creditScore > 700 && creditScore <= 720):
      return 6.25;
    case (creditScore > 680 && creditScore <= 700):
      return 6.375;
    case (creditScore > 660 && creditScore <= 680):
      return 6.5;
    case (creditScore > 640 && creditScore <= 660):
      return 6.725;
    case (creditScore > 620 && creditScore <= 640):
      return 7.125;
    case (creditScore == 620):
      return 7.25;
    default:
      return 5.75;
  }
};

const DEFAULT_LOAN_TERM = 30; //year
const DEFAULT_PROPERTY_TAXES_RATE = 0.76;

const AffordabilityComponent = (props) => {
  const [indexPayments, setIndexPayments] = useState(0);
  const [curPrice, setCurPrice] = useState({
    monthlyPayment: 0,
    homePrice: 0,
    mortgagePayment: 0,
    loanAmount: 0,
    ltv: 0,
    dti: 0,
    overLTV: false,
    overJumbo: false,
  });
  const [termIncome, setTermIncome] = useState(96000);
  const [monthlyDebt, setMonthlyDebt] = useState(1500);
  const [monthlyPayment, setMonthlyPayment] = useState(
    (100000 / 12) * 0.38 - monthlyDebt
  );
  const [downPrice, setDownPrice] = useState(100000);
  const [mortgagePayment, setMortgagePayment] = useState(0);
  const [loanTerm, setLoanTerm] = useState(DEFAULT_LOAN_TERM);
  const [propertyTax, setPropertyTax] = useState(1.25);
  const [homeInsurance, setHomeInsurance] = useState(0);
  const [hoaDUE, setHOADue] = useState(0);
  const [creditScore, setCreditScore] = useState(760);
  const [baseRate, setBaseRate] = useState(calcMonthlyInterestRate(760));
  const [incomeTerm, setIncomeTerm] = useState("yearly");
  const [unitNumber, setUnitNumber] = useState(1);
  const [occupancyType, setOccupancyType] = useState("primary");
  const [propertyType, setPropertyType] = useState("singlefamily");
  const [maxLTV, setMaxLTV] = useState(97);
  const [loanState, setLoanState] = useState("CALIFORNIA");
  const [loanCounty, setLoanCounty] = useState("SANTA CLARA COUNTY");
  const [showDtiSlider, setShowDtiSlider] = useState(false);
  const [sticky, setSticky] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("30")
  const [pmi, setPmi] = useState(0.5);
  const [detailExpand, setDetailExpand] = useState(true)

  // on render, set listener for sticky house price card
  useEffect(() => {
    if (props.isMobile) {
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    }
  }, []);

  const isSticky = () => {
    /* Method that will fix the housr price card after a specific scrollable */
    const scrollTop = window.scrollY;

    let ele = document.getElementById("landingTools");
    let p = 0;
    if (ele) p = ele.offsetTop;
    if (scrollTop > p + 200) {
      if (props.isMobile){
        // setSticky("is-sticky")
        setSticky("")
      }
    }
    if (scrollTop < p - 10) {
      setSticky("")
    }
  };

  // const stickyClasses = props.isFromLanding ? `affordabilityResultFromLanding ${sticky}` : `affordabilityResult ${sticky}`;

  let changeCreditScore = (v) => {
    setCreditScore(v);
    setBaseRate(calcMonthlyInterestRate(v));
  };

  let changeIndexPayments = (v) => {
    setIndexPayments(v);
    setCurPrice(possiblePayments[indexPayments]);
  };

  const resetAll = () => {
    setUnitNumber(1);
    setOccupancyType("primary");
    setPropertyType("singlefamily");
    setHOADue(0);
    setPropertyTax(0);
    setPmi(0.5);
    setHomeInsurance(0);
    setTermIncome(96000);
    setMonthlyDebt(1500);
    setDownPrice(100000);
    setLoanTerm(DEFAULT_LOAN_TERM);
    setCreditScore(760);
    setBaseRate(calcMonthlyInterestRate(760));
    setIncomeTerm("yearly");
    setTaxes(true)
  };

  const getIncome = () => {
    if (incomeTerm === "yearly") return termIncome / 12;
    return termIncome;
  };

  const getMaxConvLTV = () => {
    let ltv = -1;
    if (occupancyType === "primary" && unitNumber === 1) {
      ltv = 97;
    } else if (occupancyType === "primary" && unitNumber === 2) {
      ltv = 85;
    } else if (
      occupancyType === "primary" &&
      (unitNumber === 3 || unitNumber === 4)
    ) {
      ltv = 75;
    } else if (occupancyType === "secondHome" && unitNumber === 1) {
      ltv = 90;
    } else if (occupancyType === "investment" && unitNumber === 1) {
      ltv = 85;
    } else if (
      occupancyType === "investment" &&
      (unitNumber === 2 || unitNumber === 3 || unitNumber === 4)
    ) {
      ltv = 75;
    }
    return ltv;
  };

  const getMaxJumboLTV = () => {
    let ltv = -1;
    if (occupancyType === "primary" && unitNumber === 1) {
      ltv = 90;
    } else if (
      occupancyType === "primary" &&
      (unitNumber === 2 || unitNumber === 3 || unitNumber === 4)
    ) {
      ltv = 80;
    } else {
      ltv = 80;
    }
    return ltv;
  };

  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option value={x}> {capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    let keys = Object.keys(loanLimits[loanState]);

    keys.forEach((x) => {
      options.push(<option value={x}> {capitalize(x)}</option>);
    });
    return options;
  };

  const getLoanLimit = () => {
    // if (loanLimits[loanState][loanCounty] !== undefined) {
    //   if (unitNumber === 1) {
    //     return loanLimits[loanState][loanCounty].OneUnit;
    //   } else if (unitNumber === 2) {
    //     return loanLimits[loanState][loanCounty].TwoUnit;
    //   } else if (unitNumber === 3) {
    //     return loanLimits[loanState][loanCounty].ThreeUnit;
    //   } else if (unitNumber === 4) {
    //     return loanLimits[loanState][loanCounty].FourUnit;
    //   }
    // } else {
    //   return 806500;
    // }
    return 806500;
  };

  const getHousePriceByLTV = (maxConvLTV, maxJumboLTV, downPay) => {
    let loanAmountLimit = getLoanLimit();

    let hpConv = downPay / (1 - maxConvLTV * 0.01);
    if (hpConv * maxConvLTV * 0.01 > loanAmountLimit) {
      hpConv = loanAmountLimit + downPay;
    }

    let hpJumbo = downPay / (1 - maxJumboLTV * 0.01);

    if (hpConv > hpJumbo) {
      setMaxLTV(maxConvLTV);
      return hpConv;
    }
    setMaxLTV(maxJumboLTV);
    return hpJumbo;
  };

  useEffect(() => {
    let maxConvLTV = getMaxConvLTV();
    let maxJumboLTV = getMaxJumboLTV();
    let housePriceLTV = getHousePriceByLTV(maxConvLTV, maxJumboLTV, downPrice);

    // console.log(maxConvLTV, maxJumboLTV);
    // console.log(propertyType, unitNumber, getLoanLimit());
    possiblePayments = {};
    counts = [];

    if (termIncome > 0 && downPrice > 0 && creditScore >= 620) {
      let n = loanTerm * 12;
      let rate = (baseRate * 0.01) / 12;

      for (let dti = 20; dti <= 45; dti++) {
        let overLTV = false;
        let overJumbo = false;
        let monthlypay = getIncome() * dti * 0.01 - monthlyDebt;
        // loanAmt * (rate * math.pow((1+rate), n)) / (math.pow((1+rate), n) - 1) + loanAmt * propertyTax + homeInsurance + hoaDUE = monthlyPayment
        let amorFactor =
          (rate * Math.pow(1 + rate, n)) / (Math.pow(1 + rate, n) - 1);
        let loanAmt =
          (monthlypay - hoaDUE/12 - homeInsurance/12 - (propertyTax*0.01)/12*downPrice) /
          (amorFactor + (propertyTax * 0.01) / 12);

        let housePriceDTI = loanAmt + downPrice;
        let finalHousePrice = housePriceDTI;

        if (downPrice < (finalHousePrice * 0.2)){
          loanAmt =
          (monthlypay - hoaDUE/12 - homeInsurance/12 - (propertyTax*0.01)/12*downPrice) /
          (amorFactor + (propertyTax * 0.01)/12 + (pmi * 0.01)/12);
          housePriceDTI = loanAmt + downPrice
          finalHousePrice = housePriceDTI
        }

        if (housePriceDTI > housePriceLTV) {
          overLTV = true;
          finalHousePrice = housePriceLTV;
          loanAmt = finalHousePrice - downPrice;
        }

        if (loanAmt > 5000000) {
          overJumbo = true;
          finalHousePrice = 5000000 + downPrice;
          loanAmt = 5000000;
        }

        let mp = loanAmt * amorFactor;
        let finalMonthlyPay = mp + (finalHousePrice * propertyTax * 0.01) / 12 + hoaDUE/12 + homeInsurance/12;

        if (downPrice < (finalHousePrice * 0.2)){
          finalMonthlyPay = finalMonthlyPay + (loanAmt * pmi *0.01)/12
        }
        // if (loanAmt > getLoanLimit() && dti > 43) {
        //   break;
        // }

        if (finalMonthlyPay > 0 && finalHousePrice > 0) {
          possiblePayments[dti] = {
            monthlyPayment: finalMonthlyPay,
            homePrice: finalHousePrice,
            mortgagePayment: mp,
            loanAmount: loanAmt,
            ltv: Math.round((100 * loanAmt) / finalHousePrice),
            dti: dti,
            overLTV: overLTV,
            overJumbo: overJumbo,
          };
          counts.push(dti);
        }
      }

      // let i = Math.round(counts.length / 2);
      let i = counts.length-1; //set dti default to 45%
      let curPay = possiblePayments[counts[i]];
      setIndexPayments(counts[i]);
      setCurPrice(curPay);
    }

    // console.log(possiblePayments);
    // console.log(curPrice);
  }, [
    termIncome,
    incomeTerm,
    monthlyDebt,
    downPrice,
    loanTerm,
    creditScore,
    baseRate,
    hoaDUE,
    homeInsurance,
    propertyTax,
    propertyType,
    unitNumber,
    occupancyType,
    loanState,
    loanCounty,
    pmi
  ]);

  let _setIncomeTerm = (e) => {
    setIncomeTerm(e.target.value);
  };

  let _setPropertyType = (e) => {
    setPropertyType(e.target.value);
    if (e.target.value === "multifamily") {
      setUnitNumber(2);
    } else {
      setUnitNumber(1);
    }
  };

  const onDtiSlider = () => {
    if (showDtiSlider) {
      setShowDtiSlider(false);
    } else {
      setShowDtiSlider(true);
    }
  };

  const getHousePriceCard = () => {
    let getStartedUrl = customerUrl + "/services/preapp?customerid=" + customerid
    if (typeof loid !== "undefined") {
      getStartedUrl = customerUrl+ "/services/preapp?customerid=" + customerid + "&loid="+loid
    }
    tempUrl = getStartedUrl
    if (props.isFromLanding){
      if(customerid === "gmcc"){
        if(props.appLink !== ""){
          getStartedUrl = props.appLink
        } else{
          getStartedUrl = props.companyLink
        }
      }
      if (props.appLink !== "") {
        getStartedUrl = props.appLink
      }
    }
    const Textoverlay = ({id,children,title}) =>(
      <OverlayTrig placement="top" overlay={<Tooltip id={id}>{children}</Tooltip>}>
        <a href="#" style={{textDecoration:"underline",fontWeight:"500",color:"#325CEB"}}>{title}</a>
      </OverlayTrig>
    )
    if (props.mobileCheck && sticky !== "") {
      return (
        <div
          className="housePriceCard"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <div>
            <div className="housePriceTitle" style={{ fontSize: "14px" }}>
              You can afford a house up to:{" "}
            </div>
            <div className="housePricePrice" style={{ fontSize: "20px" }}>
              ${commaize(curPrice.homePrice.toFixed(0))}
            </div>
            <hr style={{ marginTop: "0.1rem", marginBottom: "0.1rem" }} />
            <div>
              {curPrice.overLTV && (
                <div className="ltvAlert">
                  <Row className="ltvAlertRow">
                    <Col xs={1}>
                      <img
                        src="/images/tooltipBulb.png"
                        style={{ maxWidth: "20px" }}
                      />
                    </Col>
                    <Col xs={11}>
                      Your current affordable house price is limited by maximum
                      LTV requirements or loan limits, try increasing the
                      down payment to get a higher house price.
                    </Col>
                  </Row>
                </div>
              )}
              {curPrice.overJumbo && (
                <div className="ltvAlert">
                  <Row className="ltvAlertRow">
                    <Col xs={1}>
                      <img
                        src="/images/warningSign.png"
                        style={{ maxWidth: "20px" }}
                      />
                    </Col>
                    <Col xs={11}>
                      Currently we could provide a purchase loan as high as
                      $5,000,000
                    </Col>
                  </Row>
                </div>
              )}
              <div>
                <div className="housePriceDetailsSticky">
                  <div>Monthly payment: </div>
                  <div>${commaize(curPrice.monthlyPayment.toFixed(0))}</div>
                </div>
                <div className="housePriceDetailsSticky">
                  <div>Mortgage payment:</div>
                  <div>${commaize(curPrice.mortgagePayment.toFixed(0))}</div>
                </div>
                <div className="housePriceDetailsSticky">
                  <div>Loan amount:</div>
                  <div>
                    ${commaize(curPrice.loanAmount.toFixed(0))} ({curPrice.ltv}%
                    LTV)
                  </div>
                </div>
                <div className="housePriceDetailsSticky">
                  <div>Debt-to-income ratio (DTI):</div>
                  <div>
                    <b style={{ color: (curPrice.dti <= 37) ? '#00BE63' : ((curPrice.dti <= 44) && (curPrice.dti > 37)) ? '#FFAA29' : '#FF2929' }}>{curPrice.dti}% </b>{" "}
                    <img
                      onClick={() => onDtiSlider()}
                      className="affordabilityPlusSign"
                      src="/images/plusSign.png"
                    />
                  </div>
                </div>
              </div>
              {showDtiSlider && (
                <div className="dtiSlider">
                  <div className="dtiSliderTip">
                    <div className="dtiSliderTipTitle">Why this ratio?</div>
                    <div className="dtiSliderTipText">
                      Typically your DTI should be 36% or lower to qualify for a
                      mortgage. You may enter the percentage of your income you
                      are comfortable spending towards your mortgage. This
                      number will also take monthly debts into account.
                    </div>
                    <div className="dtiSliderTipTitle">
                      Debt-to-income ratio
                    </div>
                  </div>
                  <AffordabilityHorizontalSlider
                    className="paymentSlider"
                    onUpdate={changeIndexPayments}
                    onChange={changeIndexPayments}
                    step={1}
                    domain={[counts[0], counts[counts.length - 1]]}
                    values={[curPrice.dti]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={props.isFromLanding?"housePriceCardFromLanding":"housePriceCard"}>
        <div>
          <div className="text-center">
              <div className="housePriceTitle">You can afford a house up to: </div>
              <div className="housePricePrice">
                ${commaize(curPrice.homePrice.toFixed(0))}
              </div>
          </div>
          <div>
            {curPrice.overLTV && (
              <div className="ltvAlert">
                <Row className="ltvAlertRow">
                  <Col xs={1}>
                    <img
                      src="/images/tooltipBulb.png"
                      style={{ maxWidth: "20px" }}
                    />
                  </Col>
                  <Col xs={11}>
                    Your current affordable house price is limited by maximum
                    LTV requirements or loan limits, try increasing the
                    down payment to get a higher house price.
                  </Col>
                </Row>
              </div>
            )}
            {curPrice.overJumbo && (
              <div className="ltvAlert">
                <Row className="ltvAlertRow">
                  <Col xs={1}>
                    <img
                      src="/images/warningSign.png"
                      style={{ maxWidth: "20px" }}
                    />
                  </Col>
                  <Col xs={11}>
                    Currently we could provide a purchase loan as high as
                    $5,000,000
                  </Col>
                </Row>
              </div>
            )}
            <Row style={{marginTop:"15px", marginBottom:"10px",borderTop:"1px solid #DDDDDD", paddingTop:"18px"}}>
              <Col className="mobile-monthpay-result" md={4} xs={12}>
                <div className="main-text" style={{whiteSpace:"nowrap"}}>Monthly payment</div>
                <div style={{display:"inline-flex"}}>
                  <div className="main-value">${commaize(curPrice.monthlyPayment)}</div>
                  <img
                    src={!detailExpand?"/images/showDetail-Expand.png":"/images/showDetail-Close.png"}
                    style={{ maxHeight:"20px",maxWidth: "20px", marginLeft:"15px", marginTop:"5px" }}
                    onClick={()=> setDetailExpand(!detailExpand)}
                  />
                </div>
              </Col>
              <Col className="mobile-monthpay-result" md={4} xs={12}>
                <div className="main-text" style={{whiteSpace:"nowrap"}}>Down payment</div>
                <div className="main-value">${commaize(downPrice)}</div>
              </Col>
              <Col className="mobile-monthpay-result"  md={4} xs={12}>
                <div className="main-text" style={{whiteSpace:"nowrap"}}>Loan amount</div>
                <div className="main-value">${commaize(curPrice.loanAmount.toFixed(0))}</div>
              </Col>
            </Row>
            {detailExpand && <div className="housePriceDetailsWrap">
              <div className="housePriceDetails">
                <div>Mortgage payment</div>
                <div className="sub-value">${commaize(curPrice.mortgagePayment.toFixed(0))}</div>
              </div>
              <div className="housePriceDetails">
                <div>Property tax</div>
                {!taxes &&<div className="sub-value">$---</div>}
                {taxes &&<div className="sub-value">${commaize(((curPrice.homePrice*propertyTax*0.01)/12).toFixed(0))}</div>}
              </div>
              <div className="housePriceDetails">
                <div>Homeowner insurance</div>
                {!taxes &&<div className="sub-value">$---</div>}
                {taxes &&<div className="sub-value">${commaize((homeInsurance/12).toFixed(0))}</div>}
              </div>
              <div className="housePriceDetails">
                <div>HOA fees</div>
                {!taxes &&<div className="sub-value">$---</div>}
                {taxes &&<div className="sub-value">${commaize((hoaDUE/12).toFixed(0))}</div>}
              </div>
              <div className="housePriceDetails">
                <div>PMI</div>
                {!taxes &&<div className="sub-value">$---</div>}
                {taxes &&<div className="sub-value">{curPrice.ltv > 80 ? `$`+`${commaize(((curPrice.loanAmount*pmi*0.01)/12).toFixed(0))}` : "No Charge"}</div>}
              </div>
            </div>}
            <div className="dtiTip" style={{wordWrap:"break-word",marginTop:"20px",paddingLeft:"10px",paddingRight:"10px"}}>
              Your affordability is calculated by a common Debt-to-income ratio (DTI) of 45%.
              <Tips
                content={<div>Your debt-to-income ratio (DTI) is all your monthly debt payments divided by your gross monthly income. This number is one way lenders measure your ability to manage the monthly payments to repay the money you plan to borrow.</div>}
                placement="top"
                />
            </div>

            {props.isFromLanding&&(<div style={{marginTop:"30px", fontSize:"20px",fontWeight:"600"}}><Button className="affordability-get-started" onClick={()=>window.open(getStartedUrl, "_blank")}>Get Approved Now</Button></div>)}


          </div>
        </div>
      </div>
    );
  };


  const changeScrollValue = (val) => {
    // console.log(val, "changeScrollValue");
    setTermIncome(val);
  }

  const changeScrollValue1 = (val) => {
    // console.log(val, "changeScrollValue1");
    setMonthlyDebt(val);
  }

  const changeScrollValue2 = (val) => {
    // console.log(val, "changeScrollValue2");
    setDownPrice(val)
  }
  const [taxes, setTaxes] = useState(true)
  const switchChange = () => {

    if (!taxes) {
      setHOADue(0);
      setPropertyTax(1.25);
      setHomeInsurance(0);
      setPmi(0.50);
      setTaxes(true)
    } else {
      setHOADue(0);
      setPropertyTax(0);
      setHomeInsurance(0);
      setPmi(0);
      setTaxes(false)
    }
  }

  const updateDownPrice = (e) => {
    let val = e.target.value
    if (val==="") {
      setDownPrice(0)
    } else {
      setDownPrice(parseInt(val))
    }
  }
  const updateTermIncome= (e) => {
    let val = e.target.value
    if (val==="") {
      setTermIncome(0)
    } else {
      setTermIncome(parseInt(val))
    }
  }
  const updateMonthlyDebt = (e) => {
    let val = e.target.value
    if (val==="") {
      setMonthlyDebt(0)
    } else {
      setMonthlyDebt(parseInt(val))
    }
  }


  const displayMobileForm = () => {
    return (
      <div className="affordabilityForm">

      <Form.Row className="mt-2">
        <Col className="inputPrependAdjuster">
          <Form.Group>
            <Form.Label className="affordabilityInputLabel">
              Annual gross income
            </Form.Label>
            <CommaizedInput value={termIncome} onChange={updateTermIncome} withDollarSign={true}/>
          </Form.Group>
        </Col>
        <Col className="inputPrependAdjuster">
          <Form.Group >
            <Form.Label className="affordabilityInputLabel">
              Monthly Debt
            </Form.Label>
            <CommaizedInput value={monthlyDebt} onChange={updateMonthlyDebt} withDollarSign={true}/>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className="mt-2">
        <Col xs className="inputPrependAdjuster">
          <Form.Group >
            <Form.Label className="affordabilityInputLabel">
              Down Payment
            </Form.Label>
            <CommaizedInput value={downPrice} onChange={updateDownPrice} withDollarSign={true}/>
          </Form.Group>
        </Col>
        <Col xs>
          <Form.Group >
            <div style={{ display: 'flex', alignItems: 'top' }}>
                <Form.Label className="affordabilityInputLabel">
                  FICO Score
                </Form.Label>
            </div>
            <Form.Control
              as="select"
              value={creditScore}
              onChange={(e) => {
                changeCreditScore(e.target.value);
              }}
            >
                <option value="760">740+</option>
                <option value="740">721-740</option>
                <option value="720">701-720</option>
                <option value="700">681-700</option>
                <option value="680">661-680</option>
                <option value="660">641-660</option>
                <option value="640">621-640</option>
                <option value="620">Less than 621</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className="mt-2">
        <Col xs={12} >
          <Form.Group>
            <Form.Label className="affordabilityInputLabel">
              Property Usage
            </Form.Label>
            <Form.Control
                        as="select"
                        value={occupancyType}
                        onChange={(e) => {
                          setOccupancyType(e.target.value);
                        }}
                  >
                    {PropertyUsageList.map(ele=>(
                      <option value={ele.value} key={ele.value}>{ele.label}</option>
                    ))}
              </Form.Control>
            </Form.Group>
        </Col>

        <Col xs={12} >
          <Form.Group >
            <Form.Label className="affordabilityInputLabel">
              Property Type
            </Form.Label>
            <Form.Control
                as="select"
                value={propertyType}
                onChange={(e) => {
                  setPropertyType(e.target.value);
                }}
          >
            {PropertyTypeList.map(ele=>(
              <option value={ele.value} key={ele.value}>{ele.label}</option>
            ))}
          </Form.Control>
          </Form.Group>
        </Col>

        {propertyType === "multifamily" && (
          <Col xs={12}>
            <Form.Group >
              <Form.Label className="affordabilityInputLabel">
                Units
              </Form.Label>
              <Form.Control
                size="sm"
                as="select"
                value={unitNumber}
                defaultValue={2}
                onChange={(e) => {
                  setUnitNumber(parseInt(e.target.value));
                }}
              >
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
              </Form.Control>
            </Form.Group>
          </Col>
        )}
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label className="affordabilityInputLabel">
              Loan Term
            </Form.Label>
            <Form.Control
                      as="select"
                      value={loanTerm}
                      onChange={(e) => {
                        setLoanTerm(e.target.value);
                      }}
                >
                      <option value="30">30 year</option>
                      <option value="25">25 year</option>
                      <option value="20">20 year</option>
                      <option value="15">15 year</option>
                      <option value="10">10 year</option>
                </Form.Control>
          </Form.Group>
        </Col>
        <Col >
          <Form.Group >
            <Form.Label className="affordabilityInputLabel">
              Estimated Rate:
            </Form.Label>
            <Form.Control
              type="text"
              value={baseRate}
              placeholder={baseRate}
              onChange={(e) => {
                setBaseRate(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} className="affordabilityFormTitle1 mt-2 mb-3">
        Taxes & Insurance
        <Switch className="taxses-switch" checkedText="Include" uncheckedText="Exclude" checked={taxes} onChange={switchChange}/>
      </div>
      {
        taxes && (
          <div className="mb-2">
            <Form.Row className="mt-2">
              <Col xs={12}>
                <Form.Label style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                    <div>Property Tax</div>
                    <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                </Form.Label>

                <InputGroup style={{border: '1px solid #CFD4D9'}} className="mb-3">
                  <Form.Control
                    className="fee-control"
                    placeholder={propertyTax}
                    value={propertyTax}
                    onChange={(e) => {
                      setPropertyTax(e.target.value === "" ? "" : e.target.value);
                    }}
                    style={{border: 'none'}}
                  />
                  {Object.keys(possiblePayments).length > 0 && (
                    <InputGroup.Text style={{ border: 'none', backgroundColor: '#FFF' }}>
                      {" "}
                      {/* / $
                      {((curPrice.loanAmount * propertyTax * 0.01) / 12).toFixed(
                        0
                      )} */}
                      %
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col xs={12} className="inputPrependAdjuster">
                <Form.Group>
                  <Form.Label  style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                    <div>Homeowners insurance</div>
                    <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                  </Form.Label>
                  <NumericalInputSimple
                    size="sm"
                    value={homeInsurance}
                    type="number"
                    onChange={(e) => {
                      setHomeInsurance(e.target.value === "" ? "" : e.target.value);
                    }}
                    min={0}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mb-2">
              <Col className="inputPrependAdjuster">
                <Form.Group>
                  <Form.Label style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                    <div>HOA fees</div>
                    <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                  </Form.Label>
                  <NumericalInputSimple
                    size="sm"
                    value={hoaDUE}
                    type="number"
                    onChange={(e) => {
                      setHOADue(e.target.value === "" ? "" : e.target.value);
                    }}
                    min={0}
                  />
                </Form.Group>
              </Col>
              <Col >
                <Form.Label style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                  PMI
                  <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                </Form.Label>

                <InputGroup style={{border: '1px solid #CFD4D9', borderRadius: '4px'}}>
                  <Form.Control
                    placeholder={pmi}
                    value={pmi}
                    onChange={(e) => {
                      setPmi(e.target.value === "" ? "" : e.target.value);
                    }}
                    style={{border:"none"}}
                    disabled={!taxes}
                  />
                  {Object.keys(possiblePayments).length > 0 && (
                    <InputGroup.Text style={{border:"none", backgroundColor: '#FFF' }}>
                      %
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
            </Form.Row>
          </div>
        )
      }
  </div>
    )
  }

  return (
    <div
      id="landingAffordability"
      style={{ color: "black", alignItems:"start" }}
      className={`${props.islandingPage ? '' : 'mx-3 mb-3'} affordabilityContainer pb-2`}
    >
      {!props.isFromLanding && props.mobileCheck && <div className="main-heading" style={{paddingLeft:"15px",marginBottom:"30px"}}>
        <div style={{fontSize:"25px",fontWeight:"600"}}>Affordability Calculator</div>
        <div style={{color:"#6E6E70", fontWeight:"400", fontSize:"16px",wordWrap:"break-word"}}>Estimate a comfortable mortgage amount based on your current budget.</div>
      </div>}
      {props.mobileCheck && displayMobileForm()}
      <div className={((occupancyType === "secondHome" && unitNumber !== 1) || Object.keys(possiblePayments).length === 0 || typeof curPrice === "undefined"  || creditScore <= 600)?(sticky == "" ? "affordabilityResult" : `alertContainer ${sticky}`):(props.isFromLanding?`affordabilityResultFromLanding`: `affordabilityResult ${sticky}`)}>
        {occupancyType === "secondHome" && unitNumber !== 1 ? (
          <div>
            <Alert className="alertStyle">
              We could not provide loans for second home multifamily houses.
              <hr />
              <div className="d-flex">
                <Button onClick={() => resetAll()} className="resetbutton" variant="outline-primary">
                  Reset All Numbers
                </Button>
              </div>
            </Alert>
          </div>
        ) : Object.keys(possiblePayments).length === 0 || typeof curPrice === "undefined" || creditScore <= 600 ? (
          creditScore <= 600 ? (
            <div >
              <Alert className="alertStyle">
                Your credit score is too low for an automatic assessment. If
                you’d like you to discuss your options. Please create an account
                and contact with your loan officer.
                <hr />
                <div className="d-flex">
                  <Button onClick={() => resetAll()} className="resetbutton" variant="outline-primary">
                    Reset All Numbers
                  </Button>
                </div>
              </Alert>
            </div>
          ) : (
            <Alert className="alertStyle" >
              Based on your information, homeownership is currently out of
              reach. Try increasing income or down payment.
              <hr />
              <div className="d-flex">
                <Button onClick={() => resetAll()} className="resetbutton" variant="outline-primary">
                  Reset All Numbers
                </Button>
              </div>
            </Alert>
          )
        ) : (
          <div>
            {Object.keys(possiblePayments).length > 0 ? (
              getHousePriceCard()
            ) : (
              <p></p>
            )}
          </div>
        )}
      </div>

      <div>
      {!props.isFromLanding && !props.mobileCheck && <div className="main-heading" style={{paddingLeft:"15px",marginBottom:"30px"}}>
        <div style={{fontSize:"36px",fontWeight:"600"}}>Affordability Calculator</div>
        <div style={{color:"#6E6E70", fontWeight:"400", fontSize:"16px",width:"450px",wordWrap:"break-word"}}>Estimate a comfortable mortgage amount based on your current budget.</div>
      </div>}
      <div className="affordabilityForm-pc mx-3">
        <Form>

            <div style={{fontWeight:"600",fontSize:"20px",marginBottom:"15px"}}>Basic Information</div>
              <Row style={{ marginBottom: '8px'}} >
                <Col  md={6} sm={6} className="pl-0">
                  <Form.Group>
                    <Form.Label className="affordabilityInputLabel">
                      Annual gross income
                    </Form.Label>
                    <ScrollBar  style={{height:"2.75rem"}} max={3000000} min={1} step={100} initVal={termIncome} scrollChangeValue={changeScrollValue} hideSlider={true} />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} className="pl-0">
                  <Form.Group>
                    <Form.Label className="affordabilityInputLabel">
                      Monthly debt
                    </Form.Label>
                    <ScrollBar style={{height:"2.75rem"}} max={getIncome() * 0.6} min={1} step={100} initVal={monthlyDebt} scrollChangeValue={changeScrollValue1} hideSlider={true} />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: '8px'}} >
                <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                  <Form.Group >
                    <div style={{ display: 'flex', alignItems: 'top' }}>
                      <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                        FICO score
                      </Form.Label>
                      <OverlayTrig placement="right" overlay={<Tooltip id='fico-tip'>
                            Your FICO score, similar to a credit score, is a three-digit number indicating your creditworthiness, typically falling between 300 and 850. It plays an important role in determining the loan terms and interest rates you may be eligible for. You have several options for obtaining your FICO score, including checking your credit card statements, requesting it from major credit reporting agencies, or utilizing credit monitoring services
                          </Tooltip>}>

                        <img
                          src="/images/questionMark.png"
                          style={{ width:"15px", height:"15px",marginLeft:"5px", marginTop: "4px"}}
                          alt=''
                        />
                      </OverlayTrig>
                    </div>
                    <Form.Control
                      className="affordability-form-inputs"
                      style={{ height: '2.75rem' }}
                      as="select"
                      value={creditScore}
                      onChange={(e) => {
                        changeCreditScore(e.target.value);
                      }}
                    >
                      <option value="760">740+</option>
                      <option value="740">721-740</option>
                      <option value="720">701-720</option>
                      <option value="700">681-700</option>
                      <option value="680">661-680</option>
                      <option value="660">641-660</option>
                      <option value="640">621-640</option>
                      <option value="620">Less than 621</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6} xs={12} className="pl-0">
                  <Form.Group  style={{ marginBottom: '10px'}}>
                    <Form.Label className="affordabilityInputLabel">
                      Estimated rate
                    </Form.Label>
                    <InputGroup className="affordability-form-inputs" style={{border: '1px solid #CFD4D9', borderRadius: '4px'}}>
                      <Form.Control
                        className="affordability-form-inputs"
                        type="text"
                        value={baseRate}
                        placeholder={baseRate}
                        onChange={(e) => {
                          setBaseRate(e.target.value);
                        }}
                        style={{border:"none"}}
                      />
                      <InputGroup.Text style={{ border: 'none', backgroundColor: '#FAFAFA !important' }}>
                        {" "}
                        %
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                  <Form.Group >
                    <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                          Loan term
                    </Form.Label>
                    <Form.Control
                          className="affordability-form-inputs"
                          style={{ height: '2.75rem' }}
                          as="select"
                          value={loanTerm}
                          onChange={(e) => {
                            setLoanTerm(e.target.value);
                          }}
                    >
                          <option value="30">30 year</option>
                          <option value="25">25 year</option>
                          <option value="20">20 year</option>
                          <option value="15">15 year</option>
                          <option value="10">10 year</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md ={6} className="pl-0">
                  <Form.Group >
                    <Form.Label className="affordabilityInputLabel">
                      Down payment
                    </Form.Label>
                    <ScrollBar style={{height:"2.75rem"}} max={2000000} min={0} step={1000} initVal={downPrice} scrollChangeValue={changeScrollValue2} hideSlider={true} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                    <Form.Group >
                      <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                            Property usage
                      </Form.Label>
                      <Form.Control
                            className="affordability-form-inputs"
                            style={{ height: '2.75rem' }}
                            as="select"
                            value={occupancyType}
                            onChange={(e) => {
                              setOccupancyType(e.target.value);
                            }}
                      >
                        {PropertyUsageList.map(ele=>(
                          <option value={ele.value} key={ele.value}>{ele.label}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                      <Form.Group >
                        <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                              Property type
                        </Form.Label>
                        <Form.Control
                              className="affordability-form-inputs"
                              style={{ height: '2.75rem' }}
                              as="select"
                              value={propertyType}
                              onChange={(e) => {
                                setPropertyType(e.target.value);
                              }}
                        >
                          {PropertyTypeList.map(ele=>(
                            <option value={ele.value} key={ele.value}>{ele.label}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
              </Row>
              <Row style={{marginBottom:"10px"}}>
                    {propertyType === "multifamily" && (
                      <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                        <Form.Group>
                          <Form.Label className="affordabilityInputLabel">
                            Multi family units
                          </Form.Label>
                          <Form.Control
                            className="affordability-form-inputs"
                            size="sm"
                            as="select"
                            value={unitNumber}
                            defaultValue={2}
                            onChange={(e) => {
                              setUnitNumber(parseInt(e.target.value));
                            }}
                          >
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
              </Row>

            <div className="collapse-header" style={{marginBottom:"15px",paddingRight:"15px"}}>
              <div>
                <div style={{fontWeight:"600",fontSize:"20px"}}>Tax, insurance and fees </div>
                {taxes?<div style={{fontWeight:"500",color:"#62707C",lineHeight:"10px"}}>Including tax, insurance and fees</div>:<div style={{fontWeight:"500",color:"#62707C",lineHeight:"10px"}}>Excluding tax, fee and insurance</div>}
              </div>
              <Switch className="taxses-switch" checked={taxes} onChange={switchChange}/>
            </div>
            {taxes && <>
            <Row>
            <Col xs={6} style={{paddingLeft:"0px"}}>
                    <Form.Group className="affordabilityInput-pc">
                      <Form.Label style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                        <div>Homeowners insurance</div>
                        <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                      </Form.Label>
                      <InputGroup style={{border: '1px solid #CFD4D9', borderRadius: '4px'}}>
                        <InputGroup.Text style={{ border:"none", backgroundColor: '#FFF'}}>
                          $
                        </InputGroup.Text>
                        <Form.Control
                          className="affordability-form-inputs"
                          placeholder={homeInsurance}
                          value={homeInsurance}
                          onChange={(e) => {
                            setHomeInsurance(e.target.value === "" ? "" : e.target.value);
                          }}
                          style={{border:"none"}}
                          disabled={!taxes}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={6} style={{paddingLeft:"0px"}}>
                    <Form.Label style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                      Property tax
                      <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                    </Form.Label>

                    <InputGroup style={{border: '1px solid #CFD4D9', borderRadius: '4px'}}>
                      <Form.Control
                        className="affordability-form-inputs"
                        placeholder={propertyTax}
                        value={propertyTax}
                        onChange={(e) => {
                          setPropertyTax(e.target.value === "" ? "" : e.target.value);
                        }}
                        style={{border:"none"}}
                        disabled={!taxes}
                      />
                      {Object.keys(possiblePayments).length > 0 && (
                        <InputGroup.Text style={{border:"none", backgroundColor: '#FFF' }}>
                          %
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} style={{paddingLeft:"0px"}}>
                    <Form.Group className="affordabilityInput-pc" style={{ marginBottom: '24px'}}>
                      <Form.Label style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                        HOA fee
                        <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                      </Form.Label>
                      <InputGroup style={{border: '1px solid #CFD4D9', borderRadius: '4px'}}>
                        <InputGroup.Text style={{  border:"none",backgroundColor:'#FFF'}}>
                          $
                        </InputGroup.Text>
                        <Form.Control
                          className="affordability-form-inputs"
                          placeholder={hoaDUE}
                          value={hoaDUE}
                          onChange={(e) => {
                            setHOADue(e.target.value === "" ? "" : e.target.value);
                          }}
                          style={{border:"none"}}
                          disabled={!taxes}
                        />
                      </InputGroup>
                      {/* <NumericalInputSimple
                        size="md"
                        value={hoaDUE}
                        type="number"
                        onChange={(e) => {
                          setHOADue(e.target.value === "" ? 0 : parseFloat(e.target.value));
                        }}
                        min={0}
                      /> */}
                    </Form.Group>
                  </Col>
                  <Col xs={6} style={{paddingLeft:"0px"}}>
                    <Form.Label style={{justifyContent:"space-between"}} className="affordabilityInputLabel">
                      PMI
                      <div style={{fontWeight:"400",color:"#8A8C90"}}>/year</div>
                    </Form.Label>

                    <InputGroup style={{border: '1px solid #CFD4D9', borderRadius: '4px'}}>
                      <Form.Control
                        className="affordability-form-inputs"
                        placeholder={pmi}
                        value={pmi}
                        onChange={(e) => {
                          setPmi(e.target.value === "" ? "" : e.target.value);
                        }}
                        style={{border:"none"}}
                        disabled={!taxes}
                      />
                      {Object.keys(possiblePayments).length > 0 && (
                        <InputGroup.Text style={{border:"none", backgroundColor: '#FFF' }}>
                          %
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
                </>
              }
        </Form>
      </div>
      </div>
    </div>
  );
};

const calculateDimensions = (breakPoints, viewport) => {
  let w, h;
  if (breakPoints.xl) {
    w = 1000;
    h = 800;
  } else if (breakPoints.lg) {
    w = 800;
    h = 600;
  } else if (breakPoints.md) {
    w = 600;
    h = 400;
  } else if (breakPoints.sm || breakPoints.xs) {
    w = Math.round(viewport.width * 0.95);
    h = 300;
  }
  return { width: w, height: h };
};

function Affordability(props) {
  const ref = useRef();
  const [loaded, setLoaded] = useState(false);
  const isMobile = window.innerWidth < 576

  customerid = props.match.params["customerid"];
  if (typeof customerid === "undefined") {
    customerid = props.customerid
  }
   loid = props.match.params["loid"];

  if (typeof loid === "undefined") {
    loid = props.loid
  }

  const fetchData = () =>
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        loanLimits = res;
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setLoaded(false);
      });
  const getCustomerUrl = () => {
    fetch("/data/customerurls/" + customerid)
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        if(window.location.hostname.includes("localhost")){
          customerUrl="http://localhost:3000"
        }
        else{
          customerUrl = res.Prodapp;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  useEffect(()=>{
    fetchData()
    getCustomerUrl()
  },[])


  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: [width, height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });
  return (
    //GA tracking needs to be wrapped by BrowserRouter; the reverse cannot be true, so BrowserRouter is removed from AuthenticatedApp.js
    <Router>
      {!loaded ? (
        <Container>
          <Spinner animation="border" />
        </Container>
      ) : (
        <div
          ref={ref}
          className="AffordabilityWrap"
        >
          <div className="mt-5">
            <AffordabilityComponent mobileCheck={isMobile} {...props}/>
          </div>
        </div>
      )}
    </Router>
  );
}

export default withRouter(Affordability);
