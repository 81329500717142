import { useInViewport } from 'ahooks'
import { Button, Card, message, Row, Skeleton } from 'antd'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import smartlookClient from 'smartlook-client'
import { checkAccess } from '../../Auth'
import { GMCCSpecials } from '../../GMCCCalculator/GMCCCalculator'
import { UR_Owner } from '../../State'
import AboutMe from './components/AboutMe'
import AffordabilityModal from './components/AffordabilityModal'
import Faq from './components/Faq'
import Footer from './components/Footer'
import MortgageRates from './components/MortgageRates'
import MyCompany from './components/MyCompany'
import PersonalInfoCard from './components/PersonalInfoCard'
import Request from './components/request'
import RequireCallback from './components/RequireCallback'
import Reviews from './components/Reviews'
import './index.css'
import { useStatsigClient } from "@statsig/react-bindings/src";

const BACKGROUND_COLOR = '#F7F8FC'
const RATE_QUOTE_DISABLED_LO_IDS = [
    '16c7cbfe-fe33-405f-9600-ad7308c52f49',
    '0c80c06c-1922-4c15-bd74-f6ff57e724bb',
]
const RATE_QUOTE_DISABLED_COMPANY_IDS = ['gmcc', 'changehomemortgage']

const LandingPage = (props) => {
    return <LoLandingPage {...props} />
}

const LoLandingPage = (props) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [nmls, setNMLS] = useState('')
    const [phone, setPhone] = useState('')
    const [basicInfo, setBasicInfo] = useState({})
    const [aboutInfo, setAboutInfo] = useState({})
    const [reviewsList, setReviewsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [showContactModal, setShowContactModal] = useState(false)
    const [companyLogoUrl, setCompanyLogoUrl] = useState('')
    const isOwner = checkAccess([UR_Owner])
    const [showFAQ, setShowFAQ] = useState(true)
    const [showTestimonial, setShowTestimonial] = useState(true)
    const [showAffordability, setShowAffordability] = useState(true)
    const [showMortgage, setShowMortgage] = useState(true)
    const [showCompanyInfo, setShowCompanyInfo] = useState(true)
    const [showGmccSpecials, setShowGmccSpecials] = useState(
        props.customerid === 'gmcc'
    )

    const [landingUrl, setLandingUrl] = useState('')
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [scale, setScale] = useState(0.8)
    const [pageWidth, setPageWidth] = useState(125)
    const [pageHeight, setPageHeight] = useState(125)
    const interactionOccurredRef = useRef(false)
    const { client } = useStatsigClient()

    const tabs = useMemo(
        () => [
            { key: 'aboutMe', label: 'About Me' },
            ...(showGmccSpecials
                ? [{ key: 'gmccSpecials', label: 'Specials' }]
                : []),
            ...(showMortgage ? [{ key: 'rates', label: 'Rates' }] : []),
            ...(!showMortgage && showAffordability
                ? [{ key: 'affordability', label: 'Affordability' }]
                : []),
            ...(showCompanyInfo
                ? [{ key: 'myCompany', label: 'My Company' }]
                : []),
            ...(showTestimonial ? [{ key: 'reviews', label: 'Reviews' }] : []),
            ...(showFAQ ? [{ key: 'faq', label: 'FAQ' }] : []),
            { key: 'requireCallback', label: 'Contact' },
        ],
        [
            showTestimonial,
            showFAQ,
            showCompanyInfo,
            showGmccSpecials,
            showMortgage,
            showAffordability,
        ]
    )

    const [activeTab, setActiveTab] = useState('aboutMe')
    const [isManualScrolling, setIsManualScrolling] = useState(false) // Track manual scrolling
    const [isFirstRender, setIsFirstRender] = useState(true)
    const initialRenderTimeoutRef = useRef(null)

    // Add refs for each section
    const containerRef = useRef(null)
    const aboutMeRef = useRef(null)
    const gmccSpecialsRef = useRef(null)
    const ratesRef = useRef(null)
    const affordabilityRef = useRef(null)
    const myCompanyRef = useRef(null)
    const reviewsRef = useRef(null)
    const faqRef = useRef(null)
    const requireCallbackRef = useRef(null)

    // Combine refs into an object
    const sectionRefs = useMemo(() => {
        const baseRefs = {
            aboutMe: aboutMeRef,
            requireCallback: requireCallbackRef,
        }

        if (showMortgage) {
            baseRefs.rates = ratesRef
        }

        if (!showMortgage && showAffordability) {
            baseRefs.affordability = affordabilityRef
        }

        if (showCompanyInfo) {
            baseRefs.myCompany = myCompanyRef
        }

        if (showTestimonial) {
            baseRefs.reviews = reviewsRef
        }

        if (showFAQ) {
            baseRefs.faq = faqRef
        }

        if (showGmccSpecials) {
            baseRefs.gmccSpecials = gmccSpecialsRef
        }

        return baseRefs
    }, [
        showTestimonial,
        showFAQ,
        showCompanyInfo,
        showMortgage,
        showAffordability,
        props.customerid,
    ])

    // Setup section visibility tracking for each section
    const [inViewportAbout, ratioAbout] = useInViewport(aboutMeRef, {
        root: containerRef.current,
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        rootMargin: '-1px 0px -1px 0px',
    })
    const [inViewportRates, ratioRates] = useInViewport(ratesRef, {
        root: containerRef.current,
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        rootMargin: '-1px 0px -1px 0px',
    })
    const [inViewportAffordability, ratioAffordability] = useInViewport(
        affordabilityRef,
        {
            root: containerRef.current,
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            rootMargin: '-1px 0px -1px 0px',
        }
    )
    const [inViewportMyCompany, ratioMyCompany] = useInViewport(myCompanyRef, {
        root: containerRef.current,
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        rootMargin: '-1px 0px -1px 0px',
    })
    const [inViewportReviews, ratioReviews] = useInViewport(reviewsRef, {
        root: containerRef.current,
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        rootMargin: '-1px 0px -1px 0px',
    })
    const [inViewportFaq, ratioFaq] = useInViewport(faqRef, {
        root: containerRef.current,
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        rootMargin: '-1px 0px -1px 0px',
    })
    const [inViewportRequireCallback, ratioRequireCallback] = useInViewport(
        requireCallbackRef,
        {
            root: containerRef.current,
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            rootMargin: '-1px 0px -1px 0px',
        }
    )
    const [inViewportGmccSpecials, ratioGmccSpecials] = useInViewport(
        gmccSpecialsRef,
        {
            root: containerRef.current,
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            rootMargin: '-1px 0px -1px 0px',
        }
    )

    // Combine ratios into an object
    const sectionVisibility = useMemo(() => {
        const baseVisibility = {
            aboutMe: ratioAbout || 0,
            requireCallback: ratioRequireCallback || 0,
        }

        if (showMortgage) {
            baseVisibility.rates = ratioRates || 0
        }

        if (!showMortgage && showAffordability) {
            baseVisibility.affordability = ratioAffordability || 0
        }

        if (showCompanyInfo) {
            baseVisibility.myCompany = ratioMyCompany || 0
        }

        if (showTestimonial) {
            baseVisibility.reviews = ratioReviews || 0
        }

        if (showFAQ) {
            baseVisibility.faq = ratioFaq || 0
        }

        if (showGmccSpecials) {
            baseVisibility.gmccSpecials = ratioGmccSpecials || 0
        }

        return baseVisibility
    }, [
        ratioAbout,
        ratioRates,
        ratioAffordability,
        ratioMyCompany,
        ratioReviews,
        ratioFaq,
        ratioRequireCallback,
        showTestimonial,
        showFAQ,
        showCompanyInfo,
        showGmccSpecials,
        showMortgage,
    ])

    useEffect(() => {
        initialRenderTimeoutRef.current = setTimeout(() => {
            setActiveTab('aboutMe')
            setIsFirstRender(false)
        }, 1000)

        return () => {
            if (initialRenderTimeoutRef.current) {
                clearTimeout(initialRenderTimeoutRef.current)
            }
        }
    }, [])

    // Update active tab based on section visibility
    useEffect(() => {
        if (isManualScrolling || isFirstRender) return

        // Helper function to check if ratio is approximately 1
        const isFullyVisible = (ratio) => Math.abs(1 - ratio) < 0.01

        // Find first section with ratio = 1 or the highest ratio section
        const orderTabs = [
            'aboutMe',
            ...(showMortgage ? ['rates'] : []),
            ...(!showMortgage && showAffordability ? ['affordability'] : []),
            ...(showGmccSpecials ? ['gmccSpecials'] : []),
            ...(showCompanyInfo ? ['myCompany'] : []),
            ...(showTestimonial ? ['reviews'] : []),
            ...(showFAQ ? ['faq'] : []),
            'requireCallback',
        ]
        const maxVisibleSection = orderTabs.reduce((max, key) => {
            const currentRatio = sectionVisibility[key] || 0
            const maxRatio = max ? max.ratio : 0

            // If we already found a section with ratio = 1, keep it
            if (max && isFullyVisible(maxRatio)) {
                return max
            }

            // If current section has ratio = 1, return it immediately
            if (isFullyVisible(currentRatio)) {
                return { key, ratio: currentRatio }
            }

            // Otherwise, keep track of the highest ratio
            if (!max || currentRatio > maxRatio) {
                return { key, ratio: currentRatio }
            }

            return max
        }, null)

        // Update active tab if we have a visible section
        if (maxVisibleSection && maxVisibleSection.ratio > 0) {
            setActiveTab(maxVisibleSection.key)
        }
    }, [
        sectionVisibility,
        isManualScrolling,
        isFirstRender,
        showTestimonial,
        showFAQ,
        showCompanyInfo,
        showGmccSpecials,
        showMortgage,
        showAffordability,
    ])

    // Scroll to the specified section
    const scrollToSection = useCallback(
        (key) => {
            const container = containerRef.current
            const targetSection = sectionRefs[key]?.current

            if (container && targetSection) {
                // Set manual scrolling flag and update active tab immediately
                setIsManualScrolling(true)
                setActiveTab(key)

                // Calculate the actual offset of the target section relative to the container
                const containerRect = container.getBoundingClientRect()
                const targetRect = targetSection.getBoundingClientRect()
                const relativeOffset =
                    (targetRect.top - containerRect.top) / scale +
                    container.scrollTop

                // Add scrollend event listener
                const handleScrollEnd = () => {
                    setIsManualScrolling(false)
                    container.removeEventListener('scrollend', handleScrollEnd)
                }
                container.addEventListener('scrollend', handleScrollEnd)

                // Scroll to the target section
                container.scrollTo({ top: relativeOffset, behavior: 'smooth' })
            }
        },
        [sectionRefs, scale]
    )

    const updateDimensions = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
        adjustZoom()
    }

    const adjustZoom = () => {
        const minWidth = 1200
        const currentWidth = window.innerWidth
        let windowWidth =
            props.mode === 'preview' ? currentWidth / 2 : currentWidth
        // Subtract the right margin and horizontal padding
        windowWidth = windowWidth - 24 - 24 * 2

        const newScale = Math.min(windowWidth / minWidth, 1)

        setScale(newScale)
        setPageWidth(newScale < 1 ? `${100 / newScale}%` : '100%')

        setPageHeight(newScale < 1 ? `${100 / newScale}%` : '100%')
    }

    useEffect(() => {
        getProfile()
        adjustZoom()
        window.addEventListener('resize', updateDimensions)
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    useEffect(() => {
        if (!aboutInfo?.email) return;
        //statsig logEvent, avoid logging when entering my site
        if (!props.moduleManage || props.moduleManage.length === 0) {
            const context = client.getContext();
            const derivedFields = context.values?.derived_fields;
            client.logEvent("lo_detail_page_view", window.location.href, {
                item_name: "LoDetailPageView",
                startTime: new Date,
                timestamp: Date.now(),
                pageUrl: window.location.href,
                ip: derivedFields?.ip,
                country: derivedFields?.country,
                loanOfficerID: aboutInfo?.email,
                type: "personalsite",
            })
        }
    }, [client, aboutInfo]);

    useEffect(() => {
      if (!client) return;
        const handleScroll = () => {
        if (!interactionOccurredRef.current) {
            interactionOccurredRef.current = true;
            client.logEvent("interact_personal_website", "scroll", {
            item_name: "interactPersonalWebsite",
            type: "scroll",
            startTime: new Date,
            timestamp: Date.now(),
            pageUrl: window.location.href,
            });
        }
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
  }, [client]);

  const handleInteraction = () => {
    // const meaningfulElements = ["button", "a", "input"];
    // if (!interactionOccurredRef.current && meaningfulElements.includes(event.target.tagName.toLowerCase())) {
    if (!interactionOccurredRef.current) {
      interactionOccurredRef.current = true;
      client.logEvent("interact_personal_website", "click", {
        item_name: "interactPersonalWebsite",
        type: "click",
        startTime: new Date,
        timestamp: Date.now(),
        pageUrl: window.location.href,
      });
    };
  };

    const initialPropsRef = useRef({
        basicInfo: props.basicInfo,
        aboutInfo: props.aboutInfo,
    })

    useEffect(() => {
        const { basicInfo, aboutInfo } = initialPropsRef.current

        const shouldUpdateBasicInfo =
            JSON.stringify(basicInfo) !== JSON.stringify(props.basicInfo)
        const shouldUpdateAboutInfo =
            JSON.stringify(aboutInfo) !== JSON.stringify(props.aboutInfo)
        if (props.mode === 'preview') {
            if (shouldUpdateBasicInfo) {
                setBasicInfo((basicInfo) => ({
                    ...basicInfo,
                    file:
                        props.basicInfo.file.length > 0
                            ? [{ url: props.basicInfo.file[0].url }]
                            : undefined,
                    firstname: props.basicInfo.firstname,
                    lastname: props.basicInfo.lastname,
                    nmls: props.basicInfo.nmls,
                    states: props.basicInfo.states,
                    languages: props.basicInfo.languages,
                    tagsList: props.basicInfo.tagsList,
                    popularTags: [
                        'I speak Mandarin',
                        'Self-Employed borrowers',
                        'Bay Area for 10+ years',
                    ],
                    google: props.basicInfo.google,
                    yelp: props.basicInfo.yelp,
                    linkedin: props.basicInfo.linkedin,
                    twitter: props.basicInfo.twitter,
                }))
            }
            if (shouldUpdateAboutInfo) {
                setAboutInfo((aboutInfo) => ({
                    ...aboutInfo,
                    introduction: props.aboutInfo.introduction,
                    companyLogo: props.aboutInfo.companyLogo,
                    companyName: props.aboutInfo.companyName,
                    companyAddress: props.aboutInfo.companyAddress,
                    phone: props.aboutInfo.phone,
                    email: props.aboutInfo.email,
                    companyWebsite: props.aboutInfo.companyWebsite,
                    tagline: props.aboutInfo.tagline,

                    companyIntroduction: props.aboutInfo.companyIntroduction,
                    companyLink: props.aboutInfo.companyLink,
                    banner: props.aboutInfo.banner,
                }))
            }

            if (shouldUpdateBasicInfo || shouldUpdateAboutInfo) {
                initialPropsRef.current = {
                    basicInfo: props.basicInfo,
                    aboutInfo: props.aboutInfo,
                }
            }
        }
    }, [props.basicInfo, props.aboutInfo])

    useEffect(() => {
        if (props.mode === 'preview') {
            setShowFAQ(getModuleStatus(4, 'FAQs'))
            setShowTestimonial(getModuleStatus(4, 'testimonial'))
            setShowAffordability(getModuleStatus(3, 'affordability'))
            setShowMortgage(getModuleStatus(3, 'mortgage'))
        }
    }, [props.moduleManage])

    useEffect(() => {
        if (props.mode === 'preview') {
            setShowCompanyInfo(props.companyInfoDisable)
        }
    }, [props.companyInfoDisable])

    useEffect(() => {
        if (props.mode === 'preview') {
            setReviewsList(props.reviewList)
        }
    }, [props.reviewList])

    useEffect(() => {
        if (props.mode === 'preview') {
            setAboutInfo((aboutInfo) => ({
                ...aboutInfo,
                paramsInfo: props.paramsInfo,
                faqInfo: props.faqInfo,
            }))
        }
    }, [props.paramsInfo, props.faqInfo])

    const getModuleStatus = (num, key) => {
        return props.moduleManage
            .find((module) => module.module === num)
            .manage.find((item) => item.key === key).status
    }

    const handleSubmit = (type) => {
        if (type === 'success') {
            setShowContactModal(false)
            message.success(
                'Thanks for your submission. We will contact you shortly.'
            )
        } else {
            message.error(
                'Some internal error happened. Please try again later.'
            )
        }
    }

    const getContactMeModal = () => {
        return (
            <Modal
                className="contactMeModal"
                size="lg"
                backdropClassName="contactMeModalBackdrop"
                dialogClassName="contactMeModalDialog"
                contentClassName="contactMeModalContent"
                show={showContactModal}
                onHide={() => setShowContactModal(false)}
            >
                <Modal.Dialog>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <Request
                            withoutBanner={true}
                            fromLanding={true}
                            customerid={props.customerid}
                            loid={props.loid}
                            url={props.Url}
                            appurl={props.appurl}
                            basicInfo={basicInfo}
                            aboutInfo={aboutInfo}
                            handleSubmit={handleSubmit}
                        />
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }

    const [navDrawer, setNavDrawer] = useState(false)

    const showDrawer = () => {
        setNavDrawer(!navDrawer)
    }

    const onClose = () => {
        setNavDrawer(false)
    }

    const dynamicExternalLink = (href) => {
        if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`
        }
        return href
    }

    // statsig logEvent
    const handleClickTab = (key) => {
        switch (key) {
            case 'aboutMe':
                break;

            case 'rates':
                client.logEvent("lo_detail_page_rate_button_click", window.location.href, {
                    item_name: "loDetailPageRateButtonClick",
                    startTime: new Date,
                    timestamp: Date.now(),
                    pageUrl: window.location.href,
                    loanOfficerID: aboutInfo?.email,
                    type: "personalsite",
                });
                break;

            case 'myCompany':
                client.logEvent("lo_detail_page_company_button_click", window.location.href, {
                    item_name: "loDetailPageCompanyButtonClick",
                    startTime: new Date,
                    timestamp: Date.now(),
                    pageUrl: window.location.href,
                    loanOfficerID: aboutInfo?.email,
                    type: "personalsite",
                });
                break;

            case 'reviews':
                client.logEvent("lo_detail_page_review_button_click", window.location.href, {
                    item_name: "loDetailPageReviewButtonClick",
                    startTime: new Date,
                    timestamp: Date.now(),
                    pageUrl: window.location.href,
                    loanOfficerID: aboutInfo?.email,
                    type: "personalsite",
                });
                break;

            case 'faq':
                client.logEvent("lo_detail_page_faq_button_click", window.location.href, {
                    item_name: "loDetailPageFaqButtonClick",
                    startTime: new Date,
                    timestamp: Date.now(),
                    pageUrl: window.location.href,
                    loanOfficerID: aboutInfo?.email,
                    type: "personalsite",
                });
                break;

            case 'requireCallback':
                break;

            default:
                break;
        }
    };

    const getPreQualificationLink = () => {
        // if (props.customerid === 'gmcc') {
        //     if (basicInfo.appLink !== '') {
        //         return basicInfo.appLink
        //     }
        //     if (aboutInfo.companyLink !== '') {
        //         return aboutInfo.companyLink
        //     }
        // }
        if (basicInfo.appLink !== '') {
            return basicInfo.appLink
        }
        return (
            props.appurl +
            '/services/preapp?customerid=' +
            props.customerid +
            '&loid=' +
            props.loid
        )
    }

    const getProfile = () => {
        setLoading(true)
        let token = ''
        fetch('/los/getprofilesetup', {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'X-CustomerID': props.customerid,
                'X-LoID': props.loid,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    console.warn(
                        'Get profile setup response failed: ',
                        response
                    )
                    setLoading(false)
                } else {
                    response.json().then((js) => {
                        setCompanyLogoUrl(js.CompanyLogo)
                        const lo = js
                        const su = lo.LandingPageSetUp
                        const name = `${lo.FirstName} ${lo.LastName}`
                        smartlookClient.init(
                            '09b3763f18ea03a3078dade87fcf17e136934224'
                        )
                        smartlookClient.record({
                            emails: true,
                            forms: true,
                            numbers: true,
                            ips: true,
                        })
                        smartlookClient.identify(props.loid, {
                            name: name,
                            email: lo.Email,
                        })
                        setShowAffordability(
                            su.showAffordability !== undefined
                                ? su.showAffordability
                                : true
                        )
                        setShowMortgage(
                            su.showMortgage !== undefined
                                ? su.showMortgage
                                : true
                        )
                        setBasicInfo({
                            file: isEmpty(lo.Image) ? [] : [{ url: lo.Image }],
                            firstname: lo.FirstName,
                            lastname: lo.LastName,
                            nmls: lo.NMLS,
                            states: isEmpty(su.States) ? [] : su.States,
                            languages: isEmpty(su.Languages)
                                ? []
                                : su.Languages,
                            tagsList: isEmpty(su.TagsList) ? [] : su.TagsList,
                            popularTags: ['I speak Mandarin', 'Self-Employed borrowers', 'Bay Area for 10+ years'],
                            google: isEmpty(su.Google) ? '' : su.Google,
                            yelp: isEmpty(su.Yelp) ? '' : su.Yelp,
                            linkedin: isEmpty(su.Linkedin) ? '' : su.Linkedin,
                            twitter: isEmpty(su.Twitter) ? '' : su.Twitter,
                            appLink: isEmpty(su.appLink) ? '' : su.appLink,
                        })
                        setName(name)
                        setEmail(lo.Email)
                        setPhone(lo.Phone)
                        setNMLS(lo.NMLS)
                        setAboutInfo({
                            introduction: lo.Introduction,
                            companyLogo: lo.CompanyLogo,
                            companyName: lo.CompanyName,
                            companyAddress: lo.CompanyAddress,
                            phone: lo.Phone,
                            email: lo.Email,
                            companyWebsite: isEmpty(su.CompanyWebsite)
                                ? ''
                                : su.CompanyWebsite,
                            tagline: isEmpty(su.tagline) ? '' : su.tagline,

                            companyIntroduction: isEmpty(su.companyIntroduction)
                                ? ''
                                : su.companyIntroduction,
                            companyLink: isEmpty(su.companyLink)
                                ? ''
                                : su.companyLink,
                            banner: isEmpty(su.banner) ? '' : su.banner,
                            paramsInfo: isEmpty(su.paramsInfo)
                                ? { title: '', paramsList: [] }
                                : su.paramsInfo,
                            faqInfo: isEmpty(su.faqInfo) ? [] : su.faqInfo,
                        })
                        setReviewsList(
                            isEmpty(su.ReviewsList) ? [] : su.ReviewsList
                        )
                        setLandingUrl(
                            window.location.hostname.includes('localhost')
                                ? 'http://localhost:3001' + js.LandingUrl
                                : 'https://' +
                                      window.location.hostname +
                                      js.LandingUrl
                        )
                        setShowFAQ(su.showFAQ !== undefined ? su.showFAQ : true)
                        setShowTestimonial(
                            su.showTestimonial !== undefined
                                ? su.showTestimonial
                                : false
                        )
                        setShowCompanyInfo(
                            su.showCompanyInfo !== undefined
                                ? su.showCompanyInfo
                                : true
                        )
                        setLoading(false)
                    })
                }
            })
            .catch((error) => {
                console.error('Revalidate catch error ' + error)
            })
    }

    const [ratesData, setRatesData] = useState(null)

    const handleRatesUpdate = (data) => {
        setRatesData(data)
    }

    if (loading)
        return (
            <div className="p-4">
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </div>
        )

    return (
        <div
            className="landing-page-container"
            style={{
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                width: pageWidth,
                height: props.mode === 'preview' ? pageHeight : 'auto',
                overflowY: props.mode === 'preview' ? 'scroll' : 'auto',
            }}
            onClick={handleInteraction}
        >
            {/* SEO Automation */}
            <Helmet>
                <title>{name}'s Loan Services</title>
                <meta
                    name="title"
                    content={`Personalized Mortgage Rates with ${name} | Trusted Home Loans from My Mortgage Rate`}
                />
                <meta
                    name="description"
                    content={`Get custom mortgage rates with ${name} on My Mortgage Rate. Easily compare lenders, read reviews, and secure low-rate home loans with expert guidance.`}
                />
                <link
                    rel="icon"
                    href={
                        basicInfo.file?.[0]?.url ||
                        aboutInfo.companyLogo ||
                        '/images/smalllogo.svg'
                    }
                />
            </Helmet>

            {/* Header */}
            <Row className="landing-page-header d-flex flex-row justify-content-between align-items-center">
                <img
                    src={aboutInfo.companyLogo || '/images/logo.svg'}
                    alt="Zeitro Marketplace"
                    priority="true"
                    height={35}
                    onClick={() => {
                        client.logEvent("lo_detail_page_branding_click", window.location.href, {
                            item_name: "loDetailPageBrandingClick",
                            startTime: new Date,
                            timestamp: Date.now(),
                            pageUrl: window.location.href,
                            loanOfficerID: aboutInfo?.email,
                            type: "personalsite",
                        });
                    }
                    }
                />
                <Button
                    type="landing-apply-now"
                    onClick={() => {
                        window.open(getPreQualificationLink(), '_blank');
                        client.logEvent("lo_detail_page_apply_now_button_click", window.location.href, {
                            item_name: "loDetailPageApplyNowButtonClick",
                            startTime: new Date,
                            timestamp: Date.now(),
                            pageUrl: window.location.href,
                            loanOfficerID: aboutInfo?.email,
                            type: "personalsite",
                        });
                    }
                    }
                >
                    Apply now
                </Button>
            </Row>

            <div className="landing-page-content">
                {/* Personal Info Card */}
                <PersonalInfoCard
                    aboutInfo={aboutInfo}
                    basicInfo={basicInfo}
                    contact={() => scrollToSection('requireCallback')}
                    preQualify={() =>
                        window.open(getPreQualificationLink(), '_blank')
                    }
                />

                {/* Tabs Navigation Bar */}
                <div className="navigation-container">
                    <div className="tab-navigation">
                        {tabs.map((tab, index) => (
                            <div
                                key={tab.key}
                                className={`tab-navigation-item ${
                                    activeTab === tab.key
                                        ? 'tab-navigation-item-active'
                                        : 'tab-navigation-item-inactive'
                                } ${index === 0 ? 'first-tab' : ''} ${
                                    index === tabs.length - 1 ? 'last-tab' : ''
                                }`}
                                onClick={() => {
                                    scrollToSection(tab.key);
                                    handleClickTab(tab.key);
                                }}
                            >
                                {tab.label}
                            </div>
                        ))}
                    </div>

                    <Card
                        ref={containerRef}
                        className={
                            props.mode === 'preview'
                                ? 'card-container-preview'
                                : 'card-container'
                        }
                        bodyStyle={{ padding: 0 }}
                        styles={{
                            body: {
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            },
                        }}
                    >
                        <div className="inner-container">
                            {/* About Me Section */}
                            <div ref={aboutMeRef}>
                                <AboutMe
                                    aboutInfo={aboutInfo}
                                    basicInfo={basicInfo}
                                />
                            </div>

                            {/* GMCC Specials Section */}
                            {showGmccSpecials && (
                                <div ref={gmccSpecialsRef}>
                                    <GMCCSpecials
                                        fromLanding={true}
                                        customerid={props.customerid}
                                        loid={props.loid}
                                        url={props.Url}
                                    />
                                </div>
                            )}

                            {/* Mortgage Rates Section */}
                            {showMortgage && (
                                <div ref={ratesRef}>
                                    <MortgageRates
                                        firstName={basicInfo.firstname}
                                        customerid={props.customerid}
                                        loid={props.loid}
                                        appLink={basicInfo.appLink}
                                        companyLink={props.companyLink}
                                        onRatesUpdate={handleRatesUpdate}
                                        showAffordability={showAffordability}
                                        isPreviewMode={props.mode === 'preview'}
                                        loanOfficerEmail={aboutInfo?.email}
                                    />
                                </div>
                            )}

                            {/* Affordability Section */}
                            {!showMortgage && showAffordability && (
                                <>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #ECECEC',
                                            marginLeft: '30px',
                                            marginRight: '30px',
                                        }}
                                    ></div>
                                    <div ref={affordabilityRef}>
                                        <AffordabilityModal
                                            isFromLanding={true}
                                            customerid={props.customerid}
                                            loid={props.loid}
                                            appLink={basicInfo.appLink}
                                            companyLink={props.companyLink}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            borderBottom: '1px solid #ECECEC',
                                            marginLeft: '30px',
                                            marginRight: '30px',
                                        }}
                                    ></div>
                                </>
                            )}

                            {/* My Company Section */}
                            {showCompanyInfo && (
                                <div ref={myCompanyRef}>
                                    <MyCompany
                                        aboutInfo={aboutInfo}
                                        loanOfficerEmail={aboutInfo?.email}
                                    />
                                </div>
                            )}

                            {/* Reviews Section */}
                            {showTestimonial && (
                                <div ref={reviewsRef}>
                                    <Reviews
                                        reviewsList={reviewsList}
                                        loanOfficerEmail={aboutInfo?.email}
                                    />
                                </div>
                            )}

                            {/* FAQ Section */}
                            {showFAQ && (
                                <div ref={faqRef}>
                                    <Faq
                                        faqInfo={aboutInfo.faqInfo}
                                        loanOfficerEmail={aboutInfo?.email}
                                    />
                                </div>
                            )}

                            {/* Require Callback Section */}
                            <div ref={requireCallbackRef}>
                                <RequireCallback
                                    loid={props.loid}
                                    customerid={props.customerid}
                                    handleSubmit={handleSubmit}
                                    ratesData={ratesData}
                                    isPreviewMode={props.mode === 'preview'}
                                    loanOfficerEmail={aboutInfo?.email}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>

            <div className="back-to-top-container d-flex flex-row justify-content-center align-items-center mt-4">
                <div
                    onClick={() => {
                        scrollToSection('aboutMe')
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        })
                    }}
                >
                    Back to the top &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <img src="/images/arrow-up.svg" alt="Arrow up" />
            </div>
            {/* Footer */}
            <Footer
                scrollToSection={scrollToSection}
                basicInfo={basicInfo}
                aboutInfo={aboutInfo}
                customerID={props.customerid}
            />
        </div>
    )
}

export default withRouter(LandingPage)
