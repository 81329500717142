import { useStatsigClient } from '@statsig/react-bindings/src'
import { useEventEmitter } from 'ahooks'
import * as subscriptions from '../Store/subscriptions'
import { Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import smartlookClient from 'smartlook-client'
import './Chat.css'
import ChatHistory from './ChatHistory'
import ChatInput from './ChatInput'
import LimitReachedModal from './LimitReachedModal'
import MarkdownRenderer from './MarkdownRender'
import MessageHeader from './MessageHeader'
import MessageList from './MessageList'
import PDFViewer from './PDFViewer'
import WelcomeGuideline from './WelcomeGuideline'
import {
    PDF_API_URL,
    PDF_CONTENT,
    PDF_GUIDELINES,
    PDF_TYPE_NAME,
    PROMPT_EXAMPLES,
    findPromptAnswer,
    getPdfType,
} from './constants'
import { DocPromptExamples } from './utils'

function Chat({ fromLanding }) {
    const { threadID } = useParams()
    const { client } = useStatsigClient()
    const chatHistoryRef = useRef(null)

    const [messages, setMessages] = useState([])
    const [pdCFontent, setpdCFontent] = useState('Fannie Mae')
    const [newfiMessages, setNewfiMessages] = useState([])
    const [chatThreadID, setChatThreadID] = useState('')
    const [docThreadID, setDocThreadID] = useState('')
    const [newfiThreadID, setNewfiThreadID] = useState('')
    const [chatTitle, setChatTitle] = useState('')
    const [showDoc, setShowDoc] = useState(true)
    const [isBotThinking, setIsBotThinking] = useState(false)
    const [isReceivingAnswer, setIsReceivingAnswer] = useState(false)
    const [isBotAnswering, setIsBotAnswering] = useState(false)
    const [messageToSend, setMessageToSend] = useState('')
    const [currentDocName, setCurrentDocName] = useState(
        PDF_GUIDELINES.FANNIE_MAE
    )
    const [currentMessageID, setCurrentMessageID] = useState('')
    const [currentMessageText, setCurrentMessageText] = useState('')
    const [chatList, setChatList] = useState([])

    const [loadingChat, setLoadingChat] = useState(false)
    const [showChat, setShowChat] = useState(true)
    const [showPdfContent, setShowPdfContent] = useState(true)
    const [mobileAIChat, setMobileAIChat] = useState(true)

    const [uniqueQuoteLocations, setUniqueQuoteLocations] = useState([])

    const [isMobile, setIsMobile] = useState(window.innerWidth < 576)
    const [showHistory, setShowHistory] = useState(!isMobile && !fromLanding ? true : false)

    const currentPdfType = useSelector((state) => state.common.content)
    const currentGuidelineGPTUsage = useSelector((state) => state.subscriptionReducer.currentInfo.usage.guidelineGptUsage)

    const jumpEmitter = useEventEmitter()
    const mountEmitter = useEventEmitter()

    const [questionCount, setQuestionCount] = useState(0)
    const [showLimitModal, setShowLimitModal] = useState(false)

    const jumpParams = useRef(null)

    // Utility function to safely interact with localStorage
    const storage = {
        get: (key) => {
            try {
                return localStorage.getItem(key)
            } catch {
                return null
            }
        },
        set: (key, value) => {
            try {
                localStorage.setItem(key, value)
            } catch {
                // Fallback to session storage if localStorage is not available
                try {
                    sessionStorage.setItem(key, value)
                } catch {
                    console.warn('Storage is not available')
                }
            }
        },
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 576)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // Initialize question count and date on component mount
    useEffect(() => {
        if (!fromLanding) return

        const initializeQuestionCount = () => {
            const count = storage.get('gptQuestionCount')
            const date = storage.get('gptQuestionDate')
            const today = new Date().toDateString()

            // Reset count if it's a new day
            if (date !== today) {
                storage.set('gptQuestionDate', today)
                storage.set('gptQuestionCount', '0')
                setQuestionCount(0)
                return
            }

            // Set the existing count
            setQuestionCount(parseInt(count) || 0)
        }

        initializeQuestionCount()

        // Set up interval to check date changes
        const dateCheckInterval = setInterval(() => {
            const savedDate = storage.get('gptQuestionDate')
            const today = new Date().toDateString()

            if (savedDate !== today) {
                storage.set('gptQuestionDate', today)
                storage.set('gptQuestionCount', '0')
                setQuestionCount(0)
            }
        }, 60000) // Check every minute

        return () => clearInterval(dateCheckInterval)
    }, [fromLanding])

    // Function to check and update question count
    const checkQuestionLimit = useCallback(() => {
        if (!fromLanding) return true

        try {
            const today = new Date().toDateString()
            const savedDate = storage.get('gptQuestionDate')

            // Reset count if it's a new day
            if (savedDate !== today) {
                storage.set('gptQuestionDate', today)
                storage.set('gptQuestionCount', '1')
                setQuestionCount(1)
                return true
            }

            // Check if limit reached
            if (questionCount >= 5) {
                setShowLimitModal(true)
                return false
            }

            // Increment count
            const newCount = questionCount + 1
            storage.set('gptQuestionCount', newCount.toString())
            setQuestionCount(newCount)
            return true
        } catch (error) {
            console.error('Error checking question limit:', error)
            // If storage fails, default to allowing the question
            return true
        }
    }, [fromLanding, questionCount])

    // Add a sync function to ensure count is accurate across tabs
    useEffect(() => {
        if (!fromLanding) return

        const syncStorage = (e) => {
            if (e.key === 'gptQuestionCount') {
                setQuestionCount(parseInt(e.newValue) || 0)
            }
        }

        window.addEventListener('storage', syncStorage)
        return () => window.removeEventListener('storage', syncStorage)
    }, [fromLanding])

    const createGreetingMessage = (content, prompts, handleClick) => ({
        text: (
            <div>
                <div>
                    Hello! I'm an AI assistant specialized in the {content}{' '}
                    lending guidelines. How can I help you today?
                </div>
                {!isEmpty(prompts) && (
                    <div style={{ marginTop: 5, color: '#586570' }}>
                        You can ask me about:{' '}
                    </div>
                )}
                <div className="greeting-prompt-container">
                    {prompts.map((prompt, index) => (
                        <div
                            key={`${content}-prompt-${index}`}
                            className="greeting-prompt"
                            onClick={() =>
                                handleClick(
                                    prompt.question,
                                    PDF_GUIDELINES.FANNIE_MAE
                                )
                            }
                        >
                            {prompt.question}
                        </div>
                    ))}
                </div>
            </div>
        ),
        sender: 'bot',
    })

    useEffect(() => {
        const greetingMessage = createGreetingMessage(
            PDF_TYPE_NAME[currentPdfType],
            PROMPT_EXAMPLES[currentPdfType] || [],
            handleSendDocMessage
        )
        setDocMessages([greetingMessage])
    }, [currentPdfType])

    mountEmitter.useSubscription(() => {
        // if mobile and jumpParams is not null, emit the jump event
        if (isMobile && jumpParams.current) {
            jumpEmitter.emit(jumpParams.current)
            jumpParams.current = null
        }
    })

    const jumpToPage = (pageNumber, uniqueLocations = []) => {
        // Find matching page coordinates if uniqueLocations is provided
        const pageCoordinates = uniqueLocations.find(
            (location) => location[0] === pageNumber.toString()
        )

        if (isMobile) {
            // Store the jump parameters for later use
            jumpParams.current = {
                pageNumber: parseInt(pageNumber) || 1,
                pageCoordinates: pageCoordinates ? pageCoordinates[1] : [],
            }
            setMobileAIChat(false)
        } else {
            // For desktop, emit event immediately
            jumpEmitter.emit({
                pageNumber: parseInt(pageNumber) || 1,
                pageCoordinates: pageCoordinates ? pageCoordinates[1] : [],
            })
        }
    }

    /**
     * Formulate bot answer
     */
    const formulateAnswer = useCallback(
        (
            answer,
            quoteLocations,
            pdfType = currentPdfType,
            isPrompt = false
        ) => {
            let text = (
                <MarkdownRenderer
                    answer={answer}
                    quoteLocations={quoteLocations}
                    referenceFunc={(pageNumber, locations) =>
                        jumpToPage(pageNumber, locations)
                    }
                />
            )

            if (Array.isArray(quoteLocations) && quoteLocations.length > 0) {
                // group the page numbers
                const groupedLocations = quoteLocations.reduce(
                    (acc, [page, coords]) => {
                        if (!acc[page]) {
                            acc[page] = []
                        }
                        acc[page] = acc[page].concat(coords)
                        return acc
                    },
                    {}
                )

                // convert to the required format and deduplicate
                const uniqueLocations = Object.entries(groupedLocations).map(
                    ([page, coords]) => [page, coords]
                )
                setUniqueQuoteLocations(uniqueLocations)

                // sort the page numbers
                const sortedPages = Array.from(
                    new Set(uniqueLocations.map((location) => location[0]))
                ).sort((a, b) => a - b)

                text = (
                    <div>
                        {text}
                        <div className="mt-3">Check reference pages:</div>
                        <div
                            className="d-flex mt-2"
                            style={{ flexWrap: 'wrap' }}
                        >
                            {sortedPages.map((val, index) => (
                                <Tooltip
                                    key={index}
                                    title={`Scroll to Page ${val}`}
                                    placement="top"
                                    overlayClassName="page-reference-tooltip"
                                >
                                    <div
                                        key={`page-reference-${index}`}
                                        className="page-reference"
                                        onClick={() =>
                                            jumpToPage(val, uniqueLocations)
                                        }
                                    >
                                        {val}
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                )
            }

            return text
        },
        [jumpToPage, uniqueQuoteLocations]
    )

    const getTextToCopy = (answer, quoteLocations) => {
        // Remove last line if it contains 'Referenced context'
        let textToCopy = answer.replace(
            /^Referenced context:\s*\[[^\]]+\]\s*$/gm,
            ''
        )
        // Remove invalid reference links
        textToCopy = textToCopy.replace(
            /\[([1-9]\d*(?:\s*,\s*[1-9]\d*)*)\]/g,
            (match, numbers) => {
                // Only filter refs if we have valid locations
                const hasValidLocations = Array.isArray(quoteLocations) && quoteLocations.length > 0
                if (hasValidLocations) {
                    const refs = numbers.split(',').map((n) => parseInt(n.trim()))
                    // Get unique page numbers from quoteLocations
                    const uniquePages = new Set(
                        quoteLocations.map(([page]) => page)
                    )
                    const maxValidRef = uniquePages.size
                    const validRefs = refs.filter((ref) => ref <= maxValidRef)
                    return validRefs.length > 0 ? match : ''
                }

                // If no valid locations, keep original reference
                return match
            }
        )
        return textToCopy
    }

    const handleSendDocMessage = async (newMessage, docName) => {
        if (!checkQuestionLimit()) return

        smartlookClient.track('guidelineGPT_GeneralInteraction', {
            label: 'GPT actions',
            category: 'Clicks',
        })

        setDocMessages((prevMessages) => [
            ...prevMessages,
            { text: newMessage, sender: 'user' },
        ])
        setIsBotThinking(true)
        setIsBotAnswering(false)
        setCurrentMessageID('')

        const promptAnswer = findPromptAnswer(newMessage, currentPdfType)
        if (promptAnswer !== null) {
            setTimeout(() => {
                const answer = formulateAnswer(
                    promptAnswer.Answer,
                    promptAnswer.QuoteLocations,
                    currentPdfType,
                    true
                )

                setIsBotAnswering(true)
                setDocMessages((prevMessages) => [
                    ...prevMessages,
                    { text: answer, sender: 'bot' },
                ])
                setIsBotThinking(false)
            }, 100)

            return
        }

        let lastMessageData = null
        let isFirstMessage = true

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

        setIsReceivingAnswer(true)

        try {
            const token = sessionStorage.getItem('ZeitroA')
            const url = PDF_API_URL[currentPdfType]

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
                body: JSON.stringify({
                    Message: newMessage,
                    ThreadID: docThreadID,
                    Doc: currentPdfType,
                    FromLanding: fromLanding ? true : false,
                }),
            })

            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`)

            const reader = response.body.getReader()
            const decoder = new TextDecoder()
            let buffer = ''

            const processBuffer = async () => {
                let newlineIndex = buffer.indexOf('\n')
                while (newlineIndex > -1) {
                    let line = buffer.slice(0, newlineIndex)
                    buffer = buffer.slice(newlineIndex + 1)
                    if (line.startsWith('data: ')) {
                        const data = JSON.parse(line.slice(6))
                        if (data.RunID === 'Not Mortgage') {
                            setIsBotAnswering(true)
                            setDocMessages((prevMessages) => [
                                ...prevMessages,
                                { text: data.Answer, sender: 'bot' },
                            ])
                            setIsBotThinking(false)
                            return
                        }

                        lastMessageData = data
                        const currentAnswer = formulateAnswer(
                            data.Answer,
                            data.QuoteLocations
                        )

                        setCurrentMessageText(getTextToCopy(data.Answer, data.QuoteLocations))

                        await delay(50)

                        setIsBotAnswering(true)
                        setDocMessages((prevMessages) => {
                            if (isFirstMessage) {
                                isFirstMessage = false
                                return [
                                    ...prevMessages,
                                    { text: currentAnswer, sender: 'bot' },
                                ]
                            } else {
                                const newMessages = [...prevMessages]
                                newMessages[newMessages.length - 1] = {
                                    text: currentAnswer,
                                    sender: 'bot',
                                }
                                return newMessages
                            }
                        })
                        setIsBotThinking(false)
                    }
                    newlineIndex = buffer.indexOf('\n')
                }
            }

            while (true) {
                const { done, value } = await reader.read()
                if (done) break

                // Append new data to the buffer
                buffer += decoder.decode(value, { stream: true })

                await processBuffer()
            }

            // Process any remaining data in the buffer
            if (buffer.length > 0) {
                await processBuffer()
            }

            // After stream processing ends, update other states using the data from the last message
            if (lastMessageData) {
                // console.log( '🚀 ~ file: Chat.js:471 ~ handleSendDocMessage ~ Answer:', lastMessageData)
                setCurrentMessageID(lastMessageData.MessageID)
                if (lastMessageData.InitialChatTitle) {
                    setChatTitle(lastMessageData.InitialChatTitle)
                }
                if (docName === PDF_GUIDELINES.FANNIE_MAE) {
                    setDocThreadID(lastMessageData.ThreadID)
                } else {
                    setNewfiThreadID(lastMessageData.ThreadID)
                }

                // Refresh chat history after new thread is created
                if (chatHistoryRef.current) {
                    chatHistoryRef.current.refreshChatList()
                }
            }

            //statsig logEvent
            const savedUserInfo = JSON.parse(
                sessionStorage.getItem('statsig_user_info')
            )
            if (chatList.length === 0) {
                client.logEvent(
                    'first_time_to_use_GuidelineGPT',
                    savedUserInfo.custom.customerid,
                    {
                        item_name: 'useGuidelineGPT',
                        startTime: new Date(),
                        timestamp: Date.now(),
                        sendMessage: newMessage,
                        docName: currentPdfType,
                        threadID: docThreadID,
                        customerid: savedUserInfo.custom.customerid,
                        email: savedUserInfo.email,
                    }
                )
            }
            client.logEvent(
                'use_GuidelineGPT',
                savedUserInfo.custom.customerid,
                {
                    item_name: 'useGuidelineGPT',
                    startTime: new Date(),
                    timestamp: Date.now(),
                    sendMessage: newMessage,
                    docName: currentPdfType,
                    threadID: docThreadID,
                    customerid: savedUserInfo.custom.customerid,
                    email: savedUserInfo.email,
                }
            )
        } catch (error) {
            console.error('Fetching error:', error)
            setIsBotAnswering(true)
            setDocMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: 'Sorry something went wrong, please try later',
                    sender: 'bot',
                },
            ])
        } finally {
            dispatch(subscriptions.updateGuidelineGPTUsage(currentGuidelineGPTUsage + 1))
            setIsBotThinking(false)
            setIsReceivingAnswer(false)
        }
    }

    const greetingMessage = createGreetingMessage(
        pdCFontent,
        DocPromptExamples,
        handleSendDocMessage
    )

    const [docMessages, setDocMessages] = useState([greetingMessage])

    const handleSendMessage = async (newMessage) => {
        if (!checkQuestionLimit()) return

        setMessages([...messages, { text: newMessage, sender: 'user' }])
        setIsBotThinking(true)

        const ans = findPromptAnswer(newMessage, currentPdfType)
        if (ans !== null) {
            setIsBotThinking(true)
            setTimeout(() => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: ans, sender: 'bot' },
                ])
                setIsBotThinking(false)
            }, 3000)
            return
        }

        try {
            const token = sessionStorage.getItem('ZeitroA')
            const response = await fetch('/los/mortgagegpt/askwithoutdoc', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
                body: JSON.stringify({
                    Message: newMessage,
                    ThreadID: chatThreadID,
                }),
            })

            if (!response.ok)
                throw new Error(`HTTP error! Status: ${response.status}`)

            const data = await response.json()
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: data.answer, sender: 'bot' },
            ])
            setChatThreadID(data.threadID)
        } catch (error) {
            console.error('Fetching error:', error)
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    text: 'Sorry something went wrong, please try later',
                    sender: 'bot',
                },
            ])
        } finally {
            setIsBotThinking(false)
        }
    }

    const dispatch = useDispatch()

    const onChangeMessageToSend = (val) => {
        setMessageToSend(val)
    }

    const loadChat = async (threadID, type) => {
        setLoadingChat(true)
        const token = sessionStorage.getItem('ZeitroA')
        try {
            const response = await fetch('/los/mortgagegpt/loadchat', {
                method: 'POST',
                body: JSON.stringify({ ThreadID: threadID }),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.error(
                    'There was an error fetching the chat history: ',
                    response.status
                )
                setLoadingChat(false)
                return
            }

            const json = await response.json()
            setDocThreadID(json.thread.thread_id)
            if (json.thread.custom_title !== null) {
                setChatTitle(json.thread.custom_title)
            } else if (json.thread.initial_title !== null) {
                setChatTitle(json.thread.initial_title)
            }

            const chatHistory = json.chat_history.map((msg) => {
                // console.log('🚀 ~ file: Chat.js:776 ~ chatHistory ~ msg:', msg)
                if (msg.role === 'user') {
                    return {
                        text: msg.content,
                        sender: 'user',
                    }
                }
                // Format the answer from the bot
                return {
                    text: formulateAnswer(msg.content, msg.locations, type),
                    sender: 'bot',
                }
            })

            setDocMessages(chatHistory)
        } catch (err) {
            console.error('Fetch Error :-S', err)
        } finally {
            setLoadingChat(false)
        }
    }

    const processMoreDetails = (data) => {
        setCurrentMessageID(data.msg_id)
        setCurrentMessageText(getTextToCopy(data.answer, data.QuoteLocations))
        const answer = formulateAnswer(data.answer, data.QuoteLocations)
        const newMsg = { text: answer, sender: 'bot' }
        setDocMessages((prevMessages) => [...prevMessages, newMsg])
    }

    useEffect(() => {
        if (typeof threadID !== 'undefined') {
            loadChat(threadID)
            setChatThreadID(threadID)
        }
    }, [])

    const showPdf = useSelector((state) => state.common.showPdf)

    const getList = useCallback(
        (e) => {
            setChatList(e)
        },
        [setChatList]
    )

    const getHistoryList = useCallback(
        (threadId, type) => {
            dispatch({ type: 'SHOW_PDF', data: false })
            dispatch({ type: 'CHANGE_PDF', data: getPdfType(type) })
            loadChat(threadId, type)
        },
        [dispatch, loadChat]
    )

    const displayHistory = useMemo(() => {
        return showHistory && !fromLanding
    }, [showHistory, fromLanding])

    if (showPdf) {
        return isMobile ? (
            <>
                <WelcomeGuideline
                    showHistory={showHistory}
                    setShowHistory={setShowHistory}
                    setDocMessages={setDocMessages}
                    greetingMessage={greetingMessage}
                    setChatTitle={setChatTitle}
                    setDocThreadID={setDocThreadID}
                    setNewfiThreadID={setNewfiThreadID}
                    setChatThreadID={setChatThreadID}
                    setCurrentMessageID={setCurrentMessageID}
                    isMobile={isMobile}
                    fromLanding={fromLanding}
                />
                {displayHistory && (
                    <div
                        className="history-overlay"
                        onClick={() => setShowHistory(false)}
                    />
                )}
                <div
                    className={`history-panel ${displayHistory ? 'open' : ''}`}
                >
                    <ChatHistory
                        ref={chatHistoryRef}
                        getHistoryList={getHistoryList}
                        getList={getList}
                        showHistory={showHistory}
                        setShowHistory={setShowHistory}
                        setDocMessages={setDocMessages}
                        setChatTitle={setChatTitle}
                        threadID={docThreadID}
                        setDocThreadID={setDocThreadID}
                        setNewfiThreadID={setNewfiThreadID}
                        setChatThreadID={setChatThreadID}
                        setCurrentMessageID={setCurrentMessageID}
                        greetingMessage={greetingMessage}
                        isMobile={isMobile}
                    />
                </div>
            </>
        ) : (
            <Row>
                <Col md={showHistory ? 10 : 12} className="px-0">
                    <WelcomeGuideline
                        showHistory={showHistory}
                        setShowHistory={setShowHistory}
                        setDocMessages={setDocMessages}
                        greetingMessage={greetingMessage}
                        setChatTitle={setChatTitle}
                        setDocThreadID={setDocThreadID}
                        setNewfiThreadID={setNewfiThreadID}
                        setChatThreadID={setChatThreadID}
                        setCurrentMessageID={setCurrentMessageID}
                        isMobile={isMobile}
                        fromLanding={fromLanding}
                    />
                </Col>
                {displayHistory && (
                    <Col md={2} className="px-0">
                        <div
                            className="leftBorder"
                            style={{
                                background: '#F6F6F6',
                                height: '100vh',
                                overflow: 'auto',
                            }}
                        >
                            <ChatHistory
                                ref={chatHistoryRef}
                                getHistoryList={getHistoryList}
                                getList={getList}
                                showHistory={showHistory}
                                setShowHistory={setShowHistory}
                                setDocMessages={setDocMessages}
                                setChatTitle={setChatTitle}
                                threadID={docThreadID}
                                setDocThreadID={setDocThreadID}
                                setNewfiThreadID={setNewfiThreadID}
                                setChatThreadID={setChatThreadID}
                                setCurrentMessageID={setCurrentMessageID}
                                greetingMessage={greetingMessage}
                            />
                        </div>
                    </Col>
                )}
            </Row>
        )
    }

    if (showDoc) {
        return (
            <div className="chat">
                <LimitReachedModal
                    isOpen={showLimitModal}
                    onClose={() => setShowLimitModal(false)}
                />
                {isMobile ? (
                    <>
                        {showChat && (
                            <div className="chat-app">
                                <MessageHeader
                                    chatTitle={chatTitle}
                                    setChatTitle={setChatTitle}
                                    threadID={docThreadID}
                                    fromLanding={fromLanding}
                                    setShowHistory={setShowHistory}
                                    showHistory={showHistory}
                                    setDocMessages={setDocMessages}
                                    setDocThreadID={setDocThreadID}
                                    setNewfiThreadID={setNewfiThreadID}
                                    setChatThreadID={setChatThreadID}
                                    setCurrentMessageID={setCurrentMessageID}
                                    greetingMessage={greetingMessage}
                                    isMobile={isMobile}
                                    setMobileAIChat={setMobileAIChat}
                                />
                                {mobileAIChat ? (
                                    <>
                                        <MessageList
                                            loadingChat={loadingChat}
                                            messages={docMessages}
                                            processMoreDetails={
                                                processMoreDetails
                                            }
                                            threadID={docThreadID}
                                            isBotThinking={isBotThinking}
                                            isBotAnswering={isBotAnswering}
                                            currentMessageID={currentMessageID}
                                            currentMessageText={
                                                currentMessageText
                                            }
                                            isMobile={isMobile}
                                            setMobileAIChat={setMobileAIChat}
                                        />
                                        <div className="chat-input-container">
                                            <ChatInput
                                                onSendMessage={
                                                    handleSendDocMessage
                                                }
                                                isBotThinking={isBotThinking}
                                                isReceivingAnswer={
                                                    isReceivingAnswer
                                                }
                                                docName={currentDocName}
                                                fromLanding={fromLanding}
                                                questionCount={questionCount}
                                                threadID={docThreadID}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {showPdfContent && (
                                            <PDFViewer
                                                file={
                                                    PDF_CONTENT[currentPdfType]
                                                }
                                                jumpEmitter={jumpEmitter}
                                                mountEmitter={mountEmitter}
                                                isMobile={isMobile}
                                                setMobileAIChat={
                                                    setMobileAIChat
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {displayHistory && (
                            <div
                                className="history-overlay"
                                onClick={() => setShowHistory(false)}
                            />
                        )}
                        <div
                            className={`history-panel ${
                                displayHistory ? 'open' : ''
                            }`}
                        >
                            <ChatHistory
                                ref={chatHistoryRef}
                                getHistoryList={getHistoryList}
                                getList={getList}
                                showHistory={showHistory}
                                setShowHistory={setShowHistory}
                                setDocMessages={setDocMessages}
                                setChatTitle={setChatTitle}
                                threadID={docThreadID}
                                setDocThreadID={setDocThreadID}
                                setNewfiThreadID={setNewfiThreadID}
                                setChatThreadID={setChatThreadID}
                                setCurrentMessageID={setCurrentMessageID}
                                greetingMessage={greetingMessage}
                                isMobile={isMobile}
                            />
                        </div>
                    </>
                ) : (
                    <div style={{ width: '100%' }}>
                        <Row className="px-0">
                            {showPdfContent && (
                                <Col md={showHistory ? 5 : 6} className="px-0">
                                    <PDFViewer
                                        file={PDF_CONTENT[currentPdfType]}
                                        jumpEmitter={jumpEmitter}
                                        mountEmitter={mountEmitter}
                                    />
                                </Col>
                            )}
                            <Col md={showHistory ? 5 : 6} className="px-0">
                                {showChat && (
                                    <div className="chat-app">
                                        <MessageHeader
                                            chatTitle={chatTitle}
                                            setChatTitle={setChatTitle}
                                            threadID={docThreadID}
                                            fromLanding={fromLanding}
                                            setShowHistory={setShowHistory}
                                            showHistory={showHistory}
                                            setDocMessages={setDocMessages}
                                            setDocThreadID={setDocThreadID}
                                            setNewfiThreadID={setNewfiThreadID}
                                            setChatThreadID={setChatThreadID}
                                            setCurrentMessageID={
                                                setCurrentMessageID
                                            }
                                            greetingMessage={greetingMessage}
                                        />
                                        <MessageList
                                            loadingChat={loadingChat}
                                            messages={docMessages}
                                            processMoreDetails={
                                                processMoreDetails
                                            }
                                            threadID={docThreadID}
                                            isBotThinking={isBotThinking}
                                            isBotAnswering={isBotAnswering}
                                            currentMessageID={currentMessageID}
                                            currentMessageText={
                                                currentMessageText
                                            }
                                        />
                                        <ChatInput
                                            onSendMessage={handleSendDocMessage}
                                            isBotThinking={isBotThinking}
                                            isReceivingAnswer={
                                                isReceivingAnswer
                                            }
                                            docName={currentDocName}
                                            fromLanding={fromLanding}
                                            questionCount={questionCount}
                                            threadID={docThreadID}
                                        />
                                    </div>
                                )}
                            </Col>
                            {displayHistory && (
                                <Col md={2} className="px-0">
                                    <ChatHistory
                                        ref={chatHistoryRef}
                                        getHistoryList={getHistoryList}
                                        getList={getList}
                                        showHistory={showHistory}
                                        setShowHistory={setShowHistory}
                                        setDocMessages={setDocMessages}
                                        setChatTitle={setChatTitle}
                                        threadID={docThreadID}
                                        setDocThreadID={setDocThreadID}
                                        setNewfiThreadID={setNewfiThreadID}
                                        setChatThreadID={setChatThreadID}
                                        setCurrentMessageID={
                                            setCurrentMessageID
                                        }
                                        greetingMessage={greetingMessage}
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="chat">
            <LimitReachedModal
                isOpen={showLimitModal}
                onClose={() => setShowLimitModal(false)}
            />
            <div style={{ width: '100%' }}>
                <div className="d-flex justify-content-center">
                    <ChatApp className="width70">
                        <MessageList
                            messages={messages}
                            processMoreDetails={processMoreDetails}
                            threadID={threadID}
                            isBotThinking={isBotThinking}
                            isBotAnswering={isBotAnswering}
                            currentMessageID={currentMessageID}
                            currentMessageText={currentMessageText}
                            isMobile={isMobile}
                        />
                        <ChatInput
                            onSendMessage={handleSendMessage}
                            style={{ marginBottom: 10 }}
                            isBotThinking={isBotThinking}
                            isReceivingAnswer={isReceivingAnswer}
                            docName={currentDocName}
                            fromLanding={fromLanding}
                            questionCount={questionCount}
                            threadID={threadID}
                        />
                    </ChatApp>
                </div>
            </div>
        </div>
    )
}

function ChatApp({ children, className }) {
    return <div className={`chat-app ${className}`}>{children}</div>
}

export default withRouter(Chat)
