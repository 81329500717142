import { UPDATE_GUIDELINE_GPT_USAGE, UPDATE_MISMO_USAGE } from "../Defs/subscriptiondefs";

const initState = {
    currentInfo: {},

}
export default function subscriptionReducer(state = initState, action) {
    const { type, data } = action
    switch (type) {
        case 'SUBSCRIPTION_INFO': {
            return {

                ...state,
                currentInfo: data,
            }

        }
        case UPDATE_GUIDELINE_GPT_USAGE: {
            return {
                ...state,
                currentInfo: {
                    ...state.currentInfo,
                    usage: {
                        ...state.currentInfo.usage,
                        guidelineGptUsage: action.payload,
                    }
                }
            }
        }

        case UPDATE_MISMO_USAGE: {
            return {
                ...state,
                currentInfo: {
                    ...state.currentInfo,
                    usage: {
                        ...state.currentInfo.usage,
                        mismoUsage: action.payload,
                    }
                }
            }
        }
        



        default:
         break
    }
    return state
}