/*
 * This file contains common data for GMCC Calculator.
 * Explanation of the program types:
 * - These forllowing 3 programs have MSA and county restrictions (the requirements are in the MSAtoCountyList_*):
 *   - Conforming: might also be called "Special Conforming".
 *   - Nonagency: might also be called "Jumbo CRA".
 *   - Grandslam: might also be called "GMCC Grandslam".
 * - These programs have no MSA or county restrictions (but might have other restrictions):
 *   - Diamond: might also be called "GMCC Diamond Community Lending", this program needs to be checked with
 *     - loan limit requirements
 *     - city requirements
 *   - Grants: this includes free 5k, free 10k, free 15k or free 30k depending on the program, it might also be called
 *     with the word "celebrity" in it, for example "Celebrity 15K". This program needs to be checked with
 *     - state requirements
 *     - income requirements
 *     - minority requirements
 *     - etc.
 */

/**
 * Enum for program types.
 */
export enum EProgramType {
  GRANDSLAM = "grandslam",
  CONFORMING = "conforming",
  NONAGENCY = "nonagency",
  DIAMOND = "diamond",
  CELEBRITY = "celebrity",
}

/**
 * This is a map of MSA (Metropolitan Statistical Area, 5 digit code) to name
 */
export const MSAtoNameMap: Record<string, string> = {
  "11244": "Anaheim-Santa Ana-Irvine", // CA
  "12420": "Austin-Round Rock-Georgetown",
  "12540": "Bakersfield", // CA
  "14860": "Bridgeport-Stamford-Norwalk", // CT
  "16984": "Chicago-Naperville-Evanston", // IL
  "19124": "Dallas-Plano-Irving",
  "19740": "Denver-Aurora-Lakewood",
  "20994": "Elgin", // IL
  "22744": "Ft.Lauderdale-Pompano Beach-Sunrise", // FL
  "23224": "Frederick-Gaithersburg-Rockville", // MD
  "23420": "Fresno", // CA
  "25260": "Hanford-Corcoran", // CA
  "27260": "Jacksonville", // FL
  "29404": "Lake-Kenosha IL-WI", // IL
  "29820": "Las Vegas-Henderson-Paradise", // NV
  "31084": "Los Angeles-Long Beach-Glendale", // CA
  "32900": "Merced", // CA
  "33124": "Miami-Miami Beach-Kendall", // FL
  "33700": "Modesto", // CA
  "35004": "Nassau-Suffolk", // NY
  "35084": "Newark NJ-PA", // NJ
  "35614": "New York-Jersey City-White Plains", // NY
  "36084": "Oakland-Berkeley-Livermore", // CA
  "37100": "Oxnard-Thousand Oaks-Ventura", // CA
  "40140": "Riverside-San Bernardino-Ontario", // CA
  "40900": "Sacramento-Roseville-Ardem-Arcade", // CA
  "41740": "San Diego-Chula Vista-Carlsbad", // CA
  "41884": "San Francisco-San Mateo-Redwook City", // CA
  "41940": "San Jose-Sunnyvale-Santa Clara", // CA
  "42034": "San Rafael", // CA
  "42100": "Santa Cruz-Watsonville", // CA
  "43620": "Sioux Falls SD", // SD
  "44700": "Stockton", // CA
  "47894": "Washington-Arlington-Alexandria, DC-MD-VA-WV", // VA, DC, MD, WV
  "48424": "West Palm Beach-Boca Raton-Boynton Beach", // FL
};

/**
 * This is a map of program type to list of MSAs
 * Each MSA has a value and label. The label is the MSA name with the state(s) and MSA code.
 */
export const MSATypetoNameMap = {
  conforming: [
    { value: "31084", label: "(CA 31084) " + MSAtoNameMap["31084"] },
    { value: "41940", label: "(CA 41940) " + MSAtoNameMap["41940"] },
    { value: "41884", label: "(CA 41884) " + MSAtoNameMap["41884"] },
    { value: "36084", label: "(CA 36084) " + MSAtoNameMap["36084"] },
    { value: "19124", label: "(TX 19124) " + MSAtoNameMap["19124"] },
    { value: "12420", label: "(TX 12420) " + MSAtoNameMap["12420"] },
    { value: "35004", label: "(NY 35004) " + MSAtoNameMap["35004"] },
    { value: "33124", label: "(FL 33124) " + MSAtoNameMap["33124"] },
    { value: "35084", label: "(NJ 35084) " + MSAtoNameMap["35084"] },
    { value: "19740", label: "(CO 19740) " + MSAtoNameMap["19740"] },
    { value: "47894", label: "(DC,MD,VA,WV 47894) " + MSAtoNameMap["47894"] },
  ],
  nonagency: [
    { value: "11244", label: "(CA 11244) " + MSAtoNameMap["11244"] },
    { value: "14860", label: "(CT 14860) " + MSAtoNameMap["14860"] },
    { value: "22744", label: "(FL 22744) " + MSAtoNameMap["22744"] },
    { value: "23224", label: "(MD 23224) " + MSAtoNameMap["23224"] },
    { value: "31084", label: "(CA 31084) " + MSAtoNameMap["31084"] },
    { value: "33124", label: "(FL 33124) " + MSAtoNameMap["33124"] },
    { value: "35004", label: "(NY 35004) " + MSAtoNameMap["35004"] },
    { value: "35084", label: "(NJ 35084) " + MSAtoNameMap["35084"] },
    { value: "35614", label: "(NY,NJ 35614) " + MSAtoNameMap["35614"] },
    { value: "36084", label: "(CA 36084) " + MSAtoNameMap["36084"] },
    { value: "37100", label: "(CA 37100) " + MSAtoNameMap["37100"] },
    { value: "41884", label: "(CA 41884) " + MSAtoNameMap["41884"] },
    { value: "41940", label: "(CA 41940) " + MSAtoNameMap["41940"] },
    { value: "47894", label: "(DC,VA 47894) " + MSAtoNameMap["47894"] },
    { value: "48424", label: "(FL 48424) " + MSAtoNameMap["48424"] },
  ],
  grandslam: [
    { value: "11244", label: "(CA 11244) " + MSAtoNameMap["11244"] },
    { value: "12540", label: "(CA 12540) " + MSAtoNameMap["12540"] },
    { value: "14860", label: "(CT 14860) " + MSAtoNameMap["14860"] },
    { value: "16984", label: "(IL 16984) " + MSAtoNameMap["16984"] },
    { value: "20994", label: "(IL 20994) " + MSAtoNameMap["20994"] },
    { value: "22744", label: "(FL 22744) " + MSAtoNameMap["22744"] },
    { value: "23224", label: "(MD 23224) " + MSAtoNameMap["23224"] },
    { value: "23420", label: "(CA 23420) " + MSAtoNameMap["23420"] },
    { value: "25260", label: "(CA 25260) " + MSAtoNameMap["25260"] },
    { value: "27260", label: "(FL 27260) " + MSAtoNameMap["27260"] },
    { value: "29404", label: "(IL,WI 29404) " + MSAtoNameMap["29404"] },
    { value: "29820", label: "(NV 29820) " + MSAtoNameMap["29820"] },
    { value: "31084", label: "(CA 31084) " + MSAtoNameMap["31084"] },
    { value: "33124", label: "(FL 33124) " + MSAtoNameMap["33124"] },
    { value: "33700", label: "(CA 33700) " + MSAtoNameMap["33700"] },
    { value: "35004", label: "(NY 35004) " + MSAtoNameMap["35004"] },
    { value: "35084", label: "(NJ 35084) " + MSAtoNameMap["35084"] },
    { value: "35614", label: "(NJ,NY 35614) " + MSAtoNameMap["35614"] },
    { value: "36084", label: "(CA 36084) " + MSAtoNameMap["36084"] },
    { value: "37100", label: "(CA 37100) " + MSAtoNameMap["37100"] },
    { value: "40140", label: "(CA 40140) " + MSAtoNameMap["40140"] },
    { value: "40900", label: "(CA 40900) " + MSAtoNameMap["40900"] },
    { value: "41740", label: "(CA 41740) " + MSAtoNameMap["41740"] },
    { value: "41884", label: "(CA 41884) " + MSAtoNameMap["41884"] },
    { value: "41940", label: "(CA 41940) " + MSAtoNameMap["41940"] },
    { value: "42034", label: "(CA 42034) " + MSAtoNameMap["42034"] },
    { value: "43620", label: "(SD 43620) " + MSAtoNameMap["43620"] },
    { value: "47894", label: "(DC,MD,VA,WV 47894) " + MSAtoNameMap["47894"] },
    { value: "48424", label: "(FL 48424) " + MSAtoNameMap["48424"] },
  ],
};

export const DiamondCommunityLendingStates = [
  "California",
  "Colorado",
  "Florida",
  "Georgia",
  "Hawaii",
  "Illinois",
  "Maryland",
  "Massachusetts",
  "Nevada",
  "New Jersey",
  "New York",
  "North Carolina",
  "South Carolina",
  "Texas",
  "Virginia",
  "Washington",
];

/**
 * This is a map of state to list of MSAs for conforming program.
 * Each MSA can have one or more counties.
 */
export const MSAtoCountyList_conforming: Record<
  string,
  Record<string, string[]>
> = {
  CA: {
    "31084": ["Los Angeles County"],
    "41940": ["Santa Clara County", "San Benito County"],
    "41884": ["San Francisco County", "San Mateo County"],
    "36084": ["Alameda County", "Contra Costa County"],
  },
  TX: {
    "19124": [
      "Collin County",
      "Dallas County",
      "Denton County",
      "Ellis County",
      "Hunt County",
      "Kaufman County",
      "Rockwall County",
    ],
    "12420": [
      "Bastrop County",
      "Caldwell County",
      "Hays County",
      "Williamson County",
      "Travis County",
    ],
  },
  NY: {
    "35004": ["Nassau County", "Suffolk County"],
  },
  FL: {
    "33124": ["Miami-Dade County"],
  },
  NJ: {
    "35084": [
      "Essex County",
      "Hunterdon County",
      "Morris County",
      "Sussex County",
      "Union County",
    ],
  },
  CO: {
    "19740": [
      "Adams County",
      "Arapahoe County",
      "Broomfield County",
      "Clear Creek County",
      "Denver County",
      "Douglas County",
      "Elbert County",
      "Gilpin County",
      "Jefferson County",
      "Park County",
    ],
  },
  VA: {
    "47894": [
      "Arlington County",
      "Clarke County",
      "Culpepper County",
      "District of Columbia",
      "Fairfax County",
      "Fauquier County",
      "Loudoun County",
      "Prince William County",
      "Rappahannock County",
      "Spotsylvania County",
      "Stafford County",
      "Warren County",
    ],
  },
  MD: {
    "47894": ["Calvert County", "Charles County", "Prince George County"],
  },
  WV: {
    "47894": ["Jefferson County"],
  },
  DC: {
    "47894": ["District of Columbia"],
  },
  PA: {
    "35084": ["Pike County"],
  },
};

/**
 * This is a map of state to list of MSAs for nonagency program. Extra extra incentive only.
 * Each MSA can have one or more counties.
 */
export const MSAtoCountyList_nonagency: Record<
  string,
  Record<string, string[]>
> = {
  CA: {
    "31084": ["Los Angeles County"],
    "41940": ["Santa Clara County"],
    "41884": ["San Francisco County", "San Mateo County"],
    "11244": ["Orange County"],
    "36084": ["Alameda County", "Contra Costa County"],
    "37100": ["Ventura County"],
  },
  CT: {
    "14860": ["Fairfield County"],
  },
  DC: {
    "47894": ["District of Columbia"],
  },
  FL: {
    "33124": ["Miami-Dade County"],
    "22744": ["Broward County"],
    "48424": ["Palm Beach County"],
  },
  MD: {
    "23224": ["Montgomery County"],
  },
  NY: {
    "35004": ["Nassau County", "Suffolk County"],
    "35614": [
      "Bronx County",
      "Kings County",
      "Queens County",
      "New York County",
      "Richmond County",
      "Rockland County",
      "Westchester County",
    ],
  },
  NJ: {
    "35084": ["Essex County"],
    "35614": ["Hudson County", "Bergen County", "Passaic County"],
  },
  VA: {
    "47894": ["Fairfax County", "Arlington County", "Prince George County"],
  },
};

/**
 * This is a map of state to list of MSAs for nonagency program. Full list including extra extra incentive and non-extra extra incentive.
 * Each MSA can have one or more counties.
 */
export const MSAtoCountyList_nonagency_full: Record<
  string,
  Record<string, string[]>
> = {
  CA: {
    // Extra extra incentive
    "11244": ["Orange County"],
    "31084": ["Los Angeles County"],
    "36084": ["Alameda County", "Contra Costa County"],
    "37100": ["Ventura County"],
    "41884": ["San Francisco County", "San Mateo County"],
    "41940": ["Santa Clara County"],
    // Non-extra extra incentive
    "12540": ["Kern County"],
    "23420": ["Fresno County"],
    "25260": ["Kings County"],
    "42034": ["Marin County"],
    "32900": ["Merced County"],
    "33700": ["Stanislaus County"],
    "40900": ["Sacramento County"],
    "40140": ["Riverside County", "San Bernardino County"],
    "41740": ["San Diego County"],
    "42100": ["Santa Cruz County"],
    "44700": ["San Joaquin County"],
  },
  CT: {
    "14860": ["Fairfield County"],
  },
  DC: {
    "47894": ["District of Columbia"],
  },
  FL: {
    "33124": ["Miami-Dade County"],
    "22744": ["Broward County"],
    "48424": ["Palm Beach County"],
    // Non-extra extra incentive
    "27260": ["Duval County"],
  },
  IL: {
    // Non-extra extra incentive
    "16984": ["Cook County", "DuPage County", "McHenry County", "Will County"],
    "20994": ["Kane County"],
    "29404": ["Lake County"],
  },
  MD: {
    "23224": ["Montgomery County"],
    // Non-extra extra incentive
    "47894": ["Prince George County"],
  },
  NY: {
    "35004": ["Nassau County", "Suffolk County"],
    "35614": [
      "Bronx County",
      "Kings County",
      "Queens County",
      "New York County",
      "Richmond County",
      "Rockland County",
      "Westchester County",
    ],
  },
  NJ: {
    "35084": ["Essex County"],
    "35614": ["Hudson County", "Bergen County", "Passaic County"],
  },
  NV: {
    // non-extra extra incentive
    "29820": ["Clark County"],
  },
  // Non-extra extra incentive
  SD: {
    "43620": [
      "Lincoln County",
      "McCook County",
      "Minnehaha County",
      "Turner County",
    ],
  },
  VA: {
    "47894": [
      "Fairfax County",
      "Arlington County",
      "Prince George County",
      // "Alexandria City", "Fairfax City", "Falls Church City"
    ],
  },
};

/**
 * This is a map of state to list of MSAs for grandslam program. Only extra extra incentive.
 * Each MSA can have one or more counties.
 */
export const MSAtoCountyList_grandslam: Record<
  string,
  Record<string, string[]>
> = {
  CA: {
    "11244": ["Orange County"],
    "12540": ["Kern County"],
    "23420": ["Fresno County"],
    "25260": ["Kings County"],
    "31084": ["Los Angeles County"],
    "36084": ["Alameda County", "Contra Costa County"],
    "33700": ["Stanislaus County"],
    "37100": ["Ventura County"],
    "40140": ["Riverside County", "San Bernardino County"],
    "40900": ["Sacramento County"],
    "41740": ["San Diego County"],
    "41884": ["San Francisco County", "San Mateo County"],
    "41940": ["Santa Clara County"],
    "42034": ["Marin County"],
  },
  CT: {
    "14860": ["Fairfield County"],
  },
  DC: {
    "47894": ["District of Columbia"],
  },
  FL: {
    "22744": ["Broward County"],
    "27260": ["Duval County"],
    "33124": ["Miami-Dade County"],
    "48424": ["Palm Beach County"],
  },
  NJ: {
    "35084": ["Essex County"],
    "35614": ["Bergen County", "Hudson County", "Passaic County"],
  },
  IL: {
    "16984": ["Cook County", "DuPage County", "McHenry County", "Will County"],
    "20994": ["Kane County"],
    "29404": ["Lake County"],
  },
  MD: {
    "23224": ["Montgomery County"],
  },
  NY: {
    "35614": [
      "Bronx County",
      "Kings County",
      "Queens County",
      "New York County",
      "Richmond County",
      "Rockland County",
      "Westchester County",
    ],
    "35004": ["Nassau County", "Suffolk County"],
  },
  SD: {
    "43620": ["Lincoln County", "Minnehaha County"],
  },
  VA: {
    "47894": ["Arlington County", "Fairfax County", "Prince George County"],
  },
};

/**
 * This is a map of state to list of MSAs for grandslam program.
 * Full list including extra extra incentive and non-extra extra incentive.
 * Each MSA can have one or more counties.
 */
export const MSAtoCountyList_grandslam_full: Record<
  string,
  Record<string, string[]>
> = {
  CA: {
    "11244": ["Orange County"],
    "12540": ["Kern County"],
    "23420": ["Fresno County"],
    "25260": ["Kings County"],
    "31084": ["Los Angeles County"],
    "32900": ["Merced County"],
    "33700": ["Stanislaus County"],
    "36084": ["Alameda County", "Contra Costa County"],
    "37100": ["Ventura County"],
    "40140": ["Riverside County", "San Bernardino County"],
    "40900": ["Sacramento County"],
    "41740": ["San Diego County"],
    "41884": ["San Francisco County", "San Mateo County"],
    "41940": ["Santa Clara County"],
    "42034": ["Marin County"],
    "42100": ["Santa Cruz County"],
    "44700": ["San Joaquin County"],
  },
  CT: {
    "14860": ["Fairfield County"],
  },
  DC: {
    "47894": ["District of Columbia"],
  },
  FL: {
    "22744": ["Broward County"],
    "27260": ["Duval County"],
    "33124": ["Miami-Dade County"],
    "48424": ["Palm Beach County"],
  },

  IL: {
    "16984": ["Cook County", "DuPage County", "McHenry County", "Will County"],
    "20994": ["Kane County"],
    "29404": ["Lake County"],
  },
  MD: {
    "23224": ["Montgomery County"],
    // Non-extra extra incentive
    "47894": ["Prince George County"],
  },
  NJ: {
    "35084": ["Essex County"],
    "35614": ["Bergen County", "Hudson County", "Passaic County"],
  },
  NV: {
    // Non-extra extra incentive
    "29820": ["Clark County"],
  },
  NY: {
    "35614": [
      "Bronx County",
      "Kings County",
      "Queens County",
      "New York County",
      "Richmond County",
      "Rockland County",
      "Westchester County",
    ],
    "35004": ["Nassau County", "Suffolk County"],
  },
  SD: {
    "43620": ["Lincoln County", "Minnehaha County"],
  },
  VA: {
    "47894": ["Arlington County", "Fairfax County", "Prince George County"],
  },
};

/**
 * This is a map of state to list of cities for GMCC calculator.
 *
 * Map from state -> county -> list of cities.
 */
export const GMCCWishList: Record<string, Record<string, string[]>> = {
  AZ: {
    "Maricopa County": ["Phoenix"],
  },
  CA: {
    "Alameda County": ["Livermore", "Berkeley", "Oakland"],
    "Contra Costa County": ["Martinez"],
    "El Dorado County": ["El Dorado"],
    "Fresno County": ["Fresno", "Clovis"],
    "Kern County": ["Bakersfield"],
    "Kings County": ["Hanford", "Corcoran"],
    "Los Angeles County": [
      "Rowland Heights",
      "San Gabriel",
      "Diamond Bar",
      "El Monte",
      "Pomona",
      "Compton",
      "La Puente",
      "Pasadena",
    ],
    "Marin County": ["San Rafael"],
    "Orange County": ["Santa Ana", "Anaheim", "Irvine"],
    "Placer County": ["Roseville"],
    "Riverside County": ["Moreno Valley", "Riverside", "Palm Springs"],
    "Sacramento County": [
      "Elk Grove",
      "Sacramento",
      "Roseville",
      "Antelope",
      "Carmichael",
    ],
    "Santa Clara County": [
      "San Jose",
      "Santa Clara",
      "Cupertino",
      "Milpitas",
      "Mountain View",
      "Gilroy",
    ],
    "San Benito County": ["Hollister"],
    "San Bernardino County": [
      "Chino Hills",
      "Upland",
      "Rancho Cucamonga",
      "San Bernardino",
    ],
    "San Diego County": [
      "San Diego",
      "Carlsbad",
      "San marcos",
      "Escondido",
      "Poway",
    ],
    "San Francisco County": ["San Francisco"],
    "San Mateo County": ["San Mateo", "Burlingame", "Redwood City"],
    "Stanislaus County": ["Modesto"],
    "Ventura County": ["Oxnard"],
  },
  CO: {
    "Adams County": ["Thornton"],
    "Arapahoe County": ["Centennial"],
    "Broomfield County": ["Broomfield"],
    "Clear Creek County": ["Idaho Springs"],
    "Denver County": ["Denver"],
    "Douglas County": ["Highlands Ranch", "Castle Rock"],
    "Elbert County": ["Ponderosa Park"],
    "Gilpin County": ["Rollinsville"],
    "Jefferson County": ["Lakewood"],
    "Park County": ["Bailey"],
  },
  CT: {
    "Fairfield County": ["Bridgeport", "Stamford", "Norwalk"],
  },
  DC: {
    "District of Columbia": ["Washington DC"],
  },
  LA: {
    Orleans: ["New Orleans"],
  },

  FL: {
    "Miami-Dade County": ["Miami"],
    "Palm Beach County": ["Boynton Beach"],
    "Broward County": ["Sunrise", "Pembroke Pines"],
    "Hillsborough County": ["Tampa"],
    "Duval County": ["Jacksonville"],
  },
  GA: {
    "Fulton County": ["Atlanta"],
    "Chatham County": ["Savannah"],
    "Gwinnett County": ["Duluth", "Norcross"],
  },
  HI: {
    "Honolulu County": ["Honolulu"],
  },
  IL: {
    "Cook County": ["Chicago", "Oak Park"],
    "DuPage County": ["Naperville", "Aurora"],
    "Kane County": ["Gilberts", "Elburn"],
    "Will County": ["Beecher", "Manhattan"],
    "McHenry County": ["Richmond", "Mchenry"],
    "Lake County": ["Waukegan"],
  },
  MA: {
    "Norfolk County": ["Randolph"],
    "Suffolk County": ["Revere", "Dorchester", "Chelsea", "Boston"],
    "Essex County": ["Lawrence", "Lynn"],
    "Middlesex County": ["Burlington", "Ashland"],
  },
  MD: {
    "Frederick County": ["Frederick"],
    "Charles County": ["Hughesville"],
    "Calvert County": ["Solomons"],
    "Montgomery County": ["Clarksburg"],
    "Prince George County": ["Prince George"],
  },
  MI: {
    "Wayne County": ["Detroit"],
  },
  NC: {
    "Mecklenburg County": ["Charlotte"],
    "Wake County": ["Raleigh"],
    "Guilford County": ["Greensboro"],
  },
  NJ: {
    "Essex County": ["Newark"],
    "Hudson County": ["Jersey City", "Union City"],
    "Middlesex County": ["Edison", "New Brunswick"],
    "Hunterdon County": ["Clinton"],
    "Morris County": ["Denville"],
    "Sussex County": ["Sussex"],
    "Union County": ["Cranford"],
  },
  NV: {
    "Clark County": ["Las Vegas"],
  },
  NY: {
    "Bronx County": ["Bronx"],
    "Nassau County": [
      "Freeport",
      "Syosset",
      "Plainview",
      "Hicksville",
      "Levittown",
      "Garden City",
      "Great Neck",
      "Hempstead",
    ],
    "Kings County": ["Brooklyn", "Crown Heights", "Flatbush"],
    "Queens County": ["Flushing", "Corona", "Bayside"],
    "New York County": ["Manhattan"],
    "Richmond County": ["Staten Island", "Sunnyside"],
    "Suffolk County": ["Riverhead", "Long Island"],
    "Rockland County": ["Spring Valley"],
    "Westchester County": ["Yonkers"],
    "Bergen County": ["Hackensack"],
    "Hudson County": ["Jersey City", "Union City", "Bayonne"],
    "Passaic County": ["Paterson"],
  },
  OR: {
    "Multnomah County": ["Portland"],
  },
  SC: {
    "Charleston County": ["Charleston"],
  },
  SD: {
    "Lincoln County": ["Sioux Falls"],
    "Minnehaha County": ["Sioux Falls"],
  },
  TX: {
    "Bastrop County": ["Bastrop"],
    "Bexar County": ["San Antonio"],
    "Caldwell County": ["Lockhart"],
    "Collin County": ["Anna", "Plano"],
    "Dallas County": ["Dallas"],
    "Denton County": ["Sanger"],
    "Ellis County": ["Grand Prairie", "Mansfield", "Waxahachie"],
    "Fort Bend County": ["Sugar Land"],
    "Hays County": ["Kyle"],
    "Harris County": ["Houston"],
    "Hunt County": ["Campbell"],
    "Kaufman County": ["Forney"],
    "Rockwall County": ["Rockwall"],
    "Travis County": ["Austin"],
    "Williamson County": ["Taylor"],
  },
  UT: {
    "Summit County": ["Park City"],
  },
  VA: {
    "District of Columbia": ["Arlington"],
    "Arlington County": ["Arlington"],
    "Clarke County": ["Boyce"],
    "Culpepper County": ["Culpepper"],
    "Fairfax County": ["Arlington"],
    "Fauquier County": ["Warrenton"],
    "Loudoun County": ["Leesburg"],
    "Prince George County": ["Prince George"],
    "Prince William County": ["Dale City"],
    "Rappahannock County": ["Sperryville"],
    "Spotsylvania County": ["Spotsylvania Courthouse"],
    "Stafford County": ["Stafford"],
    "Warren County": ["Front Royal"],
  },
  WA: {
    "King County": ["Seattle", "Bellevue"],
  },
  WV: {
    "Jefferson County": ["Ranson"],
  },
};

// Qualified states for each program
export const fiveK_states = [];
export const tenK_states = ["South Carolina", "North Carolina", "Georgia"];
export const fifteenK_states = ["Massachusetts","California"];
export const zeroDown_states = [
  "California",
  "Massachusetts",
  "North Carolina",
  "South Carolina",
  "Virginia",
  "Georgia",
];
export const affluent_states = [
  "California",
  "Massachusetts",
  "North Carolina",
  "South Carolina",
  "Virginia",
];
export const universe_states = [
  "California",
  "Massachusetts",
  "Washington",
  "Texas",
  "Georgia",
  "Nevada",
];
export const buyWithoutSell_states = "Nationwide";
export const communityBenefits_states = "Nationwide";
export const radiant_states = [
  "California",
  "Hawaii",
  "Illinois",
  "New Jersey",
  "Nevada",
  "New York",
];
export const bankstatement_states_VVOE = [
  "California",
  "Texas",
  "Georgia",
  "Illinois",
  "Virginia",
  "Washington",
  "New Jersey",
  "Colorado",
];
export const hermes_states = [
  "California",
  "Massachusetts",
  "Georgia",
  "Illinois",
  "Texas",
  "Washington",
  "New Jersey",
  "Nevada",
  "Colorado",
  "Virginia",
  "New York",
];
export const ocean_states = [
  "California",
  "Texas",
  "Nevada",
];

/**
 * This is a map of state to list of counties for $5K grants.
 *
 * Including NC, SC, and GA.
 */
export const fiveK_qualifiedCounties = {};

/**
 * This is a map of state to list of counties for $30K/$15K grants.
 *
 * This variable is named thirtyK_qualifiedCounties because
 * the program used to be called $30K grants, now it's called $15K grants.
 */
export const thirtyK_qualifiedCounties = {
  Massachusetts: ["Essex", "Middlesex", "Norfolk", "Suffolk"],
  California: [
    "Contra Costa",
    "Los Angeles",
    "Napa",
    "Orange",
    "Placer",
    "Riverside",
    "Sacramento",
    "San Bernardino",
    "San Diego",
    "San Francisco",
    "San Mateo",
    "Santa Clara",
    "Ventura",
  ],
};

/**
 * This is a map of state to list of counties for $10K grants.
 *
 * This variable is named tenK_qualifiedCounties because
 * the program used to be called $10K grants, now it's called $15K grants.
 */
export const tenK_qualifiedCounties = {
  "North Carolina": [
    "Alexander",
    "Ashe",
    "Avery",
    "Beaufort",
    "Bladen",
    "Brunswick",
    "Buncombe",
    "Burke",
    "Cabarrus",
    "Caldwell",
    "Carteret",
    "Catawba",
    "Chatham",
    "Cherokee",
    "Clay",
    "Columbus",
    "Craven",
    "Cumberland",
    "Duplin",
    "Durham",
    "Forsyth",
    "Franklin",
    "Graham",
    "Greene",
    "Guilford",
    "Halifax",
    "Harnett",
    "Haywood",
    "Henderson",
    "Iredell",
    "Jackson",
    "Johnston",
    "Lee",
    "Lenoir",
    "Lincoln",
    "Macon",
    "Madison",
    "Martin",
    "McDowell",
    "Mecklenburg",
    "Moore",
    "Nash",
    "New Hanover",
    "Onslow",
    "Orange",
    "Pamlico",
    "Pasquotank",
    "Pender",
    "Pitt",
    "Polk",
    "Randolph",
    "Rockingham",
    "Rutherford",
    "Sampson",
    "Stanly",
    "Stokes",
    "Surry",
    "Swain",
    "Transylvania",
    "Union",
    "Vance",
    "Wake",
    "Warren",
    "Watauga",
    "Wayne",
    "Wilkes",
    "Wilson",
    "Yadkin",
    "Yancey",
  ],
  "South Carolina": [
    "Abbeville",
    "Aiken",
    "Anderson",
    "Barnwell",
    "Beaufort",
    "Berkeley",
    "Charleston",
    "Chester",
    "Chesterfield",
    "Clarendon",
    "Darlington",
    "Dillon",
    "Dorchester",
    "Edgefield",
    "Fairfield",
    "Florence",
    "Georgetown",
    "Greenville",
    "Greenwood",
    "Hampton",
    "Horry",
    "Kershaw",
    "Lancaster",
    "Laurens",
    "Lee",
    "Lexington",
    "Marion",
    "Marlboro",
    "McCormick",
    "Newberry",
    "Oconee",
    "Orangeburg",
    "Pickens",
    "Richland",
    "Saluda",
    "Spartanburg",
    "Sumter",
    "Williamsburg",
    "York",
  ],
  Georgia: [
    "Chatham",
    "Cherokee",
    "Cobb",
    "Columbia",
    "De Kalb",
    "Forsyth",
    "Franklin",
    "Fulton",
    "Gwinnett",
    "Hall",
    "Hart",
    "Madison",
    "McDuffie",
    "Pickens",
    "Richmond",
    "Stephens",
  ],
};
export const zeroDown_qualifiedCounties = {
  California: [
    "San Francisco",
    "San Mateo",
    "Santa Clara",
    "Sacramento",
    "Contra Costa",
    "Los Angeles",
    "Orange",
    "Riverside",
    "San Bernardino",
    "San Diego",
    "Placer",
    "Napa",
    "Ventura",
  ],
  // "Georgia": ["Barrow", "Bartow", "Cherokee", "Clayton", "Cobb", "DeKalb", "Douglas", "Fayette", "Forsyth", "Fulton", "Gwinnett", "Henry", "Paulding", "Rockdale", "Spalding"],
  Massachusetts: ["Essex", "Middlesex", "Norfolk", "Suffolk"],
  "North Carolina": [],
  "South Carolina": [],
  Georgia: [],
  Virginia: [
    "Albermarle",
    "Alleghany",
    "Arlington",
    "Augusta",
    "Bedford",
    "Botetourt",
    "Brunswick",
    "Campbell",
    "Carroll",
    "Charlottesville City",
    "Chesterfield",
    "Covington City",
    "Danville City",
    "Emporia City",
    "Fairfax",
    "Fairfax City",
    "Frederick",
    "Fredericksburg City",
    "Galax City",
    "Greensville",
    "Harrisonburg City",
    "Henrico",
    "Loudoun",
    "Lunenburg",
    "Lynchburg City",
    "Mecklenburg",
    "Montgomery",
    "Nottoway",
    "Pittsylvania",
    "Roanoke",
    "Roanoke City",
    "Rockingham",
    "Salem City",
    "Spotsylvania",
    "Staunton City",
    "Warren",
    "Waynesboro City",
    "Winchester City",
  ],
};
export const outreach_qualifiedCounties = {
  California: [
    "San Franciso",
    "Alameda",
    "San Mateo",
    "Santa Clara",
    "Sacramento",
    "Constra Costa",
    "Los Angeles",
    "Orange",
    "Riverside",
    "San Bernardino",
    "San Diego",
  ],
  Massachusetts: ["Middlesex", "Norfolk", "Suffolk"],
  Texas: ["Collin", "Dallas", "Fort Bend", "Harris"],
  Georgia: [
    "Barrow",
    "Bartow",
    "Cherokee",
    "Clayton",
    "Cobb",
    "DeKalb",
    "Douglas",
    "Fayette",
    "Forsyth",
    "Fulton",
    "Gwinnett",
    "Henry",
    "Paulding",
    "Rockdale",
    "Spalding",
  ],
  Nevada: ["Clark"],
  Washington: ["King", "Snohomish", "Pierce"],
  "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond"],
};

export const cronus_qualifiedCounties = {
  California: [
    "Alameda",
    "Contra Costa",
    "Fresno",
    "Los Angeles",
    "Kern",
    "Kings",
    "Marin",
    "San Francisco",
    "San Mateo",
    "Merced",
    "Orange",
    "Sacramento",
    "Riverside",
    "San Bernardino",
    "San Diego",
    "Santa Clara",
    "Santa Cruz",
    "San Joaquin",
    "Stanislaus",
    "Ventura",
  ],
  Connecticut: ["Fairfield"],
  Florida: ["Broward", "Miami-Dade", "Palm Beach", "Duval"],
  Illinois: ["Cook", "DuPage", "Kane", "Lake", "McHenry", "Will"],
  Maryland: ["Montgomery", "Prince George's"],
  Nevada: ["Clark"],
  "New Jersey": ["Bergen", "Hudson", "Passaic", "Essex"],
  // "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond", "Rockland", "Westchester", "Nassau", "Suffolk"],
  "South Dakota": ["Lincoln", "Turner", "McCook", "Minnehaha"],
  Virginia: [
    "Arllington",
    "Alexandria",
    "Fairfax",
    "Fairfax City",
    "Falls Church",
  ],
  Washington: ["Columbia"],
  "New York": [
    "Bronx",
    "Kings",
    "New York",
    "Queens",
    "Richmond",
    "Rockland",
    "Westchester",
    "Nassau",
    "Suffolk",
  ],
};
export const universe_qualifiedCounties = {
  California: [
    "San Francisco",
    "Alameda",
    "San Mateo",
    "Santa Clara",
    "Sacramento",
    "Contra Costa",
    "Los Angeles",
    "Orange",
    "Riverside",
    "San Bernardino",
    "San Diego",
  ],
  Georgia: [
    "Barrow",
    "Bartow",
    "Cherokee",
    "Clayton",
    "Cobb",
    "DeKalb",
    "Douglas",
    "Fayette",
    "Forsyth",
    "Fulton",
    "Gwinnett",
    "Henry",
    "Paulding",
    "Rockdale",
    "Spalding",
  ],
  Massachusetts: ["Middlesex", "Norfolk", "Suffolk"],
  Nevada: ["Clark"],
  Texas: ["Collin", "Dallas", "Fort Bend", "Harris"],
  Washington: ["King", "Pierce", "Snohomish"],
  // "New York": ["Bronx", "Kings", "New York", "Queens", "Richmond"]
};
