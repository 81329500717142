import { Skeleton } from 'antd'
import React, { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import ChatFeedback from './ChatFeedback'
import './MessageList.css'

export function Message({ text, sender, isShareChat = false }) {
    return (
        <div
            className={`${sender}-message`}
            style={{
                backgroundColor:
                    sender === 'user' && isShareChat ? '#f6f6f6' : '',
            }}
        >
            {sender === 'bot' && (
                <img
                    src="/images/chat-bot-avatar.png"
                    alt="Bot Avatar"
                    className="avatar"
                />
            )}
            <div
                className={`text-bubble ${
                    text === '...' ? 'typing-indicator' : ''
                }`}
            >
                {text === '...' ? (
                    <>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                    </>
                ) : (
                    <div>{text}</div>
                )}
            </div>
        </div>
    )
}

function MessageList({
    messages,
    processMoreDetails,
    threadID,
    isBotThinking,
    isBotAnswering,
    currentMessageID,
    currentMessageText,
    loadingChat,
    isMobile,
    setMobileAIChat,
}) {
    const messageContainerRef = useRef(null)
    const guidelineWrapperRef = useRef(null)
    const showFeedback = useMemo(
        () => currentMessageID && !isBotThinking,
        [currentMessageID, isBotThinking]
    )

    const showPdf = useSelector((state) => state.common.showPdf)

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop =
                messageContainerRef.current.scrollHeight
        }
    }

    useEffect(() => {
        // Scroll to bottom when the bot is not answering
        if (!isBotAnswering) {
            scrollToBottom()
        }
    }, [messages, isBotAnswering])

    useEffect(() => {
        const updateGuidelinePosition = () => {
            const chatInput = document.querySelector('.chat-input-container')
            const messageList = messageContainerRef.current
            if (guidelineWrapperRef.current && chatInput && messageList) {
                const inputHeight = chatInput.offsetHeight
                const guidelineHeight = guidelineWrapperRef.current.offsetHeight
                guidelineWrapperRef.current.style.bottom = `${
                    inputHeight + 8
                }px`
                messageList.style.paddingBottom = `${guidelineHeight}px`
            }
        }

        if (isMobile) {
            updateGuidelinePosition()
            // Observe the chat input container for height changes
            const resizeObserver = new ResizeObserver(updateGuidelinePosition)
            const chatInput = document.querySelector('.chat-input-container')
            if (chatInput) {
                resizeObserver.observe(chatInput)
            }

            return () => {
                if (chatInput) {
                    resizeObserver.unobserve(chatInput)
                }
                if (messageContainerRef.current) {
                    messageContainerRef.current.style.paddingBottom = ''
                }
            }
        }
    }, [isMobile])

    const handleFeedbackChange = () => {
        // Use setTimeout to ensure scrolling after DOM update
        setTimeout(scrollToBottom, 100)
    }

    if (loadingChat) {
        return (
            <div className="message-list pt-3">
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </div>
        )
    }

    return (
        <>
            <div className="message-list" ref={messageContainerRef}>
                {messages.map(({ text, sender }, index) => (
                    <Message key={index} text={text} sender={sender} />
                ))}
                {isBotThinking && <Message text="..." sender="bot" />}
                {showFeedback && (
                    <ChatFeedback
                        threadID={threadID}
                        processMoreDetails={processMoreDetails}
                        chatAnswer={currentMessageText}
                        messageID={currentMessageID}
                        onFeedbackChange={handleFeedbackChange}
                    />
                )}
            </div>
            {isMobile && (
                <div
                    className="floating-button-wrapper"
                    ref={guidelineWrapperRef}
                >
                    <div
                        className="floating-guideline-button"
                        onClick={() => setMobileAIChat(false)}
                    >
                        <img src="/images/guideline.svg" alt="Guideline" />
                        <span>Guideline</span>
                    </div>
                </div>
            )}
        </>
    )
}

export default MessageList
