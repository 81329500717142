import { CloseOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Skeleton } from 'antd'
import './ChatHistory.css'

export default React.forwardRef(function ChatHistory(props, ref) {
    const {
        getHistoryList,
        showHistory,
        setShowHistory,
        getList,
        setDocMessages,
        setChatTitle,
        threadID,
        setDocThreadID,
        setNewfiThreadID,
        setChatThreadID,
        setCurrentMessageID,
        greetingMessage,
        isMobile,
    } = props

    const [loadingChatList, setLoadingChatList] = useState(true)
    const [chatList, setChatList] = useState([])
    const [currentThreadID, setCurrentThreadId] = useState(threadID)

    const dispatch = useDispatch()

    React.useImperativeHandle(ref, () => ({
        refreshChatList: getChatList,
        //Expose chatList to the Chat() function and implement the first use of GuidelineGPT's logEvent
    }))

    const getChatList = useCallback(async () => {
        setLoadingChatList(true)
        const token = sessionStorage.getItem('ZeitroA')
        try {
            const response = await fetch('/los/mortgagegpt/chatlist', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (!response.ok) {
                setLoadingChatList(false)
                throw new Error(`HTTP error! Status: ${response.status}`)
            }

            const data = await response.json()
            setLoadingChatList(false)
            setChatList(data.chat_list)
            getList(data.chat_list)
        } catch (error) {
            setLoadingChatList(false)
            console.error('Fetch Error:', error)
        }
    }, [])

    useEffect(() => getChatList(), [])

    const groupedData = useMemo(() => {
        return chatList.reduce((acc, item) => {
            const date = new Date(item[1])
            let group
            if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
                group = 'Today'
            } else if (date > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) {
                group = 'Previous 7 days'
            } else {
                group = 'Past 30 days'
            }
            if (!acc[group]) {
                acc[group] = []
            }
            acc[group].push({
                threadId: item[0],
                date: date.toLocaleDateString(),
                titleFallback: item[2],
                title: item[2],
                type: item[4] === '' ? 'FannieMae' : item[4],
            })
            return acc
        }, {})
    }, [chatList])

    const entries = useMemo(() => Object.entries(groupedData), [groupedData])

    const handleClick = (threadId, type) => {
        setCurrentThreadId(threadId)
        getHistoryList(threadId, type)
        setShowHistory(false)
    }

    return (
        <div className="chat-history">
            {loadingChatList ? (
                <div style={{ padding: '28px 16px' }}>
                    <Skeleton active paragraph={{ rows: 10 }} />
                </div>
            ) : (
                <>
                    {/* Header */}
                    {isMobile ? (
                        <div className="chat-history-header">
                            <div className="history-title">Chat history</div>
                            <CloseOutlined
                                style={{
                                    fontSize: '15.56px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setShowHistory(!showHistory)
                                }}
                            />
                        </div>
                    ) : (
                        <div className="chat-history-header">
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setShowHistory(!showHistory)
                                }}
                            >
                                <img src="/images/close.svg"></img>
                            </div>
                            <Button
                                className="new-chat-button"
                                onClick={() => {
                                    dispatch({ type: 'SHOW_PDF', data: true })
                                    dispatch({
                                        type: 'CHANGE_PDF',
                                        data: undefined,
                                    })
                                    setDocMessages([greetingMessage])
                                    setChatTitle('')
                                    setDocThreadID('')
                                    setNewfiThreadID('')
                                    setChatThreadID('')
                                    setCurrentMessageID('')
                                }}
                                type="outline"
                            >
                                New chat
                            </Button>
                        </div>
                    )}

                    {/* History list */}
                    {chatList.length === 0 ? (
                        <div className="no-chat-text">
                            You don't have any chats yet. Select a guideline to
                            start your conversation.
                        </div>
                    ) : (
                        <div className="text-left chat-history-content">
                            {entries.map(([group, items], groupIndex) => (
                                <div
                                    key={group}
                                    style={{
                                        marginBottom:
                                            groupIndex < entries.length - 1
                                                ? '30px'
                                                : '0',
                                    }}
                                >
                                    <div className="group-title">{group}</div>
                                    {items.map((item, index) => (
                                        <div
                                            key={index}
                                            className={
                                                item.threadId ===
                                                currentThreadID
                                                    ? 'chat-history-current'
                                                    : 'chat-history-title'
                                            }
                                            onClick={() =>
                                                handleClick(
                                                    item.threadId,
                                                    item.type
                                                )
                                            }
                                        >
                                            <div className="history-item-title">
                                                {item.title}
                                            </div>
                                            <div className="history-item-type">
                                                <img
                                                    src="/images/pdfType.svg"
                                                    alt="PDF"
                                                    style={{
                                                        marginRight: '5px',
                                                    }}
                                                />
                                                {item.type}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    )
})
