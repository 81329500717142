import React, { useCallback, useState, useEffect } from 'react'
import { Checkbox, Button, Tooltip, textarea, Spin } from 'antd'
import {
    LikeOutlined,
    DislikeOutlined,
    CopyOutlined,
    CloseOutlined,
} from '@ant-design/icons'
import { useStatsigClient } from '@statsig/react-bindings/src'
import { CustomMessage } from './AlertToast'
import './ChatFeedback.css'

export default function ChatFeedback({
    threadID,
    processMoreDetails,
    chatAnswer,
    messageID,
    onFeedbackChange,
}) {
    const [isLike, setIsLike] = useState(false)
    const [isDislike, setIsDislike] = useState(false)
    const [showYesButton, setShowYesButton] = useState(true)
    const [showNoButton, setShowNoButton] = useState(true)
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const [showFeedback, setShowFeedback] = useState(false)
    const [loadingMoreDetails, setLoadingMoreDetails] = useState(false)
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false)
    const [isNotHelpful, setIsNotHelpful] = useState(false)
    const [isNotTrue, setIsNotTrue] = useState(false)
    const { client } = useStatsigClient()

    useEffect(() => {
        if (showFeedbackMessage) {
            scrollToBottom()
        }
    }, [showFeedbackMessage])

    const scrollToBottom = useCallback(() => {
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            })
        }, 100)
    }, [])

    const handleCopyClick = useCallback(() => {
        navigator.clipboard
            .writeText(chatAnswer)
            .then(() => {
                CustomMessage.success('Answer copied to clipboard')
            })
            .catch((err) => {
                console.error('Copy text to clipboard failed: ', err)
            })
    }, [chatAnswer])

    const handleLikeClick = useCallback(() => {
        setIsLike(true)
        setIsDislike(false)
        setShowNoButton(false)
        sendFeedback(true, false, '')
        setShowFeedbackMessage(true)
        onFeedbackChange()
    }, [onFeedbackChange])

    const handleDislikeClick = useCallback(() => {
        setIsLike(false)
        setIsDislike(false)
        setShowFeedback(true)
        setShowFeedbackMessage(false)
        onFeedbackChange()
    }, [onFeedbackChange])

    const clearFeedback = useCallback(() => {
        setIsLike(false)
        setIsDislike(false)
        setFeedbackMessage('')
        setIsNotHelpful(false)
        setIsNotTrue(false)
        setShowFeedback(false)
        setLoadingMoreDetails(false)
        setShowYesButton(true)
        setShowNoButton(true)
    }, [])

    const sendFeedback = useCallback(async (isLike, isDislike, feedbackMessage) => {
        const body = {
            MessageID: messageID,
            Like: isLike,
            Dislike: isDislike,
            Feedback: feedbackMessage,
        }
        const token = sessionStorage.getItem('ZeitroA')
        try {
            const response = await fetch('/los/mortgagegpt/recordinteraction', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })
            if (response.status !== 200) {
                console.log(
                    'Looks like there was a problem. Status Code: ' +
                        response.status
                )
                clearFeedback()
                return
            }
            const js = await response.json()
            setShowFeedbackMessage(true)
            //statsig logevent
            const savedUserInfo = JSON.parse(
                sessionStorage.getItem('statsig_user_info')
            )
            client.logEvent(
                'guidelineGPT_feedback',
                savedUserInfo.custom.customerid,
                {
                    item_name: 'guidelineGPTFeedback',
                    startTime: new Date(),
                    timestamp: Date.now(),
                    type: isLike ? 'thumb up' : 'thumb down',
                    feedbackMessage: feedbackMessage,
                    customerid: savedUserInfo.custom.customerid,
                    email: savedUserInfo.email,
                }
            )
        } catch (err) {
            console.log('Fetch Error :-S', err)
            clearFeedback()
        }
    }, [client])

    const getMoreDetails = useCallback(async () => {
        const body = {
            ThreadID: threadID,
            MsgID: messageID,
        }
        setLoadingMoreDetails(true)
        const token = sessionStorage.getItem('ZeitroA')
        try {
            const response = await fetch('/los/mortgagegpt/moredetail', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.log(
                    'Looks like there was a problem. Status Code: ' +
                        response.status
                )
                setLoadingMoreDetails(false)
                return
            }

            const js = await response.json()
            processMoreDetails(js)
            setLoadingMoreDetails(false)
        } catch (err) {
            console.log('Fetch Error :-S', err)
            clearFeedback()
        } finally {
            setLoadingMoreDetails(false)
        }
    }, [processMoreDetails])

    const handleClose = useCallback(() => {
        setShowFeedback(false)
        sendFeedback(false, true, '')
    }, [sendFeedback])

    const handleSubmitFeedback = useCallback(() => {
        let msg = feedbackMessage.trim()
        if (isNotHelpful) {
            msg += "\nThis isn't helpful"
        }
        if (isNotTrue) {
            msg += "\nThis isn't true"
        }
        sendFeedback(false, true, msg)
        setShowYesButton(false)
        setIsDislike(true)
        onFeedbackChange()
    }, [sendFeedback, onFeedbackChange])

    return (
        <div className="text-left" style={{ marginTop: '-10px' }}>
            <div className="d-flex align-items-center">
                <div className="chat-feedback">
                    <Tooltip
                        title="Copy"
                        placement="top"
                        overlayClassName="page-reference-tooltip"
                    >
                        <div
                            className="chat-feedback-button"
                            onClick={() => handleCopyClick()}
                        >
                            <img src="/copy.svg" alt="Copy" />
                        </div>
                    </Tooltip>
                    <div style={{ width: '1px', marginTop: '5px', marginBottom: '5px', backgroundColor: '#ECECEC' }}></div>
                    {showYesButton && (
                        <div
                            className={`chat-feedback-button ${
                                isLike ? 'active' : ''
                            }`}
                            onClick={handleLikeClick}
                        >
                            <img src="/yes.svg" alt="Yes" />
                        </div>
                    )}
                    {showNoButton && (
                        <div
                            className={`chat-feedback-button ${
                                isDislike ? 'active' : ''
                            }`}
                            onClick={handleDislikeClick}
                        >
                            <img src="/no.svg" alt="No" />
                        </div>
                    )}
                </div>
            </div>
            {showFeedbackMessage && (
                <div className="chat-feedback-content">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <img src="/images/face.svg" alt="Feedback face" />{' '}
                            Thank you for your feedback.
                        </div>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowFeedbackMessage(false)}
                        >
                            <CloseOutlined />
                        </div>
                    </div>
                    <div
                        style={{
                            paddingLeft: 24,
                            fontWeight: 400,
                            marginTop: 5,
                        }}
                    >
                        We value your input and will use it to continually
                        improve Mortgage GPT.
                    </div>
                </div>
            )}
            {showFeedback && (
                <div className="chat-feedback-input-wrap">
                    <div className="d-flex justify-content-between">
                        <div className="bold">Help us to improve it</div>
                        <CloseOutlined
                            className="rowLink"
                            onClick={handleClose}
                        />
                    </div>
                    <div className="mt-2">
                        <Checkbox
                            onChange={(e) => setIsNotHelpful(e.target.checked)}
                        >
                            <div style={{ fontSize: 14 }}>
                                This isn't helpful
                            </div>
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => setIsNotTrue(e.target.checked)}
                        >
                            <div style={{ fontSize: 14 }}>This isn't true</div>
                        </Checkbox>
                    </div>
                    <div>
                        <textarea
                            className="chat-feedback-input"
                            onChange={(e) => setFeedbackMessage(e.target.value)}
                            placeholder="What is the issue with the response? How could it be improved?"
                        />
                    </div>
                    <div className="text-right">
                        <Button type="outline" onClick={handleSubmitFeedback}>
                            Submit feedback
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}
