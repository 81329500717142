import * as def from '../Defs/subscriptiondefs'

export const updateGuidelineGPTUsage = (payload) => ({
    type: def.UPDATE_GUIDELINE_GPT_USAGE,
    payload: payload,
  });

export const updateMISMOUsage = (payload) => ({
    type: def.UPDATE_MISMO_USAGE,
    payload: payload,
  });