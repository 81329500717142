import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import smartlookClient from 'smartlook-client'
import './ChatInput.css'
import { PROMPT_EXAMPLES } from './constants'

function ChatInput({
    onSendMessage,
    style,
    isBotThinking,
    isReceivingAnswer,
    docName,
    fromLanding,
    questionCount,
    threadID,
}) {
    const [inputValue, setInputValue] = useState('')
    const [showPromptText, setShowPromptText] = useState(false)
    const [currentPromptIndex, setCurrentPromptIndex] = useState(0)
    const textareaRef = useRef(null)
    const timerRef = useRef(null)
    const blurTimerRef = useRef(null)

    const pdfType = useSelector((state) => state.common.content)
    const prompts = useMemo(() => PROMPT_EXAMPLES[pdfType] || [], [pdfType])

    const isButtonActive = useMemo(() => {
        return inputValue.trim() && !isReceivingAnswer
    }, [inputValue, isReceivingAnswer])

    // useEffect(() => {
    //     textareaRef.current.focus()
    // }, [])

    useEffect(() => {
        adjustTextareaHeight()
    }, [inputValue])

    useEffect(() => {
        // Clear existing timers when prompts or thread changes
        if (timerRef.current) {
            clearInterval(timerRef.current)
            timerRef.current = null
        }

        // Reset state when prompts or thread changes
        setShowPromptText(false)
        setCurrentPromptIndex(0)

        // Start initial blur timer only if prompts exist
        if (!isEmpty(prompts)) {
            if (blurTimerRef.current) {
                clearTimeout(blurTimerRef.current)
            }
            blurTimerRef.current = setTimeout(() => {
                // Only show prompt if textarea is not focused
                if (document.activeElement !== textareaRef.current) {
                    setShowPromptText(true)
                }
            }, 5000)
        }

        return () => {
            if (blurTimerRef.current) {
                clearTimeout(blurTimerRef.current)
            }
        }
    }, [prompts, threadID])

    useEffect(() => {
        if (isEmpty(prompts)) {
            return
        }

        if (showPromptText) {
            timerRef.current = setInterval(() => {
                setCurrentPromptIndex(
                    (prevIndex) => (prevIndex + 1) % prompts.length
                )
            }, 5000)
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [prompts, showPromptText])

    // Handle textarea focus
    const handleTextareaFocus = useCallback(() => {
        setShowPromptText(false)
        if (timerRef.current) {
            clearInterval(timerRef.current)
        }
        if (blurTimerRef.current) {
            clearTimeout(blurTimerRef.current)
        }
        smartlookClient.track('guidelineGPT_Typing', {
            label: 'Chat typing',
            category: 'Focus',
        })
    }, [])

    // Handle textarea blur
    const handleTextareaBlur = useCallback(() => {
        if (isEmpty(prompts)) return

        // Clear any existing blur timer
        if (blurTimerRef.current) {
            clearTimeout(blurTimerRef.current)
        }

        // Set a new timer for 5 seconds after blur
        blurTimerRef.current = setTimeout(() => {
            setShowPromptText(true)
        }, 5000)
    }, [prompts])

    // Cleanup timers on unmount
    useEffect(() => {
        return () => {
            if (blurTimerRef.current) {
                clearTimeout(blurTimerRef.current)
            }
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [])

    const adjustTextareaHeight = useCallback(() => {
        const textarea = textareaRef.current
        if (textarea) {
            textarea.style.height = 'auto'
            const scrollHeight = textarea.scrollHeight
            const maxHeight = 8 * 26

            // When the content exceeds 7 lines, add the scrollable class
            if (scrollHeight <= maxHeight) {
                textarea.style.height = `${scrollHeight}px`
                textarea.classList.remove('scrollable')
            } else {
                textarea.style.height = `${maxHeight}px`
                textarea.classList.add('scrollable')
            }
        }
    }, [])

    const handleSend = useCallback(() => {
        if (inputValue.trim() && !isReceivingAnswer) {
            smartlookClient.track('guidelineGPT_GeneralInteraction', {
                label: 'GPT actions',
                category: 'Clicks',
            })
            onSendMessage(inputValue.trim(), docName)
            setInputValue('')
            textareaRef.current.style.height = 'auto'
        }
    }, [inputValue, isReceivingAnswer, onSendMessage, docName])

    const handleKeyPress = useCallback((e) => {
        if (e.key !== 'Enter') return

        e.preventDefault()

        if (isReceivingAnswer) return

        if (!e.shiftKey) {
            handleSend()
        }
    }, [isReceivingAnswer, handleSend])

    const handleInputChange = useCallback((e) => {
        setInputValue(e.target.value)
        adjustTextareaHeight()
    }, [adjustTextareaHeight])

    const handleQuestionClick = useCallback((question) => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
            timerRef.current = null
        }
        setShowPromptText(false)
        setInputValue(question)
        textareaRef.current.focus()
        adjustTextareaHeight()
    }, [adjustTextareaHeight])

    return (
        <div className="chat-input" style={style}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    {showPromptText && !isEmpty(prompts) && (
                        <div className="prompt-text-wrapper">
                            You may ask: "
                            <span
                                className="prompt-question"
                                onClick={() => handleQuestionClick(prompts[currentPromptIndex]?.question)}
                            >
                                {prompts[currentPromptIndex]?.question}
                            </span>
                            "
                        </div>
                    )}
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-end',
                            position: 'relative',
                        }}
                    >
                        <textarea
                            ref={textareaRef}
                            className={`chat-textarea ${
                                showPromptText ? 'has-prompt' : ''
                            }`}
                            value={inputValue}
                            onChange={handleInputChange}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                            onKeyPress={handleKeyPress}
                            placeholder={showPromptText ? '' : 'Ask AI...'}
                            rows={1}
                        />
                        <div
                            className={`send-button ${
                                isButtonActive ? 'active' : ''
                            }`}
                            onClick={handleSend}
                            style={{
                                pointerEvents:
                                    isBotThinking || isReceivingAnswer
                                        ? 'none'
                                        : 'auto',
                            }}
                        >
                            <img
                                src={
                                    isButtonActive
                                        ? '/images/sendBlue.svg'
                                        : '/images/send.svg'
                                }
                                alt="Send"
                            />
                        </div>
                    </div>
                </div>
                <div className="gpt-limit-reached-text">
                    <span>AI may make mistakes. Check responses.</span>
                    {fromLanding && (
                        <>
                            <span>
                                • {5 - questionCount} questions remaining today
                            </span>
                            <span>
                                • © {new Date().getFullYear()} Zeitro, Inc.
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChatInput
