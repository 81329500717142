import React, { useState, useRef, useEffect } from 'react';
import { Spin } from 'antd';
import {Row, Col} from "react-bootstrap";
import {Form} from 'react-bootstrap';
import * as com from "../Common";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import "./CensusChecker.css";

const CensusChecker = (props) => {
    const [propertyAddress, setPropertyAddress] = useState("");
    const [displayedAddress, setDisplayedAddress] = useState("");
    const [propertyState, setPropertyState] = useState("");
    const [propertyCounty, setPropertyCounty] = useState("");
    const [propertyCity, setPropertyCity] = useState("");
    const [propertyZip, setPropertyZip] = useState("");
    const [units, setUnits] = useState("1");
    const [loanLimits, setLoanLimits] = useState({})
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loanLimit, setLoanLimit] = useState({});
    const [medianIncome, setMedianIncome] = useState(0);
    const [incomeIndicator, setIncomeIndicator] = useState(0);
    const [showPropertyInfo, setShowPropertyInfo] = useState(false)
    const [mfi, setMfi] = useState(0)
    const [isHighCostArea, setIsHighCostArea] = useState(false);
    const [minority, setMinority] = useState(0);
    const [loadingTractData, setLoadingTractData] = useState(false);

  const fetchLoanLimits = () =>
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res)
      })
      .catch((err) => {
        console.error(err);
      });

  useEffect(() => {
    fetchLoanLimits()
  }, [])

  useEffect(() => {
    if (propertyState !== "" && propertyCounty !== "") {
      setLoadingTractData(true)
      fetch("/data/loanlimitswithincome", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Address: propertyAddress,
          State: propertyState,
          City: propertyCity,
          Zip: propertyZip,
        })
      })
        .then((res) => {
          if (!res.ok) {
            console.log(res);
            setShowError(true)
            return
          }
          res.json().then((js) => {
            let conformingLoanLimit = [806500, 1032650, 1248150, 1551250]
            let highCost = false
            let ll = ""
            switch (units) {
              case "1":
                ll = js.OneUnit
                setLoanLimit(js.OneUnit)
                if (js.OneUnit > conformingLoanLimit[0]) {
                  highCost = true
                  setIsHighCostArea(true)
                  break
                }
              case "2":
                ll = js.TwoUnit
                setLoanLimit(js.TwoUnit)
                if (js.TwoUnit > conformingLoanLimit[1]) {
                  highCost = true
                  setIsHighCostArea(true)
                  break
                }
              case "3":
                ll = js.ThreeUnit
                setLoanLimit(js.ThreeUnit)
                if (js.ThreeUnit > conformingLoanLimit[2]) {
                  highCost = true
                  setIsHighCostArea(true)
                  break
                }
              case "4":
                ll = js.FourUnit
                setLoanLimit(js.FourUnit)
                if (js.FourUnit > conformingLoanLimit[3]) {
                  highCost = true
                  setIsHighCostArea(true)
                  break
                }
              default:
                break
            }
            setMedianIncome(js.MedianIncome)
            setIncomeIndicator(js.IncomeIndicator)
            setMinority(js.MinorityPercent)
            setLoadingTractData(false)
        })
    })
    .catch((err) => {
        console.error(err);
        alert("Some internal error happened. Please try again later.")
    });
    }

  },[propertyState,propertyCounty,propertyAddress])


  useEffect(() => {
    if (propertyState !== "" && propertyCounty !== "") {
        fetch("/data/gethudmedianfamilyincome", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              State: propertyState,
              County: propertyCounty,
            })
          })
            .then((res) => {
              if (!res.ok) {
                console.log(res);
                setLoading(false)
                //comment this set after testing
                setMfi(181300)
                // alert("Server currently cannot get MFI data. Please try again later.")
                return
              }
              res.json().then((js) => {
                setMfi(js.MedianIncome)
              });
            })
            .catch((err) => {
              console.error(err);
              alert("Some internal error happened. Please try again later.")
            });
    }   
  },[propertyState,propertyCounty,propertyAddress])


  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );

    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    console.log(longstate)
    setPropertyState(longstate)

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
    setShowPropertyInfo(true)
  };
  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);
    options.push(<option value="">...</option>);
    keys.forEach((x) => {
      options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];
    options.push(<option value="">...</option>);
    try {
      if (loanLimits[propertyState.toUpperCase()]) {
        let keys = Object.keys(loanLimits[propertyState.toUpperCase()]);
        keys.forEach((x) => {
          options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>);
        });
      }
    } catch (error) {
      console.log(error)
    }

    return options;
  };



  return (
    <div className='CheckerWrap'>
        <div className='CheckerHeader'>
            <div className='CheckerTitle'>Census data checker</div>
            <div className='CheckerSub'>Instant tract info check for your target property</div>
        </div>
        <div className='CheckerPane'>
            <div className='CheckerContent'>
                <Row>
                    <Col md={12} xs={12} >
                        <AddressAutocomplete
                            id='fastsearch-address'
                            label="Property address"
                            required={true}
                            name="addessline"
                            defaultValue={displayedAddress}
                            onChange={(e) => setDisplayedAddress(e.target.value)}
                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                            placeholder="Please type in your home address and select from the dropdown addresses"
                            goodfeedback="Looks good!"
                            badfeedback="Please provide your street address."
                            onCompletion={e => onStreetAddressCompletion(e)}
                            size="md"
                        />
                    </Col>  
                                
                </Row>

                <div className='ml-3' style={{marginBottom:"30px", marginTop:"20px", fontSize:"12px", fontWeight:"500"}}>
                    <div>Looking for listing property address to check? Visit  <span onClick={() => window.open("https://www.mls.com","_blank")} style={{color:"#325CEB", textDecoration:"underline"}}>MLS Listing</span> website to find a target property address.</div>
                </div>
            
                <div style={{display:"flex", justifyContent:"center"}}>
                    <div className='CheckerInfoPreview'>
                        {!loadingTractData ?
                            <div>
                                <div className='CheckerInfoWrap'>
                                    <div>Tract Income Level:</div> 
                                    {showPropertyInfo ?
                                        <div>
                                            {incomeIndicator === 1 && <div>Low Income Area</div>}
                                            {incomeIndicator === 2 &&<div>Moderate Income Area</div>}
                                            {incomeIndicator === 3 &&<div>Middle Income Area</div>}
                                            {incomeIndicator === 4 &&<div>Upper Income Area</div>}
                                        </div>
                                        :
                                        <div>---</div>
                                    }
                                </div>
                                <div className='CheckerInfoWrap'>
                                    <div>Tract Minority:</div>
                                    <div>{showPropertyInfo? `${Math.floor(minority)}%` : "---"}</div>
                                </div>
                                <div className='CheckerInfoWrap'>
                                    <div>80% AMI:</div>
                                    <div>{showPropertyInfo? `$${com.commaize(0.8* medianIncome)}` : "---"}</div>
                                </div>
                                <div className='CheckerInfoWrap'>
                                    <div>100% AMI:</div>
                                    <div>{showPropertyInfo? `$${com.commaize(medianIncome)}` : "---"}</div>
                                </div>
                                <div className='CheckerInfoWrap'>
                                    <div>150% AMI:</div>
                                    <div>{showPropertyInfo? `$${com.commaize(1.5* medianIncome)}` : "---"}</div>
                                </div>
                                <div className='CheckerInfoWrap'>
                                    <div>80% MFI:</div>
                                    <div>{showPropertyInfo? `$${com.commaize(0.8* mfi)}` : "---"}</div>
                                </div>
                                <div className='CheckerInfoWrap'>
                                    <div>100% MFI:</div>
                                    <div>{showPropertyInfo? `$${com.commaize(mfi)}` : "---"}</div>
                                </div>
                                <div className='CheckerInfoWrap'>
                                    <div>150% MFI:</div>
                                    <div>{showPropertyInfo? `$${com.commaize(1.5* mfi)}` : "---"}</div>
                                </div>
                            </div>
                            :
                            <div className='SpinnerWrap'>
                            <Spin />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default CensusChecker;
